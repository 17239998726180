
import { Avatar, Button, DatePicker, Progress, Select, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import range from 'lodash/range';
import split from 'lodash/split';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUsers, toggleUsersLoading } from '../../store/actions/teamManagement';
import { CaseType, MOMENT_CCNT_DAY_FORMAT, MOMENT_CCNT_MONTH_FORMAT, MOMENT_FORMAT_DISPLAY_DATE } from '../../utils/constants';
import Network from '../../utils/network';
import { FullUserProps, withFullName } from '../../utils/objects/withFullName';
import { RouterProps, User, UserJobTMP } from '../../utils/types/generalTypes';
import { MonthlyHoursOfTheYear, MonthlyHoursOfTheYearArray } from '../../utils/types/planningTypes';
import { CcntType, CctSecurityType, DayType, GroupByDayMonthlyReportType, MonthlyReportEvent, MonthlyReportType } from '../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, convertNetworkMonthlyReportToMonthlyReport, downloadBlob, getCaseAndPlural, pickBestTextColor } from '../../utils/utils';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import Card from '../common/general/card';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, FullUserProps {
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
}

interface State {
    startMonth: Moment;
    endMonth: Moment;
    selectedUsers: number[];
    isLoading: number | undefined; //the id of the concerned user, or -1 if all
    usersToDisplay?: User[];
    searchedUsers?: User[];
    filteredGroups?: User[];
    selectGroups?: number[];
    users?: User[];
    selectedJob?: { userId: number, jobId: number | undefined; }[];
    isCcntVisible: boolean;
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
    monthlyReport?: MonthlyReportType;
    monthlyHours?: MonthlyHoursOfTheYearArray;
    compressionProgression: number;
    canChangeGroup: boolean;
    modalDownloadMultipleVisible: boolean;
    manualForceRefreshTable: number;
}

/**
 * Component that represent the report tab in the report page
 */
class ReportTabv2 extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            startMonth: moment().startOf("month"),
            endMonth: moment().endOf("month"),
            selectedUsers: [],
            isLoading: undefined,
            usersToDisplay: undefined,
            searchedUsers: undefined,
            filteredGroups: undefined,
            isCcntVisible: false,
            compressionProgression: 0.0,
            canChangeGroup: true,
            modalDownloadMultipleVisible: false,
            manualForceRefreshTable: 0
        };
    }



    //function to groupe ccnt events by date / day
    groupBy = (x: MonthlyReportEvent[], f: any) => {
        return x.reduce((a: GroupByDayMonthlyReportType[], b: MonthlyReportEvent) => {
            const found = a.find(t => t.date === f(b));
            if (found) {
                found.events.push(b);
            } else {
                a.push({
                    date: f(b),
                    events: [b]
                });
            }
            return a;
        }, []);
    };


    componentDidMount() {
        // get all users
        this.props.toggleUsersLoading!(true);
        Network.getAllUsers().then(
            response => this.props.changeUsers!(response),
            () => alert("Un problème est survenu pendant le chargement des utilisateurs", "warning"),
        );

        Network.getMonthlyHours(this.state.startMonth.year()).then(
            (response: MonthlyHoursOfTheYear) => {
                const monthlyHours: MonthlyHoursOfTheYearArray = {
                    id: response.id,
                    year: response.year,
                    hours: [
                        response.janHours ? response.janHours : 0,
                        response.febHours ? response.febHours : 0,
                        response.marHours ? response.marHours : 0,
                        response.aprHours ? response.aprHours : 0,
                        response.mayHours ? response.mayHours : 0,
                        response.junHours ? response.junHours : 0,
                        response.julHours ? response.julHours : 0,
                        response.augHours ? response.augHours : 0,
                        response.sepHours ? response.sepHours : 0,
                        response.octHours ? response.octHours : 0,
                        response.novHours ? response.novHours : 0,
                        response.decHours ? response.decHours : 0,
                    ]
                };
                this.setState({ monthlyHours: monthlyHours });
            },
            (error) => {
                if (error.message.includes("No monthly hours")) {
                    this.setState({ monthlyHours: undefined });
                } else {
                    alert("Un problème est survenu pendant le chargement des heures par année", "error");
                }
            },
        );

        if (this.props.currentUser && this.props.currentUser.groupsAdmin && this.props.currentUser.groupsAdmin.length > 0) {
            this.changeGroup(this.props.currentUser.groupsAdmin.map(ga => ga.group_id));
            this.setState({ canChangeGroup: false });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { users } = this.state;
        if (this.props.currentUser && this.props.currentUser.groupsAdmin && this.props.currentUser.groupsAdmin.length > 0 && (!isEqual(prevProps.currentUser, this.props.currentUser) || !isEqual(prevProps.users, this.props.users))) {
            this.changeGroup(this.props.currentUser.groupsAdmin.map(ga => ga.group_id));
            this.setState({ canChangeGroup: false });
        }
        if (this.props.users && this.props.users.length > 0 && (!users || users.length === 0)) {
            // make a safely copy (deeply copy, not by reference)
            const users = (JSON.parse(JSON.stringify(this.props.users)) as User[]).filter(u => u.visible !== false);
            const selectedJob: { userId: number, jobId: number | undefined; }[] = [];
            users?.forEach((u: User) => {
                if (u.job && u.job.length > 0) {
                    u.job = this.contractsAvailableBetweenMonths(u.job);
                    selectedJob.push({
                        userId: u.id,
                        jobId: u.job[0]?.id
                    });
                }
            });

            this.setState({ users: users, selectedJob });
        }

        if (prevState.startMonth !== this.state.startMonth || prevState.endMonth !== this.state.endMonth) {
            const users2 = (JSON.parse(JSON.stringify(this.props.users)) as User[]).filter(u => u.visible !== false);

            users2?.forEach((u: User) => {
                u.job && (u.job = this.contractsAvailableBetweenMonths(u.job));
            });

            this.setState({ users: users2 });
        }
    }

    columns: ColumnsType<User> = [
        {
            className: "__report-userslist-image",
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            fixed: true,
            render: (link: string) => (
                <div className="both-center">
                    <Avatar size="large" src={link} icon={<FAIcon prefix='fad' name='user' />} style={{ backgroundColor: 'var(--primary-color)' }} />
                </div>
            ),
            width: '80px',
        },
        {
            title: 'Nom',
            dataIndex: 'last_name',
            key: 'last_name',
            fixed: true,
            sorter: (a: User, b: User) => a.last_name.localeCompare(b.last_name),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Prénom',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
        },
        {
            title: 'Groupes',
            key: 'group_users',
            render: (record: User) => {
                if (!record || !record.group_users) {
                    return "";
                }
                const groups = record.group_users.sort((a, b) => (a.group_name && b.group_name) ? a.group_name.localeCompare(b.group_name) : 0).map(gu => gu);

                return (
                    <>
                        {groups.map(group => (
                            <Tag className="__report-groups-users-tags" style={group.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={group.id} onClick={() => group.id && this.changeGroup([group.group])}>
                                {group.group_name}
                            </Tag>
                        ))}
                    </>
                );
            }
        },
        {
            title: 'Contrats disponibles',
            key: 'group_users',
            render: (record: User) => {
                const { Option } = Select;
                if (!record || !record.job || record.job.length === 0) {
                    return <span><FormattedMessage defaultMessage={'No contract corresponds to the selected months'} /></span>;
                }
                const { selectedJob } = this.state;
                const selectValue = selectedJob?.find(e => e.userId === record.id);
                return (
                    (record.job && record.job.length > 0) ?
                        <Select value={selectValue?.jobId} style={{ width: 300 }} onChange={(e) => {
                            selectValue && (selectValue.jobId = e);
                            this.setState({ selectedJob, manualForceRefreshTable: (this.state.manualForceRefreshTable + 1) });
                        }}>
                            {record.job.map(contract => (
                                <Option key={`contract-${contract.id}`} value={contract.id!}>{contract.name ? contract.name + ": " + contract.date_in_report + " > " + contract.contract_expiry_date : "Contrat sans nom"}</Option>
                            ))}
                        </Select>
                        :
                        <span><FormattedMessage defaultMessage={'No contract corresponds to the selected months'} /></span>
                );
            }
        },
        {
            className: "__report-userslist-download",
            title: 'Actions',
            key: 'download',
            render: (user: User) => (
                <div className="flex-center">
                    <CircleButton
                        title={"Télécharger"}
                        icon={<FAIcon prefix='fad' name='download' />}
                        onClick={() => this.generateReports(user)}
                        disabled={Boolean(this.state.isLoading)}
                        loading={this.state.isLoading === user.id} />
                </div>
            ),
            width: '150px',
        }
    ];

    /**
     * Called when a user is (un)checked
     * @param keys the new keys of checked groups
     */
    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    contractsAvailableBetweenMonths = (user_contracts: Array<UserJobTMP>) => {
        const { startMonth, endMonth } = this.state;
        const contracts = user_contracts.sort((a, b) => {
            const dateA = moment(a.date_in_report);
            const dateB = moment(b.date_in_report);
            return dateB.diff(dateA);
        });

        return contracts.filter((c) => !(endMonth.isBefore(moment(c.date_in_report), "month") || startMonth.isAfter(moment(c.contract_expiry_date), "month")));

        // return contracts.filter((c) => moment(c.date_in_report).isSameOrBefore(this.state.startMonth, 'days') && moment(c.contract_expiry_date).isSameOrAfter(this.state.endMonth, 'days'))
    };

    /**
     * Generate the reports for the selected users
     * @param user the concerned user, if one report must be generated - optional
     */
    generateReports = (user?: User) => {
        let userIds: number[] | undefined = [];

        //if a userId is specified, create an array with one id 
        if (user) {
            userIds.push(user.id);
            this.setState({ isLoading: user.id });
        }
        // else use the selected users array
        else {
            userIds = this.state.selectedUsers;
            userIds.forEach(() => {
                // Faire userContracts
            });
            this.setState({ isLoading: -1 });
        }

        // if all users are selected, unset userIds
        // if (userIds.length === this.state.users?.length) userIds = undefined;

        // create filename
        let filename = `${this.state.startMonth.format("YYYY-MM")}.zip`;
        if (user) {
            filename = `${this.state.startMonth.format("YYYY-MM")}_${user.last_name}_${user.first_name}.xlsx`.replace(" ", "-");
        } else if (userIds && userIds.length === 1) {
            const selectedUser = this.state.users?.find(u => u.id === userIds![0]);
            if (selectedUser) filename = `${this.state.startMonth.format("YYYY-MM")}_${selectedUser.last_name}_${selectedUser.first_name}.xlsx`.replace(" ", "-");
        }
        let jobIds: [number] | undefined = undefined;

        // if(userIds?.length === 1) {
        //     // const userId = user ? user.id : this.state.users?.find(u => u.id === (userIds ? userIds[0] : undefined));
        //     let userJob = this.state.selectedJob?.find(e => e.userId === (userIds ? userIds[0] : undefined));
        //     if(userJob && userJob && userJob.jobId && (jobIds = [userJob.jobId])
        // }else 

        if (userIds && userIds.length > 0) {
            userIds.forEach(u => {
                // const userId = user ? user.id : this.state.users?.find(u => u.id === (userIds ? userIds[0]));
                const userJob = this.state.selectedJob?.find(e => e.userId === u);
                if (userJob && userJob && userJob.jobId) {
                    if (jobIds && jobIds.length > 0) {
                        jobIds.push(userJob.jobId);
                    } else {
                        jobIds = [userJob.jobId];
                    }
                } else {
                    if (jobIds && jobIds.length > 0) {
                        jobIds.push(-1);
                    } else {
                        jobIds = [-1];
                    }
                }
            });
        }

        // generate reports

        Network.generateReportsOld(this.state.startMonth.format("YYYY-MM-01"), this.state.endMonth.format("YYYY-MM-01"), userIds, jobIds).then(
            (blob) => {
                downloadBlob(blob, filename);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert("Un problème est survenu pendant la création des rapports.", "warning");
                this.setState({ isLoading: undefined });
            }
        );

    };

    /**
     * Generate the company report for the selected users
     */
    generateCompanyReport = () => {
        this.setState({ isLoading: -2 });
        // generate reports
        Network.generateCompanyReport(this.state.startMonth.format("YYYY-MM-01")).then(
            blob => {
                downloadBlob(blob, `company_${this.state.startMonth.format("YYYY-MM")}.xlsx`);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert("Un problème est survenu pendant la création des rapports.", "warning");
                this.setState({ isLoading: undefined });
            }
        );
    };

    /**
     * Handle users filtering with search bar
     * @param event the triggered event
     */
    handleSearchUsers = (event: InputFieldOnChangeEvent) => {
        const { getFullName } = this.props;
        const search = event.target.value;
        if (search.length === 0) {
            this.setState({ searchedUsers: undefined, usersToDisplay: this.state.filteredGroups ? this.state.filteredGroups : undefined });
        } else {
            const searchedUsers = this.state.users?.filter(u => getFullName(u).toLowerCase().indexOf(search.toLowerCase()) >= 0);
            const usersToDisplay = this.state.filteredGroups ? this.state.filteredGroups.filter(u => getFullName(u).toLowerCase().indexOf(search.toLowerCase()) >= 0) : searchedUsers;
            this.setState({ usersToDisplay, searchedUsers });
        }
    };

    changeGroup = (values: number[]) => {
        if (values.length === 0) {
            this.setState({ selectGroups: values, filteredGroups: undefined, usersToDisplay: this.state.searchedUsers ? this.state.searchedUsers : undefined });
        } else {
            const filteredGroups = this.state.users?.filter(u => u.group_users ? u.group_users.some(gu => values.includes(gu.group)) : true);
            const usersToDisplay = this.state.searchedUsers ? this.state.searchedUsers.filter(u => u.group_users ? u.group_users.some(gu => values.includes(gu.group)) : true) : filteredGroups;
            this.setState({ selectGroups: values, usersToDisplay, filteredGroups });
        }
    };

    changeMonth = (values: any) => {
        const startMonth = (values && values.length > 0 && values[0]) ? moment(values[0]) : this.state.startMonth;
        let endMonth = (values && values.length > 1 && values[1]) ? moment(values[1]) : this.state.startMonth;
        startMonth > endMonth && (endMonth = startMonth);
        endMonth = endMonth.endOf('month');
        this.setState({ startMonth, endMonth, ccnt: undefined, isCcntVisible: false }, () => {

            Network.getMonthlyHours(this.state.startMonth.year()).then(
                (response: MonthlyHoursOfTheYear) => {
                    const monthlyHours: MonthlyHoursOfTheYearArray = {
                        id: response.id,
                        year: response.year,
                        hours: [
                            response.janHours ? response.janHours : 0,
                            response.febHours ? response.febHours : 0,
                            response.marHours ? response.marHours : 0,
                            response.aprHours ? response.aprHours : 0,
                            response.mayHours ? response.mayHours : 0,
                            response.junHours ? response.junHours : 0,
                            response.julHours ? response.julHours : 0,
                            response.augHours ? response.augHours : 0,
                            response.sepHours ? response.sepHours : 0,
                            response.octHours ? response.octHours : 0,
                            response.novHours ? response.novHours : 0,
                            response.decHours ? response.decHours : 0,
                        ]
                    };
                    this.setState({ monthlyHours: monthlyHours });
                },
                (error) => {
                    if (error.message.includes("No monthly hours")) {
                        this.setState({ monthlyHours: undefined });
                    } else {
                        alert("Un problème est survenu pendant le chargement des heures par année", "error");
                    }
                },
            );
        });
    };

    changeOnlyMonth = (values: any) => {
        const startMonth = (values && values.length > 0 && values[0]) ? moment(values[0]) : this.state.startMonth;
        this.setState({ startMonth });
    };

    generateReportView = () => {
        const { Option } = Select;
        const { RangePicker } = DatePicker;
        const { canChangeGroup, manualForceRefreshTable } = this.state;


        return (
            <>
                <div className="report-report-header">
                    <div className="report-report-month">
                        <InputField
                            onChange={this.handleSearchUsers}
                            className="report-report-month-search"
                            type="search" />
                        <RangePicker
                            picker="month"
                            className="report-report-month-range"
                            allowClear={false}
                            allowEmpty={[false, true]}
                            value={[this.state.startMonth, this.state.endMonth]}
                            onChange={(d) => this.changeMonth(d)}
                            format={'MMM YYYY'}
                        />
                        <Select
                            mode="multiple"
                            maxTagCount="responsive"
                            allowClear
                            placeholder="Groupes"
                            className="report-report-month-select"
                            onChange={this.changeGroup}
                            value={this.state.selectGroups}
                            filterOption={true}
                            optionFilterProp="label"
                            showArrow
                            disabled={!canChangeGroup}
                        >
                            {
                                this.props.groups && this.props.groups.sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0).map((g) => {
                                    return <Option label={g.name} value={g.id!} key={"groups-" + g.id}>{g.name}</Option>;
                                })
                            }
                        </Select>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {
                            this.state.selectedUsers.length > 0 &&
                            <>
                                <Button
                                    className="fade-in-animation"
                                    style={{ animationDuration: '0.5s' }}
                                    type="primary"
                                    icon={<FAIcon prefix='fad' name='download' />}
                                    onClick={() => this.generateReports()}
                                    disabled={Boolean(this.state.isLoading)}
                                    loading={this.state.isLoading === -1}>
                                    {`Télécharger ${this.state.selectedUsers.length} rapport${this.state.selectedUsers.length > 1 ? 's' : ''}`}
                                </Button>
                            </>
                        }
                        <Button
                            className='report-report-header-download-button '
                            icon={<FAIcon prefix='fad' name='download' />}
                            onClick={() => this.generateCompanyReport()}
                            disabled={Boolean(this.state.isLoading)}
                            loading={this.state.isLoading === -2}>
                            {"Rapport complet"}
                        </Button>
                    </div>
                </div>
                <div>
                    <Table
                        key={`table-manual-refresh-id${manualForceRefreshTable}-sm${this.state.startMonth.format("MMYYYY")}}-em${this.state.endMonth.format("MMYYYY")}}`}
                        className="__report-user-list"
                        loading={this.props.usersLoading}
                        dataSource={this.state.usersToDisplay?.filter(u => u.active === true && u.report === true) ?? this.state.users?.filter(u => u.active === true && u.report === true)}
                        columns={this.columns}
                        pagination={false}
                        scroll={{ x: true }}
                        rowKey={(g: any) => g.id}
                        rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }} />
                </div>
            </>
        );
    };

    //######################################### Excel report #########################################################
    /*
    Excel creation librairie documentation
    https://github.com/exceljs/exceljs#writing-xlsx
    */

    //return the number and forcing an given amount of decimals
    decimalize(numberToDecimalize: number, nbDecimals = 2) {
        return (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
    }

    downloadExcel = (rawsData: any, startMonth: string, endMonth: string) => {
        const zip = new JSZip();
        rawsData.forEach((element: any) => {
            const networkMonthlyReportTMP = element.data;
            const rawData: MonthlyReportType = convertNetworkMonthlyReportToMonthlyReport(networkMonthlyReportTMP);

            //getting all necessary information
            const firstMonth = parseInt(moment(startMonth).format("MM"));
            const lastMonth = parseInt(moment(endMonth).format("MM"));
            const targetYear = moment(startMonth).format("YYYY");

            const initialHours = rawData.balanceHours;
            const workRate: number = rawData.workRate / 100;
            const workName: string | undefined = rawData.workName;
            const workStartDate: Moment | undefined = rawData.workStartDate ? moment(rawData.workStartDate) : undefined;
            const workEndDate: Moment | undefined = rawData.workEndDate ? moment(rawData.workEndDate) : undefined;
            const normalDayLength = rawData.dailyPlannedHours; //length in decimal hours of an normal 100% day

            // let employeeStandartDay = normalDayLength * workRate; // lenght of the day for an employee after adapting to his percentage of work 

            const fullName: string = rawData.employee;
            const name: string[] = split(fullName, " ");
            const firstName: string = name[0];
            const lastName: string = name[1];

            const dayOffTypes: DayType[] = [];

            rawData.balanceOfTypeOfDaysOff.forEach(typeToAdd => {
                const newDayType: DayType = {
                    "type": typeToAdd.name,
                    "toGet": typeToAdd.balance,
                    "recieved": 0,
                    "effectiveTime": 0,
                    "isNotWorkType": true
                };
                dayOffTypes.push(newDayType);
            });


            //Creating workbook
            const workbook = new ExcelJS.Workbook();

            //setting up options
            const filename = targetYear + "-" + moment(startMonth).format("MM") + "_" + lastName + "_" + firstName + ".xlsx";

            workbook.creator = rawData.company;
            workbook.lastModifiedBy = rawData.company;
            workbook.created = new Date();
            workbook.properties.date1904 = true;
            workbook.calcProperties.fullCalcOnLoad = true;

            workbook.views = [
                {
                    x: 0, y: 0, width: 10000, height: 20000,
                    firstSheet: 0, activeTab: 0, visibility: 'visible'
                }
            ];

            let lastMonthHoursBalance = initialHours;
            let thisMonthWorkHours = 0;
            let thisMonthOvertiumeHours = 0;
            let thisMonthWorkHoursBalance = 0;

            let plannedMonthlyHours = 0;

            // for (let currentMonth = 1; currentMonth <= lastMonth; currentMonth++)
            range(1, lastMonth + 1).forEach((currentMonth) => {
                thisMonthWorkHours = 0;
                thisMonthOvertiumeHours = 0;
                thisMonthWorkHoursBalance = 0;

                if (workStartDate && currentMonth < workStartDate.month() + 1) {
                    return;
                }

                if (workEndDate && currentMonth > workEndDate.month() + 1) {
                    return;
                }

                //getting general information about the current month
                if (this.state.monthlyHours?.hours !== undefined) {
                    plannedMonthlyHours = this.state.monthlyHours.hours[currentMonth - 1];
                }

                let monthNumber = '';

                if (currentMonth >= 10) {
                    monthNumber = currentMonth.toString();
                } else {
                    monthNumber = '0' + currentMonth;
                }

                const dayInMonth = moment((targetYear + monthNumber), "YYYYMM").daysInMonth();

                //creating main sheet
                const worksheetname = moment(startMonth).format("YYYY") + "-" + monthNumber + '_' + fullName;
                let reportSheet: (ReturnType<typeof workbook.addWorksheet> | undefined) = undefined;
                if (currentMonth >= firstMonth) {
                    reportSheet = workbook.addWorksheet(worksheetname, {
                        headerFooter: { firstHeader: "Rapport_" + fullName, firstFooter: "Footer?" }
                    });
                }

                if (reportSheet !== undefined) {
                    reportSheet.pageSetup = { fitToPage: true };

                    //setting column width
                    //NOTE : Commented code is to put lines on the whole page
                    reportSheet.getColumn(1).width = 19.89;
                    reportSheet.getColumn(1).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(2).width = 19.89;
                    reportSheet.getColumn(2).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(3).width = 19.89;
                    reportSheet.getColumn(3).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(4).width = 19.89;
                    reportSheet.getColumn(4).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(5).width = 19.89;
                    reportSheet.getColumn(5).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(6).width = 19.89;
                    reportSheet.getColumn(6).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(7).width = 19.89;
                    reportSheet.getColumn(7).font = {
                        name: "Calibri"
                    };
                    reportSheet.getColumn(8).width = 19.89;
                    reportSheet.getColumn(8).font = {
                        name: "Calibri"
                    };

                    //filling Excel Sheet
                    //Top part
                    reportSheet.getCell('A1').value = 'Nom';
                    reportSheet.getCell('B1').value = lastName.toString();

                    reportSheet.getCell('A2').value = 'Prénom';
                    reportSheet.getCell('B2').value = firstName.toString();

                    if (workName) {
                        reportSheet.getCell('D1').value = 'Contrat';
                        reportSheet.getCell('E1').value = workName.toString();
                    }
                    if (workStartDate) {
                        reportSheet.getCell('D2').value = 'Données calculées du ';
                        reportSheet.getCell('E2').value = workStartDate.format(MOMENT_FORMAT_DISPLAY_DATE);
                        reportSheet.getCell('F2').value = `au ${workEndDate?.format(MOMENT_FORMAT_DISPLAY_DATE)}`;
                    }

                    reportSheet.mergeCells('A4', 'H4');
                    reportSheet.getCell('A4').value = moment(currentMonth, "MM").format('MMMM');
                    reportSheet.getCell('A4').font = { bold: true };
                    reportSheet.getCell('A4').alignment = { vertical: 'middle', horizontal: 'center' };
                    reportSheet.getCell('A4').border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' }
                    };

                    reportSheet.mergeCells('A5', 'H5');
                    reportSheet.getCell('A5').value = (workRate * 100).toString() + "%";
                    reportSheet.getCell('A5').font = { bold: true };
                    reportSheet.getCell('A5').alignment = { vertical: 'middle', horizontal: 'center' };
                    reportSheet.getCell('A5').fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'Fc7c7c7' },
                    };
                    reportSheet.getCell('A5').border = {
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };

                    reportSheet.getCell('A6').value = 'Dates';
                    reportSheet.getCell('B6').value = 'Titre';
                    reportSheet.getCell('C6').value = 'Type de jour';
                    reportSheet.getCell('D6').value = 'Type de vacance';
                    reportSheet.getCell('E6').value = 'Heures par jour';
                    reportSheet.getCell('F6').value = 'Heures supp.';
                    reportSheet.getCell('G6').value = 'Heures totales';
                    reportSheet.getCell('H6').value = getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : 'Projet', true, CaseType.FIRST_LETTER_UPPERCASE);
                    reportSheet.getRow(6).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                }

                //Main table

                let rowOffsetIndex = 7;

                const objectMonths: GroupByDayMonthlyReportType[] = this.groupBy(rawData.events, (event: MonthlyReportEvent) => event.dateFrom.format(MOMENT_CCNT_MONTH_FORMAT));

                const targetMonthDays = objectMonths.find(events => events.date === monthNumber + "/" + targetYear);

                // going through each day
                let currentRow = rowOffsetIndex;
                // for (let index = 0; index < dayInMonth; index++)
                range(0, dayInMonth).forEach((index) => {

                    let dayNumber: string = '0' + (index + 1);
                    if (index + 1 >= 10) {
                        dayNumber = (index + 1).toString();
                    } else {
                        dayNumber = '0' + (index + 1);
                    }
                    const dailyDate: string = dayNumber + '/' + monthNumber + '/' + targetYear;


                    const dailyFormatedDate = moment(`${dayNumber}/${monthNumber}/${targetYear}`, "DD/MM/YYYY").format(MOMENT_CCNT_DAY_FORMAT);
                    const dailyEvents: MonthlyReportEvent[] | undefined = targetMonthDays?.events.filter(event => event.dateFrom.format(MOMENT_CCNT_DAY_FORMAT) === dailyFormatedDate);

                    if (targetMonthDays !== undefined && dailyEvents !== undefined && dailyEvents.length > 0) {
                        dailyEvents.forEach((event, eventIndex) => {

                            //Excel Friendly ARGB color code
                            let eventColor;
                            if (event.color !== null) {
                                const colorsplit: string[] = split(event.color, "#");

                                eventColor = "ff" + colorsplit[1];
                            } else {
                                eventColor = "ffbb6313";
                            }


                            let workOvertime = 0;
                            let workBreaktime = 0;

                            if (event?.overtime !== undefined) {
                                if (event.overtime !== undefined) {
                                    // for (let i = 0; i < event.overtime.length; i++) 
                                    range(0, event.overtime.length).forEach(i => {
                                        const daydateFrom = event.overtime[i].dateFrom;
                                        const daydateTo = event.overtime[i].dateTo;
                                        let time = moment.duration(daydateTo.diff(daydateFrom)).asHours();

                                        if (event.overtime[i].isNegative === true) {
                                            time = time * -1;
                                        }

                                        workOvertime += time;
                                    });
                                }
                            }
                            if (event?.overtime !== undefined) {
                                if (event.breaktime !== undefined) {
                                    // for (let i = 0; i < event.breaktime.length; i++) {

                                    range(0, event.breaktime.length).forEach(i => {
                                        const daydateFrom = event.breaktime[i].dateFrom;
                                        const daydateTo = event.breaktime[i].dateTo;
                                        const time = moment.duration(daydateTo.diff(daydateFrom)).asHours();
                                        workBreaktime += time;
                                    });
                                }
                            }

                            //final calcul of the daily worktime
                            const dateFrom = event.dateFrom;
                            const dateTo = event.dateTo;
                            const dayMoment = moment.duration(dateTo.diff(dateFrom)).asHours();
                            const dayWorkDurationNoOvertime = dayMoment - workBreaktime;

                            const dailyWorkTime = dayWorkDurationNoOvertime + workOvertime;

                            let eventIsWorkEvent = true;

                            //seperating time in the different dayOfftypes
                            // let isAlreadyAdded = false; //to avoid adding more than once the daily hours to the monthly hours
                            if (event.type_of_day_off) {
                                const dayOffType = dayOffTypes.find(dayOffType => dayOffType.type === event.type_of_day_off);
                                if (dayOffType) {
                                    dayOffType.effectiveTime += dailyWorkTime;
                                    eventIsWorkEvent = false;
                                }
                            }

                            thisMonthWorkHours += parseFloat(this.decimalize(dayWorkDurationNoOvertime, 2));//!ICI
                            thisMonthWorkHoursBalance += parseFloat(this.decimalize(dailyWorkTime, 2));
                            thisMonthOvertiumeHours += parseFloat(this.decimalize(workOvertime, 2));
                            // dayOffTypes.forEach(dayOffType => {
                            //     if (event.type_of_day !== dayOffType.type) {
                            //         if (!isAlreadyAdded) {
                            //             thisMonthWorkHours += parseFloat(this.decimalize(dayWorkDurationNoOvertime, 2));//!ICI
                            //             thisMonthWorkHoursBalance += parseFloat(this.decimalize(dailyWorkTime, 2))
                            //             thisMonthOvertiumeHours += parseFloat(this.decimalize(workOvertime, 2))
                            //             eventIsWorkEvent = true;
                            //             isAlreadyAdded = true;
                            //             console.log("dayMoment" + dayMoment);//!
                            //             console.log("dayWorkDurationNoOvertime" + dayWorkDurationNoOvertime);//!
                            //             console.log("workBreaktime" + workBreaktime);//!            
                            //         }
                            //     }
                            //     if (event.type_of_day_off === dayOffType.type) {
                            //         dayOffType.effectiveTime += dailyWorkTime;
                            //         eventIsWorkEvent = false;
                            //     }

                            // });
                            if (reportSheet !== undefined) {
                                if (eventIndex > 0) {
                                    reportSheet.getCell('A' + currentRow).value = '';
                                    reportSheet.getCell('B' + currentRow).value = event.title;
                                    reportSheet.getCell('C' + currentRow).value = event.type_of_day;
                                    const textColor = "ff" + pickBestTextColor("#" + eventColor.substring(2)).substring(1);
                                    if (eventIsWorkEvent) {
                                        reportSheet.getCell('C' + currentRow).fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: (eventColor) },
                                        };
                                        reportSheet.getCell('C' + currentRow).font = {
                                            color: { argb: (textColor) },
                                            name: 'Calibri'
                                        };
                                    }
                                    reportSheet.getCell('D' + currentRow).value = event.type_of_day_off;
                                    if (!eventIsWorkEvent) {
                                        reportSheet.getCell('D' + currentRow).fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: (eventColor) },
                                        };
                                        reportSheet.getCell('D' + currentRow).font = {
                                            color: { argb: (textColor) },
                                            name: 'Calibri'
                                        };
                                    }
                                    reportSheet.getCell('E' + currentRow).value = this.decimalize(dayWorkDurationNoOvertime, 2);
                                    reportSheet.getCell('E' + currentRow).alignment = { vertical: 'middle', horizontal: 'right' };
                                    reportSheet.getCell('F' + currentRow).value = this.decimalize(workOvertime, 2);
                                    reportSheet.getCell('F' + currentRow).alignment = { vertical: 'middle', horizontal: 'right' };
                                    reportSheet.getCell('G' + currentRow).value = this.decimalize(dailyWorkTime, 2);
                                    reportSheet.getCell('G' + currentRow).alignment = { vertical: 'middle', horizontal: 'right' };
                                    reportSheet.getCell('H' + currentRow).value = event.project;
                                    reportSheet.getRow(currentRow).border = {
                                        top: { style: 'thin' },
                                        left: { style: 'thin' },
                                        bottom: { style: 'thin' },
                                        right: { style: 'thin' }
                                    };
                                    currentRow++;


                                } else {
                                    reportSheet.getCell('A' + currentRow).value = dailyDate;
                                    reportSheet.getCell('B' + currentRow).value = event.title;
                                    reportSheet.getCell('C' + currentRow).value = event.type_of_day;
                                    const textColor = "ff" + pickBestTextColor("#" + eventColor.substring(2)).substring(1);
                                    if (eventIsWorkEvent) {
                                        reportSheet.getCell('C' + currentRow).fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: (eventColor) },
                                        };
                                        reportSheet.getCell('C' + currentRow).font = {
                                            color: { argb: (textColor) },
                                            name: 'Calibri'
                                        };
                                    }
                                    reportSheet.getCell('D' + currentRow).value = event.type_of_day_off;
                                    if (!eventIsWorkEvent) {
                                        reportSheet.getCell('D' + currentRow).fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: (eventColor) },
                                        };
                                        reportSheet.getCell('D' + currentRow).font = {
                                            color: { argb: (textColor) },
                                            name: 'Calibri'
                                        };
                                    }
                                    reportSheet.getCell('E' + currentRow).value = this.decimalize(dayWorkDurationNoOvertime, 2);
                                    reportSheet.getCell('E' + currentRow).alignment = { vertical: 'middle', horizontal: 'right' };
                                    reportSheet.getCell('F' + currentRow).value = this.decimalize(workOvertime, 2);
                                    reportSheet.getCell('F' + currentRow).alignment = { vertical: 'middle', horizontal: 'right' };
                                    reportSheet.getCell('G' + currentRow).value = this.decimalize(dailyWorkTime, 2);
                                    reportSheet.getCell('G' + currentRow).alignment = { vertical: 'middle', horizontal: 'right' };
                                    reportSheet.getCell('H' + currentRow).value = event.project;
                                    reportSheet.getRow(currentRow).border = {
                                        top: { style: 'thin' },
                                        left: { style: 'thin' },
                                        bottom: { style: 'thin' },
                                        right: { style: 'thin' }
                                    };
                                    currentRow++;

                                }
                            }
                        });


                    } else {

                        if (reportSheet !== undefined) {
                            reportSheet.getCell('A' + currentRow).value = dailyDate;
                            reportSheet.getCell('B' + currentRow).value = '';
                            reportSheet.getCell('C' + currentRow).value = '';
                            reportSheet.getCell('D' + currentRow).value = '';
                            reportSheet.getCell('E' + currentRow).value = '';
                            reportSheet.getCell('F' + currentRow).value = '';
                            reportSheet.getCell('G' + currentRow).value = '';
                            reportSheet.getCell('H' + currentRow).value = '';
                            reportSheet.getRow(currentRow).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        }
                        currentRow++;

                    }
                });


                rowOffsetIndex = currentRow;


                //Bottom part
                let lastRow = 0;
                let currentBottomRow = 0;

                if (reportSheet !== undefined) {
                    reportSheet.getRow(rowOffsetIndex + currentBottomRow - 1).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };

                    reportSheet.getRow(rowOffsetIndex + currentBottomRow).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };

                    reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).value = 'Total';
                    reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                    reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(thisMonthWorkHours, 2);
                    reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                    reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                    reportSheet.getCell(('F' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(thisMonthOvertiumeHours, 2);
                    reportSheet.getCell(('F' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                    reportSheet.getCell(('F' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                    reportSheet.getCell(('G' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(thisMonthWorkHoursBalance, 2);
                    reportSheet.getCell(('G' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                    reportSheet.getCell(('G' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                }
                //Summary
                if (rawData.summaryErrors.length < 1) {

                    if (reportSheet !== undefined) {
                        currentBottomRow = 3;
                        reportSheet.mergeCells(('A' + (rowOffsetIndex + currentBottomRow)), ('H' + (rowOffsetIndex + currentBottomRow)));
                        reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).value = 'Récapitulatif des heures et vacances';
                        reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getRow(rowOffsetIndex + currentBottomRow).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };

                        currentBottomRow = 4;
                        reportSheet.getRow(currentBottomRow + rowOffsetIndex).height = 28;
                        reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).value = 'Récapitulatif';
                        reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getCell(('B' + (rowOffsetIndex + currentBottomRow))).value = 'Report du mois \n précédent';
                        reportSheet.getCell(('B' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getCell(('C' + (rowOffsetIndex + currentBottomRow))).value = 'Heures mensuelles \n à effectuer';
                        reportSheet.getCell(('C' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getCell(('D' + (rowOffsetIndex + currentBottomRow))).value = 'Décompte mensuel';
                        reportSheet.getCell(('D' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).value = 'Solde à reporter';
                        reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.mergeCells(('F' + (rowOffsetIndex + currentBottomRow)), ('H' + (rowOffsetIndex + currentBottomRow)));
                        reportSheet.getCell(('F' + (rowOffsetIndex + currentBottomRow))).value = 'Remarques';
                        reportSheet.getCell(('F' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getRow(rowOffsetIndex + currentBottomRow).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    }

                    const monthlyTotalToSendNextMonth = (thisMonthWorkHoursBalance - (workRate * plannedMonthlyHours) + lastMonthHoursBalance);

                    if (reportSheet !== undefined) {
                        currentBottomRow = 5;
                        reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).value = 'Heures';
                        reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                        reportSheet.getCell(('B' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(lastMonthHoursBalance, 2);
                        reportSheet.getCell(('B' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                        reportSheet.getCell(('C' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize((workRate * plannedMonthlyHours), 2);
                        reportSheet.getCell(('C' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                        reportSheet.getCell(('D' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(thisMonthWorkHoursBalance, 2);
                        reportSheet.getCell(('D' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                        reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(monthlyTotalToSendNextMonth, 2);
                        reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                        reportSheet.getRow(rowOffsetIndex + currentBottomRow).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    }


                    lastMonthHoursBalance = monthlyTotalToSendNextMonth;

                    dayOffTypes.forEach(dayType => {
                        if (dayType.isNotWorkType) {

                            dayType.recieved = (dayType.effectiveTime) / normalDayLength;

                            const vacationTypeBalance = dayType.toGet - dayType.recieved;

                            currentBottomRow++;

                            if (reportSheet !== undefined) {
                                reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).value = dayType.type;
                                reportSheet.getCell(('A' + (rowOffsetIndex + currentBottomRow))).font = { bold: true };
                                reportSheet.getCell(('B' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(dayType.toGet, 2);
                                reportSheet.getCell(('B' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                                reportSheet.getCell(('C' + (rowOffsetIndex + currentBottomRow))).value = '';
                                reportSheet.getCell(('D' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(dayType.recieved, 2);
                                reportSheet.getCell(('D' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                                reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).value = this.decimalize(vacationTypeBalance, 2);
                                reportSheet.getCell(('E' + (rowOffsetIndex + currentBottomRow))).alignment = { vertical: 'middle', horizontal: 'right' };
                                reportSheet.getRow(rowOffsetIndex + currentBottomRow).border = {
                                    top: { style: 'thin' },
                                    left: { style: 'thin' },
                                    bottom: { style: 'thin' },
                                    right: { style: 'thin' }
                                };
                            }

                            dayType.toGet = vacationTypeBalance;
                            dayType.effectiveTime = 0;
                        }

                    });

                    lastRow = rowOffsetIndex + currentBottomRow + 1;

                    if (reportSheet !== undefined) {
                        reportSheet.pageSetup.printArea = 'A1:G' + lastRow;
                    }

                }
                //  else {
                //     showNotification("Une erreur est survenue pendant la création du sommaire", "error");
                // }
            });
            // let wbout = workbook.xlsx.write(workbook, {bookType: 'xlsx', bookSST: true, type: 'binary'});
            // zip.file("shubham"+i+".xlsx", wbout, {binary: true});
            // var zip = new JSZip();
            // zip.generateAsync({ type: "blob" })
            //     .then(function (content) {
            //         // see FileSaver.js
            //         saveAs(content, "example.zip");
            //     });
            if (rawsData.length === 1) {
                workbook.xlsx.writeBuffer()
                    .then(buffer => FileSaver.saveAs(new Blob([buffer]), filename))
                    .catch(err => console.log('Error writing excel export', err));
            } else {
                const buffer = workbook.xlsx.writeBuffer();
                zip.file(filename, buffer, { binary: true });
            }
            //downloading Excel file
            // .then(buffer => FileSaver.saveAs(new Blob([buffer]), filename))
            // .catch(err => console.log('Error writing excel export', err));

        });
        if (rawsData.length === 1) {
            this.setState({ isLoading: undefined, selectedUsers: [], compressionProgression: 0.0 });
        } else {
            zip.generateAsync({ type: "blob", compression: "STORE" }, (metadata: any) => {
                this.setState({ compressionProgression: metadata.percent });
            }).then((content: any) => {
                FileSaver.saveAs(new Blob([content]), "reports.zip");
                this.setState({ isLoading: undefined, selectedUsers: [], compressionProgression: 0.0 });
            });
        }
    };


    //######################################## report excel END #################################################

    render() {
        return (
            <div>
                <Card icon={<FAIcon prefix='fad' name='user' />} title={"Rapport"} headerElements={
                    [
                        <div key={`repor-tab-v2-header-actions`} style={this.state.isLoading === -1 ? { display: "flex", justifyContent: 'flex-end', alignItems: 'center' } : { display: 'none' }} >
                            <Progress strokeColor={"var(--primary-color)"} style={this.state.isLoading === -1 ? { width: "300px", marginLeft: '5px' } : { width: '0px' }} percent={this.state.compressionProgression} showInfo={false} />
                        </div>,
                        // <CircleButton
                        //     style={this.props.company?.type === "2" ? {} : { display: "none" }}
                        //     title={"Télécharger plusieurs"}
                        //     icon={<DownloadOutlined />}
                        //     onClick={() => this.setState({ modalDownloadMultipleVisible: true })}
                        //     disabled={this.state.modalDownloadMultipleVisible} />
                    ]
                }
                >
                    {this.generateReportView()}
                </Card>
                <div>

                    {/* <Button type="primary" onClick={() => ccnt && this.downloadExcel(ccnt, 4)} >EXCELSIOOOR</Button> */}

                </div>
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    toggleUsersLoading: (b: boolean) => dispatch(toggleUsersLoading(b)),
});

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    company: state.user.company,
    usersLoading: state.teamManagement.usersLoading,
    width: state.window.width,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withRouter(withFullName(ReportTabv2)));



