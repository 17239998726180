import { Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { selectActiveReportUsers } from '../../../../store/selectors/usersSelectors';
import { GREEN_COLOR, MOMENT_MONTH_FORMAT, ORANGE_COLOR, RED_COLOR } from '../../../../utils/constants';
import ReportExcel, { IReportExcelRow } from '../../../../utils/exports/ReportExcel';
import Network from '../../../../utils/network';
import { FullUserProps, withFullName } from '../../../../utils/objects/withFullName';
import { userColumn } from '../../../../utils/tableUtils';
import { IFilterUsers, initFilterUsers, User, UserJobTMP } from '../../../../utils/types/generalTypes';
import { IEventCalculated, IEventCalculatedDict } from '../../../../utils/types/reportTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { filterContracts, filterUsers, isNullOrEmpty, roundDecimals, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import AmazingDatePicker, { PickerMode } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import VirtualTable from '../../../common/general/virtualTable';
import { FilterSidebar } from '../../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';
import Filters from '../../../planningPerf/tabs/common/filters';
import { compareUser } from '../../../teamManagement/utils/utils';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, ContainerTabsItemProps, FullUserProps { }

interface State {
    from: Moment;
    to: Moment;
    loading: boolean;
    downloading: boolean;
    filters: IFilterUsers;
    data?: IEventCalculatedDict;
}

class PeriodBreakdown extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        const from = moment().startOf('month');
        const to = moment().endOf('month');

        this.state = {
            from,
            to,
            loading: false,
            downloading: false,
            filters: { ...initFilterUsers },
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            !this.state.from.isSame(prevState.from, "day") ||
            !this.state.to.isSame(prevState.to, "day") ||
            this.state.loading !== prevState.loading ||
            this.state.downloading !== prevState.downloading
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { from, to, loading, downloading } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.MONTH}
                    disabled={downloading}
                    loadingData={loading}
                    selectByWeek
                    selectByMonth
                    selectByManual
                    controlled={{
                        valueFrom: from,
                        valueTo: to,
                        onChange: this.onChangeDates,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.loadData()}
                    disabled={downloading}
                    loading={loading} />

                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Download' })}
                    icon={<FAIcon prefix={'fad'} name="download" />}
                    onClick={() => this.downloadData()}
                    disabled={loading}
                    loading={downloading} />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setFilters({ users: val }),
                }}
                groups={{
                    usersToExclude: filters.usersToExclude,
                    selectedGroups: filters.groups,
                    changeGroups: (groups, toExclude) => this.setFilters({ groups, usersToExclude: toExclude })
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    setFilters = (filters: Partial<IFilterUsers>) => this.setState((prevState) => ({ filters: { ...prevState.filters, ...filters } }));

    resetFilters = () => this.setState({ filters: { ...initFilterUsers } });


    downloadData = async () => {
        this.setState({ downloading: true });

        const { users, groups, intl, company, getFullName } = this.props;
        const { filters, from, to } = this.state;
        const filteredUsers = filterUsers(users, groups, filters)?.sort((a, b) => compareUser(a, b, getFullName)) ?? [];

        //! Take care to update the columns in the antd table
        const columns: IReportExcelRow<User> = [
            {
                name: intl.formatMessage({ defaultMessage: 'User' }),
                width: 40,
                totalsRowLabel: intl.formatMessage({ defaultMessage: 'Totals' }),
                filterButton: true,
                alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                render: (u) => getFullName(u)
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Gross' }),
                width: 17,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.grossSec / 3600) : value;

                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Paid breaks' }),
                width: 13,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.paidBreaktimeSec / 3600) : value;
                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Unpaid breaks' }),
                width: 13,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.unpaidBreaktimeSec / 3600) : value;
                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Effective time' }),
                width: 17,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.effectiveSec / 3600) : value;
                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Overtimes' }),
                width: 18,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.overtimeSec / 3600) : value;
                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Increased hours' }),
                width: 14,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.increasedHoursSec / 3600) : value;
                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Increased vacation' }),
                width: 14,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.increasedVacationSec / 3600) : value;
                    }
                    return value;
                }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Total' }),
                width: 14,
                totalsRowFunction: 'sum',
                alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                render: (record: User) => {
                    let value = 0.0;
                    if (this.state.data) {
                        const data = this.state.data[record.id];
                        value = data ? roundDecimals(data.effectiveWithIncreasedSec / 3600) : value;
                    }
                    return value;
                }
            },
        ];
        const generator = new ReportExcel(`${from.format(MOMENT_MONTH_FORMAT)}_${to.format(MOMENT_MONTH_FORMAT)}`, columns.length, intl, company);
        // , `${this.props.intl.formatMessage({ defaultMessage: 'From {start} to {end}' }, { start: from.format(getFormat('DATE')), end: to.format(getFormat('DATE')) })}`
        const buffer = await generator.generateExcel(intl.formatMessage({ defaultMessage: "Period breakdown" }), columns, filteredUsers, company?.logo);

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        const documentName = `${intl.formatMessage({ defaultMessage: "Period breakdown" })}_${from.format(MOMENT_MONTH_FORMAT)}_${to.format(MOMENT_MONTH_FORMAT)}`;
        a.href = url;
        a.download = `${documentName.toLocaleLowerCase().replaceAll(" ", "-")}.xlsx`;
        a.click();
        this.setState({ downloading: false });
    }

    loadData = () => {
        const { loading, from, to, } = this.state;
        const { users } = this.props;
        if (!loading) {
            this.setState({ loading: true });
            Network.periodBreakdown(from, to, users.map(u => u.id))
                .then(
                    (response) => {
                        if (response.error) {
                            showNotification("Problem est survenu", "error")
                        } else {
                            this.setState({ data: response.data, loading: false });
                        }
                    }
                )
                .catch(
                    () => {
                        showNotification("Problem est survenu", "error")
                        this.setState({ loading: false });
                    }
                )
        }
    }

    onChangeDates = (from: Moment | null, to: Moment | null) => {
        if (!from || !to || from.isAfter(to, "days")) return;
        this.setState({ from, to }, () => this.loadData());
    }

    eventCalculatedDictSorter = (a: User, b: User, key: keyof IEventCalculated) => {
        const { data } = this.state;
        if (data) {
            const dataA = data[a.id];
            const dataB = data[b.id];
            const valueA = dataA && dataA[key] ? dataA[key] : 0.0;
            const valueB = dataB && dataB[key] ? dataB[key] : 0.0;
            return valueA - valueB;
        }
        return 0;
    }

    //! Take care to update the columns of the excel generation
    columns = (): ColumnsType<User> => [
        ...userColumn(this.props.intl, this.props.getFullName, this.props.isSmartphone),
        {
            title: <FormattedMessage defaultMessage={'Contracts'} />,
            key: 'contracts',
            dataIndex: 'job',
            className: '__width_100 __centered-text',
            render: (contracts: UserJobTMP[] | undefined) => {
                let availableContracts: UserJobTMP[] = [];
                if (!isNullOrEmpty(contracts)) {
                    availableContracts = filterContracts(this.state.from, this.state.to, contracts);
                }
                switch (availableContracts.length) {
                    case 0:
                        return <FAIcon color={RED_COLOR} title={this.props.intl.formatMessage({ defaultMessage: "No contract" })} prefix={'fad'} name="circle-0" />
                    case 1:
                        return <FAIcon color={GREEN_COLOR} title={this.props.intl.formatMessage({ defaultMessage: "Single contract" })} prefix={'fad'} name="circle-1" />
                    default:
                        return <FAIcon color={ORANGE_COLOR} title={this.props.intl.formatMessage({ defaultMessage: "Multiple contracts" })} prefix={'fad'} name="layer-group" />
                }
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Gross'} />,
            key: 'grossDuration',
            className: '__width_150 __centered-text',
            sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "grossSec"),
            render: (record: User) => {
                let value = "0.00";
                if (this.state.data) {
                    const data = this.state.data[record.id];
                    value = data ? roundDecimals(data.grossSec / 3600).toFixed(2) : value;

                }
                return <span>{value}</span>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Breaks'} />,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Paid'} />,
                    key: 'paidBreaks',
                    className: '__width_120 __centered-text',
                    sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "paidBreaktimeSec"),
                    render: (record: User) => {
                        let value = "0.00";
                        if (this.state.data) {
                            const data = this.state.data[record.id];
                            value = data ? roundDecimals(data.paidBreaktimeSec / 3600).toFixed(2) : value;
                        }
                        return <span className='_i'>{value}</span>
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Unpaid'} />,
                    key: 'unpaidBreaks',
                    className: '__width_120 __centered-text',
                    sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "unpaidBreaktimeSec"),
                    render: (record: User) => {
                        let value = "0.00";
                        if (this.state.data) {
                            const data = this.state.data[record.id];
                            value = data ? roundDecimals(data.unpaidBreaktimeSec / 3600).toFixed(2) : value;
                        }
                        return <span>{value}</span>
                    }
                },
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Effective time'} />,
            key: 'effectiveHours',
            className: '__width_150 __centered-text',
            sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "effectiveSec"),
            render: (record: User) => {
                let value = "0.00";
                if (this.state.data) {
                    const data = this.state.data[record.id];
                    value = data ? roundDecimals(data.effectiveSec / 3600).toFixed(2) : value;
                }
                return <span>{value}</span>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Overtimes'} />,
            key: 'overtimes',
            className: '__width_150 __centered-text',
            sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "overtimeSec"),
            render: (record: User) => {
                let value = "0.00";
                if (this.state.data) {
                    const data = this.state.data[record.id];
                    value = data ? roundDecimals(data.overtimeSec / 3600).toFixed(2) : value;
                }
                return <span>{value}</span>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Increase'} />,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Hours'} />,
                    key: 'increasedHours',
                    className: '__width_120 __centered-text',
                    sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "increasedHoursSec"),
                    render: (record: User) => {
                        let value = "0.00";
                        if (this.state.data) {
                            const data = this.state.data[record.id];
                            value = data ? roundDecimals(data.increasedHoursSec / 3600).toFixed(2) : value;
                        }
                        return <span>{value}</span>
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Holiday'} />,
                    key: 'increasedVacations',
                    className: '__width_120 __centered-text',
                    sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "increasedVacationSec"),
                    render: (record: User) => {
                        let value = "0.00";
                        if (this.state.data) {
                            const data = this.state.data[record.id];
                            value = data ? roundDecimals(data.increasedVacationSec / 3600).toFixed(2) : value;
                        }
                        return <span>{value}</span>
                    }
                },
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Total'} />,
            key: 'totalTime',
            fixed: 'right',
            className: '__width_150 __centered-text __report-hours-summary-fixed-odd',
            sorter: (a: User, b: User) => this.eventCalculatedDictSorter(a, b, "effectiveWithIncreasedSec"),
            render: (record: User) => {
                let value = "0.00";
                if (this.state.data) {
                    const data = this.state.data[record.id];
                    value = data ? (data.effectiveWithIncreasedSec / 3600).toFixed(2) : value;
                }
                return <span>{value}</span>
            }
        },
    ];

    render() {
        const { users, groups, height, getFullName } = this.props;
        const { filters, loading } = this.state;

        const filteredUsers = filterUsers(users, groups, filters)?.sort((a, b) => compareUser(a, b, getFullName));

        let tableHeight = height - 172;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <Spin spinning={loading} indicator={<FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                <div style={{ display: 'flex', gap: 10 }}>
                    <VirtualTable
                        className='__basic-table'
                        dataSource={filteredUsers}
                        columns={this.columns()}
                        scroll={{ x: true, y: tableHeight }}
                    />
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    users: selectActiveReportUsers(state),
    groups: state.teamManagement.groups,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
    company: state.user.company
});

const connector = connect(mapStateToProps);
export default connector(injectIntl(withFullName(PeriodBreakdown)));