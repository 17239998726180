import { Col, Empty, Pagination, Row, Spin, Switch, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { customersFetched } from '../../../store/features/customerManagement';
import { DisableForGlobalOffice, DisableForUnassignedMandates, PLACEHOLDER_IMAGE } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { ICustomer, ICustomerStat } from '../../../utils/types/customerTypes';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { isNullOrEmpty, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import IntegrationsSyncButton from '../../common/general/integrationsSyncButton';
import { FilterSidebar } from '../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';
import CustomerCard from '../../common/ui/modernCard/customerCard';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps { }
interface Filters {
    unassignedMandate: boolean;
    searchValue: string;
}
interface State {
    filteredCustomers?: ICustomer[];
    customersStats: ICustomerStat[];
    pageSize: number;
    currentPage: number;
    filters: Filters;
    shoudRefresh: boolean;
}

export class Customers extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: 1,
            pageSize: 12,
            filters: {
                unassignedMandate: false,
                searchValue: '',
            },
            customersStats: [],
            shoudRefresh: false
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

        this.getCustomersStats();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!isEqual(this.props.customers, prevProps.customers)) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }

        if (this.state.shoudRefresh) {
            this.setState({ shoudRefresh: false });
            this.props.customersFetched(true);
        }
    }

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        if (
            this.props.integrationDetails.data?.sync.customers?.percentage &&
            nextProps.integrationDetails.data?.sync.customers?.percentage &&
            this.props.integrationDetails.data?.sync.customers?.percentage < nextProps.integrationDetails.data?.sync.customers?.percentage &&
            nextProps.integrationDetails.data?.sync.customers?.percentage === 100
        )
            this.setState({ shoudRefresh: true });

        return true;
    }

    getExtra = () => {
        const { customers, customersFetched, intl } = this.props;
        const isGlobalOfficeEnabled = this.props.company && this.props.company.isGlobalOfficeEnabled;
        return (
            <>
                <IntegrationsSyncButton type={'customers'} />
                <Tooltip placement='left' open={customers.updatedAt ? undefined : false} title={<p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}><FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: customers.updatedAt?.format(getFormat('DATE_AND_ON_TIME')) }} /></p>}>
                    <CircleButton
                        small
                        withoutTooltip
                        title={intl.formatMessage({ defaultMessage: 'Force update' })}
                        icon={<FAIcon prefix={'fad'} name="rotate" />}
                        onClick={() => { customersFetched(true); this.getCustomersStats(true); }}
                        loading={customers.loading} />
                </Tooltip>
                <CircleButton
                    small
                    key="customer-add"
                    title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Add a customer' })}
                    disabled={isGlobalOfficeEnabled}
                    icon={<FAIcon prefix='far' name='plus' />}
                    withoutTooltip />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { searchValue, unassignedMandate } = this.state.filters;
        const content = (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Search
                    value={searchValue}
                    placeholder={this.props.intl.formatMessage({ defaultMessage: 'Search by title or code' })}
                    allowClear
                    key={`search-customer`}
                    onChange={(event) => this.searchCustomers(event.target.value)} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                    <span><FormattedMessage defaultMessage={'Unassigned mandates'} /></span>
                    <Switch
                        checked={unassignedMandate}
                        checkedChildren={<FAIcon prefix='far' name='check' />}
                        unCheckedChildren={<FAIcon prefix='far' name='xmark' />}
                        onChange={this.unassignedMandate}
                    />
                </div>
            </div>
        );


        return [FilterSidebar(content, intl)];
    };

    getCustomersStats = (forceReload = false) => {
        Network.getCustomersStats(forceReload).then(
            (response) => this.setState({ customersStats: response.data }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the statistics' }), "error")
        );
    };

    searchCustomers = (value: string) => {
        const search = value.toLocaleLowerCase();
        this.setState(prevState => {
            const filters: Filters = { ...prevState.filters, searchValue: search };
            return ({ filters, filteredCustomers: this.filterCustomers(filters) });
        });
    };

    unassignedMandate = (checked: boolean) => {
        this.setState(prevState => {
            const filters: Filters = { ...prevState.filters, unassignedMandate: checked };
            return ({ filters, filteredCustomers: this.filterCustomers(filters) });
        });
    };

    filterCustomers = (filters: Filters) => {
        const { searchValue, unassignedMandate } = filters;
        let filteredCustomers = cloneDeep(this.props.customers.data);

        if (isNullOrEmpty(searchValue) && !unassignedMandate)
            this.setState({ filteredCustomers: undefined });

        if (!isNullOrEmpty(searchValue)) {
            filteredCustomers = (filteredCustomers ?? this.props.customers.data)?.filter(customer => `${customer.title} ${customer.code}`.toLocaleLowerCase().indexOf(searchValue) >= 0);
        }

        if (unassignedMandate) {
            filteredCustomers = (filteredCustomers ?? this.props.customers.data)?.filter(customer => customer.unassigned);
        }

        return filteredCustomers ?? [];
    };

    goToCustomer = (customerId: number) => this.props.history.push(`/${this.props.match.params.lang}/crm/customer/${customerId}/details`);

    render() {
        const { customers, sidebarType, width, isSmartphone, intl } = this.props;
        const { filteredCustomers, currentPage, pageSize, customersStats } = this.state;
        const customersData = filteredCustomers ?? customers.data;
        const isGlobalOfficeEnabled = this.props.company && this.props.company.isGlobalOfficeEnabled;

        let firstCustomerPagination = (currentPage - 1) * pageSize;
        let lastCustomerPagination = currentPage * pageSize;
        if (customersData && firstCustomerPagination > customersData?.length) firstCustomerPagination = 0;
        if (customersData && lastCustomerPagination > customersData?.length) lastCustomerPagination = pageSize;

        const showPagination = customersData !== undefined && customersData.length > pageSize;
        return (
            <>
                <Spin spinning={customers.loading} indicator={<FAIcon prefix='fas' name='spinner-third' fontSize={7} spin />} size='large' style={{ backgroundColor: 'transparent', minHeight: '70px' }} >
                    <Row gutter={[20, 20]} style={{ marginBottom: showPagination ? '5px' : undefined }}>
                        {
                            customers.updatedAt && customersData && customersData.length > 0 ?
                                (showPagination ? customersData.slice(firstCustomerPagination, lastCustomerPagination) : customersData).map(customer => {
                                    return (
                                        <Col xs={{ span: 24 }} md={sidebarType ? { span: 24 } : { span: 12 }} lg={sidebarType ? { span: 24 } : { span: 12 }} xl={sidebarType ? { span: 24 } : { span: 12 }} xxl={width > 2000 ? sidebarType ? { span: 8 } : { span: 6 } : sidebarType ? { span: 12 } : { span: 8 }} key={`customer-${customer.id}`} onClick={() => this.goToCustomer(customer.id)}>
                                            <CustomerCard
                                                customerStats={customersStats.find(stat => stat.customerId === customer.id)}
                                                title={customer.title}
                                                description={customer.description ?? ""}
                                                color={customer.color}
                                                unassigned={customer.unassigned}
                                                code={customer.code}
                                                logo={<img src={`${customer.logoUrl ?? PLACEHOLDER_IMAGE}`} />}
                                                actions={
                                                    <>
                                                        <CircleButton
                                                            small
                                                            withoutTooltip
                                                            disabled={isGlobalOfficeEnabled || customer.unassigned}
                                                            title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Add a branch' })}
                                                            icon={<FAIcon prefix='fad' name='code-pull-request-draft' onClick={(e) => { e?.stopPropagation(); }} key="add-site" />}
                                                        />
                                                        <CircleButton
                                                            small
                                                            withoutTooltip
                                                            disabled={customer.unassigned || isGlobalOfficeEnabled}
                                                            title={customer.unassigned ? intl.formatMessage(DisableForUnassignedMandates) : intl.formatMessage({ defaultMessage: 'Add a contact' })}
                                                            icon={<FAIcon prefix='fad' name='user-plus' onClick={(e) => { e?.stopPropagation(); }} key="add-contact" />}
                                                        />
                                                        <CircleButton
                                                            small
                                                            withoutTooltip
                                                            disabled={isGlobalOfficeEnabled}
                                                            title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Add a mandate' })}
                                                            icon={<FAIcon prefix='fad' name='folder-plus' onClick={(e) => { e?.stopPropagation(); }} key="add-mandate" />}
                                                        />
                                                    </>
                                                } />
                                        </Col>
                                    );
                                })
                                :
                                <Col xs={{ span: 24 }} >
                                    <Empty description={filteredCustomers ? <FormattedMessage defaultMessage={'No customers match filters'} /> : <FormattedMessage defaultMessage={'No customers'} />} />
                                </Col>
                        }
                    </Row>
                    {
                        showPagination ?
                            <div style={{ position: 'sticky', zIndex: 100, bottom: '0px', right: 0, background: 'var(--background-color)', borderRadius: '8px 0 0 8px', padding: '5px 0px 2px 10px', float: 'right' }}>
                                <Pagination
                                    size={isSmartphone ? 'small' : 'default'}
                                    current={currentPage}
                                    onChange={(page) => this.setState({ currentPage: page })}
                                    defaultPageSize={pageSize}
                                    total={customersData?.length}
                                    showSizeChanger={false}
                                    hideOnSinglePage={true}
                                    showTotal={(total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} customers'} values={{ range0: range[0], range1: range[1], total }} />}
                                />
                            </div>
                            : null
                    }
                </Spin >
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    customers: state.customerManagement.customers,
    sidebarType: state.window.sidebarType,
    isSmartphone: state.window.isSmartphone,
    integrationDetails: state.integrations.globalOfficeDetails,
    width: state.window.width,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    customersFetched: (forceReload?: boolean) => dispatch(customersFetched({ forceReload: forceReload ?? false })),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(injectIntl(Customers)));