import { Drawer, Tabs, TabsProps } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeGroups } from '../../store/actions/teamManagement';
import { changeCompany } from '../../store/actions/user';
import { Company, Group, RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps, UserDispatchProps, } from '../../utils/types/storeTypes';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import IntegrationsSyncButton from '../common/general/integrationsSyncButton';
import Container from '../common/navigations/container';
import Export from './tabs/export';
import UsersIntegration from './tabs/usersManagement';
import UsersUnlock from './tabs/usersUnlock';
import ShiftsValidation from './tabs/validation/ShiftsValidation';
import IntegrationSelector from './utils/integrationSelector';
import IntegrationsDrawerSelector from './utils/integrationsDrawerSelector';


type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {

}

type Props = IProps & ReduxProps & TeamManagementDispatchProps & UserDispatchProps & IntlProps & RouterProps;

interface State {
    openUnlockDrawer: boolean;
}

const TABS = {
    // INTEGRATIONS_DASHBOARD: 'dashboard',
    INTEGRATIONS_EXPORT: 'exports',
    INTEGRATIONS_VALIDATE: 'hours-validation',
    INTEGRATIONS_LINK: 'link',
    INTEGRATIONS_UNLOCK: 'unlock',
};

class Integrations extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);



        this.state = {
            openUnlockDrawer: false
        };
    }

    /**
     * Go to a specific page
     * @param link the link of the page to go to
     */
    goTo = (link: string): void => this.props.history.push(`/${this.props.match.params.lang}/${link}`);
    onChangeTab = (tab: string): void => {
        this.goTo('integrations/' + tab);
    };
    getCurrentTab = () => {
        switch (this.props.match.params.tab) {
            // case TABS.INTEGRATIONS_DASHBOARD:
            case TABS.INTEGRATIONS_EXPORT:
            case TABS.INTEGRATIONS_VALIDATE:
            case TABS.INTEGRATIONS_LINK:
                return this.props.match.params.tab;
            default:
                this.goTo(`integrations/${TABS.INTEGRATIONS_VALIDATE}`);
        }
        this.goTo(`integrations/${TABS.INTEGRATIONS_VALIDATE}`);
    };

    render() {
        const { fullscreen, intl } = this.props;

        const items: TabsProps['items'] = [
            // {
            //     key: TABS.INTEGRATIONS_DASHBOARD,
            //     label: <span><FAIcon prefix="far" name="grid-horizontal" style={{ marginRight: 6 }} /><FormattedMessage defaultMessage={'Dashboard'} /></span>,
            //     children: <IntegrationSelector icon={<FAIcon prefix={'fas'} name={'grid-horizontal'} />} title={intl.formatMessage({ defaultMessage: 'Integrator' })} element={Dashboard} />
            // },
            {
                key: TABS.INTEGRATIONS_VALIDATE,
                label: <span><FAIcon prefix='fad' name='user-clock' style={{ marginRight: 6 }} /> <FormattedMessage defaultMessage={'Hours validation'} /></span>,
                children: <ShiftsValidation />
            },
            {
                key: TABS.INTEGRATIONS_EXPORT,
                label: <span><FAIcon prefix='fad' name='file-export' style={{ marginRight: 6 }} /> <FormattedMessage defaultMessage={'Hours exportations'} /></span>,
                children: <IntegrationSelector tab={TABS.INTEGRATIONS_EXPORT} icon={<FAIcon prefix='fad' name='file-export' />} title={intl.formatMessage({ defaultMessage: 'Hours' })} element={Export} />
            },
            {
                key: TABS.INTEGRATIONS_LINK,
                label: <span><FAIcon prefix='fad' name='gear' style={{ marginRight: 6 }} /> <FormattedMessage defaultMessage={'Configurations'} /></span>,
                children: <IntegrationSelector extra={<IntegrationsSyncButton type={'users'} />} tab={TABS.INTEGRATIONS_LINK} icon={<FAIcon prefix='fad' name='gear' />} title={intl.formatMessage({ defaultMessage: 'Configurations' })} element={UsersIntegration} />
            },
        ];
        return (
            <div id='integrations-root' style={{ height: '100%' }}>
                {fullscreen ?
                    <>{items.find(i => i.key === this.getCurrentTab())?.children}</>
                    :
                    <Container className={`container-content-background-integrations`} breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Integrations' }), link: "" }]}>
                        <Tabs 
                            activeKey={this.getCurrentTab()} 
                            tabBarStyle={{ display: fullscreen ? 'none' : '' }} 
                            onChange={this.onChangeTab} 
                            items={items} 
                            className="integrations-tabs-parent" 
                            destroyInactiveTabPane={true} 
                            tabBarExtraContent={this.props.integrations?.globalOfficeEnabled ? <CircleButton small onClick={() => this.setState({openUnlockDrawer: true})} title={intl.formatMessage({defaultMessage: 'Unlock'})} icon={<FAIcon prefix='fad' name='unlock'/>}/>:null}
                        />
                    </Container>
                }
                <Drawer
                    destroyOnClose={true}
                    className="__drawer __integrations__drawer"
                    //title={<FormattedMessage defaultMessage={'Locked details'} />}
                    width={'100%'}
                    onClose={() => this.setState({openUnlockDrawer: false})}
                    open={this.state.openUnlockDrawer}
                    // footer={
                    //     <div
                    //         style={{
                    //             textAlign: 'right',
                    //         }}
                    //     >
                    //         {
                    //             <>
                    //                 <Button onClick={this.closeShowDetailsProject} style={{ marginRight: 8 }}>
                    //                     <FormattedMessage defaultMessage={'Close'} />
                    //                 </Button>
                    //                 <Button onClick={() => this.showAddProject(showProject)} type="primary">
                    //                     <FormattedMessage defaultMessage={'Edit'} />
                    //                 </Button>
                    //             </>
                    //         }
                    //     </div>
                    // }
                >
                    <IntegrationsDrawerSelector toLastMonth={false} datePicker open={this.state.openUnlockDrawer} onClose={() => this.setState({openUnlockDrawer: false})} title={intl.formatMessage({ defaultMessage: 'Unlock' })} element={UsersUnlock} />
                </Drawer>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeCompany: (c: Company) => dispatch(changeCompany(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    fullscreen: state.window.fullscreen,
    windowWidth: state.window.width,
    groups: state.teamManagement.groups,
    company: state.user.company,
    currentUser: state.user.currentUser,
    integrations: state.integrations.enabledIntegrations.data
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(Integrations)));
