import { captureMessage } from "@sentry/react";
import { Button, Checkbox, Col, DatePicker, Empty, Input, Row, Select, TimePicker } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../../rbacRules';
import { loadCountries } from '../../../store/actions/configurations';
import { MOMENT_MONTH_FORMAT, MOMENT_TIME_FORMAT, WIDTH_3_COL } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Group, RouterProps, StaffType, UserEducation, UserEducations, UserField, UserInput } from '../../../utils/types/generalTypes';
import { CreateUserBodyRequest, CreateUserInput } from '../../../utils/types/networkTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { alert, isNullOrEmpty, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from "../../common/FAIcon";
import FileUploader from '../../common/fields/fileUploader';
import ImageUploader from '../../common/fields/imageUploader';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import TagField from '../../common/fields/tagField';
import Bloc from '../../common/general/bloc';
import Can from '../../common/general/can';
import Card from '../../common/general/card';
import Container from '../../common/navigations/container';
import { UserFieldValue } from './editUserTab';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouterProps, PropsFromRedux, IntlProps { }
interface State {
    values: CreateUserBodyRequest;
    loading: boolean;

    currentGroups?: Group[];
    loadingStaffTypes: boolean;
    currentStaffTypes?: StaffType[];
    staffTypes?: StaffType[];
    usernameIsValid: boolean;
    usernameIsValidError: string;
    userFieldValues: UserFieldValue[];
}

/**
 * Page to create a new user
 */
class CreateUser extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            values: {
                general: { role: 1, team_visibility: true, language: props.company ? props.company.language : 'fr' },
                contact: {},
                about_me: {},
                educations: [],
                bio: {},
                job: {},
            },
            loading: false,
            loadingStaffTypes: false,
            usernameIsValid: true,
            usernameIsValidError: '',
            userFieldValues: []
        };
    }

    componentDidMount(): void {
        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );
        this.props.loadCountries();

    }

    /**
     * Called when the file was uploaded
     * @param file the file
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onFileUploaded = (file: File, group: string, name: string): void => {
        const { values } = this.state;
        values[group][name] = file;
        this.setState({ values });
    };

    onDynamicFileExpiryDate = (fieldId: number, expiry: Moment | null) => {
        const { company } = this.props;
        const { values } = this.state;

        if (isNullOrEmpty(company?.userFields)) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        const field = company?.userFields.find(field => field.id === fieldId && ['IMG', 'VDO', 'FLE'].includes(field.type));
        if (field === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        if (values.files == undefined) {
            values.files = [];
        }

        const fileIndex = values.files.findIndex(f => f.field.id == fieldId);
        if (fileIndex === -1) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        values.files[fileIndex].expiryDate = expiry ? expiry.format(MOMENT_MONTH_FORMAT) : undefined;

        this.setState({ values });
    };

    onDynamicFileUploaded = (fieldId: number, file: File | undefined): void => {
        const { company } = this.props;
        const { values } = this.state;

        if (isNullOrEmpty(company?.userFields)) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        const field = company?.userFields.find(field => field.id === fieldId && ['IMG', 'VDO', 'FLE'].includes(field.type));
        if (field === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        console.log("THERE FILE", file);
        if (file === undefined) {
            if (values.files == undefined) {
                return;
            }

            const fileIndex = values.files.findIndex(f => f.field.id == fieldId);
            if (fileIndex === -1) {
                return;
            }

            if (values.files[fileIndex].id === -1) {
                values.files.splice(fileIndex, 1);
            } else {
                values.files[fileIndex].file = undefined;
            }
        } else {
            if (values.files == undefined) {
                values.files = [];
            }

            let fileIndex = values.files.findIndex(f => f.field.id == fieldId);
            if (fileIndex === -1) {
                fileIndex = 0;
                values.files.push({
                    id: -1,
                    field: field,
                    file: file
                });
            } else {
                values.files[fileIndex].file = file;
            }
            console.log('THERE FILES', values.files);
        }

        this.setState({ values });
    };

    /**
     * Called when an InputField changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeInputField = (event: any, group: string, name: string): void => {
        const { values } = cloneDeep(this.state);
        let errorMsg = this.props.intl.formatMessage({ defaultMessage: 'The username is not valid' });

        values![group][name] = event.target.value;
        this.setState({ values }, () => {
            if (name === 'username') {
                if (event.target.value.length < 3) {
                    this.setState({ usernameIsValid: false, usernameIsValidError: errorMsg });
                    return;
                }
                Network.usernameIsValid(event.target.value).then(
                    (response) => {
                        if (response.error !== undefined && response.error === true) {
                            if (response.code && response.code === 'username-already-in-use-701') {
                                errorMsg = this.props.intl.formatMessage({ defaultMessage: 'Username already in use' });
                            }
                            else if (response.message) {
                                errorMsg = response.message;
                            }
                            this.setState({ usernameIsValid: false, usernameIsValidError: errorMsg });
                        } else {
                            this.setState({ usernameIsValid: true });
                        }
                    },
                    (error) => {
                        if (error.message) {
                            try {
                                const jsonObject: { error: boolean, message: string | { username: string[]; }, status?: string, data?: any; code?: string; } = JSON.parse(error.message);
                                if (jsonObject.code && jsonObject.code === 'username-already-in-use-701')
                                    errorMsg = this.props.intl.formatMessage({ defaultMessage: 'Username already in use' });
                                else if (typeof jsonObject.message === 'string')
                                    errorMsg = jsonObject.message;
                            } catch (e) {
                                errorMsg = error.message;
                            }
                        }
                        console.log("ERROR2", errorMsg);
                        this.setState({ usernameIsValid: false, usernameIsValidError: errorMsg });
                    }
                );
            }
        });





    };
    /**
     * Called when an InputNumber changed
     * @param number the number
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeInputNumber = (number: number | undefined, group: string, name: string): void => {
        const { values } = this.state;
        values[group][name] = number ? number : undefined;
        this.setState({ values });
    };

    /**
     * Called when a Select changed
     * @param value the option's value
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeSelect = (value: number | string | 'undefined', group: string, name: string): void => {
        const { values } = this.state;
        values[group][name] = value === 'undefined' ? undefined : value;
        this.setState({ values });
    };

    /**
     * Called when a DatePicker changed
     * @param date the selected date
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeDate = (date: Moment, group: string, name: string): void => {
        const { values } = this.state;
        if (date) {
            values![group][name] = date.format("YYYY-MM-DD");
        } else {
            values![group][name] = undefined;
        }
        this.setState({ values });
    };

    onChangeBoolean = (event: CheckboxChangeEvent) => {
        const { values } = this.state;
        values!.job.is_current = event.target.checked;
        this.setState({ values });
    };

    /**
     * Called when a Checkbox changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeCheckbox = (event: CheckboxChangeEvent, group: string, name: string): void => {
        const { values } = this.state;
        values[group][name] = event.target.checked;
        this.setState({ values });
    };

    /**
     * Called when a tag is created
     * @param tag the new tag's title
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onTagCreated = (tag: string, group: string, name: string): void => {
        const { values } = this.state;
        if (!values[group][name]) values[group][name] = [];
        values[group][name].push(tag);
        this.setState({ values });
    };

    /**
     * Called when a tag is deleted
     * @param index the index of the tag to delete
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onTagClosed = (index: number, group: string, name: string): void => {
        const { values } = this.state;
        values[group][name].splice(index, 1);
        this.setState({ values });
    };

    /**
     * Decide wether or not to show the create button
     */
    showButton = () => {

        const { values } = this.state;
        return values.general.first_name && values.general.first_name.length > 0 &&
            values.general.last_name && values.general.last_name.length > 0 &&
            values.contact.email && values.contact.email.length > 0 &&
            values.general.username && values.general.username.length > 0;
    };

    /**
     * Add an education section fields
     */
    addEducation = () => {
        const { values } = this.state;

        if (values.educations.filter((e) => e.school && e.school.length > 0 && e.degree && e.degree.length > 0 && e.start_date && e.end_date).length !== values.educations.length) return;

        const education: UserEducation = {
            school: undefined,
            degree: undefined,
            start_date: undefined,
            end_date: undefined,
        };

        (values.educations as UserEducations).push(education);
        this.setState({ values });
    };

    /**
     * Called when an InputField of an education section changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    educationOnChangeInputField = (event: any, index: number, name: string) => {
        const { values } = this.state;
        values.educations[index][name] = event.target.value;
        this.setState({ values });
    };

    /**
     * Called when an RangePicker of an education section changed
     * @param date the selected dates
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    educationOnChangeDate = (dates: Moment[], index: number) => {
        const { values } = this.state;
        values.educations[index].start_date = dates[0];
        values.educations[index].end_date = dates[1];
        this.setState({ values });
    };

    completeUserBodyRequestWithInputs = (): CreateUserInput[] => {
        const { userFieldValues } = this.state;
        const fields = this.getUserInputFields();

        const createFieldValue = (field: UserField, value: UserInput[keyof UserInput] | undefined): Omit<CreateUserInput, 'fieldId'> => {
            const fields: Omit<CreateUserInput, 'fieldId'> = {};

            switch (field.type) {
                case 'BOOL':
                    fields['booleanValue'] = value as boolean;
                    break;
                case 'CHAR':
                    fields['textValue'] = value as string;
                    break;
                case 'TEXT':
                    fields['textareaValue'] = value as string;
                    break;
                case 'DATE':
                    fields['dateValue'] = moment(value as string).format("YYYY-MM-DD");
                    break;
                case 'DATETIME':
                    fields['datetimeValue'] = value as string;
                    break;
                case 'FLOAT':
                    fields['floatNumberValue'] = value as number;
                    break;
                case 'INT':
                    fields['integerNumberValue'] = Math.round(value as number);
                    break;
                case 'TIME':
                    fields['timeValue'] = moment(value as string, 'HH:mm:ss').format(MOMENT_TIME_FORMAT);
                    break;
            }

            return fields;
        };

        const inputs: CreateUserInput[] = [];

        if (fields) {
            fields.forEach(field => {
                const userValue = userFieldValues.find(fv => fv.fieldId === field.id);
                if (userValue !== undefined) {
                    inputs.push({
                        fieldId: userValue.fieldId,
                        ...createFieldValue(field, userValue.value),
                    });
                } else if (field.isRequired) {
                    inputs.push({
                        fieldId: field.id,
                        ...createFieldValue(field, this.getDefaultUserFieldValue(field.id))
                    });
                }
            });
        }

        return inputs;
    };

    /**
     * Called when the create button is clicked
     * Submit data to API
     */
    handleOnSubmit = () => {
        const { intl } = this.props;
        const { values, currentGroups, currentStaffTypes } = this.state;
        const user: CreateUserBodyRequest = JSON.parse(JSON.stringify(values));
        if (values?.files) {
            user.files = values.files; // @mario Pour ce magnifique workarround ci-dessus qui n'est même pas expliqué dans le commit....
        }

        if (values?.general.pic) {
            user.general.pic = values?.general.pic;
        }

        if (values?.job.contract) {
            user.job.contract = values?.job.contract;
        }

        // check that email as @
        if (user.contact.email && !user.contact.email.includes("@")) {
            alert(intl.formatMessage({ defaultMessage: 'The specified email is not valid' }), "error");
            return;
        }

        // start loading
        this.setState({ loading: true });

        //change the tags to strings with comma separated values
        Object.keys(user.about_me).forEach((k: string) => { if (user.about_me[k] instanceof Array) user.about_me[k] = user.about_me[k].join(","); });

        user.groupsIds = currentGroups ? currentGroups.filter(g => g.id).map(g => g.id!) : [];
        user.stafftypesIds = currentStaffTypes ? currentStaffTypes.filter(s => s.id).map(s => s.id!) : [];

        user.inputs = this.completeUserBodyRequestWithInputs();

        //create user
        Network.createUser(user).then(
            r => {
                this.setState({ loading: false });
                const userId = r?.about?.id ? r.id : r?.user?.id;
                this.props.history.push({ pathname: `/${this.props.match.params.lang}/team-management/user-details/informations?id=${userId}`, state: { successMessage: intl.formatMessage({ defaultMessage: 'The user has been successfully created' }) } });
            },
            e => {
                this.setState({ loading: false });
                const parsedError = JSON.parse(e.message);
                if (parsedError.error && parsedError.message) {
                    const message = String(parsedError.message).toLowerCase();
                    if (message.includes("email taken, username taken")) {
                        alert(intl.formatMessage({ defaultMessage: 'The email and the username are already used by another user' }), "warning");
                    } else if (message.includes("email taken")) {
                        alert(intl.formatMessage({ defaultMessage: 'The email is already used by another user' }), "warning");
                    } else if (message.includes("username taken")) {
                        alert(intl.formatMessage({ defaultMessage: 'The username is already used by another user' }), "warning");
                    } else {
                        alert(intl.formatMessage({ defaultMessage: 'An error occurred while creating the user' }), "warning");
                    }
                } else {
                    if (parsedError.error) captureMessage(`Error has error, but not message`);
                    alert(intl.formatMessage({ defaultMessage: 'An error occurred while creating the user' }), "warning");
                }
            }
        );
    };


    onChangeStaffTypes = (values: number[]): void => {
        const { staffTypes } = this.state;
        let { currentStaffTypes } = this.state;
        currentStaffTypes = staffTypes?.filter(g => values.find(id => id === g.id) !== undefined);
        this.setState({ currentStaffTypes });
    };

    onChangeGroups = (values: number[]): void => {
        const { groups } = this.props;
        let { currentGroups } = this.state;
        currentGroups = groups?.filter(g => values.find(id => id === g.id) !== undefined);
        this.setState({ currentGroups });
    };

    getDefaultUserFieldValue = (fieldId: number): UserFieldValue['value'] | undefined => {
        const f = this.getUserInputFields()?.find(f => f.id === fieldId);

        if (f) {
            switch (f.type) {
                case 'BOOL':
                    return f.defaultBooleanValue;
                case 'CHAR':
                    return f.defaultTextValue;
                case 'TEXT':
                    return f.defaultTextareaValue;
                case 'DATE':
                    return f.defaultDateValue;
                case 'DATETIME':
                    return f.defaultDatetimeValue;
                case 'FLOAT':
                    return f.defaultFloatNumberValue;
                case 'TIME':
                    return f.defaultTimeValue;
                case 'INT':
                    return f.defaultIntegerNumberValue;
            }
        }

        return undefined;
    };

    getUserFileFields = () => this.props.company?.userFields?.filter(f => ['IMG', 'VDO', 'FLE'].includes(f.type));

    getUserInputFields = () => this.props.company?.userFields?.filter(f => !['IMG', 'VDO', 'FLE'].includes(f.type));

    getUserFieldEditedValue = (fieldId: number): UserInput[keyof UserInput] | undefined => {
        const { userFieldValues } = this.state;

        const fieldValue = userFieldValues.find(f => f.fieldId === fieldId);
        if (fieldValue !== undefined && fieldValue.value !== undefined)
            return fieldValue.value;
        return this.getDefaultUserFieldValue(fieldId);
    };

    editUserInputFieldValue = (fieldId: number, newValue: UserFieldValue['value']) => {
        const newFieldValues = [...this.state.userFieldValues];
        const targetFieldIndex = newFieldValues.findIndex(f => f.fieldId === fieldId);
        if (targetFieldIndex >= 0) {
            newFieldValues[targetFieldIndex] = {
                ...newFieldValues[targetFieldIndex],
                value: newValue
            };
        } else {
            newFieldValues.push({
                fieldId,
                value: newValue
            });
        }

        this.setState({ userFieldValues: [...newFieldValues] });
    };

    renderUserInputField = (field: UserField): ReactNode => {
        const currentFieldValue = this.getUserFieldEditedValue(field.id);

        const onChange = (newValue: UserInput[keyof UserInput]) => {
            // if (e && e.preventDefault) e.preventDefault();
            this.editUserInputFieldValue(field.id, newValue);
        };

        let input: ReactNode | undefined = undefined;

        if (currentFieldValue !== undefined) {
            switch (field.type) {
                case 'CHAR':
                    input = <Input required={field.isRequired} type='text' value={currentFieldValue?.toString()} onChange={(e) => onChange(e.target.value)} />;
                    break;
                case 'TEXT':
                    input = <TextArea required={field.isRequired} value={currentFieldValue?.toString()} onChange={(e) => onChange(e.target.value)} />;
                    break;
                case 'DATETIME':
                    input = <>
                        <DatePicker
                            style={{ width: '130px' }}
                            format={getFormat('DATE')}
                            value={currentFieldValue ? moment(currentFieldValue?.toString()) : undefined}
                            allowClear={false}
                            onChange={d => onChange(d?.toISOString() ?? '')}
                        />
                        <TimePicker
                            style={{ width: '120px' }}
                            format={getFormat('TIME')}
                            value={currentFieldValue ? moment(currentFieldValue?.toString()) : undefined}
                            onChange={e => onChange(e?.toISOString() ?? '')}
                        />
                    </>;
                    break;
                case 'DATE':
                    input = <DatePicker
                        style={{ width: '130px' }}
                        format={getFormat('DATE')}
                        value={currentFieldValue ? moment(currentFieldValue?.toString()) : undefined}
                        allowClear={false}
                        onChange={d => onChange(d?.toISOString() ?? '')}
                    />;
                    break;
                case 'TIME':
                    input = <TimePicker
                        style={{ width: '120px' }}
                        format={getFormat('TIME')}
                        value={currentFieldValue ? moment(currentFieldValue?.toString(), 'HH:mm:ss') : undefined}
                        onChange={e => onChange(e?.format('HH:mm:ss') ?? '')}
                    />;
                    break;
                case 'BOOL':
                    input = <Checkbox
                        checked={currentFieldValue as boolean}
                        onChange={(e) => onChange(e.target.checked)}
                    />;
                    break;
                case 'FLOAT':
                    input = <Input required={field.isRequired} type='number' value={currentFieldValue?.toString()} onChange={(e) => onChange(e.target.value)} />;
                    break;
                case 'INT':
                    input = <Input required={field.isRequired} type='number' value={currentFieldValue?.toString()} onChange={(e) => onChange(parseInt(e.target.value))} />;
                    break;
            }
        }

        if (input) {
            return <>
                <div style={{ display: 'flex', margin: '10px 0px', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p>{field.name} {field.isRequired ? '*' : ''}</p>
                    <div>{input}</div>
                </div>
            </>;
        }

        return <></>;
    };


    render() {
        const { company, countries, intl } = this.props;
        const { values, staffTypes, currentStaffTypes, currentGroups, loadingStaffTypes } = this.state;
        const xxxl = this.props.windowWidth > WIDTH_3_COL ? 8 : 12;
        const xxxl16 = this.props.windowWidth > WIDTH_3_COL ? 16 : 12;
        const userFileFields = company?.userFields?.filter((field) => ['IMG', 'VDO', 'FLE'].includes(field.type));
        const userInputFields = company?.userFields?.filter((field) => !['IMG', 'VDO', 'FLE'].includes(field.type));
        console.log("USER INPUT FIELDS", userInputFields);
        return (
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Team management' }), link: "/team-management/list" }, { title: intl.formatMessage({ defaultMessage: 'Add an user' }), link: "/team-management/create-user" }]}>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                        <Card title={<FormattedMessage defaultMessage={'General'} />} icon={<FAIcon prefix='fad' name='id-card' />}>
                            <div className="team-create-user-body">
                                <span className="team-create-user-group team-create-user-group-inline">
                                    <p><FormattedMessage defaultMessage={'Avatar'} /></p>
                                    <ImageUploader
                                        style={{ width: 100, height: 100 }}
                                        image={values.general.pic}
                                        onUploaded={(i: File) => this.onFileUploaded(i, "general", "pic")} />
                                </span>
                                {(this.props.company && ['read-write'].includes(this.props.company.userCodeDisplayAuthorization)) ?
                                    <span className="team-create-user-group">
                                        <p><FormattedMessage defaultMessage={'Internal identifier'} /></p>
                                        <InputField value={values?.general.code} placeholder={"0001"} className="team-create-user-inputs" onChange={(e) => this.onChangeInputField(e, "general", "code")} />
                                    </span>
                                    :
                                    null
                                }
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'First name'} />{'*'}</p>
                                    <InputField value={values.general.first_name} placeholder={"Prénom"} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "general", "first_name")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Last name'} />{'*'}</p>
                                    <InputField value={values.general.last_name} placeholder={"Nom"} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "general", "last_name")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Username'} />{'*'}</p>
                                    <InputField error={!this.state.usernameIsValid ? this.state.usernameIsValidError : undefined} value={values.general.username} placeholder={"Nom d'utilisateur"} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "general", "username")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Email'} />{'*'}</p>
                                    <InputField value={values.contact.email} placeholder={"Email"} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "contact", "email")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Birthdate'} /></p>
                                    <DatePicker
                                        value={values.general.doj ? moment(values.general.doj) : undefined}
                                        placeholder={"dd-mm-yyyy"}
                                        format={getFormat('DATE')}
                                        className="team-create-user-inputs"
                                        onChange={(m: any) => this.onChangeDate(m, "general", "doj")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Sex'} /></p>
                                    <Can rule={Rules.TeamManagement.MakeAdmin} otherwise={<p>{values?.general.role === 1 ? "Utilisateur" : "Admin"}</p>}>
                                        <Select defaultValue={'undefined'} defaultActiveFirstOption value={values?.general.sex || 'undefined'} className="team-create-user-inputs" onChange={(v: 'M' | 'F' | 'X' | 'undefined') => this.onChangeSelect(v, "general", "sex")}>
                                            <Select.Option default value={'undefined'}><FormattedMessage defaultMessage={'Not defined'} /></Select.Option>
                                            <Select.Option value={'M'}><FormattedMessage defaultMessage={'Male'} /></Select.Option>
                                            <Select.Option value={'F'}><FormattedMessage defaultMessage={'Female'} /></Select.Option>
                                            <Select.Option value={'X'}><FormattedMessage defaultMessage={'Not gendered'} /></Select.Option>
                                        </Select>
                                    </Can>
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Language'} /></p>
                                    <Select
                                        defaultValue={this.props.company?.language ?? 'fr'}
                                        value={values?.general.language}
                                        className="team-create-user-inputs"
                                        onChange={(v: string) => this.onChangeSelect(v, "general", "language")}
                                        options={this.props.languages.map(l => ({ label: l.name, value: l.code }))}
                                    />

                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Role'} /></p>
                                    <Can rule={Rules.TeamManagement.MakeAdmin} otherwise={<p>{values?.general.role === 1 ? "Utilisateur" : "Admin"}</p>}>
                                        <Select value={values.general.role} className="team-create-user-inputs" onChange={(v: number) => this.onChangeSelect(v, "general", "role")}>
                                            <Select.Option value={1}><FormattedMessage defaultMessage={'User'} /></Select.Option>
                                            <Select.Option value={2}><FormattedMessage defaultMessage={'Administrator'} /></Select.Option>
                                        </Select>
                                    </Can>
                                </span>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                        <Card title={<FormattedMessage defaultMessage={'About'} />} icon={<FAIcon prefix='fad' name='list-ul' />}>
                            <div className="team-create-user-body">
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Expertise'} /></p>
                                    <TagField tags={values.about_me.expertise as string[]} onTagCreated={(t: string) => this.onTagCreated(t, "about_me", "expertise")} onTagClosed={(t: string, i: number) => this.onTagClosed(i, "about_me", "expertise")} className="team-create-user-inputs" />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Skills'} /></p>
                                    {/* <Select
                                        className='team-create-user-inputs'
                                        // disabled={this.props.loading}
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        allowClear
                                        placeholder="Compétences à assigner"
                                        style={{ width: '100%' }}
                                        // value={this.state.selectGroups}
                                        // onChange={this.changeGroup}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                    >
                                        {
                                            staffType.map((g) => {
                                                return <Option label={g.name} value={g.id!} key={"stafftype-" + g.id}>{g.name}</Option>
                                            })
                                        }
                                    </Select> */}
                                    <TagField tags={values.about_me.skills as string[]} onTagCreated={(t: string) => this.onTagCreated(t, "about_me", "skills")} onTagClosed={(t: string, i: number) => this.onTagClosed(i, "about_me", "skills")} className="team-create-user-inputs" />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Department'} /></p>
                                    <TagField tags={values.about_me.dept as string[]} onTagCreated={(t: string) => this.onTagCreated(t, "about_me", "dept")} onTagClosed={(t: string, i: number) => this.onTagClosed(i, "about_me", "dept")} className="team-create-user-inputs" />
                                </span>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                        <Card title={<FormattedMessage defaultMessage={'Contact'} />} icon={<FAIcon prefix='fad' name='address-book' />}>
                            <div className="team-create-user-body">
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Address'} /></p>
                                    <InputField type={'textarea'} value={values?.contact.address} placeholder={intl.formatMessage({ defaultMessage: 'Address' })} className="team-create-user-inputs-large" onChange={(e: any) => this.onChangeInputField(e, "contact", "address")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'NPA'} /></p>
                                    <InputField value={values?.contact.zip} placeholder={intl.formatMessage({ defaultMessage: 'NPA' })} className="team-create-user-inputs-large" onChange={(e: any) => this.onChangeInputField(e, "contact", "zip")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'City'} /></p>
                                    <InputField value={values?.contact.location} placeholder={intl.formatMessage({ defaultMessage: 'City' })} className="team-create-user-inputs-large" onChange={(e: any) => this.onChangeInputField(e, "contact", "location")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Country'} /></p>
                                    <Select
                                        className="team-create-user-inputs-large"
                                        showSearch
                                        allowClear
                                        showArrow
                                        maxTagCount='responsive'
                                        placeholder={countries.updated === undefined || countries.data.length === 0 ? <FormattedMessage defaultMessage={'No country available'} /> : <FormattedMessage defaultMessage={'Select a country'} />}
                                        disabled={countries.updated === undefined || countries.data.length === 0}
                                        onChange={(code: string) => this.onChangeSelect(code, "contact", "country")}
                                        value={values?.contact.country}
                                        filterOption={true}
                                        optionFilterProp="label"
                                    >
                                        {countries.data?.sort((a, b) => a.code === 'CH' ? -1 : b.code === 'CH' ? 1 : (a.code <= b.code) ? -1 : 1).map(c => <Select.Option value={c.code} label={c.name} key={`team-create-user-promotions-country-${c.code}`}>{c.name}</Select.Option>)}
                                    </Select>
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Phone number'} /></p>
                                    <InputField value={values.contact.phone} placeholder={intl.formatMessage({ defaultMessage: 'Phone number' })} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "contact", "phone")} />
                                </span>
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Cell phone number'} /></p>
                                    <InputField value={values.contact.mobile} placeholder={intl.formatMessage({ defaultMessage: 'Cell phone number' })} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "contact", "mobile")} />
                                </span>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                        <Card title={<FormattedMessage defaultMessage={'Other'} />} icon={<FAIcon prefix='fad' name='layer-group' />}>
                            <div className="team-create-user-body">
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Visible in the team tab (mobile application)'} /></p>
                                    <Checkbox checked={values.general.team_visibility} onChange={e => this.onChangeCheckbox(e, "general", "team_visibility")} />
                                </span>
                                {
                                    values.general.role === 1 &&
                                    <span className="team-create-user-group">
                                        <p><FormattedMessage defaultMessage={'The user can modify his planning'} /></p>
                                        <Checkbox checked={values.general.can_edit_planning} onChange={e => this.onChangeCheckbox(e, "general", "can_edit_planning")} />
                                    </span>
                                }
                                {
                                    values?.general.role && values.general.role === 1 &&
                                    <span className="team-create-user-group">
                                        <p><FormattedMessage defaultMessage={'The user can consult the global planning (without modifying it)'} /></p>
                                        <Checkbox checked={values.general.can_read_only_all_planning} onChange={e => this.onChangeCheckbox(e, "general", "can_read_only_all_planning")} />
                                    </span>
                                }
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'Biography'} /></p>
                                    <InputField type="textarea" value={values.bio.bio} placeholder={intl.formatMessage({ defaultMessage: 'Biography' })} className="team-create-user-inputs" onChange={(e: InputFieldOnChangeEvent) => this.onChangeInputField(e, "bio", "bio")} />
                                </span>
                                <div className="team-create-user-body">
                                    <span className="team-create-user-group team-create-user-group-inline">
                                        <p><FormattedMessage defaultMessage={'Education'} /></p>
                                        <Button icon={<FAIcon prefix='far' name='plus' />} onClick={this.addEducation} />
                                    </span>
                                    {
                                        values.educations.map((e: UserEducation, index: number) => (
                                            <div key={`team-create-user-educations-${index}`} style={{ marginLeft: '10px' }}>
                                                {index !== 0 ? <div className="team-create-user-educations-divider" /> : null}
                                                <span className="team-create-user-group">
                                                    <p><FormattedMessage defaultMessage={'School'} /></p>
                                                    <InputField value={e.school} placeholder={intl.formatMessage({ defaultMessage: 'School' })} className="team-create-user-inputs" onChange={(e) => this.educationOnChangeInputField(e, index, "school")} />
                                                </span>
                                                <span className="team-create-user-group">
                                                    <p><FormattedMessage defaultMessage={'Degree'} /></p>
                                                    <InputField value={e.degree} placeholder={intl.formatMessage({ defaultMessage: 'Degree' })} className="team-create-user-inputs" onChange={(e) => this.educationOnChangeInputField(e, index, "degree")} />
                                                </span>
                                                <span className="team-create-user-group">
                                                    <p><FormattedMessage defaultMessage={'Date'} /></p>
                                                    <DatePicker.RangePicker
                                                        allowClear={false}
                                                        className="team-create-user-inputs"
                                                        value={[e.start_date as Moment, e.end_date as Moment]}
                                                        placeholder={[intl.formatMessage({ defaultMessage: 'Start' }), intl.formatMessage({ defaultMessage: 'End' })]}
                                                        format={getFormat('DATE_SHORT')}
                                                        onChange={(m: any) => this.educationOnChangeDate(m, index)} />
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Card>
                    </Col>

                    {
                        this.props.company && userFileFields && userFileFields.length > 0 ?
                            <Col xl={{ span: 24 }} xxl={{ span: xxxl16 }}>
                                <Card title={<FormattedMessage defaultMessage={'Files'} />} icon={<FAIcon prefix='fad' name='folders' />}>
                                    <div className="team-create-user-body">
                                        <Row gutter={[10, 10]} style={{ marginTop: '15px' }}>
                                            {
                                                userFileFields.map(fileField => {
                                                    const file = values.files?.find(file => file.field.id === fileField.id);
                                                    const expiryDate = file?.expiryDate ? moment(file.expiryDate, MOMENT_MONTH_FORMAT) : undefined;
                                                    return (
                                                        <Col xs={{ span: 24 }} md={{ span: 12 }} key={`file-field-${fileField.id}`}>
                                                            <Bloc title={fileField.name}>
                                                                <div>
                                                                    <span className="team-create-user-group team-create-user-group-inline">
                                                                        <p><FormattedMessage defaultMessage={'File'} /></p>
                                                                        <FileUploader
                                                                            style={{ width: '100%' }}
                                                                            containerStyle={{ minWidth: '180px', maxWidth: '180px' }}
                                                                            file={file?.file}
                                                                            accept=".pdf"
                                                                            onUploaded={(i: File) => this.onDynamicFileUploaded(fileField.id, i)} />

                                                                    </span>

                                                                    <span className="team-create-user-group team-create-user-group-inline">
                                                                        <p><FormattedMessage defaultMessage={'Expiration date'} /></p>
                                                                        <DatePicker
                                                                            disabled={file === undefined}
                                                                            placeholder={intl.formatMessage({ defaultMessage: 'Expiration date' })}
                                                                            style={{ maxWidth: '180px' }}
                                                                            format={getFormat('DATE')}
                                                                            value={expiryDate}
                                                                            onChange={(date) => this.onDynamicFileExpiryDate(fileField.id, date)} />
                                                                    </span>
                                                                </div>
                                                            </Bloc>
                                                        </Col>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            : null
                    }
                    {company && userInputFields && userInputFields.length > 0 &&
                        <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                            <Card title={<FormattedMessage defaultMessage={'Additionnal fields'} />} icon={<FAIcon prefix='fad' name='pen-field' />}>
                                <div className="team-create-user-body">
                                    <div>
                                        {userInputFields.map(fileField => {
                                            return (<>
                                                {this.renderUserInputField(fileField)}
                                            </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    }

                    <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                        <Card title={<FormattedMessage defaultMessage={'Groups'} />} icon={<FAIcon prefix='fad' name='users' />}>
                            {
                                isNullOrEmpty(this.props.groups) ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'Your company does not own any groups'} />} />
                                    :
                                    <>
                                        <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'Please select the group(s) to which the user will be assigned, from the list of groups available to your company, shown above.'} /></p>
                                        <Select
                                            listHeight={500}
                                            className="__filter-limit-size __select-auto-height"
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Groups'} />}
                                            style={{ width: '100%' }}
                                            value={currentGroups ? currentGroups.map(g => g.id ?? -1) : []}
                                            onChange={(e) => this.onChangeGroups(e)}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                        >
                                            {
                                                this.props.groups.filter(g => g.is_team_group !== true).map((g) => {
                                                    return <Select.Option label={g.name} value={g.id!} key={"groups-" + g.id}>{g.name}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </>
                            }

                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                        <Card title={<FormattedMessage defaultMessage={'Abilities'} />} icon={<FAIcon prefix='fad' name='head-side-gear' />}>
                            {
                                this.state.staffTypes == null || this.state.staffTypes?.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'Your company does not own any abilities'} />} />
                                    :
                                    <>
                                        <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'Please select the abilities the user possesses, from the list of abilities available to your company, shown above.'} /></p>
                                        <Select
                                            loading={loadingStaffTypes}
                                            mode="multiple"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Abilities'} />}
                                            autoClearSearchValue={false}
                                            style={{ width: '100%' }}
                                            value={currentStaffTypes ? currentStaffTypes.map(p => p.id ?? -1) : []}
                                            onChange={this.onChangeStaffTypes}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                staffTypes?.map((p: StaffType) => {
                                                    return <Select.Option label={p.name} value={p.id!} key={"stafftype-" + p.id}>{p.name}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </>
                            }
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={{ width: '150px' }}
                            onClick={this.handleOnSubmit}
                            type="primary"
                            loading={this.state.loading}
                            disabled={!this.showButton() || !this.state.usernameIsValid}
                        >
                            <FormattedMessage defaultMessage={'Create'} />
                        </Button>
                    </Col>
                </Row>
            </Container >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    loadCountries: () => dispatch(loadCountries())
});



const mapStateToProps = (state: ApplicationState) => ({
    windowWidth: state.window.width,
    groups: state.teamManagement.groups,
    company: state.user.company,
    countries: state.configurations.countries,
    languages: state.configurations.languages.data
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(CreateUser));