import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Segmented, Select, Space } from "antd";
import { toFinite } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useFullName } from "../../hooks/useUsers";
import Network from "../../utils/network";
import { Message } from "../../utils/types/generalTypes";
import { CreateMessageBody, CreateMessageBodyRefType } from "../../utils/types/networkTypes";
import { ApplicationState } from "../../utils/types/storeTypes";
import { showNotification } from "../../utils/utils";
import InputField, { InputFieldOnChangeEvent } from "../common/fields/inputField";
import SwitchLabeled from "../common/fields/switchLabeled";
import SimpleBloc from "../common/general/simpleBloc";

interface Props {
    handleClose: () => void;
    addMessage: (message: Message) => void;
}

const CreateMessageModal = (props: Props) => {
    const intl = useIntl();
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const fullName = useFullName()

    const users = useSelector((state: ApplicationState) => state.teamManagement.users);
    const groups = useSelector((state: ApplicationState) => state.teamManagement.groups);

    const [values, setValues] = useState<CreateMessageBody>({
        title: '',
        text: '',
        optional: false,
        ref_id: undefined,
        ref_ids: undefined,
        ref_type: CreateMessageBodyRefType.USER,
    });
    const [loading, setLoading] = useState(false);

    const showButton = useMemo((): boolean => {
        let bool: boolean = values.ref_type !== undefined && values.title.length > 0 && values.text.length > 0;

        if (values.ref_type === "user")
            bool = bool && values.ref_ids !== undefined && values.ref_ids.length > 0;

        if (values.ref_type === "group")
            bool = bool && values.ref_ids !== undefined && values.ref_ids.length > 0;

        return bool;
    }, [values.ref_ids, values.ref_type, values.text.length, values.title.length]);

    const onChangeRefType = useCallback((val: string) => {
        setValues({ ...values, ref_type: val as CreateMessageBodyRefType, ref_id: undefined, ref_ids: undefined });
    }, [values]);

    const onChangeOptional = useCallback((checked: boolean) => {
        setValues({ ...values, optional: checked });
    }, [values]);

    const onChangeUsers = useCallback((groupsIds: number[]) => {
        setValues({ ...values, ref_ids: groupsIds });
    }, [values]);

    const onChangeGroups = useCallback((userIds: number[]) => {
        setValues({ ...values, ref_ids: userIds });
    }, [values]);

    const handleCreateMessage = useCallback(() => {
        if (values.ref_type === CreateMessageBodyRefType.USER) {
            values.ref_ids = (values.ref_ids as string[]).map((id: string) => parseInt(id));
            values.ref_id = values.ref_ids![0];
        } else if (values.ref_type === CreateMessageBodyRefType.GROUP) {
            values.ref_id = parseInt(values.ref_id as string);
        } else if (values.ref_type === CreateMessageBodyRefType.COMPANY) {
            values.ref_id = currentUser?.company_id as number;
        }

        setLoading(true);
        Network.createMessage(values).then(
            (response) => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'The message has been successfully sent' }), 'success');
                props.handleClose();
                props.addMessage(response[0]);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the message' }), 'warning');
            }
        );
    }, [currentUser?.company_id, intl, props, values]);

    const availableGroups = useCallback(() => {
        let tmpGroups = groups ? groups : [];
        if (currentUser !== undefined && ![2, 3].includes(currentUser.role))
            tmpGroups = tmpGroups.filter(g => currentUser.group_users?.findIndex(gu => gu.group === g.id) !== -1);
        return tmpGroups;
    }, [currentUser, groups]);

    return (
        <Modal
            open
            onCancel={props.handleClose}
            title={<FormattedMessage defaultMessage={'Write a new message'} />}
            width={900}
            closable={!loading}
            footer={[
                <Space key='create-message-modal-footer'>
                    <Button
                        onClick={props.handleClose}
                        type='default'
                        disabled={loading}
                    >
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>
                    <Button
                        type='primary'
                        onClick={handleCreateMessage}
                        loading={loading}
                        disabled={!showButton}
                    >
                        <FormattedMessage defaultMessage={'Send'} />
                    </Button>
                </Space>
            ]}
        >
            <Row gutter={[20, 10]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                            <InputField
                                style={{ width: '100%' }}
                                maxLength={100}
                                showCount
                                onChange={(e: InputFieldOnChangeEvent) => setValues({ ...values, title: e.target.value })}
                                value={values.title}
                                placeholder={intl.formatMessage({ defaultMessage: 'Title' })} />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <InputField
                                type="textarea"
                                autoSize={{ minRows: 10 }}
                                maxLength={2500}
                                showCount
                                onChange={(e: InputFieldOnChangeEvent) => setValues({ ...values, text: e.target.value })}
                                value={values.text}
                                placeholder={intl.formatMessage({ defaultMessage: 'Message' })} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Row>
                        {/* {
                            values.ref_type && [CreateMessageBodyRefType.USER, CreateMessageBodyRefType.GROUP].includes(values.ref_type) ? */}
                        <Col xs={{ span: 24 }}>
                            <SimpleBloc style={{ padding: 0 }}>
                                <Segmented
                                    block
                                    size={isSmartphone ? 'middle' : 'large'}
                                    style={{ width: "100%", fontSize: isSmartphone ? '12px' : '14px' }}
                                    key={'timeclock-show-options'}
                                    value={values.ref_type}
                                    options={[
                                        {
                                            value: CreateMessageBodyRefType.USER,
                                            label: intl.formatMessage({ defaultMessage: 'For selected users' })
                                        },
                                        {
                                            value: CreateMessageBodyRefType.GROUP,
                                            label: intl.formatMessage({ defaultMessage: 'For specified groups' })
                                        },
                                        {
                                            value: CreateMessageBodyRefType.COMPANY,
                                            label: intl.formatMessage({ defaultMessage: 'For everyone' })
                                        },
                                    ]}
                                    onChange={(mode) => onChangeRefType(mode as any)}
                                />
                                {
                                    values.ref_type && [CreateMessageBodyRefType.USER, CreateMessageBodyRefType.GROUP].includes(values.ref_type) &&
                                    <div style={{ padding: 12 }}>
                                        {
                                            values.ref_type === CreateMessageBodyRefType.USER ?
                                                <div style={{ marginTop: 10 }}>
                                                    <SwitchLabeled
                                                        style={{ marginBottom: '8px' }}
                                                        onChange={onChangeOptional}
                                                        label={intl.formatMessage({ defaultMessage: 'Confirmation option for recipients' })}
                                                        checked={values.optional}
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                    />
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        placeholder={<FormattedMessage defaultMessage={'Select users'} />}
                                                        style={{ width: '100%' }}
                                                        className='__select-auto-height'
                                                        value={values.ref_ids?.map(id => toFinite(id))}
                                                        onChange={onChangeUsers}
                                                        filterOption={true}
                                                        optionFilterProp="label"
                                                        showArrow
                                                    >
                                                        {
                                                            users && users.map((u) => {
                                                                const label = fullName.getFullName(u);
                                                                return <Select.Option label={label} value={u.id} key={"users-" + u.id}>{label}</Select.Option>;
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                                :
                                                values.ref_type === CreateMessageBodyRefType.GROUP ?
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        placeholder={<FormattedMessage defaultMessage={'Select groups'} />}
                                                        style={{ width: '100%', marginTop: 10 }}
                                                        className='__select-auto-height'
                                                        value={values.ref_ids?.map(id => toFinite(id))}
                                                        onChange={onChangeGroups}
                                                        filterOption={true}
                                                        optionFilterProp="label"
                                                        showArrow
                                                    >
                                                        {
                                                            availableGroups().map((g) => {
                                                                const label = g.name;
                                                                return <Select.Option value={g.id} key={"groups-" + g.id} label={label}>{label}</Select.Option>;
                                                            })
                                                        }
                                                    </Select>
                                                    : null
                                        }
                                    </div>
                                }
                            </SimpleBloc>
                        </Col>
                        {/* : null */}
                        {/* } */}
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default CreateMessageModal;