import { Dropdown, Menu } from 'antd';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { languageChanged } from '../../../store/features/LanguageSlice';
import { RootState } from '../../../store/store';
import { AppLanguage, AvailableLanguages } from '../../../utils/constants';
import { RouterProps } from '../../../utils/types/generalTypes';
import { StoreDispatch } from '../../../utils/types/storeTypes';
import { getFlagCode } from '../../../utils/utils';
import Flag from './flag';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps {
    collapsed: boolean;
}

interface State {
}

class languageSwitcher extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    changeLanguage = (newLang: string) => {
        this.props.history.push(`/${newLang}${this.props.location.pathname.substring(3)}`);
    };

    menu = () => {
        return (
            <Menu className='language-switcher-menu'>
                {
                    AvailableLanguages.map((item) => (
                        <Menu.Item key={item.value} onClick={e => this.changeLanguage(e.key)} className={`language-switcher-item ${this.props.match.params.lang === item.value ? 'selected' : ''}`}>
                            <div style={{ display: 'flex' }}>
                                <span style={{ minHeight: 32 }}><Flag countryCode={item.flag.toLocaleUpperCase()} /></span>
                                <span style={{ margin: 'auto auto auto 10px' }}>{item.label}</span>
                            </div>
                        </Menu.Item>
                    ))
                }
            </Menu>
        );
    };

    render() {
        const { lang } = this.props.match.params;
        const { collapsed } = this.props;
        return (
            <>
                {
                    collapsed ?
                        <div style={{ width: '100%', display: 'flex' }}>
                            {
                                AvailableLanguages.map(item => {
                                    return (
                                        <div key={item.value} style={{ margin: 'auto' }} onClick={() => this.changeLanguage(item.value)}>
                                            <Flag countryCode={item.flag.toLocaleUpperCase()} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        :
                        <Dropdown overlay={this.menu()} placement={'top'} trigger={['click']}>
                            <div onClick={e => { e.preventDefault(); }} style={{ margin: 'auto 12px auto auto', cursor: 'pointer' }}>
                                <Flag countryCode={getFlagCode(lang ? lang : 'fr')} />
                            </div>
                        </Dropdown>
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    language: state.language.current,
    isSmartphone: state.window.isSmartphone
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    languageChanged: (language: AppLanguage) => dispatch(languageChanged(language)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(languageSwitcher));