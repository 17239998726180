import { InputNumber, Select } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useProjectName } from "../../../hooks/useProjectName";
import useProjects from "../../../hooks/useProjects";
import useTypeOfDays from "../../../hooks/useTypeOfDays";
import { CaseType } from "../../../utils/constants";
import { IMandate } from "../../../utils/types/customerTypes";
import { Project, TypeOfDay } from "../../../utils/types/planningTypes";
import { IWorkItemConfig } from "../../../utils/types/productivityTypes";

interface Props {
    workItemConfigs: IWorkItemConfig[];
}

export interface WorkItemConfigCreateRef {
    config: Partial<Omit<IWorkItemConfig, "workItemId">>;
    setConfig: (config: IWorkItemConfig) => void;
    createConfig: () => void;
}

const WorkItemConfigCreate = forwardRef((props: Props, ref) => {
    const [configId, setConfigId] = useState<number>();
    const [timePerUnit, setTimePerUnit] = useState(0);
    const typeOfDays = useTypeOfDays();
    const [typeOfDayId, setTypeOfDayId] = useState<TypeOfDay['id']>();
    const projects = useProjects();
    const [projectId, setProjectId] = useState<Project['id']>();
    const [mandateId, setMandateId] = useState<IMandate['id']>();
    const projectTitle = useProjectName({ caseType: CaseType.FIRST_LETTER_UPPERCASE, isPlural: false });

    console.log("TODELETE", mandateId); // TODO: Delete this line (temporary disable warning)

    useImperativeHandle(ref, (): WorkItemConfigCreateRef => ({
        config: {
            id: configId ?? -1,
            projectId: projectId,
            secondsPerItem: timePerUnit,
            typeOfDayId: typeOfDayId
        },
        createConfig: () => {
            setConfigId(-1);
            setTypeOfDayId(undefined);
            setProjectId(undefined);
            setMandateId(undefined);
        },
        setConfig: (config) => {
            setConfigId(config.id);
            setTypeOfDayId(config.typeOfDayId);
            setProjectId(config.projectId ?? undefined);
            setMandateId(config.mandateId ?? undefined);
            setTimePerUnit(config.secondsPerItem);
        }
    }), [configId, timePerUnit, typeOfDayId, projectId]);

    return (
        <div className="__wic-creation-form">
            <div>
                <div><FormattedMessage defaultMessage={'Type of day'} /></div>
                <Select
                    className="__wic-create-select"
                    value={typeOfDayId}
                    onChange={setTypeOfDayId}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={typeOfDays.map(tod => ({ value: tod.id, label: tod.title }))}
                />
            </div>
            <div>
                <div>{projectTitle}</div>
                <Select
                    className="__wic-create-select"
                    value={projectId}
                    allowClear
                    onChange={setProjectId}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={projects.map(p => ({ value: p.id, label: p.title }))}
                />
            </div>
            <div>
                <div><FormattedMessage defaultMessage={'Time per unit'} /> (<FormattedMessage defaultMessage={'in seconds'} />)</div>
                <InputNumber value={timePerUnit} onChange={(v) => v !== null && setTimePerUnit(v)} />
            </div>
        </div>
    );
});

WorkItemConfigCreate.displayName = "workItemConfigCreate";

export default WorkItemConfigCreate;