import { Button, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment, { Moment } from "moment";
import isEqual from "react-fast-compare";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectActiveReportUsers, selectActiveReportUsersKeyed } from "../../store/selectors/usersSelectors";
import '../../styles/productivity-reports.css';
import { MOMENT_MONTH_FORMAT, PRIMARY_COLOR } from "../../utils/constants";
import Network from "../../utils/network";
import { IFilterUsers, initFilterUsers, RouterProps } from "../../utils/types/generalTypes";
import { Loaded, StatusType } from "../../utils/types/networkTypes";
import { IProductivityReports, IUserProductivityReport, IWorkItem } from "../../utils/types/productivityTypes";
import { ApplicationState } from "../../utils/types/storeTypes";
import { filterUsers, isNullOrEmpty, showNotification } from "../../utils/utils";
import { IntlProps } from "../app/LanguageProvider";
import FAIcon from "../common/FAIcon";
import AmazingDatePicker, { PickerMode } from "../common/fields/AmazingDatePicker/amazingDatePicker";
import CircleButton from "../common/fields/circleButton";
import FullUser from "../common/general/fullUser";
import PageSpinner from "../common/general/pageSpinner";
import VirtualTable from "../common/general/virtualTable";
import { FilterSidebar } from "../common/navigations/containerTabs";
import ContainerTabsItem, { ContainerTabsItemProps } from "../common/navigations/containerTabsItem";
import Filters from "../planningPerf/tabs/common/filters";
import ProductivityUserDetails from "./productivity/productivityUserDetails";

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps, ContainerTabsItemProps { }

interface State {
    reports: Loaded<IProductivityReports>;
    selectedProductivityPercentage?: number;
    dateFrom: Moment;
    dateTo: Moment;
    filters: IFilterUsers & { projects: number[]; };
}

class ProductivityReport extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            reports: { status: StatusType.NONE },
            dateFrom: moment().startOf('month'),
            dateTo: moment().endOf('month'),
            filters: { ...initFilterUsers, projects: [] }
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.loadReports();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            this.state.reports.status !== prevState.reports.status ||
            !this.state.dateFrom.isSame(prevState.dateFrom, "day") ||
            !this.state.dateTo.isSame(prevState.dateTo, "day")
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { reports, dateFrom, dateTo } = this.state;
        return (
            <>
                <AmazingDatePicker
                    disableFuture
                    initialPickerType={PickerMode.MONTH}
                    disabled={reports.status === StatusType.PENDING}
                    loadingData={reports.status === StatusType.PENDING}
                    selectByDay
                    selectByWeek
                    selectByMonth
                    selectByYear
                    selectByManual
                    controlled={{
                        valueFrom: dateFrom,
                        valueTo: dateTo,
                        onChange: this.onChangeDate,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.loadReports()}
                    loading={reports.status === StatusType.PENDING} />
            </>
        );
    };
    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setFilters({ users: val }),
                }}
                projects={{
                    selectedProjects: filters.projects,
                    changeProjects: (val) => this.setState({ filters: { ...filters, projects: val } }, () => this.loadReports())
                }}
                groups={{
                    usersToExclude: filters.usersToExclude,
                    selectedGroups: filters.groups,
                    changeGroups: (groups, toExclude) => this.setFilters({ groups, usersToExclude: toExclude })
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    setFilters = (filters: Partial<IFilterUsers>) => {
        this.setState((prevState) => ({ filters: { ...prevState.filters, ...filters } }));
    };

    resetFilters = () => {
        this.setState({ filters: { ...initFilterUsers, projects: [] } });
    };

    onChangeDate = (from: Moment | null, to: Moment | null) => {
        this.setState({ dateFrom: from ?? moment(), dateTo: to ?? moment() }, () => this.loadReports());
    };

    // #region Network
    loadReports = async () => {
        try {
            const { dateFrom, dateTo, filters } = this.state;
            const { projects } = filters;

            this.setState({ reports: { status: StatusType.PENDING }, selectedProductivityPercentage: undefined });


            const reportsData = await Network.getProductivityReports(dateFrom.clone(), dateTo.clone(), projects);


            let max = 0;
            for (const report of Object.values(reportsData.data.reports))
                if (max < report.avgProductivity)
                    max = report.avgProductivity;


            this.setState({
                reports: {
                    updatedAt: moment().toISOString(),
                    status: StatusType.FULFILLED,
                    data: reportsData.data
                },
                selectedProductivityPercentage: max
            });

        } catch (e) {
            this.setState({ reports: { status: StatusType.REJECTED }, selectedProductivityPercentage: undefined });
            showNotification(this.props.intl.formatMessage({ defaultMessage: "An error has occurred" }), 'error');
        }
    };

    getWorkItemTotal = (workItem: IWorkItem) => {
        const { reports } = this.state;
        const data = Object.values(reports.data?.reports ?? {});
        let total = 0;

        data.forEach((d => {
            if (workItem.id in d.workItems)
                total += d.workItems[workItem.id];
        }));
        return total;

    };

    getWorkSecondsTotal = () => {
        const { reports } = this.state;
        const data = Object.values(reports.data?.reports ?? {});

        return (data.reduce((acc, value) => acc + value.workTimeSeconds, 0) / 3600).toFixed(2);
    };

    getAverageProductivityTotal = () => {
        const { reports } = this.state;
        const data = Object.values(reports.data?.reports ?? {});
        let workTimes = {
            effective: 0,
            theoretical: 0
        };

        workTimes = data.reduce((acc, value) => {
            acc.effective += value.workTimeSeconds;
            acc.theoretical += value.theoreticalWorkTimeSeconds;
            return acc;
        }, workTimes);

        if (workTimes.effective > 0 && workTimes.theoretical > 0)
            return (100 / workTimes.effective * workTimes.theoretical).toFixed(2);

        return (0).toFixed(2);
    };

    // #endregion

    // #region Table columns
    createColumns = (): ColumnProps<IUserProductivityReport>[] => {
        const { intl, usersKeyed, workItems } = this.props;
        const { selectedProductivityPercentage } = this.state;
        let workItemsColumns: ColumnProps<IUserProductivityReport>[] = [];
        if (!isNullOrEmpty(workItems)) {
            workItemsColumns = workItems.map(wi => ({
                className: '__width_70 __centered-text',
                title: (
                    <div className='__pr-header-total'>
                        <div>
                            <Tooltip title={wi.title}>
                                <FAIcon prefix={wi.icon.split(' ')[0]} name={wi.icon.split(' ')[1]} color={'#626262'} />
                            </Tooltip>
                        </div>
                        <div className='__pr-header-total-value'>
                            {this.getWorkItemTotal(wi)}
                        </div>
                    </div>
                ),
                render: (_, report) => {
                    return (
                        <span>{report.workItems[wi.id] ?? ''}</span>
                    );
                }
            }));
        }

        return [
            {
                className: '__min-width-300',
                title: `${intl.formatMessage({ defaultMessage: 'Users' })}`,
                render: (_, report) => {
                    const user = usersKeyed[report.userId];
                    if (user) {
                        return <FullUser user={user} withAvatar />;
                    }
                    return <></>;
                },
            },
            ...workItemsColumns,
            {
                className: '__width_130 __centered-text',
                dataIndex: 'workTimeSeconds',
                key: 'workTimeSeconds',
                title: (
                    <div className='__pr-header-total'>
                        <div>
                            <FormattedMessage defaultMessage={'Duration'} />
                        </div>
                        <div className='__pr-header-total-value'>
                            {this.getWorkSecondsTotal()}
                        </div>
                    </div>
                ),
                sorter: (ra, rb) => ra.workTimeSeconds - rb.workTimeSeconds,
                render: (workTimeSeconds) => (workTimeSeconds / 3600).toFixed(2)
            },
            {
                title: <>{intl.formatMessage({ defaultMessage: 'Delta to' })} <FAIcon prefix={'fad'} name="star" color={PRIMARY_COLOR} /></>,
                className: '__width_180 __centered-text',
                key: 'selectedProductivityPercentage',
                render: (_, report) => {
                    if (selectedProductivityPercentage) {
                        return (
                            <span>
                                {
                                    selectedProductivityPercentage === 0 ?
                                        '-'
                                        : selectedProductivityPercentage === report.avgProductivity ?
                                            <FAIcon prefix={'fad'} name="star" color={PRIMARY_COLOR} />
                                            : `${(report.avgProductivity / (selectedProductivityPercentage ?? 1) * 100).toFixed(2)}%`
                                }
                            </span>
                        );
                    }
                    return <></>;
                }
            },
            {
                className: '__width_180 __centered-text',
                dataIndex: 'avgProductivity',
                key: 'avgProductivity',
                defaultSortOrder: 'descend',
                title: (
                    <div className='__pr-header-total'>
                        <div>
                            <FormattedMessage defaultMessage={'Avg productivity'} />
                        </div>
                        <div className='__pr-header-total-value'>
                            {this.getAverageProductivityTotal()} {'%'}
                        </div>
                    </div>
                ),
                fixed: 'right',
                sorter: (ra, rb) => ra.avgProductivity - rb.avgProductivity,
                render: (avgProductivity) => `${(avgProductivity).toFixed(2)}%`
            }
        ];
    };
    // #endregion

    // #region Render
    render() {
        const { reports, dateFrom, dateTo, filters } = this.state;
        const { height, users, groups } = this.props;
        let tableHeight = height - 165;
        if (tableHeight < 250) tableHeight = 250;

        if (reports.status === StatusType.PENDING) {
            return (<PageSpinner />);
        } else if (reports.status === StatusType.REJECTED) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 10 }}>
                    <p><FormattedMessage defaultMessage={"An error has occurred"} /></p>
                    <Button type="primary" onClick={this.loadReports}><FormattedMessage defaultMessage={'Retry'} /></Button>
                </div>
            );
        }
        const data = reports.data?.reports ?? {};
        const filteredUsers = filterUsers(users, groups, filters);

        let filteredData: IProductivityReports['reports'] = data;
        if (filteredUsers && Object.keys(data).length > 0) {
            filteredData = {};
            filteredUsers?.forEach(user => {
                const found: IUserProductivityReport | undefined = data[user.id];
                if (found) filteredData[user.id] = found;
            });
        }

        return (
            <VirtualTable
                className="__basic-table"
                dataSource={Object.values(filteredData)}
                rowKey={(r: IUserProductivityReport) => `productivity-report-${r.userId}`}
                expandable={{
                    rowExpandable: () => true,
                    expandedRowRender: (report: IUserProductivityReport) => <ProductivityUserDetails report={report} dateFrom={dateFrom.format(MOMENT_MONTH_FORMAT)} dateTo={dateTo.format(MOMENT_MONTH_FORMAT)} />
                }}
                onRow={(r: IUserProductivityReport) => ({
                    onClick: () => this.setState({ selectedProductivityPercentage: r.avgProductivity })
                })}
                columns={this.createColumns()}
                pagination={false}
                scroll={{ x: true, y: tableHeight }}
            />
        );
    }
    // #endregion
}

const mapStateToProps = (state: ApplicationState) => ({
    groups: state.teamManagement.groups,
    height: state.window.height,
    workItems: state.productivity.workItems.data,
    users: selectActiveReportUsers(state),
    usersKeyed: selectActiveReportUsersKeyed(state),
});

const connector = connect(mapStateToProps);

export default withRouter(connector(injectIntl(ProductivityReport)));