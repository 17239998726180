import { captureException } from "@sentry/react";
import { InputNumber, List, Select } from 'antd';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Network from '../../../utils/network';
import { Company, DictionaryNumber, User } from '../../../utils/types/generalTypes';
import { NetworkDaysOffByType } from '../../../utils/types/networkTypes';
import { DaysOffByType, TypeOfDayOff, UserContractSmall } from '../../../utils/types/planningTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from "../../common/FAIcon";
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';

interface Props extends IntlProps {
    daysOffByType: DaysOffByType;
    alreadyUsedVacationTypes: DictionaryNumber<number[]>;
    isAddingAnElement: boolean;
    year?: number;
    daysOffNotUsed?: TypeOfDayOff[];
    // refresh: (message?: string) => void;
    refreshTypesOfDaysOff: () => void;
    stopAddTypeOfDayOff: () => void;

    company: Company | undefined;
    user: User | undefined;
}

interface State {
    daysOffByType: DaysOffByType | undefined;
    contractsForYear?: UserContractSmall[];
    userContractsLoading: boolean;
}

/**
 * Component that represent a list item for the types of day list
 */
class DaysOffByTypeItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            daysOffByType: undefined,
            userContractsLoading: false
        };
    }

    componentDidMount() {
        // if it is a new type of day, set state's type of day
        if (this.props.daysOffByType.id === undefined) {
            this.setState({ daysOffByType: { ...this.props.daysOffByType } });
        }

        this.getContracts();
    }

    getContracts = () => {
        if (this.props.user) {
            if (!this.state.userContractsLoading) {
                this.setState({ userContractsLoading: true });
                Network.getUserContracts(this.props.user.id, undefined, this.props.year)
                    .then(
                        response => {
                            this.setState({ contractsForYear: response });
                        },
                        () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the contracts' }), "error"),
                    )
                    .finally(() => this.setState({ userContractsLoading: false }));
            }
        }
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        // when add or edit a type of day, focus the input field
        if (prevProps.user === undefined && this.props.user !== undefined && this.state.contractsForYear === undefined) {
            this.getContracts();
        }
        if (this.state.daysOffByType !== undefined && prevState.daysOffByType === undefined) {
            const input = document.getElementById(`tod-edit-input-${this.props.daysOffByType.id ? this.props.daysOffByType.id : '0'}`);
            if (input) input.focus();
        }
    }

    onChangeExpiryDate = (value: Moment | null) => {
        const { daysOffByType } = this.state;
        value && daysOffByType ? daysOffByType.expiryDate = value : (daysOffByType && (daysOffByType.expiryDate = undefined));
        this.setState({ daysOffByType });
    };

    /**
     * Change a type of day title
     * @param event the triggered event
     */
    onChangeNumberOfDays = (value: number) => {
        const { daysOffByType } = this.state;
        daysOffByType && (daysOffByType.numberOfDays = value);
        this.setState({ daysOffByType });
    };

    onChangeTypeOfDayOff(todo_id: number): void {
        const { daysOffByType } = this.state;
        daysOffByType && (daysOffByType.typeOfDayOff = (this.props.daysOffNotUsed ? this.props.daysOffNotUsed.find(t => t.id === todo_id) : undefined));
        this.setState({ daysOffByType });
    }
    onChangeUserJob(user_job_id: number): void {
        const { daysOffByType } = this.state;
        daysOffByType && (daysOffByType.userJob = (this.state.contractsForYear ? this.state.contractsForYear.find(t => t.id === user_job_id) : undefined));
        this.setState({ daysOffByType });
    }

    /**
     * Convert to input occupancy rate into a occupancy rate body request
     * @returns the converted occupancy rate body request
     */
    convertDaysOffByTypeToNetwork = (): NetworkDaysOffByType => {
        const { daysOffByType } = this.state;

        return {
            id: daysOffByType?.id,
            typeOfDayOff: daysOffByType?.typeOfDayOff,
            user: daysOffByType?.user,
            inReports: daysOffByType?.inReports,
            countAsWorktime: daysOffByType?.countAsWorktime,
            numberOfDays: daysOffByType?.numberOfDays,
            expiryDate: (daysOffByType && daysOffByType.expiryDate) ? moment(daysOffByType.expiryDate).format("YYYY-MM-DD") : undefined,
            year: daysOffByType?.year,
            userJob: daysOffByType?.userJob,
        };
    };

    /**
     * Create or edit the type of day
     */
    onUpdateUserDaysOffByType = (): void => {
        const { daysOffByType } = this.state;

        if (daysOffByType?.userJob === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Please select a contract' }), "warning");
            return;
        }

        if (daysOffByType?.typeOfDayOff === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Please select a type of vacation' }), "warning");
            return;
        }

        Network.createUserDaysOffByType(this.convertDaysOffByTypeToNetwork()).then(
            (response) => {
                if (response.error) {
                    if (response.message === "This configuration already exists") {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An identical configuration already exist' }), "error");
                    } else {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the type of day' }), "error");

                        this.setState({ daysOffByType: undefined }, () => {
                            this.props.refreshTypesOfDaysOff();
                        });
                    }
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The type of day has been successfully updated' }), "success");
                    this.setState({ daysOffByType: undefined }, () => {
                        this.props.refreshTypesOfDaysOff();
                    });

                }
            },
            (error) => {
                let parsedError: any = undefined;
                try {
                    parsedError = JSON.parse(error.message);
                } catch (error) {
                    captureException(error);
                }
                if (parsedError && parsedError["code"] && (parsedError["code"] === "us-todo-100" || parsedError["code"] === "us-todo-101")) {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An identical configuration already exist' }), "warning");
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the type of day' }), "error");
                }
            },
        );
    };

    /**
     * Delete a type of day
     */
    deleteUserDaysOffByType = () => {
        const { daysOffByType } = this.props;
        (daysOffByType && daysOffByType.id) &&
            Network.deleteUserDaysOffByType(daysOffByType.id).then(
                () => {
                    this.props.refreshTypesOfDaysOff();
                },
                () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the vacations' }), "warning")
            );
    };

    usedVacationTypesByContract = () => {
        const { alreadyUsedVacationTypes } = this.props;
        const { daysOffByType } = this.state;

        const contractId = daysOffByType?.userJob ? daysOffByType?.userJob.id : -1;
        return alreadyUsedVacationTypes[contractId] ? alreadyUsedVacationTypes[contractId] : [];
    };

    render() {
        const { daysOffByType } = this.state;
        const { intl, isAddingAnElement: addingItem } = this.props;
        const disableSave = daysOffByType?.userJob == undefined || daysOffByType?.typeOfDayOff === undefined || daysOffByType.numberOfDays === undefined;

        return (
            <List.Item
                actions={[
                    daysOffByType ?
                        <CircleButton
                            small
                            icon={<FAIcon prefix='fad' name='floppy-disk' />}
                            title={intl.formatMessage({ defaultMessage: 'Save' })}
                            placement="left"
                            disabled={disableSave}
                            onClick={this.onUpdateUserDaysOffByType} />
                        :
                        <CircleButton
                            small
                            key="team-user-vacations-edit-vacations"
                            icon={<FAIcon prefix='fad' name='pencil' />}
                            title={intl.formatMessage({ defaultMessage: 'Edit vacation' })}
                            disabled={addingItem}
                            onClick={() => this.setState({ daysOffByType: { ...this.props.daysOffByType } })} />,
                    (daysOffByType && daysOffByType.id) ?
                        <CircleButton
                            small
                            icon={<FAIcon prefix='far' name='xmark' />}
                            title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                            placement="left"
                            onClick={() => this.setState({ daysOffByType: undefined })} />
                        :
                        (this.props.daysOffByType.id ?
                            <DeleteButton
                                small
                                key="team-user-vacations-delete-vacations"
                                text={<FormattedMessage defaultMessage={'Do you want to delete this vacation setting?'} />}
                                disabled={addingItem}
                                okText={intl.formatMessage({ defaultMessage: 'Yes' })}
                                cancelText={intl.formatMessage({ defaultMessage: 'No' })}
                                onConfirm={() => this.deleteUserDaysOffByType()} />
                            :
                            <CircleButton
                                small
                                icon={<FAIcon prefix='far' name='xmark' />}
                                title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                placement="left"
                                onClick={this.props.stopAddTypeOfDayOff} />
                        )
                ]}
            >
                {
                    (daysOffByType && daysOffByType.id) ?
                        <div className="team-periods-list-item">
                            <Select
                                style={{ width: '40%' }}
                                // className="configurations-section-field"
                                value={daysOffByType?.userJob?.id}
                                onChange={(t) => this.onChangeUserJob(t)}
                                placeholder={<FormattedMessage defaultMessage={'Contract'} />}
                                showSearch
                                filterOption={true}
                                optionFilterProp="label">
                                {this.state.contractsForYear?.map(t => <Select.Option label={t.name} value={t.id} key={`configurations-select-userjob-${t.id}`}>{t.name}</Select.Option>)}
                            </Select>
                            <p style={{ width: '40%', marginLeft: '10px', }}>{daysOffByType.typeOfDayOff?.title}</p>
                            <InputNumber
                                style={{ marginLeft: '10px', width: '20%', minWidth: '20%' }}
                                placeholder={intl.formatMessage({ defaultMessage: 'Number of days' })}
                                value={daysOffByType.numberOfDays ? daysOffByType.numberOfDays : 0}
                                onChange={(e) => this.onChangeNumberOfDays(toFinite(e))} />
                        </div>
                        :
                        (this.props.daysOffByType.id ?
                            <div className="team-periods-list-item">
                                <p style={{ width: '40%' }}>{this.props.daysOffByType.userJob ? this.props.daysOffByType.userJob.name : '-'}</p>
                                <p style={{ width: '40%' }}>{this.props.daysOffByType.typeOfDayOff?.title}</p>
                                <p style={{ width: '20%' }}>{(this.props.daysOffByType.numberOfDays !== undefined) ? this.props.daysOffByType.numberOfDays : ((this.props.daysOffByType.typeOfDayOff && this.props.daysOffByType.typeOfDayOff.daysPerYear) ? <FormattedMessage defaultMessage={'Default value ({days})'} values={{ days: this.props.daysOffByType.typeOfDayOff.daysPerYear }} /> : "-")}</p>
                            </div>
                            :

                            <div className="team-periods-list-item">
                                <Select
                                    style={{ width: '40%' }}
                                    // className="configurations-section-field"
                                    value={daysOffByType?.userJob?.id}
                                    onChange={(t) => this.onChangeUserJob(t)}
                                    placeholder={<FormattedMessage defaultMessage={'Contract'} />}
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.state.contractsForYear?.map(t => <Select.Option label={t.name} value={t.id} key={`configurations-select-userjob-${t.id}`}>{t.name}</Select.Option>)}
                                </Select>
                                <Select
                                    style={{ marginLeft: '10px', width: '40%' }}
                                    value={daysOffByType?.typeOfDayOff?.id}
                                    onChange={(t) => this.onChangeTypeOfDayOff(t)}
                                    disabled={daysOffByType?.userJob?.id === undefined}
                                    placeholder={<FormattedMessage defaultMessage={'Type of vacation'} />}
                                    allowClear
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.daysOffNotUsed?.filter(d => d.id && !this.usedVacationTypesByContract().includes(d.id)).map(t => <Select.Option label={t.title} value={t.id ? t.id : 'undefined'} key={`configurations-select-tod-${t.id}`}>{t.title}</Select.Option>)}
                                </Select>
                                <InputNumber
                                    disabled={daysOffByType?.userJob?.id === undefined}
                                    style={{ marginLeft: '10px', width: '20%', minWidth: '20%' }}
                                    placeholder={intl.formatMessage({ defaultMessage: 'N° days' })}
                                    value={daysOffByType?.numberOfDays !== undefined ? daysOffByType.numberOfDays : undefined}
                                    onChange={(e) => this.onChangeNumberOfDays(toFinite(e))} />
                            </div>
                        )
                }
            </List.Item>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    user: state.teamManagement.user,
});

export default connect(mapStateToProps)(injectIntl(DaysOffByTypeItem));