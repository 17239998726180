import { Avatar, Divider, Empty, Popconfirm, Popover, Spin } from "antd";
import cloneDeep from 'lodash/cloneDeep';
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import { useFullName } from "../../../hooks/useUsers";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { UserJobWithUser } from "../../../utils/types/generalTypes";
import { NetworkDynamicFile } from "../../../utils/types/networkTypes";
import { showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import SwitchLabeled from "../../common/fields/switchLabeled";
import Card from "../../common/general/card";
import SimpleBloc from "../../common/general/simpleBloc";


export const Expiry = () => {
    const [contracts, setContracts] = useState<UserJobWithUser[]>([]);

    const [hideOld, setHideOld] = useState<boolean>(true);
    const [hideExclusion, setHideExclusion] = useState<boolean>(true);

    const [contractsLoading, setContractsLoading] = useState<boolean>(false);
    const contractsLoaded = useRef<boolean>(false);


    const [files, setFiles] = useState<NetworkDynamicFile[]>([]);

    const [filesLoading, setFilesLoading] = useState<boolean>(false);
    const filesLoaded = useRef<boolean>(false);
    const fullName = useFullName();

    const [ignoreLoadingIds, setIgnoreLoadingIds] = useState<number[]>([]);

    const history = useHistory();
    const { lang } = useParams<{ lang?: string; }>();

    const intl = useIntl();

    const loadContracts = useCallback((force = false) => {
        if (force || (!contractsLoading && !contractsLoaded.current)) {
            setContractsLoading(true);
            Network.getDashboardExpiryContracts().then(
                response => {
                    contractsLoaded.current = true;
                    setContracts(response);
                    setContractsLoading(false);
                },
                () => {
                    setContractsLoading(false);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the contracts' }), "warning");
                }
            );
        }
    }, [contractsLoading, intl]);

    const loadFiles = useCallback((force = false) => {
        if (force || (!filesLoading && !filesLoaded.current)) {
            setFilesLoading(true);
            Network.getDashboardExpiryFiles().then(
                response => {
                    filesLoaded.current = true;
                    setFiles(response);
                    setFilesLoading(false);
                },
                () => {
                    filesLoaded.current = true;

                    setFilesLoading(false);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                }
            );
        }
    }, [filesLoading, intl]);

    useEffect(() => {
        if (!contractsLoading && !contractsLoaded.current) {
            loadContracts();
        }
    }, [contractsLoading, loadContracts]);

    useEffect(() => {
        if (!filesLoading && !filesLoaded.current) {
            loadFiles();
        }
    }, [filesLoading, loadFiles]);

    const filteredContracts = useMemo((): UserJobWithUser[] => {
        if (contractsLoading && !contractsLoaded.current)
            return [];

        let expiryContractsFiltered = cloneDeep(contracts);

        if (hideExclusion) {
            expiryContractsFiltered = contracts.filter(e => e.expiration_exclusion !== true);
        }
        if (hideOld) {
            const nowDate = moment();
            expiryContractsFiltered = expiryContractsFiltered.filter(e => moment(e.contract_expiry_date).diff(nowDate, "days") > -30);
        }

        return expiryContractsFiltered;
    }, [contracts, contractsLoading, hideExclusion, hideOld]);

    const filteredFiles = useMemo((): NetworkDynamicFile[] => {
        if (filesLoading && !filesLoaded.current)
            return [];

        let filesFiltered = cloneDeep(files);
        if (hideOld) {
            const nowDate = moment();
            filesFiltered = filesFiltered.filter(e => moment(e.expiryDate).diff(nowDate, "days") > -30);
        }

        return filesFiltered;

    }, [files, filesLoading, hideOld]);

    const goTo = useCallback((link: string): void => history.push(`/${lang}/${link}`), [lang, history]);

    // const addAutomaticExclusion = useCallback((user: User, userJob: UserJobWithUser) => {
    //     const expiryContracts = cloneDeep(contracts);
    //     if (userJob.contract_expiry_date === undefined) return;
    //     const editPeriod: PlanningPeriodEdit = {
    //         startDate: moment(userJob.contract_expiry_date).clone().add(1, "day"),
    //         endDate: moment(userJob.contract_expiry_date).clone().add(100, "year"),
    //         user: user,
    //         group: undefined,
    //         title: "Fin de contrat (automatique)",
    //         rrule: {},
    //         isUserCreated: false
    //     };

    //     //update period
    //     if (editPeriod.startDate && editPeriod.endDate) {
    //         const editPeriodParsed: PlanningPeriod = {
    //             ...editPeriod,
    //             startDate: editPeriod.startDate,
    //             endDate: editPeriod.endDate
    //         }
    //         Network.updateExclusion(editPeriodParsed).then(
    //             () => {
    //                 Network.setUserJobExpirationExclusion(userJob.id);
    //                 const userJobFind = expiryContracts?.find(e => e.id === userJob.id);
    //                 if (userJobFind) {
    //                     userJobFind.expiration_exclusion = true;
    //                 }
    //                 setContracts(expiryContracts)
    //                 message.success("Exclusion ajoutée.");
    //             },
    //             () => {
    //                 showNotification("Un problème est survenu pendant la création de l'exclusion'", "error");
    //             }
    //         );
    //     } else {
    //         showNotification("Veuillez renseigner la date de début et la date de fin.", "error");
    //     }
    // }, [contracts])

    const handleIgnoreFileWarning = useCallback((id: number) => {
        setIgnoreLoadingIds(cloneDeep(ignoreLoadingIds).concat(id));

        Network.ignoreFileExpirationWarning(id).then(
            () => {
                setIgnoreLoadingIds(cloneDeep(ignoreLoadingIds).filter(i => i !== id));

                const tmpFiles = cloneDeep(files).filter(f => f.id !== id);
                setFiles(tmpFiles);
            },
            () => {
                setIgnoreLoadingIds(cloneDeep(ignoreLoadingIds).filter(i => i !== id));

                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while ignoring the warning' }), 'error');
            }

        );
    }, [files, ignoreLoadingIds, intl]);

    return (
        <Card
            className="dashboard-card dashboard-card-500"
            title={<FormattedMessage defaultMessage={'Due dates'} />}
            icon={<FAIcon prefix='fad' name='calendar-exclamation' />}
            containerClassName="dashboard-scrollable"
            headerElements={[
                <CircleButton
                    small
                    loading={contractsLoading}
                    disabled={contractsLoaded.current === false}
                    key="team-periods-add-button"
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    title={intl.formatMessage({ defaultMessage: 'Update data' })}
                    onClick={() => {
                        loadFiles(true);
                        loadContracts(true);
                    }} />
            ]}>
            {

                (contracts === undefined && files === undefined) ?
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                    :
                    <>
                        <SimpleBloc>
                            <div style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: 'wrap' }}>
                                <SwitchLabeled
                                    onChange={setHideOld}
                                    label={intl.formatMessage({ defaultMessage: 'Hide the olds' })}
                                    checked={hideOld}
                                    checkedChildren={<FAIcon prefix='far' name='check' />}
                                    unCheckedChildren={<FAIcon prefix='far' name='xmark' />}
                                />
                                <SwitchLabeled
                                    onChange={setHideExclusion}
                                    label={intl.formatMessage({ defaultMessage: 'Hide auto exclusion' })}
                                    checked={hideExclusion}
                                    checkedChildren={<FAIcon prefix='far' name='check' />}
                                    unCheckedChildren={<FAIcon prefix='far' name='xmark' />}
                                />
                            </div>
                        </SimpleBloc>


                        <div>
                            <Divider className="dashboard-section-title"><FormattedMessage defaultMessage={'Contracts'} /></Divider>

                            {!contractsLoading ?
                                filteredContracts.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No due date for the selected filters'} />} />
                                    :
                                    <>
                                        {
                                            filteredContracts.sort((a, b) => {
                                                if (a.contract_expiry_date === undefined) {
                                                    return -1;
                                                } else if (b.contract_expiry_date === undefined) {
                                                    return 1;
                                                }
                                                if (a.contract_expiry_date > b.contract_expiry_date) {
                                                    return 1;
                                                } else if (a.contract_expiry_date < b.contract_expiry_date) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            }).map((c, idx) => (
                                                <div
                                                    className="dashboard-contract"
                                                    onClick={() => goTo(`team-management/user-details/informations?id=${c.user?.id}`)}
                                                    key={`dashboard-contracts-user-${c.user?.id}-${idx}`}>
                                                    <span><Avatar src={c.user?.image} icon={<FAIcon prefix='fad' name='user' />} size="small" className="dashboard-user-avatar" style={{ backgroundColor: 'var(--primary-color)' }} />{c.user && fullName.getFullName(c.user)}</span>
                                                    <span>
                                                        {moment(c.contract_expiry_date).format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR'))}
                                                        {/* {
                                                            c.expiration_exclusion ?
                                                                <BiCalendarCheck className='anticon' style={{ marginLeft: "5px", fontSize: '18px', color: GREEN_COLOR }} title='Une exclusion automatique existe déjà' />
                                                                :
                                                                <BiCalendarX className='anticon' style={{ marginLeft: "5px", fontSize: '18px' }} title="Créer une exclusion" onClick={(e) => { e.stopPropagation(); c.user && addAutomaticExclusion(c.user, c) }} />
                                                        } */}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </>
                                : <div style={{ textAlign: 'center' }}>
                                    <Spin />
                                </div>
                            }
                        </div>



                        <div>
                            <Divider className="dashboard-section-title"><FormattedMessage defaultMessage={'Files'} /></Divider>

                            {!filesLoading ?
                                filteredFiles.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No due date for the selected filters'} />} />
                                    :
                                    <>
                                        {
                                            filteredFiles.sort((a, b) => {
                                                if (a.expiryDate === undefined) {
                                                    return -1;
                                                } else if (b.expiryDate === undefined) {
                                                    return 1;
                                                }
                                                if (a.expiryDate > b.expiryDate) {
                                                    return 1;
                                                } else if (a.expiryDate < b.expiryDate) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            }).map((c, idx) => (
                                                <Spin
                                                    key={`dashboard-contracts-user-${c.user?.id}-${idx}`}
                                                    spinning={ignoreLoadingIds.includes(c.id)}
                                                >
                                                    <div
                                                        className="dashboard-contract"
                                                        onClick={() => goTo(`team-management/user-details/informations?id=${c.user?.id}`)}
                                                    >
                                                        <span><Avatar src={c.user?.image} icon={<FAIcon prefix='fad' name='user' />} size="small" className="dashboard-user-avatar" style={{ backgroundColor: 'var(--primary-color)' }} />{`${c.user?.last_name} ${c.user?.first_name}`}</span>
                                                        <span>
                                                            {
                                                                c.note !== undefined && c.note !== null ?
                                                                    <Popover content={c.note}>
                                                                        <FAIcon prefix='fad' name='memo' style={{ paddingRight: '5px' }} />
                                                                    </Popover>
                                                                    :
                                                                    undefined
                                                            }
                                                            {c.field.name}
                                                        </span>
                                                        <span className='dashboard-expiration-file-action'>
                                                            {moment(c.expiryDate).format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR'))}
                                                            <Popconfirm
                                                                title={<FormattedMessage defaultMessage={'Are you sure you want to ignore this warning?'} />}
                                                                onCancel={(e) => e?.stopPropagation()}
                                                                okText={<FormattedMessage defaultMessage={'Yes'} />}
                                                                onConfirm={(e) => {
                                                                    e?.stopPropagation();
                                                                    handleIgnoreFileWarning(c.id);
                                                                }}
                                                                placement='left'
                                                            >
                                                                <CircleButton
                                                                    small
                                                                    title={intl.formatMessage({ defaultMessage: 'Ignore warning' })}
                                                                    icon={<FAIcon prefix='fad' name='calendar-exclamation' />}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                />
                                                            </Popconfirm>
                                                        </span>

                                                    </div>
                                                </Spin>
                                            ))
                                        }
                                    </>
                                :
                                <div style={{ textAlign: 'center' }}>
                                    <Spin />
                                </div>
                            }
                        </div>



                    </>
            }

        </Card>
    );
};
export default Expiry;