
import { Avatar, Image } from "antd";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { useFullName } from "../../../hooks/useUsers";
import { isNullOrEmpty } from "../../../utils/utils";
import FAIcon from "../FAIcon";

export interface UserSmall {
    first_name: string;
    last_name: string;
    image?: string;
    code?: string;
    id: number;
}
interface Props {
    user: UserSmall;
    withAvatar?: boolean;
    avatarSize?: 'large' | 'small' | 'default';
    textStyle?: CSSProperties;
}


const FullUser = React.memo((props: Props) => {
    const { user, withAvatar, avatarSize, textStyle } = props;

    // Appel du hook à la racine du composant
    const fullNameProvider = useFullName();
    const fullName = useMemo(() => fullNameProvider.getFullName(user), [user, fullNameProvider]);


    const handleAvatarClick = useCallback((e?: React.MouseEvent) => {
        e?.stopPropagation();
    }, []);

    const handleImageClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    return (
        <div className="full_user">
            {withAvatar && (
                <Avatar
                    onClick={handleAvatarClick}
                    size={avatarSize ?? 'large'}
                    src={
                        isNullOrEmpty(user.image)
                            ? undefined
                            : (
                                <Image
                                    onClick={handleImageClick}
                                    preview={{ mask: <FAIcon prefix="fad" name="eye" /> }}
                                    // height="40px"
                                    // width="40px"
                                    src={user.image}
                                    style={{ objectFit: 'cover' }}
                                />
                            )
                    }
                    icon={<FAIcon prefix="fad" name="user" />}
                    style={{ backgroundColor: 'var(--primary-color)' }}
                />
            )}
            <span style={textStyle} title={fullName} className="full_name">{fullName}</span>
        </div>
    );
});

FullUser.displayName = "FullUser";

export default FullUser;