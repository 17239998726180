import { Avatar, Badge, Col, Divider, Menu, Modal, Row } from 'antd';
import Cookie from 'js-cookie';
import React from 'react';
import { FormattedMessage, WrappedComponentProps as IntlProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LogoFull from '../../../images/logo/logo_sunkhronos_full.png';
import SwisskischoolLogo from '../../../images/svg/swisskischool.svg';
import { CompanyDetailsPrivilege, Privileges } from '../../../privileges';
import { Rules } from '../../../rbacRules';
import { reset as resetConfiguration } from '../../../store/actions/configurations';
import { reset as resetCRM } from '../../../store/actions/crm';
import { reset as resetLocation } from '../../../store/actions/location';
import { reset as resetPlanning } from '../../../store/actions/planning';
import { reset as resetPosts } from '../../../store/actions/posts';
import { reset as resetTeamManagement } from '../../../store/actions/teamManagement';
import { reset as resetUser } from '../../../store/actions/user';
import { toggleDarkMode, toggleNavigationCollapsed } from '../../../store/actions/window';
import { reset as resetCustomerManagement } from '../../../store/features/customerManagement';
import { reset as resetIntegrations } from '../../../store/features/integrationsSlice';
import { reset as resetNews, resetNewsCount } from '../../../store/features/newsSlice';
import '../../../styles/sideMenu.css';
import { CaseType } from '../../../utils/constants';
import Network from '../../../utils/network';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { checkCompanyDetailsPrivilege, checkPrivilege, checkRBACRule, getCaseAndPlural, showNotification } from '../../../utils/utils';
import { CustomerManagementTabLink } from '../../customersManagement/customersManagement';
import { ProjectManagementTabLink } from '../../projectV2/projectManagement';
import { getMenusForCompanyAndUser as getReportsMenusForCompany } from '../../reportv2/reportsManagement';
import { WorkControlManagementTabLink } from '../../workControl/v2/workControlManagement';
import FAIcon from '../FAIcon';
import CheckVersionButton from '../fields/checkVersionButton';
import Anticon from '../general/anticon';
import Can from '../general/can';
import LanguageSwitcher from '../general/languageSwitcher';
import NewsCard from '../general/newsCard';
import injectIntlHOC from '../wrapper/injectIntlHOC';

const scrollableItemsId = "menu-items-scrollable";

export const enum Direction {
    Vertical = "v",
    Horizontal = "h"
}
export const checkScroll = (el: any, d: Direction) => {
    const direction = (d === Direction.Vertical) ? 'scrollTop' : 'scrollLeft';
    try {
        let result = !!el[direction];

        if (!result) {
            el[direction] = 1;
            result = !!el[direction];
            el[direction] = 0;
        }
        return result;
    } catch (error) {
        return false;
    }
};
//the menu keys
export enum MenuKeys {
    CurrentUser = "c_u",
    Logout = "l_o",
    Dashboard = "da",
    Newsfeed = "n",
    Messages = "ms",
    DirectoryListing = "d",
    Team = "t",
    CustomerManagement = "cm",
    Course = "c_o",
    SunkhronosLive = "s_l",
    SunkhronosLiveMap = "s_l_m",
    Emergencies = "e",
    MobileApp = 'mobile-app',
    Administration = "a",
    OldProject = "opr",
    Project = "pr",
    Planning = "p",
    MonthlyPlanning = "m_p",
    MonthlyPlanningBeta = "m_p_b",
    MonthlyPlanningPerf = "m_p_b-p",
    Report = "r",
    Reportv2 = "r_2",
    Reports = "r_3",
    Configurations = "c",
    CRM = "crm",
    Help = "he",
    Upgarde = "upg",
    LegacyWorkControl = "lwrk_ctrl",
    WorkControl = "wrk_ctrl",
    Integrations = "integrations",
    News = 'news'
}

//the menu links
export enum MenuLinks {
    Dashboard = "dashboard",
    Newsfeed = "newsfeed",
    Messages = "messages",
    DirectoryListing = "directory-listing",
    Team = "team-management",
    CustomerManagement = "crm",
    Course = "course-management",
    SunkhronosLive = "sunkhronos-live",
    SunkhronosLiveMap = "sunkhronos-live-map",
    Emergencies = "emergencies",
    MobileApp = "mobile-app",
    Administration = "administration",
    LegacyProject = "legacy-projects",
    Project = "projects",
    Planning = "planning-old",
    MonthlyPlanning = "monthly-planning",
    MonthlyPlanningBeta = "planning",
    MonthlyPlanningPerf = "planning-v2",
    Report = "report",
    Reportv2 = "reportv2",
    Reports = "reports",
    Configurations = "configurations",
    CRM = "customer-relationship-management",
    Help = "help",
    Upgarde = "upgrade",
    LegacyWorkControl = "legacy-workcontrol",
    WorkControl = "work-control",
    Integrations = "integrations"
}

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends RouterProps, ReduxProps, IntlProps { }

interface State {
    scrollableItemAtTop: boolean;
    scrollableItemAtBottom: boolean;
    showNewsModal: boolean;
}

/**
 * Component that represent the side menu navigation bar 
 */
class SideMenu extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            scrollableItemAtTop: true,
            scrollableItemAtBottom: false,
            showNewsModal: false,
        };
    }

    /**
     * Go to an address
     * @param link the link to load
     */
    goTo = (link: string) => {
        this.props.history.push(`/${this.props.match.params.lang}/${link}`);
    };

    /**
     * Get the selected and opened keys according to current address
     */
    getSelectedKey = (): string[] => {
        const group = this.props.match.url.split("/")[2];

        switch (group) {
            case MenuLinks.Newsfeed:
                return [MenuKeys.Newsfeed];
            case MenuLinks.Messages:
                return [MenuKeys.Messages];
            case MenuLinks.Team:
                return [MenuKeys.Team];
            case MenuLinks.Course:
                return [MenuKeys.Course];
            case MenuLinks.DirectoryListing:
                return [MenuKeys.DirectoryListing];
            case MenuLinks.Emergencies:
                return [MenuKeys.Emergencies];
            case MenuLinks.MobileApp:
                return [MenuKeys.MobileApp];
            case MenuLinks.Administration:
                return [MenuKeys.Administration];
            case MenuLinks.Project:
                return [MenuKeys.Project];
            case MenuLinks.LegacyProject:
                return [MenuKeys.OldProject];
            case MenuLinks.CustomerManagement:
                return [MenuKeys.CustomerManagement];
            case MenuLinks.Planning:
                return [MenuKeys.Planning];
            case MenuLinks.MonthlyPlanning:
                return [MenuKeys.MonthlyPlanning];
            case MenuLinks.MonthlyPlanningBeta:
                return [MenuKeys.MonthlyPlanningBeta];
            case MenuLinks.MonthlyPlanningPerf:
                return [MenuKeys.MonthlyPlanningPerf];
            case MenuLinks.Report:
                return [MenuKeys.Report];
            case MenuLinks.Reportv2:
                return [MenuKeys.Reportv2];
            case MenuLinks.Reports:
                return [MenuKeys.Reports];
            case MenuLinks.Configurations:
                return [MenuKeys.Configurations];
            case MenuLinks.CRM:
                return [MenuKeys.CRM];
            case MenuLinks.Help:
                return [MenuKeys.Help];
            case MenuLinks.SunkhronosLive:
                return [MenuKeys.SunkhronosLive];
            case MenuLinks.SunkhronosLiveMap:
                return [MenuKeys.SunkhronosLiveMap];
            case MenuLinks.LegacyWorkControl:
                return [MenuKeys.LegacyWorkControl];
            case MenuLinks.WorkControl:
                return [MenuKeys.WorkControl];
            case MenuLinks.Integrations:
                return [MenuKeys.Integrations];
            default:
                return [MenuKeys.Dashboard];
        }
    };
    resetAllStore = () => {

        console.log("RESET ALL STORE CALLED 1");
        this.props.resetPosts();
        this.props.resetPlanning();
        this.props.resetTeamManagement();
        this.props.resetCRM();
        this.props.resetConfiguration();
        this.props.resetLocation();
        this.props.resetUser();
        this.props.resetCustomerManagement();
        this.props.resetIntegrations();
        this.props.resetNews();
    };
    /**
     * Handle log out of user
     */
    logout = () => {
        const token = Cookie.get('userId');
        if (token) {
            Network.logout(token);
        }
        Cookie.remove('userId');
        this.props.history.push(`/${this.props.match.params.lang}/login`);
        this.resetAllStore();

    };

    handleScroll = (e: any) => {
        const top = e.target.scrollTop === 0;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight + 1;
        if (top) {
            if (!this.state.scrollableItemAtTop) {
                this.setState({ scrollableItemAtBottom: false, scrollableItemAtTop: true });
            }
        } else if (bottom) {
            if (!this.state.scrollableItemAtBottom) {
                this.setState({ scrollableItemAtBottom: true, scrollableItemAtTop: false });
            }
        } else {
            if (this.state.scrollableItemAtTop || this.state.scrollableItemAtBottom) {
                this.setState({ scrollableItemAtBottom: false, scrollableItemAtTop: false });
            }
        }
    };

    renderUserItem = () => {
        const { collapsed, currentUser, intl } = this.props;
        return (
            <>
                {
                    collapsed ?
                        <Avatar src={currentUser?.image} icon={<FAIcon prefix={'fad'} name="user" />} size={34} style={{ marginLeft: '-8px', backgroundColor: 'var(--primary-color)' }} />
                        :
                        <span className="sidemenu-submenu" style={{ justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar src={currentUser?.image} icon={<FAIcon prefix={'fad'} name="user" />} size={34} style={{ backgroundColor: 'var(--primary-color)' }} />
                                <p className="text-disable-select" style={{ maxWidth: '110px' }}><span className='__ellipsis-text' title={currentUser?.username}>{currentUser?.username}</span></p>
                                <Can rule={Rules.Sidemenu.Admin}>
                                    <Anticon><FAIcon prefix={'fad'} name="crown" size='xs' /></Anticon>
                                </Can>
                            </div>
                            <LanguageSwitcher collapsed={collapsed} />
                            <Anticon><FAIcon style={{ cursor: 'pointer' }} prefix={'fad'} name="right-from-bracket" onClick={this.logout} title={intl.formatMessage({ defaultMessage: 'Logout' })} /></Anticon>
                        </span>
                }
            </>
        );
    };

    handleClose = () => {
        Network.putAdminNews().then(
            () => undefined,
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while setting the last seen news' }), 'error')
        );
        this.setState({ showNewsModal: false });
        this.props.resetNewsCount();
    };



    render() {
        const { intl } = this.props;
        const selectedKey = this.getSelectedKey();
        const { collapsed } = this.props;

        const version = process.env.REACT_APP_VERSION;

        const hasScroll = checkScroll(document.getElementById(scrollableItemsId), Direction.Vertical);
        const showTop = hasScroll && this.state.scrollableItemAtTop === false;
        const showBottom = hasScroll && this.state.scrollableItemAtBottom === false;

        const timeclockEnabled = checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser);

        return (
            <div className="sidemenu-background">
                {
                    this.props.isSmartphone ?
                        null
                        :
                        <>
                            <div className='sidemenu-collapse'>
                                <div className={`sidemenu-collapse-open ${collapsed ? 'hide' : ''}`}>
                                    <Anticon icon={<FAIcon prefix={'fad'} name="chevrons-left" style={{ fontSize: '12px' }} title={intl.formatMessage({ defaultMessage: 'Maximize the menu' })} onClick={() => this.props.toggleNavigationCollapsed()} />} />
                                </div>
                            </div>
                            <div className='sidemenu-collapse'>
                                <div className={`sidemenu-collapse-close ${collapsed ? '' : 'hide'}`}>
                                    <Anticon icon={<FAIcon prefix={'fad'} name="chevrons-right" style={{ fontSize: '12px' }} title={intl.formatMessage({ defaultMessage: 'Minimize the menu' })} onClick={() => this.props.toggleNavigationCollapsed()} />} />
                                </div>
                            </div>
                            {/* TODO: Dark mode temporary disable. Need better implementation */}
                            {/* <div className='sidemenu-darkmode'>
                                <div className={`sidemenu-darkmode-dark ${darkMode ? 'hide' : ''}`}>
                                    <Anticon icon={<MdDarkMode title='Dark mode' onClick={() => this.props.toggleDarkMode()} />} />
                                </div>
                            </div>
                            <div className='sidemenu-darkmode'>
                                <div className={`sidemenu-darkmode-light ${darkMode ? '' : 'hide'}`}>
                                    <Anticon icon={<MdLightMode title='Light mode' onClick={() => this.props.toggleDarkMode()} />} />
                                </div>
                            </div> */}
                        </>
                }
                <div className={`sidemenu-logo-span ${collapsed ? 'logo-small' : ''}`}>
                    <img src={LogoFull} className="sidemenu-logo-full" alt="small Sunkhronos logo" />
                </div>
                <Divider className='sidemenu-submenu-divider' />
                <div id={scrollableItemsId} style={{ flex: '1', overflow: 'auto', position: 'relative' }} onScroll={this.handleScroll}>
                    <div className={`sidemenu-top ${showTop ? '' : 'sidemenu-top-hide'}`}>
                        <FAIcon prefix='far' name='chevrons-up' color='white' />
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={selectedKey}
                        style={{ backgroundColor: 'transparent', overflowY: 'auto', marginTop: '-12px', marginBottom: '-12px' }}>
                        <Menu.Item key={MenuKeys.Dashboard} onClick={() => this.goTo(MenuLinks.Dashboard)}>
                            <Anticon><FAIcon prefix={'fad'} name="gauge" /></Anticon>
                            <span> <FormattedMessage defaultMessage={'Dashboard'} description={'Sidebar menu'} /></span>
                        </Menu.Item>
                        <Menu.Item key={MenuKeys.Newsfeed} onClick={() => this.goTo(MenuLinks.Newsfeed + '/list')}>
                            <Anticon><FAIcon prefix={'fad'} name="newspaper" /></Anticon>
                            <span> <FormattedMessage defaultMessage={'News'} description={'Sidebar menu'} /></span>
                        </Menu.Item>
                        <Menu.Item key={MenuKeys.Messages} onClick={() => this.goTo(MenuLinks.Messages + '/list')}>
                            <Anticon><FAIcon prefix={'fad'} name="messages" /></Anticon>
                            <span> <FormattedMessage defaultMessage={'Messages'} description={'Sidebar menu'} /></span>
                        </Menu.Item>
                        <Divider className='sidemenu-submenu-divider' />
                        {
                            checkRBACRule(Rules.TeamManagement.Visit, this.props.currentUser?.role) &&
                            <Menu.Item key={MenuKeys.Team} onClick={() => this.goTo(MenuLinks.Team + '/list')}>
                                <Anticon><FAIcon prefix={'fad'} name="users" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Team management'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            (checkRBACRule(Rules.Emergencies.Visit, this.props.currentUser?.role) ||
                                checkRBACRule(Rules.DirectoryListing.Visit, this.props.currentUser?.role)) &&
                            <Menu.Item key={MenuKeys.MobileApp} onClick={() => this.goTo(MenuLinks.MobileApp)}>
                                <Anticon><FAIcon prefix={'fad'} name="mobile-screen" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Mobile app'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                            <Menu.Item key={MenuKeys.Course} onClick={() => this.goTo(MenuLinks.Course + '/list')}>
                                <Anticon><FAIcon prefix={'fad'} name="location-dot" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Points of interest'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            checkRBACRule(Rules.CustomerManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                            <Menu.Item key={MenuKeys.CustomerManagement} onClick={() => this.goTo(`${MenuLinks.CustomerManagement}/${CustomerManagementTabLink.CUSTOMERS}`)}>
                                <Anticon><FAIcon prefix={'fad'} name="rectangle-history-circle-user" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'CRM'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && (this.props.currentUser?.company_detail?.ext === true ? false : true) && checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                            <Menu.Item key={MenuKeys.OldProject} onClick={() => this.goTo(`${MenuLinks.LegacyProject}`)} >
                                <Anticon><FAIcon prefix={'fad'} name="sitemap" /></Anticon>
                                <span> {this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}</span>
                            </Menu.Item>
                        }
                        {
                            !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && (this.props.currentUser?.company_detail?.ext === true ? false : true) && checkRBACRule(Rules.ProjectV2.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                            <Menu.Item key={MenuKeys.Project} onClick={() => this.goTo(`${MenuLinks.Project}/${ProjectManagementTabLink.LIST_OF_PROJECTS}`)} >
                                <Anticon><FAIcon prefix={'fad'} name="sitemap" /></Anticon>
                                <span> {this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}</span>
                            </Menu.Item>
                        }
                        <Divider className='sidemenu-submenu-divider' />
                        {/* {
                            checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.MonthlyPlanningBeta} onClick={() => this.goTo(MenuLinks.MonthlyPlanningBeta)}>
                                <Anticon><FAIcon prefix={'fad'} name="calendar-days" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Planning'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        } */}
                        {
                            checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.MonthlyPlanningPerf} onClick={() => this.goTo(MenuLinks.MonthlyPlanningPerf)}>
                                <Anticon><FAIcon prefix={'fad'} name="calendar-days" /></Anticon>
                                <span><FormattedMessage defaultMessage={'Planning'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {/* {
                            (checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && (
                                checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) ||
                                checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.Overtime.Visit, this.props.company, this.props.currentUser)
                            ) || checkRBACRule(Rules.SunkhronosLive.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) &&
                            <Menu.Item key={MenuKeys.LegacyWorkControl} onClick={() => this.goTo(MenuLinks.LegacyWorkControl)}>
                                <Anticon><FAIcon prefix={'fad'} name="clipboard-check" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Work control'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        } */}
                        {
                            (checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && (
                                checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) ||
                                checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.Overtime.Visit, this.props.company, this.props.currentUser)
                            ) || checkRBACRule(Rules.SunkhronosLive.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) &&
                            <Menu.Item key={MenuKeys.WorkControl} onClick={() => this.goTo(`${MenuLinks.WorkControl}/${timeclockEnabled ? WorkControlManagementTabLink.TIME_TRACKING : WorkControlManagementTabLink.OVERTIME_TRACKING}`)}>
                                <Anticon><FAIcon prefix={'fad'} name="clipboard-check" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Work control'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.CRM} onClick={() => this.goTo(`${MenuLinks.CRM}/products`)} >
                                <img alt='' src={SwisskischoolLogo} className={collapsed ? "sidemenu-image-collapsed" : "sidemenu-image"} style={{ filter: selectedKey[0] === MenuKeys.CRM ? 'invert(0%) sepia(0%) saturate(0%) hue-rotate(94deg) brightness(200%) contrast(102%)' : undefined }} />
                                <span> <FormattedMessage defaultMessage={'CRM'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {/* {
                            checkRBACRule(Rules.Report.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) && checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.Reportv2} onClick={() => this.goTo(MenuLinks.Reportv2)} >
                                <Anticon><FAIcon prefix={'fad'} name="file-invoice" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Reports'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        } */}
                        {
                            checkRBACRule(Rules.Report.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) && checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.Reports} onClick={() => this.goTo(`${MenuLinks.Reports}/${getReportsMenusForCompany(this.props.currentUser, this.props.company)[0]}`)} >
                                <Anticon><FAIcon prefix={'fad'} name="file-invoice" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Reports'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            checkRBACRule(Rules.Integrations.Admin, this.props.currentUser?.role, undefined, this.props.currentUser?.groupsAdmin?.length) && checkPrivilege(Privileges.Integrations.Admin, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.Integrations} onClick={() => this.goTo(MenuLinks.Integrations)} className="sidemenu-swisskischool">
                                <Anticon><FAIcon prefix="fad" name="grid-2-plus" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Integrations'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                    </Menu>
                    <div className={`sidemenu-bottom ${showBottom ? '' : 'sidemenu-bottom-hide'}`}>
                        <FAIcon prefix='far' name='chevrons-down' color='#ffffffaa' />
                    </div>
                </div>
                <div >
                    {hasScroll ? <Divider className='sidemenu-submenu-divider' /> : null}
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={selectedKey}
                        style={{ backgroundColor: 'transparent' }}
                        className="sidemenu-footer-menu">
                        {
                            this.props.news && this.props.news?.length > 0 &&
                            <Menu.Item key={MenuKeys.News} onClick={() => this.setState({ showNewsModal: true })}>
                                <Anticon>
                                    {
                                        this.props.newsCount && this.props.newsCount > 0 ?
                                            <FAIcon prefix='fad' name='bell' bounce />
                                            :
                                            <FAIcon prefix='fad' name='bell' />
                                    }
                                </Anticon>
                                <span>
                                    <FormattedMessage defaultMessage={'News'} description={'Sidebar menu'} />
                                    <Badge count={this.props.newsCount} size='small' showZero={false} style={{ marginLeft: 10 }}></Badge>
                                </span>
                            </Menu.Item>
                        }
                        <Menu.Item key={MenuKeys.Help} onClick={() => this.goTo(`${MenuLinks.Help}`)}>
                            {collapsed ?
                                <>
                                    <Anticon><FAIcon prefix={'fad'} name="circle-question" /></Anticon>
                                    <span><FormattedMessage defaultMessage={'Help'} description={'Sidebar menu'} /></span>
                                </>
                                :
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} title={version}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Anticon><FAIcon prefix={'fad'} name="circle-question" /></Anticon>
                                        <span> <FormattedMessage defaultMessage={'Help'} description={'Sidebar menu'} /></span>
                                    </div>
                                    <CheckVersionButton
                                        updateGoToReleaseNotes
                                        updatingButtonTooltip={intl.formatMessage({ defaultMessage: 'See version notes' })}
                                        updatingButtonTitle=''
                                        loadingButtonTitle=''
                                        buttonStyle={{ background: 'none', color: 'rgba(255, 255, 255, 0.65)', border: "none" }}
                                        tooltipTitle='Rechercher de nouvelles mises à jour'
                                    />
                                </div>
                            }
                        </Menu.Item>

                        {
                            checkRBACRule(Rules.Configurations.Visit, this.props.currentUser?.role) && checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                            <Menu.Item key={MenuKeys.Configurations} onClick={() => this.goTo('configurations')}>
                                <Anticon><FAIcon prefix={'fad'} name="gear" /></Anticon>
                                <span> <FormattedMessage defaultMessage={'Configurations'} description={'Sidebar menu'} /></span>
                            </Menu.Item>
                        }
                        {
                            collapsed ?
                                <Menu.SubMenu key={MenuKeys.CurrentUser} className="sidemenu-submenu-user" title={this.renderUserItem()}>
                                    <Menu.Item key={MenuKeys.Logout} onClick={this.logout}>
                                        <Anticon><FAIcon prefix={'fad'} name="right-from-bracket" /></Anticon>
                                        <span><FormattedMessage defaultMessage={'Logout'} description={'Sidebar menu'} /></span>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <LanguageSwitcher collapsed={collapsed} />
                                    </Menu.Item>
                                </Menu.SubMenu>
                                :
                                <Menu.Item key={MenuKeys.CurrentUser} className="sidemenu-submenu-user">
                                    {this.renderUserItem()}
                                </Menu.Item>
                        }
                    </Menu>
                    {
                        this.state.showNewsModal &&
                        <Modal
                            key={'sunkhronos-news-modal'}
                            open={true}
                            title={<FormattedMessage defaultMessage={'Messages from Sunkhronos'} />}
                            footer={[]}
                            width={1200}
                            onCancel={() => this.handleClose()}
                        >
                            <Row gutter={[10, 10]}>
                                {
                                    this.props.news && this.props.news.map(news => (
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: this.props.news && this.props.news.length === 1 ? 24 : 12 }} key={`sunkhronos-news-${news.id}`}>
                                            <NewsCard
                                                title={news.title}
                                                tags={news.newsTags}
                                                date={news.startDate}
                                                content={news.content}
                                                type={news.newsType}
                                                image={news.image}
                                            />
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Modal>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
    collapsed: state.window.navigationCollapsed,
    darkMode: state.window.darkMode,
    group: state.teamManagement.groups && state.teamManagement.groups.length > 0 ? state.teamManagement.groups[0] : undefined,
    news: state.news.news.data?.news,
    newsCount: state.news.news.data?.count
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleNavigationCollapsed: () => dispatch(toggleNavigationCollapsed()),
    toggleDarkMode: () => dispatch(toggleDarkMode()),
    resetPosts: () => dispatch(resetPosts()),
    resetPlanning: () => dispatch(resetPlanning()),
    resetTeamManagement: () => dispatch(resetTeamManagement()),
    resetCRM: () => dispatch(resetCRM()),
    resetConfiguration: () => dispatch(resetConfiguration()),
    resetLocation: () => dispatch(resetLocation()),
    resetUser: () => dispatch(resetUser()),
    resetCustomerManagement: () => dispatch(resetCustomerManagement()),
    resetIntegrations: () => dispatch(resetIntegrations()),
    resetNews: () => dispatch(resetNews()),
    resetNewsCount: () => dispatch(resetNewsCount())
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntlHOC(withRouter(connector(SideMenu)));
