import { Input, Popconfirm, Switch, Tooltip } from "antd";
import isArray from "lodash/isArray";
import moment, { Moment } from "moment";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import rfdc from "rfdc";
import getFormat from "../../../../utils/Lang";
import { BreakTime } from "../../../../utils/types/planningTypes";
import FAIcon from "../../../common/FAIcon";
import { InputFieldOnChangeEvent } from "../../../common/fields/inputField";
import TimeRangePickerWrapper from "../../../common/fields/TimeRangePickerWrapper/TimeRangePickerWrapper";
import { automaticChangeEndDate } from "./modifyEvent";
const cloneDeep = rfdc({ proto: true });

type ValuePiece = Date | string | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface EditBreaktimeProps {
    breaktime: BreakTime;
    minDate: Moment;
    maxDate: Moment;
    onChange: (breaktime: BreakTime) => void
    delete: (breaktimeId: number) => void;
    className?: string;
    iconClassName?: string;
    title?: string;

    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const EditBreaktime: React.FC<EditBreaktimeProps> = (props: EditBreaktimeProps) => {
    const intl = useIntl();
    const [isNew] = useState<boolean>(props.breaktime.id < 0);

    const { onChange, breaktime, minDate, maxDate } = props;

    const onBreakimeChangeDates = useCallback((dates: Value) => {
        if (dates && isArray(dates)) {
            const editedBreaktime = cloneDeep(breaktime);
            dates.forEach((date, idx) => {
                if (date) {
                    const timeString = date instanceof Date ? moment(date).format("HH:mm:ss") : date;
                    const [hours, minutes, seconds] = timeString.split(":").map(Number);
                    if (idx === 0) editedBreaktime.startDate = minDate.clone().set({ hour: hours, minute: minutes, second: seconds });
                    else editedBreaktime.endDate = maxDate.clone().set({ hour: hours, minute: minutes, second: seconds });
                    [editedBreaktime.startDate, editedBreaktime.endDate] = automaticChangeEndDate(editedBreaktime.startDate, editedBreaktime.endDate);
                }
            })

            const [newStartDate, newEndDate] = automaticChangeEndDate(editedBreaktime.startDate, editedBreaktime.endDate);
            editedBreaktime.startDate = newStartDate;
            editedBreaktime.endDate = newEndDate;
            onChange(editedBreaktime);
        }
    }, [onChange, breaktime, minDate, maxDate]);

    return (
        <div
            title={props.title}
            className={props.className}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexWrap: 'wrap', width: '100%', gap: '5px' }}>
            <FAIcon className={props.iconClassName} prefix='fad' name='mug-saucer' />
            <Tooltip mouseEnterDelay={0.8} overlay={<FormattedMessage defaultMessage={'If the break is paid for by the employer, it will not be deducted from the duration of the event'} />} placement="top">
                <Switch
                    title={intl.formatMessage({ defaultMessage: 'Break paid by the employer' })}
                    style={{ backgroundColor: props.breaktime.isPaid ? 'var(--primary-color)' : undefined }}
                    size='small'
                    checked={props.breaktime.isPaid}
                    onClick={(e) => props.onChange({ ...props.breaktime, isPaid: e })}
                    checkedChildren={<FAIcon prefix='fad' name='dollar-sign' size='sm' />}
                    unCheckedChildren={<FAIcon prefix='fad' name='dollar-sign' size='sm' />}
                />
            </Tooltip>
            <Input
                className="border-transparent"
                status={props.breaktime.title.length < 1 ? "error" : ""}
                placeholder={`${intl.formatMessage({ defaultMessage: 'Title' })}*`}
                value={props.breaktime.title}
                style={{ flex: 1 }}
                onChange={(e: InputFieldOnChangeEvent) => props.onChange({ ...props.breaktime, title: e.target.value })} />
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <TimeRangePickerWrapper
                    className={"trp-bg-white border-transparent"}
                    shouldOpenClock={() => false}
                    openClockOnFocus={false}
                    clockIcon={null}
                    clearIcon={null}
                    format={getFormat("TIME")}
                    maxDetail="second"
                    onChange={onBreakimeChangeDates}
                    value={[props.breaktime.startDate!.format(getFormat("TIME")), props.breaktime.endDate!.format(getFormat("TIME"))]} />
                {
                    isNew ?
                        <FAIcon
                            prefix={"fad"}
                            name='trash-can'
                            style={{ cursor: 'pointer', width: '16px' }}
                            onClick={() => props.delete(props.breaktime.id)}
                        />
                        :
                        <Popconfirm
                            placement="left"
                            title={<FormattedMessage defaultMessage='Do you want to delete these break?' />}
                            okText={<FormattedMessage defaultMessage={'Delete'} />}
                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                            okButtonProps={{ type: 'link', danger: true }}
                            cancelButtonProps={{ type: 'link' }}
                            onConfirm={() => props.delete(props.breaktime.id)}
                        >
                            <FAIcon
                                prefix={"fad"}
                                name='trash-can'
                                style={{ cursor: 'pointer', width: '16px' }}
                            />
                        </Popconfirm>

                }
            </div>
        </div >
    )
};


export default EditBreaktime;


export function hasBreaktimeOverlap(breakTimes: BreakTime[] = []) {
    const sortedBreaks = [...breakTimes].sort((a, b) => a.startDate.diff(b.startDate));

    for (let i = 1; i < sortedBreaks.length; i++) {
        const prev = sortedBreaks[i - 1];
        const curr = sortedBreaks[i];

        if (curr.startDate.isBefore(prev.endDate)) {
            return true;
        }
    }

    return false;
}


export const findOverlappingBreakTimes = (breakTimes: BreakTime[]): { [key: number]: number[] } => {
    const overlaps: { [key: number]: number[] } = {};

    const sortedBreaks = [...breakTimes].sort((a, b) => a.startDate.diff(b.startDate));

    sortedBreaks.forEach((current, index) => {
        const overlappingIds: number[] = [];

        for (let i = index + 1; i < sortedBreaks.length; i++) {
            const next = sortedBreaks[i];

            if (current.startDate.isBefore(next.endDate) && next.startDate.isBefore(current.endDate)) {
                overlappingIds.push(next.id);
                if (!overlaps[next.id]) {
                    overlaps[next.id] = [];
                }
                overlaps[next.id].push(current.id);
            }
        }

        if (overlappingIds.length > 0) {
            overlaps[current.id] = overlappingIds;
        }
    });

    return overlaps;
};
