import { Modal, Spin } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useProjectName } from '../../../hooks/useProjectName';
import { useProjectsKeyed } from '../../../hooks/useProjects';
import { useTypeOfDayKeyed } from '../../../hooks/useTypeOfDays';
import { CaseType } from '../../../utils/constants';
import Network from '../../../utils/network';
import { Loaded, StatusType } from '../../../utils/types/networkTypes';
import { IWorkItem, IWorkItemConfig } from '../../../utils/types/productivityTypes';
import { showNotification } from '../../../utils/utils';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import WorkItemConfigCreateModal, { WorkItemConfigCreateRef } from './workItemConfigCreateModal';

// Wether to prevent creation / edition if no default config is or will be present. True will allow no default config. False will prevent having no default config
const DEFAULT_CONFIG_OPTIONAL = true;
export interface WorkItemConfigRef {
    setWorkItem: (workItem: IWorkItem) => void;
    clearWorkItem: () => void;
}

const WorkItemConfigModal = forwardRef((props, ref) => {
    const [workItem, setWorkItem] = useState<IWorkItem>();
    const [opened, setOpened] = useState(false);

    const handleOpen = useCallback((workItem: IWorkItem) => {
        setWorkItem(workItem);
        setOpened(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpened(false);
        setTimeout(() => setWorkItem(undefined), 300); // Time for the close animation to complete
    }, []);

    useImperativeHandle(ref, (): WorkItemConfigRef => ({
        setWorkItem: handleOpen,
        clearWorkItem: handleClose
    }));

    return (<Modal
        open={opened}
        onCancel={handleClose}
        title={workItem?.title}
        width={'80%'}
        style={{ width: '80%', maxWidth: '1200px', minWidth: '400px' }}
        footer={null}
        bodyStyle={{ padding: 0 }}
    >
        <WorkItemConfig workItem={workItem} />
    </Modal>
    );
});
WorkItemConfigModal.displayName = "WorkItemConfigModal";

interface WorkItemConfigProps {
    workItem?: IWorkItem;
}
const WorkItemConfig = (props: WorkItemConfigProps) => {
    const workItemId = props.workItem?.id;
    const [configs, setConfigs] = useState<Loaded<IWorkItemConfig[]>>({ status: StatusType.NONE });
    const configsLoadedForWorkItem = useRef<number>();
    const typeOfDays = useTypeOfDayKeyed();
    const projects = useProjectsKeyed();
    const projectTitle = useProjectName({ caseType: CaseType.FIRST_LETTER_UPPERCASE, isPlural: false });
    const intl = useIntl();
    const [configModalOpened, setConfigModalOpened] = useState(false);
    const configRef = useRef<WorkItemConfigCreateRef>();

    const loadConfigsForWorkItem = useCallback(async () => {
        configsLoadedForWorkItem.current = workItemId;
        if (workItemId) {
            setConfigs(s => ({ ...s, status: StatusType.PENDING }));
            try {
                const response = await Network.getWorkItemConfigs(workItemId);
                const workItemConfigs = response.data;
                setConfigs({ status: StatusType.FULFILLED, data: workItemConfigs, updatedAt: moment().toISOString() });
            }
            catch (e) {
                setConfigs({ status: StatusType.REJECTED });
                showNotification(intl.formatMessage({ defaultMessage: "An error occurred while loading productivity settings" }), "warning");
            }

        }
    }, [workItemId, intl]);

    const createNewConfig = useCallback(() => {
        setConfigModalOpened(true);
        configRef.current?.createConfig();
    }, []);

    const editConfig = useCallback((config: IWorkItemConfig) => {
        setConfigModalOpened(true);
        configRef.current?.setConfig(config);
    }, []);

    const doesConfigAlreadyExist = useCallback((config: Partial<Omit<IWorkItemConfig, "workItemId">>) => {
        // Les "==" sont voulu. Undefined ou null donc pas de "==="
        const configFound = configs.data?.findIndex(c => c.id !== config.id && c.mandateId == config.mandateId && c.projectId == config.projectId && c.typeOfDayId === config.typeOfDayId);
        return configFound !== undefined && configFound != -1;
    }, [configs]);

    const handleCreateConfig = useCallback(async (config: Partial<Omit<IWorkItemConfig, "workItemId">>) => {
        if (workItemId) {
            if (config.secondsPerItem && config.secondsPerItem > 0 && config.typeOfDayId) {
                if (!doesConfigAlreadyExist(config)) {
                    if (DEFAULT_CONFIG_OPTIONAL || (!config.mandateId && !config.projectId) || (configs.data && configs.data.findIndex(i => i.typeOfDayId === config.typeOfDayId && !i.mandateId && !i.projectId) >= 0)) {
                        try {
                            const response = await Network.createWorkItemConfig(workItemId, {
                                mandateId: config.mandateId ?? null,
                                projectId: config.projectId ?? null,
                                secondsPerItem: config.secondsPerItem,
                                typeOfDayId: config.typeOfDayId
                            });
                            setConfigs(c => ({
                                ...c,
                                data: c.data ? [
                                    ...c.data,
                                    response.data
                                ] : []
                            }));
                            setConfigModalOpened(false);
                        } catch (e) {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error has occurred' }), 'error');
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'Please create a default configuration first for this type of day.' }), 'error');
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'This specific configuration already exists' }), 'error');
                }
            } else {
                showNotification(intl.formatMessage({ defaultMessage: 'The configuration is not valid' }), 'error');
            }
        }
    }, [workItemId, doesConfigAlreadyExist, intl, configs]);

    const handleEditConfig = useCallback(async (config: Omit<IWorkItemConfig, "workItemId">) => {
        const configsWithoutEdited = configs.data?.filter(c => c.id !== config.id) ?? [];
        if (workItemId) {
            if (config.secondsPerItem && config.secondsPerItem > 0 && config.typeOfDayId) {
                if (!doesConfigAlreadyExist(config)) {
                    if (DEFAULT_CONFIG_OPTIONAL || (!config.mandateId && !config.projectId) || (configsWithoutEdited.findIndex(i => i.typeOfDayId === config.typeOfDayId && !config.mandateId && !config.projectId) >= 0)) {
                        try {
                            const response = await Network.editWorkItemConfig(workItemId, {
                                id: config.id,
                                mandateId: config.mandateId ?? null,
                                projectId: config.projectId ?? null,
                                secondsPerItem: config.secondsPerItem,
                                typeOfDayId: config.typeOfDayId
                            });
                            setConfigs(c => ({
                                ...c,
                                data: c.data ? c.data.map(c => c.id === response.data.id ? {
                                    ...c,
                                    mandateId: response.data.mandateId,
                                    projectId: response.data.projectId,
                                    typeOfDayId: response.data.typeOfDayId,
                                    secondsPerItem: response.data.secondsPerItem,
                                    workItemId: response.data.workItemId,
                                } : c) : []
                            }));
                            setConfigModalOpened(false);
                        } catch (e) {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error has occurred' }), 'error');
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'This will remove the default configuration. Please create another one.' }), 'error');
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'This specific configuration already exists' }), 'error');
                }
            } else {
                showNotification(intl.formatMessage({ defaultMessage: 'The configuration is not valid' }), 'error');
            }
        }
    }, [workItemId, doesConfigAlreadyExist, intl, configs]);

    const handleDeleteConfig = useCallback(async (config: IWorkItemConfig) => {
        if (workItemId) {
            await Network.deleteWorkItemConfig(workItemId, config);
            setConfigs(c => ({
                ...c,
                data: c.data ? c.data.filter(c => c.id !== config.id) : []
            }));
        }
    }, [workItemId]);

    const submitConfig = useCallback(() => {
        const config = configRef.current?.config;

        if (config) {
            if (config.id === -1)
                handleCreateConfig(config);
            else if (config.id)
                handleEditConfig({
                    id: config.id,
                    mandateId: config.mandateId ?? null,
                    projectId: config.projectId ?? null,
                    secondsPerItem: config.secondsPerItem ?? 0,
                    typeOfDayId: config.typeOfDayId ?? 0
                });
        }
    }, [handleCreateConfig, handleEditConfig]);

    useEffect(() => {
        if (configsLoadedForWorkItem.current != workItemId) {
            loadConfigsForWorkItem();
        }
    }, [workItemId, loadConfigsForWorkItem]);

    // Prevent deleting default config if project/mandate specific config exists. A work item cannot be only for a specific project or mandate. It has to have a default config per type of day.
    const canDeleteDefaultConfig = (typeOfDayId: number) => {
        const foundConfig = configs.data?.findIndex((i) => i.typeOfDayId === typeOfDayId && (i.projectId || i.mandateId)) ?? -1;
        if (foundConfig >= 0) {
            return false;
        }
        return true;

    };

    const columns: ColumnProps<IWorkItemConfig>[] = [
        {
            title: intl.formatMessage({ defaultMessage: "Type of day" }),
            render: (_, config) => (
                <div>{typeOfDays[config.typeOfDayId]?.title ?? ''}</div>
            )
        },
        {
            title: projectTitle,
            render: (_, config) => {
                if (!config.projectId && !config.mandateId) {
                    return (<div className='__wic-default-text'>{intl.formatMessage({ defaultMessage: "By default" })}</div>);
                }
                else if (config.projectId) {
                    return (<div>{projects[config.projectId]?.title ?? ''}</div>);
                } else if (config.mandateId) {
                    return (<div>{config.mandateId}</div>);
                }
            }
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Time per unit' }),
            align: 'right',
            className: '__width_160',
            render: (_, config) => (
                <div>{config.secondsPerItem}s</div>
            )
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Actions' }),
            align: 'right',
            className: '__width_160',
            render: (_, config) => (
                <div className='__wic_actions'>
                    <CircleButton
                        small
                        placement='topLeft'
                        title={intl.formatMessage({ defaultMessage: 'Settings' })}
                        icon={<FAIcon prefix='fad' name='gear' size="2x" />}
                        onClick={() => editConfig(config)}
                    />
                    {(config.projectId || config.mandateId) || canDeleteDefaultConfig(config.typeOfDayId) ?
                        <DeleteButton
                            small
                            onConfirm={() => handleDeleteConfig(config)}
                            text={<><p>{intl.formatMessage({ defaultMessage: "Are you sure that you want to delete this record ?" })}</p><p className='error'>{intl.formatMessage({ defaultMessage: "This action is irreversible and might cause productivity reports to change retro-actively !" })}</p></>}
                        />
                        : <></>
                    }
                </div>
            )
        }
    ];

    if (!props.workItem || configs.status !== StatusType.FULFILLED) {
        return (<div style={{ display: 'flex', justifyContent: 'center', padding: 10, width: '100%' }}><Spin size={'large'} /></div>);
    }
    return (<div>
        {configs.data.length > 0 ? <Table
            columns={columns}
            dataSource={configs.data ?? []}
            pagination={false}
        /> : null}
        <div className='__wic-add-row __clickable' onClick={createNewConfig}>
            <FAIcon prefix='fad' name='circle-plus' color='grey' /> <FormattedMessage defaultMessage={"Add configuration"} />
        </div>

        <Modal
            open={configModalOpened}
            onCancel={() => setConfigModalOpened(false)}
            onOk={submitConfig}
            destroyOnClose={false}
            forceRender={true}
        >
            <WorkItemConfigCreateModal ref={configRef} workItemConfigs={configs.data} />
        </Modal>
    </div>);
};

export default WorkItemConfigModal;