import { useEffect, useRef, useState } from "react";

const interpolate = (value: number, x: [number, number], y: [number, number]) => {
    if (x[1] === x[0]) throw new Error("x0 and x1 cannot be equal (division by zero).");
    return y[0] + ((value - x[0]) * (y[1] - y[0])) / (x[1] - x[0]);
};

interface Props {
    svgString: string;
    angleForSquareCase?: boolean;
}

const MAXIMUM_TILT_ANGLE = -40;
const MINIMUM_TILT_ANGLE = -20;

const SvgComponent = (props: Props) => {
    const { svgString } = props;
    const ref = useRef<HTMLDivElement>(null);
    const [angle, setAngle] = useState(0);

    useEffect(() => {
        if (ref.current) {
            ref.current.innerHTML = svgString;
            const svg = ref.current.querySelector('svg');
            if (svg) {
                const width = parseFloat(svg.getAttribute('width') ?? '0');
                const height = parseFloat(svg.getAttribute('height') ?? '0');
                const widthFactor = width / height;
                setAngle(widthFactor);
            }
        }
    }, [svgString]);

    let calcAngle = Math.min(Math.max((interpolate(angle, [1, 3], [0, MAXIMUM_TILT_ANGLE])), MAXIMUM_TILT_ANGLE), 0);
    calcAngle = calcAngle > MINIMUM_TILT_ANGLE ? 0 : calcAngle;

    return <div style={{ transform: `rotateZ(${props.angleForSquareCase ? calcAngle : 0}deg)` }} ref={ref} />;
};

export default SvgComponent;