import React, { CSSProperties } from 'react';
import { withRouter } from 'react-router-dom';
import { RouterProps } from '../../../utils/types/generalTypes';

interface IProps {
    children: React.ReactNode;
    title: React.ReactNode;
    containerStyle?: CSSProperties;
    textStyle?: CSSProperties;
    className?: string;
}

type Props = IProps & RouterProps;

interface State {

}

class Bloc extends React.Component<Props, State> {

    render() {
        const { title, children, containerStyle, textStyle, className } = this.props;
        return (
            <div style={{ position: 'relative', border: 'var(--border)', borderRadius: 'var(--border-radius)', padding: '18px 15px 15px 15px' }} className={className}>
                <p style={{ fontSize: '95%', position: 'absolute', top: '-11.9px', left: '18px', backgroundColor: 'white', paddingLeft: '8px', paddingRight: '8px', ...textStyle }}>{title}</p>
                <div style={containerStyle}>{children}</div>
            </div>
        );
    }
}

export default withRouter(Bloc);