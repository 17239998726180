import { TimeRangePicker, TimeRangePickerProps } from "@wojtekmaj/react-timerange-picker";
import React, { useEffect, useRef } from "react";
import './TimeRangePicker.css';

const TimeRangePickerWrapper: React.FC<TimeRangePickerProps> = (props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        if (!wrapper) return;

        const inputs = wrapper.querySelectorAll<HTMLInputElement>(
            ".react-timerange-picker__inputGroup__input"
        );

        const handlers: { input: HTMLInputElement; focus: () => void; blur: () => void }[] = [];

        inputs.forEach((input) => {
            let timeoutId: ReturnType<typeof setTimeout> | null = null;

            const focusHandler = () => {
                timeoutId = setTimeout(() => {
                    if (document.activeElement === input) {
                        if (input.type === "number") {
                            input.select();
                        } else {
                            const isAlreadySelected =
                                input.selectionStart === 0 &&
                                input.selectionEnd === input.value.length &&
                                input.value.length > 0;

                            if (!isAlreadySelected) {
                                input.select();
                            }
                        }
                    }
                }, 180);
            };

            const blurHandler = () => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = null;
                }
            };

            input.addEventListener("focus", focusHandler);
            input.addEventListener("blur", blurHandler);
            handlers.push({ input, focus: focusHandler, blur: blurHandler });
        });

        return () => {
            handlers.forEach(({ input, focus, blur }) => {
                input.removeEventListener("focus", focus);
                input.removeEventListener("blur", blur);
            });
        };
    }, []);

    return (
        <div ref={wrapperRef}>
            <TimeRangePicker {...props} />
        </div>
    );
};

export default TimeRangePickerWrapper;
