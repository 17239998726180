import { CalendarOutlined, HomeOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { createTheme, CssBaseline, SxProps, ThemeProvider } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { frFR } from '@mui/x-date-pickers/locales';
import { Col, Empty, Popover, Row, Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { getCurrentUser } from '../../store/actions/user';
import { CaseType, MOMENT_LONG_DATE_FORMAT } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { RouterProps, StatsOnlyPlanning } from '../../utils/types/generalTypes';
import { ExternalAppOptimizedEvent } from '../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, colorIsBright, getCaseAndPlural } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import CircleButton from '../common/fields/circleButton';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import Card from '../common/general/card';
import Event, { EventSource } from '../common/general/event';
import { AppTheme } from '../specialRoutes/externalRouteSelector';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    theme: AppTheme;
}

type Props = IProps & RouterProps & ReduxProps & IntlProps;

interface State {
    statistics?: StatsOnlyPlanning;
    loading: boolean;
    day: Moment;
    companyId?: string;
    search?: string;
    sortBy: string;
    selectedGroups: number[]

}

class PlanningStatistics extends React.Component<Props, State> {
    TimePickerStyle: SxProps;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedGroups: [],
            statistics: undefined,
            loading: true,
            day: moment(),
            sortBy: "1",
        };
        this.TimePickerStyle = [
            {
                '.MuiFormControl-root': {
                }
            },
            {
                '.MuiInputBase-root': {
                    borderRadius: '30px ',
                }
            },
            {
                '.MuiInputBase-root:hover fieldset': {
                    borderColor: '#c78034',
                }
            },
            {
                '.Mui-focused fieldset': {
                    borderColor: '#c78034!important',
                    borderWidth: '1px!important'
                }
            },
            {
                input: {
                    textAlign: 'center',
                    padding: '0px 10px',
                    height: '33px',
                    color: this.props.theme.color,
                    fontSize: '16px',
                    fontFamily: 'Nunito, sans-serif'
                }
            },
            {
                fieldset: {
                    borderColor: 'var(--border-color)',
                }
            },
        ];
    }

    componentDidMount() {
        if (this.props.currentUser) {
            const companyId = this.props.currentUser?.company_id
            // get all users
            if (companyId !== undefined) {
                this.setState({ companyId: String(companyId) }, () => this.loadPlanningStatistics());

            }
        }

    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.currentUser !== undefined && (prevProps.currentUser === undefined || prevProps.currentUser.company_id !== this.props.currentUser?.company_id)) {
            this.setState({ companyId: String(this.props.currentUser?.company_id) }, () => this.loadPlanningStatistics());
        }
    }

    loadPlanningStatistics = () => {
        const { companyId, day } = this.state;
        if (companyId === undefined) return;
        this.setState({ loading: true });
        Network.getPlanningStatistics(parseInt(companyId), day.format("YYYY/MM/DD")).then(
            response => this.setState({ statistics: response.planning, loading: false }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning"),
        );

    }

    /**
     * Get the text for an eventy entry for the planning card
     * @param event the current event
     * @returns the text to display
     */
    getUserText = (event: ExternalAppOptimizedEvent): string => {
        return `${event.userName} ${event.userCode ? ` (${event.userCode})` : ''}`;
    }

    previousDay = () => {
        let { day } = this.state;
        day = moment(day).add(-1, "day");
        this.setState({ day }, () => this.loadPlanningStatistics());
    }

    nextDay = () => {
        let { day } = this.state;
        day = moment(day).add(1, "day");
        this.setState({ day }, () => this.loadPlanningStatistics());
    }

    onChangeMonth = (date: Moment | null) => {
        if (date) {
            this.setState({ day: date }, () => this.loadPlanningStatistics());
        }
    }

    handleSearch = (event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        this.setState({ search: search.length === 0 ? undefined : search });
    }

    onChangeSort = (value: SelectValue) => {
        this.setState({ sortBy: value ? value.toString() : "1" });
    }

    render() {
        const { loading, day, search, sortBy, selectedGroups } = this.state;
        const { theme, intl } = this.props;

        const planning = this.state.statistics;
        let todayEvents = cloneDeep(planning?.todayEvents);


        if (sortBy === "1") {
            todayEvents = todayEvents?.sort((a, b) => `${a.title}${a.userName}`.localeCompare(`${b.title}${b.userName}`));

        } else if (sortBy == "2") {
            todayEvents = todayEvents?.sort((a, b) => `${a.userName}${a.title}`.localeCompare(`${b.userName}${b.title}`));
        } else if (sortBy == "3") {
            todayEvents = todayEvents?.sort((a, b) => (moment(a.startDate).unix() < moment(b.startDate).unix()) ? -1 : 1);
        } else if (sortBy == "4") {
            todayEvents = todayEvents?.sort((a, b) => (moment(a.endDate).unix() < moment(b.endDate).unix()) ? -1 : 1);
        }

        if (search) {
            todayEvents = todayEvents?.filter(e => e.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || e.userName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        }
        if (selectedGroups.length > 0) {
            todayEvents = todayEvents?.filter(e => e.userGroups.find(uG => uG.id !== undefined && selectedGroups.includes(uG.id)) !== undefined ? true : false)
        }
        // todayEvents = todayEvents ? [...new Map(todayEvents.map(item => [JSON.stringify(item), item])).values()] : [];
        const themePicker = createTheme({
            palette: {
                mode: theme && colorIsBright(theme.backgroundColor) ? 'light' : 'dark',
            },
        });

        return (

            <Card
                style={{
                    padding: 0,
                    borderRadius: 0,
                    boxShadow: 'none'
                }}
                className="planning-statistics-card"
                title={<FormattedMessage defaultMessage={'Daily planning'} />}
                icon={<CalendarOutlined />}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', zIndex: 3, paddingTop: '10px', paddingBottom: '10px', marginTop: '-10px', position: 'sticky', top: '0px', left: 0, width: '100%', backgroundColor: theme.backgroundColor }}>
                    <CircleButton
                        small
                        style={{ minWidth: "32px", backgroundColor: theme.backgroundColor, color: theme.color, borderColor: theme.color + "aa" }}
                        icon={<LeftOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Previous day' })}
                        disabled={loading}
                        onClick={this.previousDay}
                    />
                    <ThemeProvider theme={themePicker}>
                        <CssBaseline />
                        <LocalizationProvider dateAdapter={AdapterMoment} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <MobileDatePicker
                                sx={this.TimePickerStyle}
                                views={['month', 'day']}
                                value={day}
                                format={getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR')}
                                onChange={this.onChangeMonth}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                    <CircleButton
                        small
                        style={{ minWidth: "32px", backgroundColor: this.props.theme.backgroundColor, color: this.props.theme.color, borderColor: this.props.theme.color + "aa" }}
                        icon={<RightOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Next day' })}
                        disabled={loading}
                        onClick={this.nextDay}
                    />
                    <CircleButton
                        small
                        icon={<HomeOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Today' })}
                        onClick={() => this.onChangeMonth(moment())}
                        loading={loading}
                        disabled={day.format('YYYYMMDD') === moment().format('YYYYMMDD')}
                    />
                </div>
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <InputField
                            type="search"
                            onChange={this.handleSearch}
                            style={{ width: '100%' }} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Select
                            defaultValue={"1"}
                            placeholder={<FormattedMessage defaultMessage={'Order by'} />}
                            onChange={this.onChangeSort}
                            style={{ width: '100%' }}
                            showArrow
                        >
                            <Select.Option value="1"><FormattedMessage defaultMessage={'Order by events'} /></Select.Option>
                            <Select.Option value="2"><FormattedMessage defaultMessage={'Order by users'} /></Select.Option>
                            <Select.Option value="3"><FormattedMessage defaultMessage={'Order by start time'} /></Select.Option>
                            <Select.Option value="4"><FormattedMessage defaultMessage={'Order by end time'} /></Select.Option>
                        </Select>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Select
                            disabled={planning?.groups.length === 0}
                            loading={loading}
                            mode={'multiple'}
                            maxTagCount={'responsive'}
                            value={selectedGroups}
                            placeholder={<FormattedMessage defaultMessage={'User with group'} />}
                            onChange={(selectedGroups) => this.setState({ selectedGroups })}
                            style={{ width: '100%' }}
                            showArrow
                            options={planning?.groups.map(g => ({ value: g.id, label: g.name }))}
                        />
                    </Col>
                </Row>

                {
                    loading ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spin size='large' style={{ marginTop: '15px' }} indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />} />
                        </div>
                        :
                        planning && planning.todayEvents.length > 0 ?
                            <div style={{ marginTop: 20 }} >
                                <Row gutter={[10, 10]} style={{ width: '100%' }} key={`dashboard-today-events`} >

                                    {todayEvents?.map(e => {
                                        const duration = moment.duration(moment(e.endDate).diff(e.startDate));

                                        return (
                                            <>
                                                <Col span={2} style={{ minWidth: 20, fontSize: 40, display: 'flex', alignItems: 'center', color: e.color?.color ?? 'var(--primary-color)' }}>•</Col>
                                                <Popover placement='bottomLeft' trigger={'click'} title={e.userGroups.length > 0 ? <FormattedMessage defaultMessage={'The user has the following groups'} /> : <FormattedMessage defaultMessage={'The user has no groups'} />} content={e.userGroups.length > 0 ? <ul key={`group-list-container-items-event-${e.id}-user-${e.userId}`}>{e.userGroups.map(uG => <li key={`group-list-item-${uG.id}-event-${e.id}-user-${e.userId}`}>{uG.name}</li>)}</ul> : undefined} >
                                                    <Col span={10} style={{ display: 'flex', alignItems: 'center' }}><span>{this.getUserText(e)}</span></Col>
                                                </Popover>
                                                <Popover
                                                    placement="bottomRight"
                                                    trigger={'click'}
                                                    //arrowPointAtCenter={true}
                                                    key={`statistics-event-popover-${e.id}`}
                                                    title={
                                                        <div style={{ lineHeight: '25px' }}>
                                                            <p><span style={{ fontWeight: 600 }}>{e.title}</span></p>
                                                        </div>}
                                                    content={
                                                        <Row gutter={[10, 0]} style={{ width: 350 }}>
                                                            <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'From'} />{':'}</span></Col>
                                                            <Col span={18}>{moment(e.startDate).format(MOMENT_LONG_DATE_FORMAT)}</Col>
                                                            <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'To'} />{':'}</span></Col>
                                                            <Col span={18}>{moment(e.endDate).format(MOMENT_LONG_DATE_FORMAT)}</Col>

                                                            <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Duration'} />{':'}</span></Col>
                                                            <Col span={18}>{moment.utc(duration.asMilliseconds()).format(getFormat('TIME_SHORT'))}</Col>
                                                            {e.userNote && <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'User note'} />{':'}</span></Col>}
                                                            {e.userNote && <Col span={18}>{e.userNote}</Col>}
                                                            {e.poi && <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Workplace'} />{':'}</span></Col>}
                                                            {e.poi && <Col span={18}>{e.poi?.title}</Col>}

                                                            {e.project && <Col span={6}><span style={{ fontWeight: 600 }}>{this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}{':'}</span></Col>}
                                                            {e.project && <Col span={18}>{e.project?.title}</Col>}

                                                            {e.staffType && <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Skill'} />{':'}</span></Col>}
                                                            {e.staffType && <Col span={18}>{e?.staffType?.name}</Col>}
                                                        </Row>
                                                    }
                                                >
                                                    <Col span={12}>
                                                        <Event key={`event-${e.id}`} event={e} source={EventSource.PLANNINGSTATISTIC} displayIcons displayTimes />
                                                    </Col>
                                                </Popover>

                                            </>
                                        )
                                    }
                                    )}
                                </Row>

                            </div>
                            :
                            <>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No event planned today'} />} />
                            </>
                }
            </Card>

        );
    }
}
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
});
const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    windowWidth: state.window.width,
    company: state.user.company
});

const connector = connect(mapStateToProps, mapDispatchToProps);


export default connector(injectIntl(PlanningStatistics));

