import { Input as InputAntd } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { CSSProperties } from 'react';
import '../../../styles/input.css';

interface Props extends InputProps {
    disableWaveEffect?: boolean;
    wrapperStyle?: CSSProperties;
}

class Input extends React.Component<Props> {
    render() {
        const { placeholder, wrapperStyle, disableWaveEffect, ...props } = this.props;

        return (
            <div className="__wave-group" style={wrapperStyle}>
                <InputAntd
                    id="genericInput"
                    {...props}
                    className="__input"
                    required />
                {
                    placeholder ?
                        <label htmlFor='genericInput' className="__label">
                            {
                                [...placeholder].map((char, idx) => <span key={`char-${idx}-${char}`} className="__label-char" style={{ "--index": disableWaveEffect ? 0 : idx } as any}>{char}</span>)
                            }
                        </label>
                        : null
                }
            </div>
        );
    }
}

export default Input;