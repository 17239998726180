import { Button, Spin } from 'antd';
import isArray from 'lodash/isArray';
import { Moment } from 'moment';
import React, { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { GOOGLE_STORAGE_URL } from '../../../utils/constants';
import { GenericFile } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../FAIcon';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps {
    onUploaded(files: (File | string | GenericFile)[] | undefined): void;
    files?: (File | string | GenericFile)[];
    accept?: string;
    className?: string;
    containerStyle?: CSSProperties;
    editText?: string;
    addText?: string;
    deleteTextMaxWidth?: number;
    max?: number;
    loading?: boolean;

}

interface State {
    isBucketProtected: boolean;
    bucketName?: string;
    filesName?: string[];
    signedUrl?: string[];
    bucketProtectionExpiration?: Moment;
}

/**
 * Component that represent an image uploader field
 */
class FileUploaderMultiple extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isBucketProtected: false
        };
    }
    /**
     * Check that the file is less than 2MB
     * @param file the uploaded file
     * @return true if the file is less than 2MB
     */
    componentDidMount(): void {
        this.setFileFromProps();
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isEqual(prevProps.files, this.props.files)) {
            this.setFileFromProps();
        }
    }
    setFileFromProps = () => {
        const filesName: string[] = [];
        this.props.files && this.props.files.forEach(file => {
            if (file && typeof file === 'string' && file.startsWith('sunkhronos_admin/')) {

                const splittedFile = file.split('/');
                if (splittedFile.length > 1) {
                    filesName.push(splittedFile[1]);
                }
            }
        });
        this.setState({ filesName });
    };

    checkFile = (file: File): boolean => {
        const isLessThan20MB = file.size / 1024 / 1024 <= 20;

        if (!isLessThan20MB) alert(this.props.intl.formatMessage({ defaultMessage: 'The file must be less than {size}MB.' }, { size: 20 }), "warning");

        return isLessThan20MB;
    };

    /**
     * Call after that the image is uploaded
     * @param event event the triggered event
     */
    checkUploadFile = (event: any): void => {
        const files: File[] = Array.from(event.target.files);
        const max = this.props.max !== undefined ? this.props.files !== undefined ? (this.props.max - this.props.files.length) : this.props.max : undefined;

        if (max !== undefined && files.length > max) {
            showNotification(`${this.props.intl.formatMessage({ defaultMessage: 'The maximum file limit is {limit}' }, { limit: this.props.max })}\n${max > 0 ? this.props.intl.formatMessage({ defaultMessage: 'You can still upload {max} files' }, { max }) : this.props.intl.formatMessage({ defaultMessage: 'You can no longer upload files.' })}`, 'warning');
            return;
        }
    };
    uploadFile = (event: any): void => {
        const files: File[] = Array.from(event.target.files);
        const max = this.props.max !== undefined ? this.props.files !== undefined ? (this.props.max - this.props.files.length) : this.props.max : undefined;

        if (max !== undefined && files.length > max) {
            showNotification(`${this.props.intl.formatMessage({ defaultMessage: 'The maximum file limit is {limit}' }, { limit: this.props.max })}\n${max > 0 ? this.props.intl.formatMessage({ defaultMessage: 'You can still upload {max} files' }, { max }) : this.props.intl.formatMessage({ defaultMessage: 'You can no longer upload files.' })}`, 'warning');
            return;
        }

        if (files && files.length > 0 && files.every((file: File) => this.checkFile(file))) {
            const f: (string | File | GenericFile)[] = this.props.files && isArray(this.props.files) ? this.props.files : [];

            this.props.onUploaded([...f, ...files]);
        }
    };
    onChange = (event: any): void => {
        event.stopPropagation();
        event.preventDefault();
        this.uploadFile(event);
    };

    /**
     * Called when the user drag the file over the component
     * @param event event the triggered event
     */
    onDragOver = (event: any): void => {
        event.preventDefault();
        event.stopPropagation();

        const div = document.getElementById("file-uploader")!;
        if (div.className.includes("image-uploader-drag")) return;
        div.className += " image-uploader-drag";
    };

    /**
     * Called when the user drop the file (Drag and Drop)
     * @param event event the triggered event
     */
    onDrop = (event: any): void => {
        const div = document.getElementById("file-uploader")!;
        div.className = div.className.replace("image-uploader-drag", "");
        this.uploadFile(event);
    };


    render() {
        const disabled = (this.props.files !== undefined && this.props.max !== undefined && this.props.files.length >= this.props.max);
        const max = this.props.max !== undefined ? this.props.files !== undefined ? (this.props.max - this.props.files.length) : this.props.max : undefined;
        return (
            <div style={this.props.containerStyle}>
                {this.props.loading ? <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}><Spin size={'large'} spinning={this.props.loading} /></div>

                    : <div id="file-uploader" onDragOver={this.onDragOver} className={`file-uploader-parent${disabled ? '-disabled' : ''} ${this.props.className ? this.props.className : ''}`}>
                        <input disabled={disabled} style={disabled ? { cursor: 'default' } : {}} className="image-uploader-input" type="file" multiple max={max} accept={this.props.accept} onChange={this.onChange} />
                        <div className="file-uploader-content file-uploader-button">
                            <FAIcon prefix='fad' name='file-arrow-up' /> {this.props.files && this.props.files.length > 0 ? this.props.editText ?? <FormattedMessage defaultMessage={'Edit files'} /> : this.props.addText ?? <FormattedMessage defaultMessage={'Add files'} />}
                        </div>
                    </div>}
                <div className="file-uploader-divider" />

                {this.props.files?.map((file) => {
                    const fileSrc = (file && typeof file === 'string') ? file.startsWith("http") ? file : GOOGLE_STORAGE_URL + file : (file && typeof file !== 'string' && !(file instanceof File)) ? file.auth_url : undefined;
                    return (<>
                        {(typeof file !== 'string' && file instanceof File) && file?.name ?
                            <div className="file-uploader-list" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p title={file.name} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: this.props.deleteTextMaxWidth }} >{file.name}</p>
                                <Button icon={<FAIcon prefix='fad' name='trash-can' />} onClick={() => this.props.onUploaded(this.props.files?.filter(f => f !== file))} />
                            </div>
                            :
                            (typeof file !== 'string' && !(file instanceof File)) && file?.file_name ?
                                <div className="file-uploader-list" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p title={file.file_name} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: this.props.deleteTextMaxWidth }} >{file.file_name}</p>
                                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                        <a target={'_blank'} href={fileSrc} rel="noreferrer"><FormattedMessage defaultMessage={'Download'} /></a>
                                        <Button icon={<FAIcon prefix='fad' name='trash-can' />} onClick={() => this.props.onUploaded(this.props.files?.filter(f => !isEqual(f, file)))} />
                                    </div>
                                </div>
                                :
                                file && typeof file === "string" &&
                                <div className="file-uploader-list" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <a target={'_blank'} href={fileSrc} rel="noreferrer"><FormattedMessage defaultMessage={'Download'} /></a>
                                    <Button icon={<FAIcon prefix='fad' name='trash-can' />} onClick={() => this.props.onUploaded(this.props.files?.filter(f => !isEqual(f, file)))} />
                                </div>
                        }
                    </>
                    );
                })}


            </div>
        );
    }
}




const mapStateToProps = (state: ApplicationState) => ({
    width: state.window.width,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(FileUploaderMultiple));