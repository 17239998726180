import { Button, Drawer } from 'antd';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../../../../utils/types/storeTypes';
import { IntlProps } from '../../../../app/LanguageProvider';
import DeleteButton from '../../../../common/fields/deleteButton';
import SpaceContent from '../../../../common/general/spaceContent';
import EditRecurring, { IEventRecurring } from './editRecurring';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends IntlProps, ReduxProps {
    eventRecurring?: IEventRecurring;
    onCancel: () => void;
    onOk: (eventRecurring?: IEventRecurring) => void;
    onChange: (eventRecurring?: IEventRecurring) => void;
}

interface State {
}

class EditRecurringModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    onCancel = () => this.props.onCancel();

    onDelete = () => this.props.onOk(undefined);

    onDone = () => this.props.onOk(this.props.eventRecurring);

    render() {
        const { eventRecurring, isSmartphone } = this.props;
        return (
            <Drawer
                maskClosable={false}
                closable={false}
                bodyStyle={{ padding: '15px' }}
                width={isSmartphone ? '100%' : "400px"}
                destroyOnClose={true}
                visible={!!eventRecurring}
                title={<FormattedMessage defaultMessage={'Edit the recurring'} />}
                footer={[
                    <SpaceContent style={{ justifyContent: 'space-between' }} key={`edit-recurring-actions`}>
                        <SpaceContent style={{ justifyContent: 'space-between' }}>
                            <Button type="dashed" onClick={this.onCancel}>
                                <FormattedMessage defaultMessage={'Cancel'} />
                            </Button>
                            <DeleteButton
                                small
                                onConfirm={this.onDelete}
                                text={<FormattedMessage defaultMessage={"Are you sure that you want to delete this ?"} />}
                            />
                        </SpaceContent>
                        <Button type="primary" onClick={this.onDone}>
                            {<FormattedMessage defaultMessage={'Save'} />}
                        </Button>
                    </SpaceContent>
                ]}>
                {
                    eventRecurring ?
                        <EditRecurring
                            eventRecurring={eventRecurring}
                            onChange={this.props.onChange}
                        />
                        : null
                }
            </Drawer>
        );
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,

});
const connector = connect(mapStateToProps);

export default connector(injectIntl(EditRecurringModal));