import { Button, Col, DatePicker, Empty, Modal, Row, Spin, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useFullName } from "../../../hooks/useUsers";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { GeneralResponse } from "../../../utils/types/networkTypes";
import { UserAvailability, UserShort } from "../../../utils/types/planningTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { availabilityRruleToString, convertUserAvailabilitiesNetworkToUserAvailabilities, showNotification } from "../../../utils/utils";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";

export const AvailabilitiesConfirmation = () => {

    const [teamAvailabilitiesRangeStartDate, setTeamAvailabilitiesRangeStartDate] = useState<Moment>(moment());
    const [teamAvailabilitiesRangeStopDate, setTeamAvailabilitiesRangeStopDate] = useState<Moment>(moment().add(1, "day"));
    const [teamAvailabilitiesToValidate, setTeamAvailabilitiesToValidate] = useState<UserAvailability[] | undefined>(undefined);
    const [teamAvailabilitiesNotConfirmed, setTeamAvailabilitiesNotConfirmed] = useState<UserAvailability[]>([]);
    const [availabilitiesLoading, setAvailabilitiesLoading] = useState<boolean>(false);
    const availabilitiesLoaded = useRef<boolean>(false);

    const intl = useIntl();
    const fullName = useFullName()


    const windowWidth = useSelector((state: ApplicationState) => state.window.width);

    const loadTeamAvailabilitiesNotConfirmed = useCallback((force = false) => {
        if (force || (!availabilitiesLoading && !availabilitiesLoaded.current)) {
            setAvailabilitiesLoading(true);
            Network.getTeamAvailabilitiesNotConfirmed().then(
                response => {
                    availabilitiesLoaded.current = true;
                    setTeamAvailabilitiesNotConfirmed(convertUserAvailabilitiesNetworkToUserAvailabilities(response.data));
                    setAvailabilitiesLoading(false);
                },
                () => {
                    setAvailabilitiesLoading(false);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the contracts informations' }), "warning");
                }
            );
        }
    }, [availabilitiesLoading, intl]);

    useEffect(() => {
        if (!availabilitiesLoading && !availabilitiesLoaded.current) {
            loadTeamAvailabilitiesNotConfirmed();
        }
    }, [loadTeamAvailabilitiesNotConfirmed, availabilitiesLoading]);

    const getTeamAvailabilitiesRangeToValidate = useCallback(() => {
        const teamAvailabilities = teamAvailabilitiesNotConfirmed?.filter(o => o.startDateRule?.isSameOrBefore(teamAvailabilitiesRangeStopDate) && o.endDateRule?.isSameOrAfter(teamAvailabilitiesRangeStartDate));
        return cloneDeep(teamAvailabilities);
    }, [teamAvailabilitiesNotConfirmed, teamAvailabilitiesRangeStopDate, teamAvailabilitiesRangeStartDate]);


    const teamAvailabilitiesValidate = useCallback((availabilityId: number) => {
        Network.validateTeamAvailabilitiesNotConfirmed(availabilityId).then(
            response => {
                if (response.error) {
                    const successIds = response.successIds;
                    if (successIds && successIds.length > 0) {
                        setTeamAvailabilitiesToValidate(teamAvailabilitiesToValidate?.filter(e => !successIds.includes(e.id)));
                        setTeamAvailabilitiesNotConfirmed(teamAvailabilitiesNotConfirmed?.filter(e => !successIds.includes(e.id)));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating of some of the availabilities/unavailabilities.' }), "warning");
                    } else {
                        setTeamAvailabilitiesToValidate(undefined);
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating of the availabilities/unavailabilities.' }), "error");
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'Successfully validated' }), "success");
                    setTeamAvailabilitiesToValidate(teamAvailabilitiesToValidate?.filter(a => a.id !== availabilityId));
                    setTeamAvailabilitiesNotConfirmed(teamAvailabilitiesNotConfirmed?.filter(e => availabilityId !== e.id));
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating of the availability.' }), "warning")
        );
    }, [intl, teamAvailabilitiesNotConfirmed, teamAvailabilitiesToValidate]);

    const teamAvailabilitiesRefuse = useCallback((availabilityId: number) => {
        Network.refuseTeamAvailabilitiesNotConfirmed(availabilityId).then(
            response => {
                if (response.error) {
                    const successIds = response.successIds;
                    if (successIds && successIds.length > 0) {
                        setTeamAvailabilitiesToValidate(teamAvailabilitiesToValidate?.filter(e => !successIds.includes(e.id)));
                        setTeamAvailabilitiesNotConfirmed(teamAvailabilitiesNotConfirmed?.filter(e => !successIds.includes(e.id)));

                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while refusing of some of the availabilities/unavailabilities.' }), "warning");
                    } else {
                        setTeamAvailabilitiesToValidate(undefined);
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while rejecting of the availabilities/unavailabilities.' }), "error");
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The availability has been successfully rejected' }), "success");
                    setTeamAvailabilitiesToValidate(teamAvailabilitiesToValidate?.filter(e => availabilityId !== e.id));
                    setTeamAvailabilitiesNotConfirmed(teamAvailabilitiesNotConfirmed?.filter(e => availabilityId !== e.id));

                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while rejecting of the availability' }), "warning")
        );
    }, [intl, teamAvailabilitiesNotConfirmed, teamAvailabilitiesToValidate]);

    const onChangePeriodsStartRange = useCallback((date: Moment | null): void => {
        if (date === null) {
            return;
        }

        if (date.isSameOrBefore(teamAvailabilitiesRangeStopDate)) {
            setTeamAvailabilitiesRangeStartDate(date);
            setTeamAvailabilitiesToValidate(undefined);
        } else {
            setTeamAvailabilitiesRangeStartDate(date);
            setTeamAvailabilitiesRangeStopDate(date.clone().add(1, 'days'));
            setTeamAvailabilitiesToValidate(undefined);
        }

    }, [teamAvailabilitiesRangeStopDate]);

    const onChangePeriodsStopRange = useCallback((date: Moment | null): void => {
        if (date === null) {
            return;
        }

        if (date.isSameOrAfter(teamAvailabilitiesRangeStartDate)) {
            setTeamAvailabilitiesRangeStopDate(date);
            setTeamAvailabilitiesToValidate(undefined);
        } else {
            setTeamAvailabilitiesRangeStartDate(date);
            setTeamAvailabilitiesRangeStopDate(date.clone().add(1, 'days'));
            setTeamAvailabilitiesToValidate(undefined);
        }

    }, [teamAvailabilitiesRangeStartDate]);

    const columnsPeriods = useMemo((): ColumnsType<UserAvailability> => [
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            dataIndex: 'user',
            render: (user: UserShort) => {
                return (
                    <span title={intl.formatMessage({ defaultMessage: '{type} planning by default' }, { type: user.availabilityClosedByDefault ? intl.formatMessage({ defaultMessage: 'Closed' }) : intl.formatMessage({ defaultMessage: 'Open' }) })}>{fullName.getFullName({ ...user, first_name: user.firstName, last_name: user.lastName })}</span>
                );
            }
        },

        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            key: 'type',
            dataIndex: 'user',
            render: (user: UserShort) => {
                if (user.availabilityClosedByDefault) {
                    return <span><FormattedMessage defaultMessage={'Availability'} /></span>;
                } else {
                    return <span><FormattedMessage defaultMessage={'Unavailability'} /></span>;
                }
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            className: '__width_120 __centered-text',
            render: (record) => {
                return (
                    <span style={{ display: 'flex' }}>
                        <CircleButton
                            small
                            onClick={() => teamAvailabilitiesValidate(record.id)}
                            title={intl.formatMessage({ defaultMessage: 'Accept' })}
                            icon={<FAIcon prefix='far' name='check' />}
                        />
                        <CircleButton
                            small
                            style={{ marginLeft: '5px' }}
                            onClick={() => teamAvailabilitiesRefuse(record.id)}
                            title={intl.formatMessage({ defaultMessage: 'Reject' })}
                            icon={<FAIcon prefix='far' name='xmark' />}
                        />
                    </span>
                );
            }
        },
    ], [intl, teamAvailabilitiesValidate, teamAvailabilitiesRefuse, fullName]);

    const columnsTeamAvailabilitiesForModal = useMemo((): ColumnsType<UserAvailability> => [
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            render: (record: UserAvailability) => {
                return (
                    <span>{record.user?.lastName} {record.user?.firstName}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: <FormattedMessage defaultMessage={'Overview'} />,
            key: 'summary',
            render: (record: UserAvailability) => {
                return (
                    <span>
                        {
                            record.isContinue ?
                                <FormattedMessage defaultMessage={'{availability} from {start} to {end}'} values={{ availability: record.user.availabilityClosedByDefault ? intl.formatMessage({ defaultMessage: 'Available' }) : intl.formatMessage({ defaultMessage: 'Unavaiable' }), start: `${record.startDateRule.format(getFormat('DATE'))} ${record.startTime.format(getFormat('TIME_SHORT'))}`, end: `${record.endDateRule.format(getFormat('DATE'))} ${record.endTime.format(getFormat('TIME_SHORT'))}` }} />

                                :
                                <FormattedMessage defaultMessage={'{availability} {rule}'} values={{ availability: record.user.availabilityClosedByDefault ? intl.formatMessage({ defaultMessage: 'Available' }) : intl.formatMessage({ defaultMessage: 'Unavaiable' }), rule: availabilityRruleToString(intl, record, false).toLocaleLowerCase() }} />
                        }
                    </span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            className: '__dashboard-overtimes-actions-th',
            render: (record) => {
                return (
                    <span>
                        <CircleButton
                            small
                            onClick={() => teamAvailabilitiesValidate(record.id)}
                            title={intl.formatMessage({ defaultMessage: 'Accept' })}
                            icon={<FAIcon prefix='far' name='pencil' />}
                        />
                        <CircleButton
                            small
                            style={{ marginLeft: '5px' }}
                            onClick={() => teamAvailabilitiesRefuse(record.id)}
                            title={intl.formatMessage({ defaultMessage: 'Reject' })}
                            icon={<FAIcon prefix='far' name='xmark' />}
                        />
                    </span>
                );
            }
        }

    ], [intl, teamAvailabilitiesValidate, teamAvailabilitiesRefuse]);

    const openModalToValidate = useCallback(() => {
        setTeamAvailabilitiesToValidate(getTeamAvailabilitiesRangeToValidate());
    }, [getTeamAvailabilitiesRangeToValidate]);

    const handleCancel = useCallback(() => {
        setTeamAvailabilitiesToValidate(undefined);
    }, []);

    const handleValidateAll = useCallback(() => {
        if (teamAvailabilitiesToValidate !== undefined) {
            const availabilitiesIds = teamAvailabilitiesToValidate.map(element => element.id);

            if (availabilitiesIds.length > 0) {
                Network.validateTeamAvailabilitiesNotConfirmed(undefined, availabilitiesIds).then(
                    (response: GeneralResponse) => {
                        if (response.error) {
                            const successIds = response.successIds;
                            if (successIds && successIds.length > 0) {
                                setTeamAvailabilitiesToValidate(undefined);
                                setTeamAvailabilitiesNotConfirmed(teamAvailabilitiesNotConfirmed?.filter(e => !successIds.includes(e.id)));
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating of some of the availabilities/unavailabilities.' }), "warning");
                            } else {
                                setTeamAvailabilitiesToValidate(undefined);
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating of the availabilities/unavailabilities.' }), "error");
                            }
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'Successfully validated' }), "success");
                            setTeamAvailabilitiesToValidate(undefined);
                            setTeamAvailabilitiesNotConfirmed(teamAvailabilitiesNotConfirmed?.filter(e => !availabilitiesIds.includes(e.id)));
                        }
                    },
                    () => {
                        () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating of the availability.' }), "warning");
                        setTeamAvailabilitiesToValidate(undefined);
                    }
                );
            } else {
                showNotification(intl.formatMessage({ defaultMessage: 'No availability/unavailability to be validated in the selected time range' }), "warning");
                setTeamAvailabilitiesToValidate(undefined);
            }
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'No modifications available' }), "error");
            setTeamAvailabilitiesToValidate(undefined);
        }
    }, [intl, teamAvailabilitiesNotConfirmed, teamAvailabilitiesToValidate]);

    return (
        <>
            <Card
                className="dashboard-card dashboard-card-500"
                title={<FormattedMessage defaultMessage={'Confirm availability/unavailability'} />}
                icon={<FAIcon prefix='fad' name='calendar-plus' />}
                containerClassName="dashboard-scrollable"
                headerElements={[
                    <CircleButton
                        small
                        loading={availabilitiesLoading}
                        disabled={availabilitiesLoaded.current === false}
                        key="team-periods-add-button"
                        icon={<FAIcon prefix={'fad'} name="rotate" />}
                        title={intl.formatMessage({ defaultMessage: 'Update data' })}
                        onClick={() => loadTeamAvailabilitiesNotConfirmed(true)} />
                ]}
            >
                {
                    availabilitiesLoading ?
                        <div style={{ textAlign: 'center' }}>
                            <Spin />
                        </div>
                        :
                        teamAvailabilitiesNotConfirmed && teamAvailabilitiesNotConfirmed.length > 0 ?
                            <>
                                <Row gutter={[10, 10]} style={{ textAlign: 'left' }}>
                                    <Col xs={{ span: 24 }}>
                                        <span><FormattedMessage defaultMessage={'Confirm all availabilities/unavailabilities'} />
                                            <Tooltip overlay={intl.formatMessage({ defaultMessage: 'All availabilities/unavailabilities will be confirmed within the time frame indicated.' })} placement="right">
                                                <FAIcon prefix='fad' name='info' className='tab-tooltip-info' />
                                            </Tooltip>
                                        </span>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                                            <span><FormattedMessage defaultMessage={'from'} /></span>
                                            <DatePicker
                                                allowClear={false}
                                                value={teamAvailabilitiesRangeStartDate}
                                                placeholder={"dd-mm-yyyy"}
                                                format="DD-MM-YYYY"
                                                style={{ width: '150px' }}
                                                onChange={m => onChangePeriodsStartRange(m)}
                                            />
                                            <span><FormattedMessage defaultMessage={'to'} /></span>
                                            <DatePicker
                                                allowClear={false}
                                                value={teamAvailabilitiesRangeStopDate}
                                                placeholder={"dd-mm-yyyy"}
                                                format="DD-MM-YYYY"
                                                style={{ width: '150px' }}
                                                onChange={m => onChangePeriodsStopRange(m)}
                                            />
                                            <CircleButton
                                                style={{ marginRight: '20px' }}
                                                onClick={openModalToValidate}
                                                title={intl.formatMessage({ defaultMessage: 'View and confirm' })}
                                                small
                                                icon={<FAIcon prefix='far' name='check' />}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={{ span: 24 }}>
                                        <Table
                                            columns={columnsPeriods}
                                            // scroll={{ x: 1300 }}
                                            expandable={{
                                                expandedRowRender: (record) => (
                                                    <div>
                                                        <p>
                                                            {
                                                                record.isContinue ?
                                                                    <FormattedMessage defaultMessage={'{availability} from {start} to {end}'} values={{ availability: record.user.availabilityClosedByDefault ? intl.formatMessage({ defaultMessage: 'Available' }) : intl.formatMessage({ defaultMessage: 'Unavaiable' }), start: `${record.startDateRule.format(getFormat('DATE'))} ${record.startTime.format(getFormat('TIME_SHORT'))}`, end: `${record.endDateRule.format(getFormat('DATE'))} ${record.endTime.format(getFormat('TIME_SHORT'))}` }} />

                                                                    :
                                                                    <FormattedMessage defaultMessage={'{availability} {rule}'} values={{ availability: record.user.availabilityClosedByDefault ? intl.formatMessage({ defaultMessage: 'Available' }) : intl.formatMessage({ defaultMessage: 'Unavaiable' }), rule: availabilityRruleToString(intl, record, false).toLocaleLowerCase() }} />
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            }}
                                            dataSource={teamAvailabilitiesNotConfirmed}
                                            rowKey={(o: UserAvailability) => `dashboard-team-availabilities-${o.id}`}
                                            pagination={{
                                                defaultPageSize: 8,
                                                pageSizeOptions: ["8", "16", "32", "64", "72"],
                                                showSizeChanger: true,
                                                hideOnSinglePage: true,
                                                showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} availabilities'} values={{ range0: range[0], range1: range[1], total }} />
                                            }} />
                                    </Col>
                                </Row>

                            </>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No pending request'} />} />
                }
            </Card>
            <Modal centered title={<FormattedMessage defaultMessage={'Periods in the selected range'} />} open={teamAvailabilitiesToValidate !== undefined} width={windowWidth > 1200 ? 1100 : "99%"} onCancel={handleCancel} footer={[
                <Button key="back" onClick={handleCancel}>
                    <FormattedMessage defaultMessage={'Back'} />
                </Button>,
                <Button key="ValidateRange" onClick={handleValidateAll}>
                    <FormattedMessage defaultMessage={'Validate all'} />
                </Button>]}>
                <div style={{ maxHeight: "calc(100vh - 250px)", overflow: 'auto' }}>
                    <Table
                        columns={columnsTeamAvailabilitiesForModal}
                        dataSource={teamAvailabilitiesToValidate}
                        rowKey={(o: UserAvailability) => `dashboard-team-availabilities-${o.id}`}
                        pagination={{
                            defaultPageSize: 8,
                            pageSizeOptions: ["8", "16", "32", "64", "72"],
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} availabilities'} values={{ range0: range[0], range1: range[1], total }} />
                        }} />
                </div>
            </Modal>
        </>
    );
};

export default AvailabilitiesConfirmation;