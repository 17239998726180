import { useCallback } from "react";
import { UserSmall } from "../components/common/general/fullUser";
import { useAppSelector } from "../store/store";
import { keyify } from "../utils/array_utils";
import { User } from "../utils/types/generalTypes";
import { getFullName as getFullNameUtil, getNameOrder as getNameOrderUtil } from "../utils/utils";
import { useCodeAuthorization, useCodePosition, useLastNameFirst } from "./useCompany";

export const useUsersVisible = () => useAppSelector(({ teamManagement }) => (teamManagement.users as User[]).filter(u => u.visible));

export const useUsersVisibleKeyed = () => {
    const users = useUsersVisible();
    return keyify(users, 'id')
};

export const useFullName = () => {
    const lastNameFirst = useLastNameFirst();
    const codePosition = useCodePosition();
    const codeAuthorization = useCodeAuthorization();

    const getNameOrder = useCallback((user: UserSmall) => {
        return getNameOrderUtil(user, lastNameFirst);
    }, [lastNameFirst]);

    const getFullName = useCallback((user: UserSmall) => {
        return getFullNameUtil(user, lastNameFirst, codePosition, codeAuthorization)
    }, [lastNameFirst, codePosition, codeAuthorization]);

    return {
        getNameOrder,
        getFullName,
    }
};

export default () => useAppSelector(({ teamManagement }) => teamManagement.users as User[]);