//#region Imports
import { captureException, captureMessage } from "@sentry/react";
import { Avatar, Badge, Button, Divider, Dropdown, Image, message, notification, Popover, Segmented, Space, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isEqual } from 'lodash';
import moment, { Moment, unitOfTime } from 'moment';
import React, { Component, ReactNode } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Selectable, { SelectableProps, SelectableRef } from 'react-selectable-box';
import rfdc from 'rfdc';
import { Rules } from '../../../rbacRules';
import { changeProject, changeTypesOfDay, changeTypesOfDayOff, loadDepartments } from '../../../store/actions/configurations';
import { loadPois } from '../../../store/actions/location';
import { changeNextLoading, changeNextUserRows, changeNowLoading, changeNowUserRows, changePrevLoading, changePrevUserRows, changeSelectGroups, changeSelectUsers, changeSettings, changeTeamAvailabilities, changeTemplates, changeUserRows, loadSettings, toggleLoadingPlanning } from '../../../store/actions/planning';
import { changeGroups, changeUsers } from '../../../store/actions/teamManagement';
import { toggleLoading } from '../../../store/actions/user';
import { selectActiveUsers } from "../../../store/selectors/usersSelectors";
import { API_ERROR_MESSAGE, INTERNET_ERROR_MESSAGE } from '../../../utils/api';
import { CompanyTypes, MOMENT_FORMAT_DATE_TO_NETWORK, MOMENT_FORMAT_TO_NETWORK, MOMENT_MONTH_TIME_FORMAT, MOMENT_TIME_FORMAT, YELLOW_COLOR } from '../../../utils/constants';
import { PlanningEventOwner } from '../../../utils/enumerations';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { FullUserProps, withFullName } from "../../../utils/objects/withFullName";
import { getSelectableValueForEvent, parseSelectableValue } from '../../../utils/planningUtils';
import { DateRange, DictionaryNumber, DictionaryString, Group, IUserContractInfo, PlanningCell, PlanningUserPerf, RouterProps, User, UserSummary } from '../../../utils/types/generalTypes';
import { LockedDaysByDates, NetworkEvent, NetworkMonthlyPlanningRowPerf, NetworkMonthlyPlanningRowPerfAddons, NetworkOccupancyRate, NetworkPlanningAvailabilities, NetworkSettings } from '../../../utils/types/networkTypes';
import { ContractDataPerf, CopyEventsBodyRequestV2, EventCanBeCopiedBody, EventCopyError, EventCreateError, IPlanningStorage, PlanningEvent, PlanningOvertime, PlanningSettings, PlanningTemplate, PlanningUserRow, Project, TypeOfDay, UserAvailabilityBodyRequest, UserAvailabilityWithUpdate } from '../../../utils/types/planningTypes';
import { CcntReport } from '../../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { checkRBACRule, convertNetworkEventsToPlanningEventsV2, convertNetworkEventToPlanningEvent, convertNetworkSettingsToPlanningSettings, isNullOrEmpty, isTouchDevice, showNotification, toggleFullScreen } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import Can from '../../common/general/can';
import Card from '../../common/general/card';
import FullUser from "../../common/general/fullUser";
import VirtualTable from '../../common/general/virtualTableV2';
import MissionsControlV2 from '../../customersManagement/missions/missionsControlV2';
import OccupancyRatesControl from '../../dashboard/components/occupancyRatesControl';
import SettingsModal from '../../planning/settingsModal';
import ShowEventModal from '../../planning/showEventModal';
import { CreationCallback } from '../../planningNew/tabs/planning';
import DrawerCcntReport from "../../reportv2/v2/hotelAndCatering/drawerCcntReport";
import ModifyEvent from "../common/modifyEvent/modifyEvent";
import Cell from './common/cellPerf';
import { CustomDatePicker } from './common/CustomDatePicker';
import DailySummary from './common/dailySummary';
import DeleteEventsModal from './common/deleteEventsModal';
import DownloadPlanningModal from './common/downloadPlanningModal';
import Filters from './common/filters';
// const cloneDeep = rfdc({ proto: true, circles: true });
const cloneDeep = rfdc({ proto: true });


//#endregion

//#region Typescript
type ReduxProps = ConnectedProps<typeof connector>;
type Props = RouterProps & ReduxProps & IntlProps & FullUserProps;

interface State {
    rawEvents: NetworkMonthlyPlanningRowPerf[];
    rawAvailabilities: NetworkPlanningAvailabilities;
    usersEvents: DictionaryNumber<PlanningUserPerf>;
    loaded: {
        planning: boolean;
    };
    displayMode: 'monthly' | 'weekly',
    date: Moment;
    daysInRange: string[];
    selectedEvents: DictionaryString<{ eventId: number; userId: number; }[]>;
    copiedEvents: {
        day: string;
        userId: number;
        events: NetworkMonthlyPlanningRowPerfAddons[];
        mode: 'COPY' | 'CUT';
    } | null;
    selectionMode?: SelectableProps<unknown>['mode'];
    deleteModalOpened: boolean;
    filters: {
        users: number[];
        groups: number[];
        usersToExclude: number[];
        typeOfDays: number[];
        typeOfDaysOff: number[];
        POIs: number[];
        aptitudes: number[];
        userAptitudes: number[];
        departments: number[];
        projects: number[];
        showEmpty: boolean;
        showDayOff: boolean;
        confirmed?: boolean;
        customers: number[];
        mandates: number[];
    };
    openedEvent: PlanningEvent | null | undefined; // Null if loading, else undefined
    editEvent?: PlanningEvent;
    canEditStartDate: boolean;
    sidePanels: {
        showFilters?: boolean;
        showOccupancyRate?: boolean;
        showMissionsStatus?: boolean;
        showSummary?: boolean;
    };
    showStats: 'report' | 'viewport' | null;
    showSettings?: boolean;
    userIdsEditable?: 'ALL' | number[];
    sidePanelDate: Moment;
    forceAction: {
        userId: number;
        dayStr: string;
        action: 'COPY' | 'CUT';
        events: EventCopyError[];
    } | null;
    hoursStats: DictionaryString<{ overtime: number; }>;
    usersContracts: DictionaryNumber<ContractDataPerf[]>;
    downloadPlanning: boolean;
    holidays: DictionaryString<boolean>;
    loadingDelete: boolean;
    refreshMissionsStatus: boolean;
    lockedDaysByUsersByDates?: LockedDaysByDates;

    isCcntVisible: boolean;
    loadingCcnt: boolean;
    ccnt?: CcntReport;
    userIdInfoPopover?: number;
    loadFileFromTemplate?: number;
    filteredUsersEvents: FilteredUserEvents[];
}

const getInitState = (savedStateFromStorage: IPlanningStorage | undefined): State => ({
    loaded: {
        planning: false,
    },
    rawAvailabilities: [],
    rawEvents: [],
    usersEvents: {},
    usersContracts: {},
    displayMode: savedStateFromStorage?.displayMode ?? 'monthly',
    date: savedStateFromStorage?.date && savedStateFromStorage?.savedAt && moment(savedStateFromStorage.savedAt, "YYYYMMDDHHmmss").isSame(moment(), 'day') ? moment(savedStateFromStorage.date, "YYYYMMDDHHmmss") : moment(),
    sidePanelDate: moment(),
    daysInRange: [],
    selectedEvents: {},
    copiedEvents: null,
    deleteModalOpened: false,
    filters: {
        groups: [],
        users: [],
        typeOfDays: [],
        typeOfDaysOff: [],
        aptitudes: [],
        userAptitudes: [],
        POIs: [],
        usersToExclude: [],
        departments: [],
        projects: [],
        showEmpty: true,
        showDayOff: false,
        customers: [],
        mandates: [],
        ...(savedStateFromStorage?.filters)
    },
    openedEvent: undefined,
    canEditStartDate: true,
    sidePanels: {},
    forceAction: null,
    hoursStats: {},
    showStats: savedStateFromStorage?.showStats ?? null,
    downloadPlanning: false,
    holidays: {},
    loadingDelete: false,
    refreshMissionsStatus: false,

    isCcntVisible: false,
    loadingCcnt: false,
    loadFileFromTemplate: undefined,
    filteredUsersEvents: []
});

interface FilteredUserEvents {
    days: {
        [x: string]: PlanningCell;
    };
    availabilityClosedByDefault: boolean;
    id: number;
    firstName: string;
    lastName: string;
    code?: string | undefined;
    fullName: string;
    image?: string | undefined;
    hasRights: boolean;
}

const enum PlanningTypes {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

const enum StatsTypes {
    REPORT = 'report',
    VIEWPORT = 'viewport',
}
//#endregion

//#region Misc
const PlanningTypesMessages = defineMessages<PlanningTypes>({
    [PlanningTypes.WEEKLY]: { defaultMessage: 'Weekly' },
    [PlanningTypes.MONTHLY]: { defaultMessage: 'Monthly' },
});

export const PlanningShowType = [
    {
        value: 'weekly',
        label: PlanningTypesMessages[PlanningTypes.WEEKLY]
    },
    {
        value: 'monthly',
        label: PlanningTypesMessages[PlanningTypes.MONTHLY]
    }
];

const StatsTypesMessages = defineMessages<StatsTypes>({
    [StatsTypes.REPORT]: { defaultMessage: 'Weekly' },
    [StatsTypes.VIEWPORT]: { defaultMessage: 'Monthly' },
});

export const StatsShowType = [
    {
        value: 'report',
        label: StatsTypesMessages[StatsTypes.REPORT]
    },
    {
        value: 'viewport',
        label: StatsTypesMessages[StatsTypes.VIEWPORT]
    }
];
//#endregion

class planningPerf extends Component<Props, State> {
    //#region Init
    nodeRef = React.createRef<SelectableRef>();
    private occupancyRateRef = React.createRef<any>();
    private missionControlRef = React.createRef<any>();
    private lastSelectedEventPerUser: DictionaryNumber<{ eventId: number; dayStr: string; startDate: string; }> = {};

    constructor(props: Props) {
        super(props);
        const dataFromStorage: string | null | undefined = this.props.company ? localStorage.getItem(`planning_data_${this.props.currentUser?.id}`) : undefined;
        let dataParsedFromStroage: IPlanningStorage | undefined = undefined;
        if (dataFromStorage)
            dataParsedFromStroage = JSON.parse(dataFromStorage);
        this.state = {
            ...getInitState(dataParsedFromStroage)
        };
    }
    //#endregion

    //#region Lifecycle
    loadData = () => {
        this.loadPlanning();
        this.loadReportStatistics(true);
        this.refreshTemplates();
        this.props.loadDepartments();
    };

    componentDidMount(): void {
        this.loadData();

        window.addEventListener('keydown', this._keyDown);
        window.addEventListener('keyup', this._keyUp);

        this.centerViewToToday();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevState.date.unix() != this.state.date.unix())
            this.centerViewToToday();

        if (prevProps.departments.loading && !this.props.departments.loading && (!prevProps.departments.updated || !isEqual(prevProps.departments.data, this.props.departments.data)))
            this.loadPlanning();
        const filtersChanged = !isEqual(prevState.filters, this.state.filters);
        const usersChanged = !isEqual(prevProps.users, this.props.users);
        if (this.props.company && (
            filtersChanged ||
            prevState.showStats !== this.state.showStats ||
            prevState.displayMode !== this.state.displayMode ||
            prevState.date.unix() !== this.state.date.unix()
        )) {

            localStorage.setItem(`planning_data_${this.props.currentUser?.id}`, JSON.stringify({
                filters: { ...this.state.filters },
                showStats: this.state.showStats,
                date: this.state.date.format("YYYYMMDDHHmmss"),
                savedAt: moment().format("YYYYMMDDHHmmss"),
                displayMode: this.state.displayMode
            }));
        }

        if (filtersChanged || usersChanged) {
            this.setState({ filteredUsersEvents: this.filterUsersEvents(this.state.usersEvents) });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this._keyDown);
        window.removeEventListener('keyup', this._keyUp);
    }
    //#endregion

    //#region Selection
    _keyUp = (e?: KeyboardEvent) => {
        if (e && (e.key === 'Control' || e.key === 'Meta' || e.key === 'Shift') && this.state.selectionMode) {
            this.setState({ selectionMode: undefined });
        }
    };

    _keyDown = (e?: KeyboardEvent) => {
        if (e) {
            if (e.key === "Delete") {
                if (this.state.deleteModalOpened === false && this.hasSelection()) this.setState({ deleteModalOpened: true });
            } else if (e.key === "Escape") {
                this.lastSelectedEventPerUser = {};
                if (Object.keys(this.state.selectedEvents).length > 0 || this.state.copiedEvents !== null || this.state.forceAction !== null) {
                    this.setState({ copiedEvents: null, forceAction: null });
                    this.setSelectedEvents({});
                }
            } else if (e.key === "Enter") {
                if (this.state.deleteModalOpened) this.deleteEvents();
            } else if (e.ctrlKey || e.metaKey) {
                if (this.state.selectionMode !== 'add') this.setState({ selectionMode: 'add' });
            } else if (e.shiftKey) {
                if (this.state.selectionMode !== 'remove') this.setState({ selectionMode: 'remove' });
            }
            e.stopPropagation();
        }
    };

    selectEvent = (userId: number, event: NetworkMonthlyPlanningRowPerfAddons, dayStr: string, shiftPressed = false) => {
        const added: string[] = [];
        const removed: string[] = [];

        const selected = this.state.selectedEvents[dayStr];
        if (selected !== undefined) {
            const eventIdString = getSelectableValueForEvent(dayStr, userId, event.id);

            if (selected.find(e => e.eventId === event.id))
                removed.push(eventIdString);
            else if (!shiftPressed) // Not adding if shift is pressed because it will be added below
                added.push(eventIdString);
        } else if (!shiftPressed) { // Not adding if shift is pressed because it will be added below
            added.push(getSelectableValueForEvent(dayStr, userId, event.id));
        }

        const lastSelected = this.lastSelectedEventPerUser[userId];
        if (shiftPressed && lastSelected) {
            const userEvents = this.state.usersEvents[userId].days;
            let startDate = moment(event.startDate);
            let lastSelectedStartDate = moment(lastSelected.startDate);
            if (startDate.isAfter(lastSelectedStartDate))
                [lastSelectedStartDate, startDate] = [startDate.clone(), lastSelectedStartDate.clone()]; // Swap if start is after end

            for (const day of Object.keys(userEvents)) {
                for (const e of userEvents[day].events) {
                    if (moment(e.startDate).isBetween(startDate, lastSelectedStartDate, 'minutes', '[]'))
                        added.push(getSelectableValueForEvent(moment(e.startDate).format("YYYYMMDD"), userId, e.id));
                }
            }
        }


        this.lastSelectedEventPerUser[userId] = { dayStr, eventId: event.id, startDate: event.startDate };
        this.updateSelection(added, removed);
    };

    updateSelection = (added: string[], removed: string[]) => {
        const selection = cloneDeep(this.state.selectedEvents);

        // First remove all to avoid duplucating added keys
        for (const r of [...added, ...removed]) {
            const e = parseSelectableValue(r);
            if (e)
                selection[e.dayStr] = selection[e.dayStr]?.filter(event => e.eventId !== event.eventId);
        }

        // Then add the keys added
        for (const a of added) {
            const e = parseSelectableValue(a);
            if (e) {
                const obj = { eventId: e.eventId, userId: e.userId };
                if (selection[e?.dayStr] !== undefined)
                    selection[e.dayStr].push(obj);
                else
                    selection[e.dayStr] = [obj];
            }
        }

        this.setSelectedEvents(selection);
    };

    setSelectedEvents = (obj: State['selectedEvents']) => {
        const objWithoutEmpty = Object.keys(obj).filter(k => obj[k] !== undefined && obj[k].length > 0).reduce((newObj, key) => {
            newObj[key] = obj[key];
            return newObj;
        }, {} as typeof obj);
        this.setState({
            selectedEvents: objWithoutEmpty,
        });
    };

    hasSelection = () => Object.values(this.state.selectedEvents).length > 0;

    selectionCount = () => Object.values(this.state.selectedEvents).reduce((count, i) => count + i.length, 0);

    cutEvent = (userId: number, events: NetworkMonthlyPlanningRowPerfAddons[], dayStr: string) => {
        this.setState({ copiedEvents: { mode: 'CUT', day: dayStr, userId, events: cloneDeep(events) } });
    };

    copyEvent = (userId: number, events: NetworkMonthlyPlanningRowPerfAddons[], dayStr: string) => {
        this.setState({ copiedEvents: { mode: 'COPY', day: dayStr, userId, events: cloneDeep(events) } });
    };

    copySelectedDay = (userId: number, dayStr: string) => {
        const userData = this.state.usersEvents[userId].days[dayStr];
        const selectedEventsOnThatDay = this.state.selectedEvents[dayStr].filter(e => e.userId === userId).map(e => e.eventId);

        const events: NetworkMonthlyPlanningRowPerfAddons[] = [];
        selectedEventsOnThatDay.forEach(se => {
            if (userData) {
                const foundEvent = userData.events.find(e => e.id === se);
                if (foundEvent)
                    events.push(foundEvent);
            }
        });
        this.setState({
            selectedEvents: {},
            copiedEvents: {
                mode: 'COPY',
                day: dayStr,
                userId,
                events: cloneDeep(events)
            }
        });
    };

    hasCopies = () => this.state.copiedEvents !== null && this.state.copiedEvents.events.length > 0;

    quitModes = () => {
        this.setState({
            selectedEvents: {},
            copiedEvents: null,
            forceAction: null,
        });

        this.lastSelectedEventPerUser = {};
    };
    //#endregion

    //#region Network
    getCcntReport = (month: Moment, usersData: IUserContractInfo[]) => {
        const { intl } = this.props;
        this.setState({ loadingCcnt: true });
        Network.generateReportsCcntV2(month.clone().startOf("month").format(MOMENT_FORMAT_DATE_TO_NETWORK), month.format(MOMENT_FORMAT_DATE_TO_NETWORK), usersData).then(
            (networkCCnt) => {
                const ccnt = networkCCnt[Object.keys(networkCCnt)[0]];
                this.setState({ isCcntVisible: true, ccnt, loadingCcnt: false, userIdInfoPopover: undefined });
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), 'warning');
                this.setState({ loadingCcnt: false });
            }
        );
    };

    getStartEnd = (withTime = false) => {
        const { displayMode, date } = this.state;
        const startOf = displayMode === 'monthly' ? 'month' : 'week';

        const format = withTime ? MOMENT_FORMAT_TO_NETWORK : MOMENT_FORMAT_DATE_TO_NETWORK;
        return {
            startOf: startOf as unitOfTime.StartOf,
            start: date.clone().startOf(startOf).format(format),
            end: date.clone().endOf(startOf).format(format),
            startMoment: date.clone().startOf(startOf),
            endMoment: date.clone().endOf(startOf)
        };
    };

    isEventSingleDay = (startDate: Moment, endDate: Moment) => {
        return startDate.isSame(endDate, 'date');
    };

    monthlyPlanningRowToRowPerf = (e: NetworkMonthlyPlanningRowPerf): NetworkMonthlyPlanningRowPerfAddons => {
        const startMoment = moment.utc(e.startDate);
        const endMoment = moment.utc(e.endDate);
        const d = startMoment.format("YYYYMMDD");

        return {
            ...e,
            dateKey: d,
            colorHex: e.colorHex ?? (e.color ?? undefined),
            startTime: startMoment.format(getFormat('TIME_SHORT')),
            endTime: endMoment.format(getFormat('TIME_SHORT')),
            isSingleDay: this.isEventSingleDay(startMoment, endMoment)
        };
    };

    refreshSidePanels = () => {
        this.occupancyRateRef.current?.updateDays();
        this.missionControlRef.current?.getMissionsStatus();
    };

    parsePlanning = (users: User[], events: any, rights: 'ALL' | number[]) => {
        const usersEvents: State['usersEvents'] = {};
        users?.forEach(u => {
            const hasRights = rights === 'ALL' ? true : rights.includes(u.id);
            const fullName = this.props.getFullName(u);
            const userWithEvents: PlanningUserPerf = {
                availabilityClosedByDefault: u.availabilityClosedByDefault ?? false,
                id: u.id,
                code: u.code,
                firstName: u.first_name,
                lastName: u.last_name,
                fullName,
                image: u.image,
                days: events[u.id],
                hasRights
            };
            usersEvents[u.id] = userWithEvents;
        });

        return usersEvents;
    };

    loadUsers = async (forceReload = true) => {
        if (forceReload || isNullOrEmpty(this.props.users)) {
            try {
                const response = await Network.getAllUsers();
                this.props.changeUsers(response);
                return response;
            } catch (e) {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurref while loading the users' }), "warning");
            }
        }
    };

    loadPlanning = async (centerView = true) => {
        this.lastSelectedEventPerUser = {};
        const { displayMode, date } = this.state;
        const { startOf } = this.getStartEnd();
        const nbDaysInRange = displayMode === 'monthly' ? date.daysInMonth() : 7;
        const daysInRange = new Array(nbDaysInRange).fill(null).map((x, i) => `${date.startOf(startOf).add(i, 'days').format("YYYYMMDD")}`);

        try {
            const { users, departments } = this.props;

            if (!departments.updated) return;
            const { start, end } = this.getStartEnd();

            const response = await Network.getPlanningPerf(start, end);
            const events = response.data.events;
            const newHolidays = response.data.holidays;
            const lockedDays = response.data.lockedDays;
            const contracts = response.data.contracts;

            const holidays = newHolidays.reduce((obj, item) => {
                obj[moment(item.startDate).format('YYYYMMDD')] = true;
                return obj;
            }, {} as State['holidays']);

            const usersEvents = this.parsePlanning(users, events, response.allUsers ? 'ALL' : response.rights);

            this.setState(state => ({
                usersContracts: contracts,
                holidays,
                lockedDaysByUsersByDates: lockedDays,
                usersEvents,
                filteredUsersEvents: this.filterUsersEvents(usersEvents),
                loaded: { ...state.loaded, planning: true, contracts: true, lockedDays: true },
                daysInRange,
                userIdsEditable: response.allUsers ? 'ALL' : response.rights,
                // copiedEvents: null,
                selectedEvents: {}
            }), centerView ? () => this.centerViewToToday : undefined);
        } catch (err) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events' }), "error");
        }
    };

    loadReportStatistics = async (force = false) => {
        if (this.props.company?.type === CompanyTypes.NORMAL && (this.state.showStats === 'report' || force)) {
            const { date } = this.state;
            const { startOf } = this.getStartEnd();
            const until = date.clone().endOf(startOf).endOf('day');

            try {
                const response = await Network.getPlanningStatsV2(until);

                this.setState(state => ({
                    loaded: {
                        ...state.loaded,
                    },
                    hoursStats: response.reduce((obj, curr) => {
                        if (curr.monthTotals.length > 0)
                            obj[curr.user.id] = { overtime: curr.monthTotals.reverse()[0].toReport };
                        return obj;
                    }, {} as State['hoursStats'])
                }));
            } catch (err) {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events' }), "error");
            }
        }
    };

    openEvent = async (eventId?: number, canEdit?: boolean) => {
        if (this.hasSelection() || this.hasCopies()) return;

        try {

            if (eventId === undefined)
                throw new Error();
            const response = await Network.getEventsV2(eventId);
            this.setState({ openedEvent: convertNetworkEventToPlanningEvent(response.data, canEdit) });
        } catch (err) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "error");
        }
    };

    createEventFromMission = (ruleId: number, date: Moment, templateId?: number) => {
        const startDate: Moment = date.clone().startOf('day');
        const endDate: Moment = date.clone().endOf('day');
        if (templateId) {
            Network.getEventFromTemplate(templateId, date).then(
                (response) => {
                    this.setState({
                        editEvent: {
                            ...convertNetworkEventToPlanningEvent(response.data),
                            lookingForUserAvailability: true,
                            ruleId
                        },
                        canEditStartDate: false,
                        loadFileFromTemplate: templateId
                    });
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template' }), "error");
                }
            );
        } else {
            this.setState({
                editEvent: {
                    title: "",
                    startDate,
                    endDate,
                    lookingForUserAvailability: true,
                    ruleId
                },
                canEditStartDate: false,
            });
        }
    };

    createEventFromOccupancyRate = (date: Moment, newOccupancyRate: NetworkOccupancyRate) => {
        let startParsed: Moment = moment(date.format(MOMENT_MONTH_TIME_FORMAT)).startOf('day');
        let endParsed: Moment = moment(date.format(MOMENT_MONTH_TIME_FORMAT)).endOf('day');
        if (newOccupancyRate.startHour && newOccupancyRate.endHour) {
            const orStartTime = moment(newOccupancyRate.startHour, MOMENT_TIME_FORMAT);
            const orEndTime = moment(newOccupancyRate.endHour, MOMENT_TIME_FORMAT);
            if (orStartTime.isAfter(orEndTime, "seconds")) {
                endParsed = endParsed.add(1, "days");
            }

            startParsed = startParsed.clone().set("hours", orStartTime.hours()).set("minutes", orStartTime.minutes()).set("second", orStartTime.seconds());
            endParsed = endParsed.clone().set("hours", orEndTime.hours()).set("minutes", orEndTime.minutes()).set("second", orEndTime.seconds());
        }

        this.setState({
            editEvent: {
                lookingForUserAvailability: true,
                startDate: startParsed,
                endDate: endParsed,
                typeOfDay: newOccupancyRate.typeOfDay,
                staffType: newOccupancyRate.staffType,
                poi: newOccupancyRate.poi,
                basePoi: newOccupancyRate.basePoi,
                title: newOccupancyRate.title,
                department: newOccupancyRate.department,
                groupId: undefined,
                isGlobal: false,
                isDraggable: true,
                finish: undefined,
                owner: PlanningEventOwner.User,
            },
            canEditStartDate: false,
        });
    };

    createEvent = (userId: number, dayStr: string) => {
        const date = moment(dayStr, 'YYYYMMDD');
        this.setState({
            editEvent: {
                lookingForUserAvailability: false,
                startDate: this.props.settings?.startHourOfDay ? date.clone().hours(this.props.settings.startHourOfDay.hours()).minutes(this.props.settings.startHourOfDay.minutes()).seconds(0).milliseconds(0) : date.clone().seconds(0).milliseconds(0),
                endDate: this.props.settings?.endHourOfDay ? date.clone().hours(this.props.settings.endHourOfDay.hours()).minutes(this.props.settings.endHourOfDay.minutes()).seconds(this.props.settings.endHourOfDay.seconds()) : date.clone().add(1, 'hours'),
                title: '',
                userId: userId,
                groupId: undefined,
                isGlobal: false,
                isDraggable: true,
                finish: undefined,
                owner: PlanningEventOwner.User,
            },
            canEditStartDate: false
        });
    };

    editEvent = async (eventId: number) => {
        const { intl } = this.props;
        try {
            const response = await Network.getEventsV2(eventId);
            const newEvent = convertNetworkEventToPlanningEvent(response.data);
            if (
                ((this.props.currentUser?.role === 2)) ||
                ((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'any') ||
                ((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'groupadmin' && (this.props.currentUser?.id === newEvent.creatorId || !this.props.users.some((user) => user.id === newEvent.creatorId && [2, 3].includes(user.role)))) ||
                ((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'owned_only' && this.props.currentUser?.id === newEvent.creatorId)
            ) {
                this.setState({
                    editEvent: newEvent,
                    canEditStartDate: false
                });

            } else
                showNotification((this.props.settings.groupAdminWriteAuthorization ?? 'groupadmin') === 'groupadmin' ? intl.formatMessage({ defaultMessage: 'You can only modify events that have not been created by an administrator.' }) : intl.formatMessage({ defaultMessage: 'You can only modify events that you have created.' }), "warning");
        } catch (err) {
            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "error");
        }

        this.refreshSidePanels();
    };

    refreshTemplates = async () => {
        return await Network.getTemplates().then(
            response => {
                if (response.error) showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning");
                else {
                    this.props.changeTemplates(convertNetworkEventsToPlanningEventsV2(response.data));
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning")
        );
    };

    onCreateEvent = (
        errors: EventCreateError[],
        event: PlanningEvent,
        userIds: number[],
        sendMessage?: boolean,
        asTemplate?: boolean,
        forceCreate?: boolean,
        skipCheckAvailability?: boolean,
        callback?: CreationCallback,
        dates?: DateRange[],
    ) => {
        const { usersEvents } = this.state;
        const { intl } = this.props;
        this.props.toggleLoading!(true);
        // method that create the event
        const createTemplate = () => {
            let i = -1;
            Network.updateTemplate({
                ...event,
                startDate: moment(event.startDate),
                endDate: moment(event.endDate),
                reminders: event?.reminders?.map(r => {
                    return ({ ...r, id: i-- });
                })
            }).then(
                () => {
                    this.refreshTemplates();
                    message.success(intl.formatMessage({ defaultMessage: 'The template has been successfully updated' }));
                    this.refreshSidePanels();
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the template' }), "error")
            );
        };
        const create = () => {
            const forcedUsersBody: { id: number, forceCreate: boolean, forceReasons: string; }[] = [];

            if (errors.length > 0)
                errors.forEach(eventData => {
                    let reasons = '';

                    eventData.errors.forEach(error => {
                        if (error.codes && error.codes.length > 0) {
                            error.codes.forEach(code => {
                                reasons += `${error.type}#${code};`;
                            });
                        }
                        else {

                            reasons += `${error?.type};`;
                        }
                    });

                    forcedUsersBody.push({
                        id: eventData.user.id,
                        forceCreate: true,
                        forceReasons: reasons
                    });
                });

            if (userIds.length > 0)
                userIds.forEach(id => {
                    const foundErrorWithId = forcedUsersBody.find(e => e.id === id);
                    if (foundErrorWithId === undefined)
                        forcedUsersBody.push({ id, forceCreate: false, forceReasons: '' });
                });

            Network.createEventV3(forcedUsersBody, event, sendMessage, dates).then(
                (response: NetworkMonthlyPlanningRowPerf[]) => {
                    asTemplate && createTemplate();
                    response.forEach(element => {
                        const userData = usersEvents[element.userId];

                        const ePerf = this.monthlyPlanningRowToRowPerf(element);
                        userData.days[ePerf.dateKey].events.push(ePerf);
                        userData.days[ePerf.dateKey].events.sort((a, b) => a.startDate.localeCompare(b.startDate));
                        this.loadReportStatistics();
                        callback && callback(element.id);
                    });

                    this.setState({ usersEvents, filteredUsersEvents: this.filterUsersEvents(usersEvents), editEvent: undefined, canEditStartDate: true, refreshMissionsStatus: true }, () => {
                        // this.loadStatsData()
                        message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully created' }));
                    });
                    this.props.toggleLoading!(false);
                    this.refreshSidePanels();
                },
                (error) => {
                    this.props.toggleLoading!(false);
                    let parsedError: any = undefined;
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        captureException(error);
                    }
                    if (parsedError?.error) {
                        if (parsedError.message === "You only can modify events of your group" ||
                            parsedError.message === "You do not have the necessary rights to perform this action.") {
                            message.error(intl.formatMessage({ defaultMessage: 'You do not have the rights for this user' }));
                            this.setState({ editEvent: undefined, canEditStartDate: true });
                            return;
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the event' }), "error");
                    }
                }
            );
        };

        // if event is global update directely, otherwise check availability
        // check availavility
        if (!skipCheckAvailability && !forceCreate) {
            Network.eventCheckAvailability(event, userIds).then(
                response => {
                    if (response.length > 0) {
                        this.props.toggleLoading!(false);

                        notification.error({
                            message: intl.formatMessage({ defaultMessage: 'Some users have no availability (periods) for the creation of this event:' }),
                            description: (
                                <ul>
                                    {response.map((u: UserSummary) => <li key={`eventCheckAvailability-user-${u.id}`}>{`${u.last_name} ${u.first_name}`}</li>)}
                                </ul>
                            ),
                            duration: null,
                        });

                    } else {
                        create();
                    }
                },
                () => {
                    this.props.toggleLoading!(false);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the event' }), "warning");
                }
            );
        } else {
            create();
        }
    };


    onConfirmEvent = async (userId: number, eventId: number) => {
        const { usersEvents } = this.state;
        const { intl } = this.props;
        this.props.toggleLoading!(true);

        try {
            const response: NetworkMonthlyPlanningRowPerf = await Network.confirmEventV2(eventId, userId);
            const userData = usersEvents[response.userId];
            const userDataDay = userData.days[moment(response.startDate).format("YYYYMMDD")].events;
            const eventIndex = userDataDay.findIndex(d => d.id === response.id);
            if (eventIndex > -1) {
                userDataDay[eventIndex] = {
                    ...userDataDay[eventIndex],
                    confirmed: true
                };
            }

            this.setState({ usersEvents, filteredUsersEvents: this.filterUsersEvents(usersEvents) }, () => {
                message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully confirmed' }));
            });

            this.props.toggleLoading!(false);
        } catch (err) {
            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the event' }), "error");
            this.props.toggleLoading!(false);
        }
    };

    onEditEvent = (errors: EventCreateError[], event: PlanningEvent, sendMessage?: boolean, forceUpdate?: boolean, skipCheckAvailability?: boolean): void => {
        const { usersEvents } = this.state;
        const { intl } = this.props;
        this.props.toggleLoading!(true);

        // method that update the event
        const update = () => {
            let ignoreTimeclock = false;
            let forceReasons: string | undefined;
            if (errors.length > 0) {
                forceReasons = '';
                ignoreTimeclock = true;
                errors.forEach(eventData => {
                    eventData.errors.forEach(error => {
                        if (error.codes && error.codes.length > 0)
                            error.codes.forEach(code => {
                                forceReasons += `${error.type}#${code};`;
                            });
                        else
                            forceReasons += `${error?.type};`;
                    });
                });
            }

            Network.updateEventV2(event, sendMessage, forceUpdate, forceReasons, ignoreTimeclock).then(
                (response: NetworkMonthlyPlanningRowPerf) => {
                    const userData = usersEvents[response.userId];
                    const dateStr = `${moment(response.startDate).format("YYYYMMDD")}`;
                    const userDataDay = userData.days[dateStr];

                    const eventIndex = userDataDay.events.findIndex(d => d.id === response.id);
                    if (eventIndex > -1) {
                        const ePerf = this.monthlyPlanningRowToRowPerf(response);
                        userDataDay.events[eventIndex] = ePerf;
                        userData.days[dateStr].events.sort((a, b) => a.startDate.localeCompare(b.startDate));
                    }

                    this.setState({ usersEvents: usersEvents, filteredUsersEvents: this.filterUsersEvents(usersEvents), editEvent: undefined, canEditStartDate: true, refreshMissionsStatus: true }, () => {
                        message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully updated' }));
                    });

                    this.props.toggleLoading!(false);
                    this.refreshSidePanels();
                    this.loadReportStatistics();
                },
                (error) => {
                    this.props.toggleLoading!(false);
                    let parsedError: any = undefined;
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (e) {
                        captureMessage(error);
                        captureMessage(error.message);
                        captureException(e);
                    }
                    if (parsedError?.error) {
                        if (parsedError.message === "You only can modify events of your group" ||
                            parsedError.message === "You do not have the necessary rights to perform this action.") {
                            showNotification(intl.formatMessage({ defaultMessage: 'You do not have the rights for this user' }), "error");
                            this.setState({ editEvent: undefined, canEditStartDate: true });
                            return;
                        } else if (parsedError.message === "You only can modify events of your group that not created by administrator") {
                            showNotification(intl.formatMessage({ defaultMessage: 'This event was created by a super administrator, you cannot modify it.' }), "error");
                            this.setState({ editEvent: undefined, canEditStartDate: true });
                            return;
                        } else if (parsedError.message === "You only can modify events of your group that you create") {
                            showNotification(intl.formatMessage({ defaultMessage: 'You can only modify events that you have created.' }), "error");
                            this.setState({ editEvent: undefined, canEditStartDate: true });
                            return;
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "error");
                    }
                }
            );
        };

        if (event.userId) {
            if (!skipCheckAvailability && !forceUpdate) {

                // check availavility
                Network.eventCheckAvailability(event, [event.userId]).then(
                    response => {
                        if (response.length > 0) {
                            this.props.toggleLoading!(false);
                            showNotification(intl.formatMessage({ defaultMessage: 'The user has no availability (periods) for the creation of this event.' }), "error");
                        } else {
                            // update the event
                            update();
                        }
                    },
                    () => {
                        this.props.toggleLoading!(false);
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "error");
                    }
                );
            } else {
                update();
            }
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'An unknown error occured, please contact the administrator' }), "error");
        }
    };

    deleteEvent = (eventId: number, userId: number, dayStr: string): void => {
        // if deleteOne, delete just this event's occurence
        const { intl } = this.props;
        Network.deleteEventV2([eventId]).then(
            (data) => {
                if (data["status"] && data["status"] === "success") {
                    this.setState((state) => {
                        const usersEvents = {
                            ...state.usersEvents,
                            [userId]: {
                                ...state.usersEvents[userId],
                                days: {
                                    ...state.usersEvents[userId].days,
                                    [dayStr]: {
                                        ...state.usersEvents[userId].days[dayStr],
                                        events: state.usersEvents[userId].days[dayStr].events.filter(e => e.id !== eventId)
                                    }
                                }
                            }
                        };
                        return {
                            usersEvents,
                            filteredUsersEvents: this.filterUsersEvents(usersEvents),
                            refreshMissionsStatus: true
                        };
                    }, () => {
                        message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully deleted' }));
                    });
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                }
                this.refreshSidePanels();
                this.loadReportStatistics();
            },
            (error) => {
                let parsedError: any = undefined;
                if (error.message && ![INTERNET_ERROR_MESSAGE, API_ERROR_MESSAGE].includes(error.message)) {
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        captureException(error);
                    }
                }
                if (parsedError?.message === "You only can delete events of your group that you create") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                } else if (parsedError?.message === "You only can delete events of your group that not created by administrator") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that have not been created by an administrator' }), "error");
                } else if (parsedError?.message === "You only can delete your own events") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete your events' }), "error");
                } else if (parsedError?.message === "You only can delete your own events than you created") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                } else if (parsedError?.message === "You only can delete events of your group") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events from your groups' }), "error");
                } else if (parsedError?.message === "You do not have the necessary rights to perform this action.") {
                    showNotification(intl.formatMessage({ defaultMessage: 'You do not have the necessary rights to perform this removal' }), "error");
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                }
            }
        );
    };

    replacePlaceholdersByEvents = (userId: number, dayStr: string, events: NetworkMonthlyPlanningRowPerf[]) => {
        const { usersEvents } = this.state;
        const userData = cloneDeep(usersEvents[userId].days[dayStr]);
        for (const event of events) {
            if (userData) {
                const eventFoundIdx = userData.events.findIndex(e => e.loading);
                if (eventFoundIdx >= 0) {
                    const eventFound = userData.events[eventFoundIdx];
                    const ePerf = this.monthlyPlanningRowToRowPerf(event);
                    userData.events[eventFoundIdx] = {
                        ...eventFound,
                        ...ePerf,
                        loading: false,
                        eventLocked: false
                    };
                    userData.events.sort((a, b) => a.startDate.localeCompare(b.startDate));
                }
            }
        }

        return {
            ...usersEvents,
            [userId]: {
                ...usersEvents[userId],
                days: {
                    ...usersEvents[userId].days,
                    [dayStr]: userData
                }
            }
        };
    };

    onPasteEvent = (userId: number, dayStr: string, forceCopy?: boolean, skipCheckAvailability?: boolean, callbackSuccess?: () => void, callbackError?: () => void) => {
        const { copiedEvents, forceAction } = this.state;
        const { intl } = this.props;
        if (!copiedEvents) return;
        // get start date
        const startDate = moment(dayStr, 'YYYYMMDD');

        const copyOrCut = () => {
            switch (copiedEvents.mode) {
                case 'COPY':
                    copy(forceAction?.events || [], copiedEvents.events);
                    break;
                case 'CUT':
                    cut(forceAction?.events || [], copiedEvents.events[0]);
                    break;
            }
        };

        const copy = (forceEvents: EventCopyError[], copiedEvents: NetworkMonthlyPlanningRowPerfAddons[]) => {
            const forcedEventsBody: CopyEventsBodyRequestV2[] = [];

            if (copiedEvents.length > 0) {
                copiedEvents.forEach(eventData => {
                    const foundEventBody = forcedEventsBody[forcedEventsBody.findIndex(eb => eb.eventId === eventData.id)];

                    if (forceEvents.some(e => e.event.id === eventData.id)) {
                        forceEvents.forEach((forceEvent) => {
                            if (forceEvent.event.id === eventData.id) {
                                let reasons = '';

                                forceEvent.errors.forEach(error => {
                                    if (error.codes && error.codes.length > 0)
                                        error.codes.forEach(code => {
                                            reasons += `${error.type}#${code};`;
                                        });
                                    else
                                        reasons += error?.type ? `${error?.type};` : '';
                                });

                                const destination = ({
                                    date: startDate.format('YYYY-MM-DD'),
                                    userId: userId,
                                    forceReasons: reasons,
                                    forceCopy: true
                                });

                                if (foundEventBody) {
                                    const foundEventBodyEvent = foundEventBody.destinations.find(e => e.date === startDate.format('YYYY-MM-DD'));

                                    if (foundEventBodyEvent)
                                        foundEventBodyEvent.forceReasons += `;${reasons}`;
                                    else
                                        foundEventBody.destinations.push(destination);
                                }
                                else
                                    forcedEventsBody.push({
                                        eventId: eventData.id,
                                        destinations: [destination]
                                    });
                            }
                        });
                    } else {
                        forcedEventsBody.push({
                            eventId: eventData.id,
                            destinations: [{
                                date: startDate.format('YYYY-MM-DD'),
                                userId: userId,
                            }]
                        });
                    }
                });
            }
            else {
                showNotification(intl.formatMessage({ defaultMessage: "You don't have any events on hold to copy" }), 'error');
                return;
            }

            Network.copyPasteEventsV2(forcedEventsBody).then(
                data => {
                    const events = data as NetworkMonthlyPlanningRowPerf[];
                    callbackSuccess && callbackSuccess();
                    const newUsersEvents = this.replacePlaceholdersByEvents(userId, dayStr, events);
                    this.refreshSidePanels();
                    this.setState({ forceAction: null, usersEvents: newUsersEvents, filteredUsersEvents: this.filterUsersEvents(newUsersEvents), refreshMissionsStatus: true });
                    this.loadReportStatistics();
                },
                (error) => {
                    let parsedError: any = undefined;
                    callbackError && callbackError();
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        captureException(error);
                    }
                    if (parsedError?.error) {
                        if (parsedError.message === "No event correspond to the given id") {
                            showNotification(intl.formatMessage({ defaultMessage: 'The event to copy does not exist anymore' }), "warning");
                            this.setState({ copiedEvents: null });
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while copying the event' }), "warning");
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while copying the event' }), "warning");
                    }
                }
            );
        };

        const cut = (forceEvents: EventCopyError[], copiedEvent: NetworkMonthlyPlanningRowPerfAddons) => {
            const forcedEventsBody: CopyEventsBodyRequestV2[] = [];

            const foundEventBody = forcedEventsBody[forcedEventsBody.findIndex(eb => eb.eventId === copiedEvent.id)];
            let reasons = '';

            if (forceEvents.some(e => e.event.id === copiedEvent.id)) {
                forceEvents.forEach((forceEvent) => {
                    if (forceEvent.event.id === copiedEvent.id) {
                        forceEvent.errors.forEach(error => {
                            if (error.codes && error.codes.length > 0)
                                error.codes.forEach(code => {
                                    reasons += `${error.type}#${code};`;
                                });
                            else
                                reasons += error?.type ? `${error?.type};` : '';
                        });

                        const destination = ({
                            date: startDate.format('YYYY-MM-DD'),
                            userId: userId,
                            forceReasons: reasons,
                            forceCopy: true
                        });

                        if (foundEventBody) {
                            const foundEventBodyEvent = foundEventBody.destinations.find(e => e.date === startDate.format('YYYY-MM-DD'));

                            if (foundEventBodyEvent)
                                foundEventBodyEvent.forceReasons += `;${reasons}`;
                            else
                                foundEventBody.destinations.push(destination);
                        }
                        else
                            forcedEventsBody.push({
                                eventId: copiedEvent.id,
                                destinations: [destination]
                            });
                    }
                });
            } else {
                forcedEventsBody.push({
                    eventId: copiedEvent.id,
                    destinations: [{
                        date: startDate.format('YYYY-MM-DD'),
                        userId: userId,
                    }]
                });
            }

            Network.cutPasteEventV2(copiedEvent.id, userId, startDate.format("YYYY-MM-DDTHH:mm:ss"), forceCopy, reasons).then(
                data => {
                    if (data.error === true) {
                        callbackError && callbackError();
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while moving the event' }), "warning");
                    } else {
                        callbackSuccess && callbackSuccess();
                        let newUsersEvents = this.replacePlaceholdersByEvents(userId, dayStr, [data]);
                        if (newUsersEvents[copiedEvents.userId].days[copiedEvents.day]) {
                            this.refreshSidePanels();
                            newUsersEvents = {
                                ...newUsersEvents,
                                [copiedEvents.userId]: {
                                    ...newUsersEvents[copiedEvents.userId],
                                    days: {
                                        ...newUsersEvents[copiedEvents.userId].days,
                                        [copiedEvents.day]: {
                                            ...newUsersEvents[copiedEvents.userId].days[copiedEvents.day],
                                            events: [
                                                ...newUsersEvents[copiedEvents.userId].days[copiedEvents.day].events.filter(e => e.id !== copiedEvent.id)
                                            ]
                                        }
                                    }
                                }
                            };
                            this.setState({
                                forceAction: null,
                                usersEvents: newUsersEvents,
                                filteredUsersEvents: this.filterUsersEvents(newUsersEvents),
                                copiedEvents: null,
                                refreshMissionsStatus: true
                            });
                        }
                        this.loadReportStatistics();
                    }
                },
                (error) => {
                    let parsedError: any = undefined;
                    callbackError && callbackError();
                    try {
                        parsedError = JSON.parse(error.message);
                    } catch (error) {
                        captureException(error);
                    }
                    if (parsedError?.error) {
                        switch (parsedError.message) {
                            case "You only can cut events of your group that not created by administrator":
                                showNotification(intl.formatMessage({ defaultMessage: 'You can only move events that have not been created by an administrator' }), "error");
                                break;
                            case "You only can cut events of your group that you create":
                            case "You only can modify your own events":
                                showNotification(intl.formatMessage({ defaultMessage: 'You can only move events that you have created' }), "error");
                                break;
                            case "You only can modify events of your group":
                                showNotification(intl.formatMessage({ defaultMessage: 'You can only move events for members of your group' }), "error");
                                break;
                            case "No event correspond to the given id":
                                showNotification(intl.formatMessage({ defaultMessage: 'The event to be copied no longer exists' }), "warning");
                                break;
                            default:
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while moving the event' }), "warning");
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while moving the event' }), "warning");
                    }
                }
            );
        };
        const userAvailabilitiesRequestData: UserAvailabilityBodyRequest[] = [];
        const originalEvents = copiedEvents.events;
        const newTemporaryEvents: NetworkMonthlyPlanningRowPerfAddons[] = [];
        const eventCanBeCopiedBody: EventCanBeCopiedBody = { data: [] };
        originalEvents.forEach(ce => {
            const tmpDate = moment(ce.startDate, MOMENT_FORMAT_TO_NETWORK);
            const date = moment.utc(dayStr, 'YYYYMMDD').hours(tmpDate.hours()).minutes(tmpDate.minutes()).seconds(tmpDate.seconds());
            const duration = moment.duration(moment(ce.endDate).diff(ce.startDate));
            const durationInSeconds = duration.asSeconds();
            const endDate = date.clone().add(durationInSeconds, "seconds");
            userAvailabilitiesRequestData.push({
                userId,
                startDate: date,
                endDate: endDate,
            });
            newTemporaryEvents.push({
                ...ce,
                userId,
                loading: true,
                id: ce.id + Math.round(Math.random() * 1_000_000),
                startDate: date.format(MOMENT_FORMAT_TO_NETWORK),
                endDate: endDate.format(MOMENT_FORMAT_TO_NETWORK),
            });
            eventCanBeCopiedBody.data.push({
                eventId: ce.id,
                destinations: [
                    {
                        userId,
                        startDate: date.format(MOMENT_FORMAT_TO_NETWORK),
                        endDate: endDate.format(MOMENT_FORMAT_TO_NETWORK)
                    }
                ]
            });
        });
        this.setState(state => {
            const usersEvents = {
                ...state.usersEvents,
                [userId]: {
                    ...state.usersEvents[userId],
                    days: {
                        ...state.usersEvents[userId].days,
                        [dayStr]: {
                            ...state.usersEvents[userId].days[dayStr],
                            events: [
                                ...[...state.usersEvents[userId].days[dayStr].events, ...newTemporaryEvents].sort((a, b) => a.startDate.localeCompare(b.startDate))
                            ]
                        }
                    }
                }
            };

            return {
                usersEvents,
                filteredUsersEvents: this.filterUsersEvents(usersEvents)
            };
        }, () => {
            if (forceCopy) {
                copyOrCut();
            } else {
                if (copiedEvents.mode === 'COPY') {
                    Network.canEventBeCopied(eventCanBeCopiedBody).then(
                        (response) => {
                            const eventErrors: EventCopyError[] = [];

                            response.data.forEach(event => {
                                const foundEventError = eventErrors[eventErrors.findIndex(eb => eb.event.id === event.eventId)];
                                const error = ({
                                    type: event.errorType,
                                    codes: event.errorValues
                                });

                                if (foundEventError) {
                                    foundEventError.errors.push(error);
                                }
                                else
                                    eventErrors.push({
                                        event: copiedEvents.events[copiedEvents.events.findIndex(e => e.id === event.eventId)],
                                        errors: [error]
                                    });
                            });

                            if (response.data.length > 0) {
                                this.props.toggleLoading(false);
                                this.setState(state => {
                                    const usersEvents = {
                                        ...state.usersEvents,
                                        [userId]: {
                                            ...state.usersEvents[userId],
                                            days: {
                                                ...state.usersEvents[userId].days,
                                                [dayStr]: {
                                                    ...state.usersEvents[userId].days[dayStr],
                                                    events: [
                                                        ...state.usersEvents[userId].days[dayStr].events.filter(e => !e.loading),
                                                    ]
                                                }
                                            }
                                        }
                                    };
                                    return {
                                        usersEvents,
                                        filteredUsersEvents: this.filterUsersEvents(usersEvents),
                                        forceAction: {
                                            action: 'COPY',
                                            dayStr,
                                            userId,
                                            events: eventErrors
                                        }
                                    };
                                });
                                message.error(intl.formatMessage({ defaultMessage: 'Incompatible user' }));
                            }
                            else {
                                copyOrCut();
                            }
                        },
                        () => {
                            showNotification(intl.formatMessage({ defaultMessage: "An error occurred while verifying the user's availability" }), "error");
                        }
                    );
                }
                else if (copiedEvents.mode === 'CUT') {
                    Network.canEventBeCut(eventCanBeCopiedBody).then(
                        (response) => {
                            const eventErrors: EventCopyError[] = [];

                            response.data.forEach(event => {
                                const foundEventError = eventErrors[eventErrors.findIndex(eb => eb.event.id === event.eventId)];
                                const error = ({
                                    type: event.errorType,
                                    codes: event.errorValues
                                });

                                if (foundEventError) {
                                    foundEventError.errors.push(error);
                                }
                                else
                                    eventErrors.push({
                                        event: copiedEvents.events[copiedEvents.events.findIndex(e => e.id === event.eventId)],
                                        errors: [error]
                                    });
                            });

                            if (response.data.length > 0) {
                                this.props.toggleLoading(false);
                                this.setState(state => {
                                    const usersEvents = {
                                        ...state.usersEvents,
                                        [userId]: {
                                            ...state.usersEvents[userId],
                                            days: {
                                                ...state.usersEvents[userId].days,
                                                [dayStr]: {
                                                    ...state.usersEvents[userId].days[dayStr],
                                                    events: [
                                                        ...state.usersEvents[userId].days[dayStr].events.filter(e => !e.loading),
                                                    ]
                                                }
                                            }
                                        }
                                    };
                                    return {
                                        usersEvents,
                                        filteredUsersEvents: this.filterUsersEvents(usersEvents),
                                        forceAction: {
                                            action: 'COPY',
                                            dayStr,
                                            userId,
                                            events: eventErrors
                                        }
                                    };
                                });
                                message.error(intl.formatMessage({ defaultMessage: 'Incompatible user' }));
                            }
                            else {
                                copyOrCut();
                            }
                        },
                        () => {
                            showNotification(intl.formatMessage({ defaultMessage: "An error occurred while verifying the user's availability" }), "error");
                        }
                    );
                }
            }
        });
    };

    onUpdateSettings = (settings: NetworkSettings) => {
        this.setState({ showSettings: false });
        this.props.changeSettings!(convertNetworkSettingsToPlanningSettings(settings));
    };

    refreshPlanning = () => {
        this.setState({
            selectedEvents: {},
            copiedEvents: null,
            loaded: {
                planning: false,
            }
        }, () => {
            this.loadPlanning(false);
            this.loadReportStatistics(true);
        });
    };

    deleteEvents = () => {
        const { selectedEvents, usersEvents } = this.state;
        const selectedEventIds = this.getSelectedEventIds();
        const { intl } = this.props;
        this.setState({ loadingDelete: true });
        if (selectedEventIds.length > 0) {
            this.setState({ loadingDelete: true });
            Network.deleteEventV2(selectedEventIds).then(
                (data) => {
                    if (data["status"] && data["status"] === "success") {
                        let newUserEvents: State['usersEvents'] = { ...usersEvents };

                        Object.keys(selectedEvents).map(k => {
                            const events = selectedEvents[k].filter(e => selectedEventIds.includes(e.eventId));
                            events.forEach(e => {
                                newUserEvents = {
                                    ...newUserEvents,
                                    [e.userId]: {
                                        ...newUserEvents[e.userId],
                                        days: {
                                            ...newUserEvents[e.userId].days,
                                            [k]: {
                                                ...newUserEvents[e.userId].days[k],
                                                events: [
                                                    ...newUserEvents[e.userId].days[k].events.filter(ev => ev.id !== e.eventId)
                                                ]
                                            }
                                        }
                                    }
                                };
                            });
                        });

                        this.setState({ usersEvents: newUserEvents, filteredUsersEvents: this.filterUsersEvents(newUserEvents), selectedEvents: {}, deleteModalOpened: false, refreshMissionsStatus: true }, () => {
                            message.success(intl.formatMessage({ defaultMessage: 'The event has been successfully deleted' }));
                        });
                    } else {
                        this.setState({ selectedEvents: {} });
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                    }
                    this.refreshSidePanels();
                },
                (error) => {
                    let parsedError: any = undefined;
                    if (error.message && ![INTERNET_ERROR_MESSAGE, API_ERROR_MESSAGE].includes(error.message)) {
                        try {
                            parsedError = JSON.parse(error.message);
                        } catch (error) {
                            captureException(error);
                        }
                    }
                    if (parsedError?.message === "You only can delete events of your group that you create") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                    } else if (parsedError?.message === "You only can delete your own events") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete your events' }), "error");
                    } else if (parsedError?.message === "You only can delete events of your group that not created by administrator") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that have not been created by an administrator.' }), "error");
                    } else if (parsedError?.message === "You only can delete your own events than you created") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events that you have created' }), "error");
                    } else if (parsedError?.message === "You only can delete events of your group") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You can only delete events from your groups' }), "error");
                    } else if (parsedError?.message === "You do not have the necessary rights to perform this action.") {
                        showNotification(intl.formatMessage({ defaultMessage: 'You do not have the necessary rights to perform this removal' }), "error");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the event' }), "error");
                    }
                }
            ).finally(() => this.setState({ loadingDelete: false }));
        }
        else {
            showNotification(intl.formatMessage({ defaultMessage: 'Unable to delete' }), "warning", intl.formatMessage({ defaultMessage: 'All the events you selected are either locked or the day they are in is locked' }));
            this.setState({ loadingDelete: false });
        }
    };

    confirmEvents = (): void => {
        const { selectedEvents, usersEvents } = this.state;
        const selectedEventIds = this.getSelectedEventIds();
        const { intl } = this.props;

        if (selectedEventIds.length > 0) {
            Network.confirmMultipleEvent(selectedEventIds).then(
                (response) => {
                    if (response.error === true) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "error");
                    } else {
                        const eventsIdsConfirmed: number[] | undefined | null = response["eventsIds"];
                        if (eventsIdsConfirmed && eventsIdsConfirmed.length > 0) {
                            let newUserEvents: State['usersEvents'] = { ...usersEvents };

                            Object.keys(selectedEvents).map(k => {
                                const events = selectedEvents[k];
                                events.forEach(e => {
                                    if (eventsIdsConfirmed.includes(e.eventId)) {
                                        newUserEvents = {
                                            ...newUserEvents,
                                            [e.userId]: {
                                                ...newUserEvents[e.userId],
                                                days: {
                                                    ...newUserEvents[e.userId].days,
                                                    [k]: {
                                                        ...newUserEvents[e.userId].days[k],
                                                        events: [
                                                            ...newUserEvents[e.userId].days[k].events.map(ev => ev.id === e.eventId ? { ...ev, confirmed: true } : ev)
                                                        ]
                                                    }
                                                }
                                            }
                                        };
                                    }
                                });
                            });

                            this.setState({ usersEvents: newUserEvents, filteredUsersEvents: this.filterUsersEvents(newUserEvents), selectedEvents: {} }, () => {
                                message.success(intl.formatMessage({ defaultMessage: 'The events has been successfully confirmed' }));
                            });
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "error");
                        }
                    }
                    this.refreshSidePanels();
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "error");
                }
            );
        }
        else {
            showNotification(intl.formatMessage({ defaultMessage: 'Unable to confirm' }), "warning", intl.formatMessage({ defaultMessage: 'All the events you selected are either locked or the day they are in is locked' }));
        }
    };

    onForceAction = (exec = false) => {
        if (!exec) {
            this.setState({ forceAction: null });
            return;
        }

        const { forceAction } = this.state;
        if (forceAction) {
            this.onPasteEvent(forceAction.userId, forceAction.dayStr, true);
        }
    };

    onDeleteOvertimeFromEvent = (overtime: PlanningOvertime) => {
        Network.deleteOvertime(overtime.id!).then(
            (response: NetworkEvent) => {
                const eventParent: PlanningEvent = convertNetworkEventToPlanningEvent(response);
                const { userRows } = this.props;
                const { usersEvents, openedEvent } = this.state;
                if (openedEvent) {
                    openedEvent.overtimes = eventParent.overtimes;
                    this.setState({ openedEvent });
                }
                let newUsersEvent = cloneDeep(usersEvents);
                if (eventParent.userId) {
                    const dayStr = eventParent.startDate.format("YYYYMMDD");
                    newUsersEvent = {
                        ...newUsersEvent,
                        [eventParent.userId]: {
                            ...newUsersEvent[eventParent.userId],
                            days: {
                                ...newUsersEvent[eventParent.userId].days,
                                [dayStr]: {
                                    ...newUsersEvent[eventParent.userId].days[dayStr],
                                    events: [
                                        ...newUsersEvent[eventParent.userId].days[dayStr].events.map(e => e.id === eventParent.id ? e : {
                                            ...e,
                                            overtimes: eventParent.overtimes
                                        }),
                                    ]
                                }
                            }
                        }
                    };
                    this.setState({ usersEvents: newUsersEvent, filteredUsersEvents: this.filterUsersEvents(newUsersEvent) });
                    this.props.changeUserRows!(userRows);
                    message.success(this.props.intl.formatMessage({ defaultMessage: 'The overtime {title} has been successfully deleted' }, { title: overtime.title }));
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the overtime' }), "warning"),
        );
    };
    //#endregion

    //#region Columns
    createColumns = (filteredUsersEvents: FilteredUserEvents[]): ColumnProps<PlanningUserPerf>[] => {
        const { lockedDaysByUsersByDates, hoursStats, usersContracts, showStats, holidays, userIdsEditable, loadingCcnt, date, userIdInfoPopover } = this.state;
        const { currentUser, departments } = this.props;
        const hasDepartments = departments.data.length > 0;
        const { endMoment } = this.getStartEnd();
        const { rowSums, colSums } = showStats ? this.getTotalHours(filteredUsersEvents) : { rowSums: undefined, colSums: undefined };
        const hasActiveFilters = this.hasFiltersActive('EVENT_RELATED');
        const canSeeReportCounters = this.props.company?.type === CompanyTypes.NORMAL && !hasDepartments;
        let daysInRange = cloneDeep(this.state.daysInRange);
        if (!daysInRange.length) {
            const { displayMode, date } = this.state;
            const { startOf } = this.getStartEnd();
            const nbDaysInRange = displayMode === 'monthly' ? date.daysInMonth() : 7;
            daysInRange = new Array(nbDaysInRange).fill(null).map((x, i) => `${date.startOf(startOf).add(i, 'days').format("YYYYMMDD")}`);
        }

        const hasCopies = this.hasCopies();

        const isLoading = this.isLoading();
        const columns: ColumnProps<PlanningUserPerf>[] = [
            {
                title: `${this.props.intl.formatMessage({ defaultMessage: 'Users' })} (${filteredUsersEvents.length})`,
                fixed: this.props.isSmartphone ? undefined : 'left',
                width: 260,
                render: (_, record) => {
                    const contracts: ContractDataPerf[] | undefined = usersContracts[record.id];
                    const events = Object.values(record.days).reduce((es, obj) => {
                        es = es.concat(obj.events);
                        return es;
                    }, [] as NetworkMonthlyPlanningRowPerfAddons[]);
                    const eventsIncludedInReports = events.filter(e => e.inReports).length;
                    return (
                        <div className='_mp-user-container'>
                            <div className='_mp-user-container-link' onClick={(currentUser && currentUser.role === 2) ? () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${record.id}`) : undefined}>
                                {/* <div className='_mp-user-container' onClick={(currentUser && currentUser.role === 2) ? () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${record.id}`) : undefined}> */}
                                <div className='_mp-user-avatar-container'>
                                    <Avatar className='_mp-user-avatar' size="large" src={record.image && record.image.length > 0 && <Image onClick={e => e.stopPropagation()} sizes='small' preview={{ mask: <FAIcon prefix='fad' name='eye' /> }} height={'40px'} width={'40px'} src={record.image} style={{ objectFit: 'cover' }} />} icon={<FAIcon prefix='fad' name='user' />} />
                                </div>
                                <div className='_mp-user-info'>
                                    {/* <Tooltip title={<FullUser user={{...record, first_name: record.firstName, last_name: record.lastName}} codePosition="first" />} mouseEnterDelay={0.5}> */}
                                    <div className='_mp-user-name'><FullUser user={{ ...record, first_name: record.firstName, last_name: record.lastName }} /></div>
                                    {/* </Tooltip> */}
                                    <div className='_mp-user-events' title={`${events.length} ${this.props.intl.formatMessage({ defaultMessage: 'events in total' })}\n${eventsIncludedInReports} ${this.props.intl.formatMessage({ defaultMessage: 'events are included in the reports' })}`}>
                                        {events.length} <FormattedMessage defaultMessage={'events'} /> ({eventsIncludedInReports})
                                    </div>
                                </div>
                            </div>
                            {
                                contracts && !hasDepartments ?
                                    <div className='_mp-user-actions'>
                                        <Popover
                                            placement='right'
                                            destroyTooltipOnHide
                                            content={
                                                <div onClick={(e) => e.stopPropagation()}>
                                                    {
                                                        contracts?.map(c => (
                                                            <div className='contract-data' key={c.id}>
                                                                <p className='contract-title'>
                                                                    {`${c.name} ${c.workRate ? `${c.workRate}%` : ''}`}
                                                                </p>
                                                                <div>
                                                                    <p>{moment(c.startDate).format(getFormat('DATE'))} - {moment(c.endDate).format(getFormat('DATE'))}</p>
                                                                    <p><FormattedMessage defaultMessage={"{nbDays} days on the current period"} values={{ nbDays: c.daysUnderThisContract }} /></p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        >
                                            <FAIcon prefix='fad' name='info' className='_mp-user-actions-icon' onClick={(e) => e.stopPropagation()} />
                                        </Popover>
                                        {
                                            this.props.company?.type === CompanyTypes.CCNT &&
                                            <Popover
                                                trigger={contracts.length > 1 ? 'click' : ''}
                                                open={userIdInfoPopover === record.id}
                                                onOpenChange={(p) => this.setState({ userIdInfoPopover: p ? record.id : undefined })}
                                                title={<FormattedMessage defaultMessage={'Which contract do you want to open?'} />}
                                                destroyTooltipOnHide
                                                content={
                                                    <div className='_mp-user-contract-popover-container'>
                                                        {
                                                            contracts.map(c => (
                                                                <Button
                                                                    className='_mp-user-contract-button'
                                                                    key={c.id}
                                                                    loading={loadingCcnt}
                                                                    onClick={() => {
                                                                        this.getCcntReport(date, [{ userId: record.id, contractId: c.id }]);
                                                                    }}
                                                                >
                                                                    {c.name}
                                                                </Button>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            >
                                                <span title={this.props.intl.formatMessage({ defaultMessage: 'Show CCNT report' })}>
                                                    <FAIcon prefix='fad' name='file-contract' className='_mp-user-actions-icon' onClick={(e) => {
                                                        if (loadingCcnt)
                                                            e.preventDefault();
                                                        else if (contracts.length == 1)
                                                            this.getCcntReport(date, [{ userId: record.id, contractId: contracts[0].id }]);
                                                    }}
                                                    />
                                                </span>
                                            </Popover>
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    );
                },
            },
            ...daysInRange.map(day => {
                //! DO EXPENSIVE STUFF HERE, NOT IN RETURN 
                //! HERE = ONCE PER DAY
                //! RETURN = ONCE PER DAY PER USER
                const dayMoment = moment(day, 'YYYYMMDD');
                const dayIdx = dayMoment.day();
                const isBusinessDay = dayIdx != 6 && dayIdx != 0;
                const isToday = !isLoading && dayMoment.isSame(moment(), 'date');
                let className = "__mp-one-cell";
                if (isToday)
                    className += " __mp-col-today";
                else {
                    if (!isBusinessDay)
                        className += " __mp-col-we";
                    if (holidays[day])
                        className += " __mp-col-hl";
                }

                const selectedEvents = this.state.selectedEvents[day] ?? [];
                const selectedEventsByUser = selectedEvents.reduce((obj, { eventId, userId }) => {
                    obj[userId] = [...(obj[userId] || []), eventId];
                    return obj;
                }, {} as DictionaryNumber<number[]>);

                const lockedUsersForToday = (lockedDaysByUsersByDates ? lockedDaysByUsersByDates[day] : []) ?? [];

                return {
                    title: <div>
                        <div>{dayMoment.format("ddd DD")}</div>
                        {colSums ? <div className='__mp-day-total'>{colSums[day] ? `${colSums[day].toFixed(2)} h` : '0h'}</div> : <></>}
                    </div>,
                    key: day,
                    width: 165,
                    className,
                    render: (_, record) => {
                        const { forceAction } = this.state;
                        let forceCallback: (() => void) | undefined = undefined;
                        if (hasCopies && forceAction && forceAction.userId === record.id && forceAction.dayStr === day)
                            forceCallback = this.onForceAction;
                        const errorEvents = forceAction?.events || [];
                        const canEdit = userIdsEditable === 'ALL' || (record.id !== undefined && userIdsEditable !== undefined && userIdsEditable.includes(record.id));

                        const isLocked = !isLoading && lockedUsersForToday.includes(record.id);

                        return <Cell
                            key={`${record.id}-${day}`}
                            selectedEventIds={selectedEventsByUser[record.id] ?? []}
                            userId={record.id}
                            data={isLoading ? { events: [], unavailabilities: null } : record.days[day]}
                            day={dayMoment}
                            isBusinessDay={isBusinessDay}
                            dayStr={day}
                            canEdit={canEdit}
                            isLocked={isLocked}
                            forceCallback={forceCallback}
                            errorEvents={hasCopies ? errorEvents : []}
                            onPaste={this.onPasteEvent}
                            onSelectEvent={this.selectEvent}
                            onOpenEvent={this.openEvent}
                            onCreateEvent={this.createEvent}
                            onConfirmEvent={this.onConfirmEvent}
                            onCopyEvent={this.copyEvent}
                            onCopySelectedEvents={this.copySelectedDay}
                            onCutEvent={this.cutEvent}
                            onDeleteEvent={this.deleteEvent}
                            onEditEvent={this.editEvent}
                        />;
                    },
                } as ColumnProps<PlanningUserPerf>;
            })
        ];

        if (rowSums && colSums) {
            columns.push({
                fixed: 'right',
                className: '__monthly-planning-column-users-stats __width_180',
                title: <div>
                    <div><Badge
                        count={showStats === 'viewport' && hasActiveFilters ?
                            <Tooltip title={<FormattedMessage defaultMessage={"Filters are impacting the result."} />}>
                                <FAIcon size="sm" onClick={() => this.setState(state => ({ sidePanels: { ...state.sidePanels, showFilters: true } }))} prefix='fas' name='circle-info' color={YELLOW_COLOR} />
                            </Tooltip> : undefined}><FormattedMessage defaultMessage={"Balance"} /></Badge> </div>
                    <div className='counter-header' onClick={() => this.switchStatMode()}>
                        {canSeeReportCounters ? <FAIcon prefix='fas' name='caret-left' /> : <></>}
                        {showStats === 'report' ? <FormattedMessage defaultMessage={`up to {date}`} values={{ date: endMoment.format(getFormat('DAY_SINGLE_AND_MONTH_HALF')) }} /> : <FormattedMessage defaultMessage={"of the period"} />}
                        {canSeeReportCounters ? <FAIcon prefix='fas' name='caret-right' /> : <></>}
                    </div>
                </div>,
                key: 'statsColumns',
                width: 180,
                render: (__, record) => {
                    const contracts: ContractDataPerf[] | undefined = usersContracts[record.id];
                    const hoursStat: { overtime: number; } | undefined = hoursStats[record.id];
                    let sum: number | undefined = rowSums[record.id];
                    if (sum)
                        sum = Math.round(sum * 100) / 100;
                    const hoursTodoByContract = contracts ? Math.round(contracts.reduce((total, contract) => total += contract.weeklyWorkingHours / 5 * contract.daysUnderThisContract, 0) * 100) / 100 : 0;
                    const overtime = (Math.round((showStats === 'report'
                        ? hoursStat ? hoursStat.overtime : 0
                        : sum - hoursTodoByContract) * 100) / 100);

                    const overtimeTxt = overtime >= 0 ? `+${overtime}h` : `${overtime}h`;

                    switch (showStats) {
                        case 'report':
                            if (canSeeReportCounters) {
                                return (
                                    <div className='__mp-user-total'>
                                        {sum ? `${sum}h` : '0h'}
                                        {hoursStat !== undefined ? <div className={`overtime-tag ${overtime >= 0 ? 'pos' : 'neg'}`}>{overtimeTxt}</div> : <></>}
                                    </div>
                                );
                            }

                            return (<></>);
                        case 'viewport':
                            return (
                                <div className='__mp-user-total'>
                                    {
                                        hasDepartments ?
                                            <div>{sum ? `${sum}h` : '0h'}</div>
                                            :
                                            <>
                                                <div>{sum ? `${sum}h` : '0h'}{!hasActiveFilters && hoursTodoByContract ? ` / ${hoursTodoByContract}h` : ''}</div>
                                                {!hasActiveFilters && contracts !== undefined ? <div className={`overtime-tag ${overtime >= 0 ? 'pos' : 'neg'}`}>{overtimeTxt}</div> : <></>}
                                            </>
                                    }
                                </div>
                            );
                        default:
                            return (<></>);
                    }
                }
            });
        }

        return columns;
    };
    //#endregion

    //#region Utils
    switchStatMode = (mode?: State['showStats']) => {
        const { departments } = this.props;
        const hasAccessToReportsCounter = this.props.company?.type === CompanyTypes.NORMAL && departments.data.length === 0;
        let nextMode: State['showStats'] = mode ?? this.state.showStats === 'viewport' ? 'report' : 'viewport';

        if (!hasAccessToReportsCounter && nextMode === 'report')
            nextMode = 'viewport';

        this.setState({ showStats: nextMode }, nextMode === 'report' ? () => this.loadReportStatistics(true) : undefined);
    };

    getTotalHours = (filteredUsersEvents: FilteredUserEvents[]) => {
        const rowSums: { [userId: string]: number; } = {}; // Sum of hours for each user (rows)
        const colSums: { [day: string]: number; } = {}; // Sum of hours for each day (columns)

        // const users = this.state.showStats === 'viewport' ? this.filterUsersEvents() : Object.values(this.state.usersEvents);
        const users = filteredUsersEvents;

        // Traverse each user
        for (const user of users) {
            // const user = users[userId];
            const userId = user.id;
            let userTotalHours = 0;

            // Traverse each day for the current user
            for (const day in user.days) {
                const events = user.days[day].events;
                let dayTotalHours = 0;

                // Sum up all totalHours for the current day
                for (const event of events) {
                    if (event.countAsWorktime) {
                        dayTotalHours += event.effectiveSec;
                    }
                }

                // Add to the total hours for the current user
                userTotalHours += dayTotalHours;

                // Add to the column (day) sum
                if (!colSums[day]) {
                    colSums[day] = 0;
                }
                colSums[day] += dayTotalHours;
            }

            // Store the total hours for the current user (row)
            rowSums[userId] = userTotalHours;
        }

        return { rowSums, colSums };
    };

    getLoadingPercentage = () => {
        const values = Object.values(this.state.loaded);
        const valuesLoaded = values.filter(v => v).length;
        if (values.length <= 0)
            return 1;
        return valuesLoaded / values.length;
    };

    isLoading = () => {
        for (const val of Object.values(this.state.loaded))
            if (!val)
                return true;
        return false;
    };

    moveDate = (increment: number) => {
        const { displayMode } = this.state;
        const startOf = displayMode === 'monthly' ? 'month' : 'week';
        let date = this.state.date.clone().add(increment, startOf).startOf(startOf);

        if (displayMode === 'monthly' && date.isSame(moment(), "month"))
            date = moment();

        this.onChangeDate(date);
    };

    onChangeDate = (d: Moment) => {
        this.setState({
            date: d,
            sidePanelDate: d,
            usersEvents: {},
            loaded: {
                planning: false
            }
        }, () => {
            this.loadPlanning();
            this.loadReportStatistics(true);
            this.refreshSidePanels();
        });
    };

    changeRangeMode = (newMode: State['displayMode']) => {
        const { date } = this.state;
        const now = moment();
        this.setState(prevState => ({
            loaded: {
                ...prevState.loaded,
                contracts: false,
                planning: false,
            },
            displayMode: newMode,
            selectedEvents: {},
            date: newMode === 'monthly'
                ? date.isSame(now, 'month')
                    ? now
                    : date.startOf('month')
                : date.isSame(now, 'month')
                    ? now.startOf('week')
                    : date.startOf('month')
        }), () => {
            this.loadPlanning();
        });
    };

    hasFiltersActive = (type: 'USER_RELATED' | 'EVENT_RELATED' | 'ANY' = 'ANY') => {
        const { filters } = this.state;

        switch (type) {
            case 'ANY':
                if (
                    filters.groups.length > 0 ||
                    filters.users.length > 0 ||
                    filters.POIs.length > 0 ||
                    filters.confirmed !== undefined ||
                    filters.typeOfDays.length > 0 ||
                    filters.userAptitudes.length > 0 ||
                    filters.aptitudes.length > 0 ||
                    filters.departments.length > 0 ||
                    filters.projects.length > 0 ||
                    filters.showEmpty !== true ||
                    filters.mandates.length > 0 ||
                    filters.customers.length > 0 ||
                    filters.typeOfDaysOff.length > 0 ||
                    filters.showDayOff
                )
                    return true;
                break;
            case 'USER_RELATED':
                if (
                    filters.groups.length > 0 ||
                    filters.users.length > 0 ||
                    filters.userAptitudes.length > 0 ||
                    filters.showEmpty !== true
                )
                    return true;
                break;
            case 'EVENT_RELATED':
                if (
                    filters.POIs.length > 0 ||
                    filters.confirmed !== undefined ||
                    filters.typeOfDays.length > 0 ||
                    filters.aptitudes.length > 0 ||
                    filters.departments.length > 0 ||
                    filters.projects.length > 0 ||
                    filters.customers.length > 0 ||
                    filters.mandates.length > 0 ||
                    filters.projects.length > 0 ||
                    filters.typeOfDaysOff.length > 0 ||
                    filters.showDayOff
                )
                    return true;
                break;
            default:
                return false;
        }

        return false;
    };

    enableSidePanel = (key: keyof State['sidePanels']) => {
        this.setState({
            sidePanels: {
                [key]: !this.state.sidePanels[key]
            }
        });
    };

    getHeadersButtons = () => {
        const { sidePanels } = this.state;
        const { intl } = this.props;

        const headerButton: ReactNode[] = [];
        headerButton.push(
            <CircleButton
                className='__monthly-planning-btn-filter'
                small={true}
                type={sidePanels.showFilters ? "primary" : "default"}
                icon={
                    <Badge dot={this.hasFiltersActive()}>
                        <FAIcon prefix='fad' name='filters' />
                    </Badge>}
                onClick={() => this.enableSidePanel("showFilters")}
                title={this.hasFiltersActive() ? intl.formatMessage({ defaultMessage: 'Active filters' }) : intl.formatMessage({ defaultMessage: 'No active filters' })} />
        );
        headerButton.push(
            <CircleButton
                small={true}
                type={sidePanels.showOccupancyRate ? "primary" : "default"}
                icon={<FAIcon prefix='fad' name='calendar-check' />}
                onClick={() => this.enableSidePanel("showOccupancyRate")}
                title={intl.formatMessage({ defaultMessage: 'Control of planned requirements' })} />
        );
        if (checkRBACRule(Rules.CustomerManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) {
            headerButton.push(
                <CircleButton
                    small={true}
                    type={sidePanels.showMissionsStatus ? "primary" : "default"}
                    icon={<FAIcon prefix='fad' name='list-check' />}
                    onClick={() => this.enableSidePanel("showMissionsStatus")}
                    title={intl.formatMessage({ defaultMessage: 'Mission control' })} />
            );
        }
        headerButton.push(
            <CircleButton
                small={true}
                type={sidePanels.showSummary ? "primary" : "default"}
                icon={<FAIcon prefix='fad' name='chart-simple' />}
                onClick={() => this.enableSidePanel("showSummary")}
                title={intl.formatMessage({ defaultMessage: 'Daily summary' })} />
        );
        headerButton.push(
            <CircleButton
                small={true}
                type={this.state.showStats ? "primary" : "default"}
                icon={<FAIcon prefix='fad' name='timer' />}
                onClick={() => this.setState(state => ({ showStats: state.showStats ? null : 'viewport' }))}
                title={intl.formatMessage({ defaultMessage: 'Counter display' })} />
        );
        headerButton.push(
            <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
        );
        return headerButton;
    };

    getSmartphoneHeadersButtons = () => {
        const headerButton = [];

        headerButton.push({
            label: <FormattedMessage defaultMessage={'Filters'} />,
            key: `sp-filters`,
            icon: <Badge dot={this.hasFiltersActive()}>
                <FAIcon prefix='fad' name='filters' />
            </Badge>,
            onClick: () => this.enableSidePanel("showFilters"),
        });
        headerButton.push({
            label: <FormattedMessage defaultMessage={'Control of planned requirements'} />,
            key: `sp-occupancy-rate`,
            icon: <FAIcon prefix='fad' name='calendar-check' />,
            onClick: () => this.enableSidePanel("showOccupancyRate"),
        });
        if (checkRBACRule(Rules.CustomerManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) {
            headerButton.push({
                label: <FormattedMessage defaultMessage={'Mission control'} />,
                key: `sp-mission`,
                icon: <FAIcon prefix='fad' name='list-check' />,
                onClick: () => this.enableSidePanel("showMissionsStatus"),
            });
        }
        headerButton.push({
            label: <FormattedMessage defaultMessage={'Daily summary'} />,
            key: `sp-summray`,
            icon: <FAIcon prefix='fad' name='chart-simple' />,
            onClick: () => this.enableSidePanel("showSummary"),
        });
        headerButton.push({
            label: <FormattedMessage defaultMessage={'Counter display'} />,
            key: `sp-counters`,
            icon: <FAIcon prefix='fad' name='timer' />,
            onClick: () => this.setState(state => ({ showStats: state.showStats ? null : 'viewport' })),
        });

        return headerButton;
    };

    filterUsersEvents = (originalUsersEvents: DictionaryNumber<PlanningUserPerf>) => {
        console.time("RENDER filterUsersEvents");
        const { filters } = this.state;
        const { users } = this.props;

        // Calcul des booléens pour simplifier les conditions
        const hasTypeOfDaysFilter = filters.typeOfDays.length > 0;
        const hasTypeOfDaysOffFilter = filters.typeOfDaysOff.length > 0;
        const hasAptitudesFilter = filters.aptitudes.length > 0;
        const hasPOIsFilter = filters.POIs.length > 0;
        const hasDepartmentsFilter = filters.departments.length > 0;
        const hasProjectsFilter = filters.projects.length > 0;
        const hasConfirmedFilters = filters.confirmed !== undefined;
        const hasCustomersFilter = filters.customers.length > 0;
        const hasMandatesFilter = filters.mandates.length > 0;

        // --- 1. Déterminer les utilisateurs à afficher sans modifier la source ---
        let usersToShow: number[] = [];
        if (filters.groups.length > 0) {
            // Préparation d'un dictionnaire pour stocker les id des utilisateurs par groupe
            const userIdInSelectedGroups = filters.groups.reduce((acc, group) => {
                if (group) acc[group] = [];
                return acc;
            }, {} as Record<string, number[]>);

            for (const user of users) {
                if (user.group_users && user.group_users.some(g => filters.groups.includes(g.group))) {
                    for (const groupUser of user.group_users) {
                        if (groupUser.group in userIdInSelectedGroups) {
                            userIdInSelectedGroups[groupUser.group].push(user.id);
                        }
                    }
                }
            }

            let userIdsFromGroups = Object.keys(userIdInSelectedGroups)
                .reduce((acc, groupId) => acc.concat(userIdInSelectedGroups[groupId]), [] as number[]);
            // Exclure les utilisateurs indiqués dans filters.usersToExclude
            userIdsFromGroups = userIdsFromGroups.filter(u => !filters.usersToExclude.includes(u));
            usersToShow = usersToShow.concat(userIdsFromGroups);
        }

        if (filters.users.length > 0) {
            usersToShow = usersToShow.concat(filters.users);
        }

        if (filters.userAptitudes.length > 0) {
            const usersIdsWithAptitude = users
                .filter(u => u.staffType?.some(s => s.id && filters.userAptitudes.includes(s.id)))
                .map(u => u.id);
            usersToShow = usersToShow.concat(usersIdsWithAptitude);
        }

        // On retire les doublons
        usersToShow = [...new Set(usersToShow)];

        // --- 2. Préparer une copie ciblée des données utilisateur ---
        // On ne clone que les utilisateurs concernés et, au niveau de chaque utilisateur, uniquement l'objet "days" et son tableau d'événements.
        let usersRows: PlanningUserPerf[];
        if (filters.users.length > 0 || filters.groups.length > 0 || filters.userAptitudes.length > 0) {
            usersRows = usersToShow
                .filter(uId => originalUsersEvents[uId])
                .map(uId => {
                    const user = originalUsersEvents[uId];
                    return {
                        ...user,
                        days: Object.keys(user.days).reduce((acc, dayKey) => {
                            acc[dayKey] = {
                                ...user.days[dayKey],
                                events: user.days[dayKey].events.slice(), // copie superficielle du tableau d'événements
                            };
                            return acc;
                        }, {} as typeof user.days),
                    };
                });
        } else {
            usersRows = Object.values(originalUsersEvents).map(user => ({
                ...user,
                days: Object.keys(user.days).reduce((acc, dayKey) => {
                    acc[dayKey] = {
                        ...user.days[dayKey],
                        events: user.days[dayKey].events.slice(),
                    };
                    return acc;
                }, {} as typeof user.days),
            }));
        }

        // Tri des utilisateurs (copie déjà clonée)
        usersRows.sort((a, b) =>
            a.fullName.localeCompare(b.fullName, undefined, { numeric: true })
        );

        // --- 3. Définition de la fonction de filtrage des événements ---
        const applyFiltersPerf = (events: NetworkMonthlyPlanningRowPerfAddons[]) => {
            const tmpEvents = events.slice(); // copie superficielle
            let hasMatchedAnyEvent = false;

            // Préparation des dictionnaires de lookup pour les filtres
            let typeOfDays: Record<number, boolean> | null = null;
            let typeOfDaysOff: Record<number, boolean> | null = null;
            if (hasTypeOfDaysFilter || (hasTypeOfDaysOffFilter && !filters.showDayOff)) {
                typeOfDays = {};
                for (const key of filters.typeOfDays) {
                    typeOfDays[key] = true;
                }
                if (!filters.showDayOff) {
                    typeOfDaysOff = {};
                    for (const key of filters.typeOfDaysOff) {
                        typeOfDaysOff[key] = true;
                    }
                }
            }

            let POIs: Record<number, boolean> | null = null;
            if (hasPOIsFilter) {
                POIs = {};
                for (const key of filters.POIs) {
                    POIs[key] = true;
                }
            }

            let aptitudes: Record<number, boolean> | null = null;
            if (hasAptitudesFilter) {
                aptitudes = {};
                for (const key of filters.aptitudes) {
                    aptitudes[key] = true;
                }
            }

            let departments: Record<number, boolean> | null = null;
            if (hasDepartmentsFilter) {
                departments = {};
                for (const key of filters.departments) {
                    departments[key] = true;
                }
            }

            let projects: Record<number, boolean> | null = null;
            if (hasProjectsFilter) {
                projects = {};
                for (const key of filters.projects) {
                    projects[key] = true;
                }
            }

            let customers: Record<number, boolean> | null = null;
            if (hasCustomersFilter) {
                customers = {};
                for (const key of filters.customers) {
                    customers[key] = true;
                }
            }

            let mandates: Record<number, boolean> | null = null;
            if (hasMandatesFilter) {
                mandates = {};
                for (const key of filters.mandates) {
                    mandates[key] = true;
                }
            }

            // Application des filtres sur une passe unique
            const filteredEvents = events.filter(e => {
                if (
                    (hasTypeOfDaysFilter || (hasTypeOfDaysOffFilter && !filters.showDayOff)) &&
                    !(
                        (e.typeOfDayId && typeOfDays && typeOfDays[e.typeOfDayId]) ||
                        (e.typeOfDayOffId && typeOfDaysOff && typeOfDaysOff[e.typeOfDayOffId])
                    )
                ) {
                    return false;
                }
                if (hasConfirmedFilters && (e.confirmed !== filters.confirmed)) {
                    return false;
                }
                if (hasPOIsFilter && (!e.poiId || !POIs || !POIs[e.poiId])) {
                    return false;
                }
                if (hasAptitudesFilter && (!e.staffTypeId || !aptitudes || !aptitudes[e.staffTypeId])) {
                    return false;
                }
                if (hasDepartmentsFilter && (!e.departmentId || !departments || !departments[e.departmentId])) {
                    return false;
                }
                if (hasProjectsFilter && (!e.projectId || !projects || !projects[e.projectId])) {
                    return false;
                }
                if (hasCustomersFilter && (!e.customerId || !customers || !customers[e.customerId])) {
                    return false;
                }
                if (hasMandatesFilter && (!e.mandateId || !mandates || !mandates[e.mandateId])) {
                    return false;
                }
                return true;
            });

            // Ajout des événements «day off» s'ils ne sont pas déjà présents
            let finalEvents = filteredEvents;
            if (filters.showDayOff) {
                const eventIds = new Set(filteredEvents.map(e => e.id));
                const dayOffEvents = tmpEvents.filter(
                    e => e.typeOfDayOffId && e.typeOfDayOffId >= 0 && !eventIds.has(e.id)
                );
                finalEvents = filteredEvents.concat(dayOffEvents);
            }

            hasMatchedAnyEvent = finalEvents.length > 0;
            return { events: finalEvents, hasMatchedAnyEvent };
        };

        // --- 4. Application des filtres sur chaque utilisateur ---
        const filteredUsers = usersRows.map(user => {
            let hasMatchedAnyEvent = false;
            // On crée une nouvelle copie de l'objet days pour chaque utilisateur
            const newDays = { ...user.days };

            for (const dayKey in newDays) {
                const { events, hasMatchedAnyEvent: dayMatched } = applyFiltersPerf(newDays[dayKey].events);
                newDays[dayKey] = { ...newDays[dayKey], events };
                if (dayMatched) {
                    hasMatchedAnyEvent = true;
                }
            }

            // Si aucun événement ne correspond pour cet utilisateur, on vide tous les jours
            const finalDays = hasMatchedAnyEvent
                ? newDays
                : Object.keys(newDays).reduce((acc, dayKey) => {
                    acc[dayKey] = { ...newDays[dayKey], events: [] };
                    return acc;
                }, {} as typeof newDays);

            return { ...user, days: finalDays };
        });

        console.timeEnd("RENDER filterUsersEvents");
        // --- 5. Renvoi du résultat en fonction du filtre "showEmpty" ---
        if (filters.showEmpty) {
            return filteredUsers;
        } else {
            return filteredUsers.filter(user =>
                Object.keys(user.days).some(dayKey => user.days[dayKey].events.length > 0)
            );
        }

    };

    getSelectedEventIds = () => {
        return [...new Set(Object.keys(this.state.selectedEvents).reduce((object, key) => {
            return [...object, ...this.state.selectedEvents[key].map(selectedEvent => selectedEvent.eventId && this.state.usersEvents[selectedEvent.userId].days[key].events.findIndex(e => e.id === selectedEvent.eventId && e.eventLocked) === -1 ? selectedEvent.eventId : -1)];
        }, [] as number[]))].filter(e => e !== -1);
    };

    hasSelectionForOnlyOneUserAndDay = (minumumEventsSelected = 1) => {
        const { selectedEvents } = this.state;
        let userId: number | undefined = undefined;
        if (Object.keys(selectedEvents).length !== 1)
            return false;
        const first = selectedEvents[Object.keys(selectedEvents)[0]];
        for (const e of first) {
            if (!userId)
                userId = e.userId;
            if (userId && userId != e.userId)
                return false;
        }

        if (first.length < minumumEventsSelected)
            return false;

        return true;
    };

    centerViewToToday = () => {
        const todaysDateViewable = (this.state.displayMode === 'monthly') ?
            moment().format("YYYYMM") === this.state.date.format("YYYYMM")
            :
            (this.state.displayMode === 'weekly') ?
                moment().week() === this.state.date.week()
                :
                moment().format("YYYYMMDD") === this.state.date.format("YYYYMMDD"); //for futur daily
        let goStartOfTable = false;
        if (todaysDateViewable) {
            const columnIndex = moment().date() + 1; // +1 compensating the user column

            const targetColumn = document.querySelector(`.ant-table-body table tbody tr:first-child td:nth-child(${columnIndex})`);
            if (targetColumn)
                targetColumn.scrollIntoView({ inline: "center" });
            else
                goStartOfTable = true;
        }
        else
            goStartOfTable = true;

        if (goStartOfTable) {
            const targetColumn = document.querySelector(`.ant-table-body table tbody tr:first-child td:nth-child(1)`);
            if (targetColumn) {
                targetColumn.scrollIntoView({ inline: "start" });
            }
        }
    };
    //#endregion

    //#region Render
    render() {
        const { height, isSmartphone, intl } = this.props;
        const { date, usersEvents, filteredUsersEvents, selectionMode, selectedEvents, sidePanelDate, displayMode, openedEvent, sidePanels, refreshMissionsStatus, isCcntVisible, ccnt, loadingCcnt } = this.state;
        const range = this.getStartEnd();

        const hasSelection = this.hasSelection();
        const hasCopies = this.hasCopies();
        const hasSider = Object.values(sidePanels).find(v => v);

        const columns = this.createColumns(filteredUsersEvents) as ColumnProps<any>[];

        const isLoading = this.isLoading();

        return (
            <>
                <Card
                    className="planning-card"
                    icon={<FAIcon prefix='fad' name='calendar-days' />}
                    title={
                        <Space style={{ position: 'sticky' }}>
                            {this.props.width > 440 &&
                                <CircleButton
                                    small
                                    icon={<FAIcon prefix='fal' name='chevron-left' />}
                                    title={displayMode === 'monthly' ? intl.formatMessage({ defaultMessage: 'Previous month' }) : displayMode === 'weekly' ? intl.formatMessage({ defaultMessage: 'Previous week' }) : intl.formatMessage({ defaultMessage: 'Previous' })}
                                    onClick={() => this.moveDate(-1)}
                                    loading={isLoading}
                                />
                            }
                            <CustomDatePicker
                                displayMode={displayMode}
                                date={date}
                                onChangeDate={(d) => d && this.onChangeDate(d)}
                                loading={isLoading}
                                key={`custom-datepicker-${date.format('YYYYMMDD')}`}
                                className='planning-datepicker-input-container'
                            />

                            {this.props.width > 440 &&
                                <CircleButton
                                    small
                                    icon={<FAIcon prefix='fal' name='chevron-right' />}
                                    title={displayMode === 'monthly' ? intl.formatMessage({ defaultMessage: 'Next month' }) : displayMode === 'weekly' ? intl.formatMessage({ defaultMessage: 'Next week' }) : intl.formatMessage({ defaultMessage: 'Next' })}
                                    onClick={() => this.moveDate(1)}
                                    loading={isLoading}
                                />
                            }
                            {!isSmartphone && this.props.width > 1450 ?
                                <>
                                    <CircleButton
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Today' })}
                                        icon={<FAIcon prefix='fad' name='house' />}
                                        onClick={() => this.onChangeDate(moment())}
                                        disabled={isLoading}
                                    />
                                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                </>
                                :
                                <></>
                            }
                            {!isSmartphone && this.props.width > 1450 ?
                                <CircleButton
                                    small
                                    title={intl.formatMessage({ defaultMessage: 'Refresh the planning' })}
                                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                                    onClick={this.refreshPlanning}
                                    disabled={isLoading}
                                />
                                :
                                <></>
                            }
                            {!isSmartphone ?
                                <CircleButton
                                    small
                                    type={'default'}
                                    title={intl.formatMessage({ defaultMessage: 'Fullscreen mode' })}
                                    icon={<FAIcon prefix='fad' name='maximize' />}
                                    onClick={() => toggleFullScreen()}
                                />
                                :
                                <></>
                            }
                            {!isSmartphone && this.props.width > 1450 ?
                                <>
                                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                    <Segmented
                                        disabled={isLoading}
                                        key={'timeclock-show-options'}
                                        value={this.state.displayMode}
                                        options={PlanningShowType.map(t => ({ value: t.value, label: intl.formatMessage(t.label) }))}
                                        onChange={(mode) => this.changeRangeMode(mode as State['displayMode'])}
                                    />
                                </>
                                :
                                <></>
                            }
                        </Space>
                    }
                    headerElements={[<Space key={`monthtlyplanningv3-fullHeaderButtons`}>
                        {!isSmartphone ?
                            <>
                                {this.getHeadersButtons()}
                                <CircleButton
                                    small={true}
                                    loading={isLoading}
                                    type={"default"}
                                    icon={<FAIcon prefix='fad' name='download' />}
                                    onClick={() => this.setState({ downloadPlanning: true })}
                                    placement='topLeft'
                                    title={intl.formatMessage({ defaultMessage: 'Download the planning' })}
                                />

                                <Can key="planning-button-settings" rule={Rules.Planning.Settings}>
                                    <CircleButton
                                        small={true}
                                        placement='topLeft'
                                        title={intl.formatMessage({ defaultMessage: 'Settings' })}
                                        icon={<FAIcon prefix='fad' name='gear' />}
                                        onClick={() => this.setState({ showSettings: true })} />
                                </Can>
                            </>
                            :
                            <>
                                <CircleButton
                                    small
                                    title={intl.formatMessage({ defaultMessage: 'Refresh the planning' })}
                                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                                    onClick={this.refreshPlanning}
                                    disabled={isLoading}
                                />
                                <Dropdown trigger={['click']}
                                    menu={{
                                        items: this.getSmartphoneHeadersButtons()
                                    }}
                                >
                                    <CircleButton
                                        small={true}
                                        loading={isLoading}
                                        type={"default"}
                                        icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                                        placement='topRight'
                                    />
                                </Dropdown>
                            </>
                        }
                    </Space>
                    ]}
                >
                    <div className="__mp-main">
                        <Selectable
                            disabled={isSmartphone || isTouchDevice()}
                            mode={selectionMode}
                            ref={this.nodeRef}
                            scrollContainer={() => document.getElementsByClassName('ant-table-body')[0] as HTMLElement}
                            value={Object.keys(selectedEvents).reduce((selected, day) => {
                                return selected.concat(selectedEvents[day].map(s => getSelectableValueForEvent(day, s.userId, s.eventId)));
                            }, [] as string[])}
                            onStart={() => { selectionMode === undefined && this.setSelectedEvents({}); }}
                            onEnd={(_, { added, removed }) => this.updateSelection(added, removed)}
                        >
                            <VirtualTable
                                id="planning-table-scroll"
                                bordered={true}
                                size='small'
                                className={`__mp-main-table ${hasSelection ? `__mp-sel-act ${this.state.selectionMode ? 'entire-row' : ''}` : hasCopies ? '__mp-copy-act' : '__mp-actions-act'}`}
                                scroll={{ y: height - 265 }}
                                loading={isLoading}
                                // key={`table-courses-${displayMode}-${date.format("YYYYMMDD")}`}
                                rowKey={(p) => (p as PlanningUserPerf).id.toString()}
                                dataSource={filteredUsersEvents}
                                columns={columns}
                            />
                            <div className={`__mp-toolbar copy ${hasCopies ? 'show' : ''}`}>
                                <div><FormattedMessage defaultMessage={'Copy mode'} /></div>
                                <div>
                                    <Button className='quit-button' onClick={this.quitModes}><FormattedMessage defaultMessage={'Quit'} /></Button>
                                </div>
                            </div>
                            <div className={`__mp-toolbar selection ${hasSelection ? 'show' : ''}`}>
                                <div><FormattedMessage defaultMessage={'Selection mode'} /></div>
                                <div>
                                    {
                                        this.hasSelectionForOnlyOneUserAndDay(2)
                                            ?
                                            <CircleButton
                                                small
                                                showTooltip
                                                title={"Dupliquer la sélection"}
                                                icon={<FAIcon prefix='fad' name='clone' />}
                                                onClick={() => this.copySelectedDay(this.state.selectedEvents[Object.keys(this.state.selectedEvents)[0]][0].userId, Object.keys(this.state.selectedEvents)[0])}
                                                loading={false}
                                            />
                                            : <></>
                                    }
                                    <CircleButton
                                        small
                                        showTooltip
                                        title={intl.formatMessage({ defaultMessage: 'Confirm {count} selected events' }, { count: this.selectionCount() })}
                                        icon={<FAIcon prefix='far' name='check' />}
                                        onClick={this.confirmEvents}
                                        loading={false}
                                    />
                                    <CircleButton
                                        small
                                        showTooltip
                                        title={intl.formatMessage({ defaultMessage: 'Delete {count} selected events' }, { count: this.selectionCount() })}
                                        icon={<FAIcon prefix='fad' name='trash-can' />}
                                        onClick={() => this.setState({ deleteModalOpened: true })}
                                        loading={false}
                                    />
                                    <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />

                                    <Button className='quit-button' onClick={this.quitModes}><FormattedMessage defaultMessage={'Quit'} /></Button>
                                </div>
                            </div>
                        </Selectable>

                        <div className={`__mp-main-siders ${hasSider ? 'sider' : 'no-sider'}`}>
                            <p className='__mp-sider-title' style={{ margin: '10px' }} >
                                {sidePanels.showFilters ? <FormattedMessage defaultMessage={'Filters'} /> : <></>}
                                {sidePanels.showOccupancyRate ? <FormattedMessage defaultMessage={'Requirements control'} /> : <></>}
                                {sidePanels.showMissionsStatus ? <FormattedMessage defaultMessage={'Missions control'} /> : <></>}
                                {sidePanels.showSummary ? <FormattedMessage defaultMessage={'Daily summary'} /> : <></>}
                            </p>
                            <div className='__mp-main-siders-content' >
                                {
                                    sidePanels.showFilters ?
                                        <Filters
                                            reset={() => this.setState({ filters: { ...getInitState(undefined).filters } })}
                                            showEmpty={{
                                                showEmpty: this.state.filters.showEmpty,
                                                setShowEmpty: (show) => this.setState(state => ({ filters: { ...state.filters, showEmpty: show } }))
                                            }}
                                            showDayOff={{
                                                showDayOff: this.state.filters.showDayOff,
                                                setShowDayOff: (show) => this.setState(state => ({ filters: { ...state.filters, showDayOff: show } }))
                                            }}
                                            users={{
                                                selectedUsers: this.state.filters.users,
                                                changeUsers: (val) => this.setState({ filters: { ...this.state.filters, users: val } })
                                            }}
                                            groups={{
                                                usersToExclude: this.state.filters.usersToExclude,
                                                selectedGroups: this.state.filters.groups,
                                                changeGroups: (groups, toExclude) => this.setState({ filters: { ...this.state.filters, groups, usersToExclude: toExclude } }),
                                            }}
                                            userStaffTypes={{
                                                selectedUserStaffTypes: this.state.filters.userAptitudes,
                                                changeUserStaffTypes: (val) => this.setState({ filters: { ...this.state.filters, userAptitudes: val } }),
                                            }}
                                            staffTypes={{
                                                selectedStaffTypes: this.state.filters.aptitudes,
                                                changeStaffTypes: (val) => this.setState({ filters: { ...this.state.filters, aptitudes: val } }),
                                            }}
                                            projects={{
                                                selectedProjects: this.state.filters.projects,
                                                changeProjects: (val) => this.setState({ filters: { ...this.state.filters, projects: val } }),
                                            }}
                                            departments={{
                                                selectedDepartments: this.state.filters.departments,
                                                changeDepartments: (val) => this.setState({ filters: { ...this.state.filters, departments: val } }),
                                            }}
                                            typeOfDays={{
                                                selectedTypeOfDays: this.state.filters.typeOfDays,
                                                changeTypeOfDays: (val) => this.setState({ filters: { ...this.state.filters, typeOfDays: val } }),
                                            }}
                                            typeOfDaysOff={{
                                                selectedTypeOfDaysOff: this.state.filters.typeOfDaysOff,
                                                changeTypeOfDaysOff: (val) => this.setState({ filters: { ...this.state.filters, typeOfDaysOff: val } }),
                                            }}
                                            POIs={{
                                                selectedPOIs: this.state.filters.POIs,
                                                changePOIs: (val) => this.setState({ filters: { ...this.state.filters, POIs: val } }),
                                            }}
                                            confirmed={{
                                                confirmed: this.state.filters.confirmed,
                                                setConfirmed: (confirmed) => this.setState(state => ({ filters: { ...state.filters, confirmed } })),
                                            }}
                                            customers={{
                                                selectedCustomers: this.state.filters.customers,
                                                changeCustomers: (val) => this.setState({ filters: { ...this.state.filters, customers: val } }),
                                            }}
                                            mandates={{
                                                selectedMandates: this.state.filters.mandates,
                                                changeMandates: (val) => this.setState({ filters: { ...this.state.filters, mandates: val } }),
                                            }}
                                        />
                                        :
                                        <></>
                                }

                                {sidePanels.showOccupancyRate ? <OccupancyRatesControl
                                    ref={this.occupancyRateRef}
                                    pickedStartDate={sidePanelDate}
                                    changePickedStartDate={(e: Moment) => this.setState({ sidePanelDate: e })}
                                    showEventDetails={this.openEvent}
                                    startDate={date.clone().startOf(range.startOf)}
                                    endDate={date.clone().endOf(range.startOf)}
                                    createNewEvent={this.createEventFromOccupancyRate}
                                    filter
                                    monthly
                                /> : <></>}

                                {sidePanels.showMissionsStatus ?
                                    <MissionsControlV2
                                        refresh={refreshMissionsStatus}
                                        endRefresh={() => this.setState({ refreshMissionsStatus: false })}
                                        startDate={this.state.date.clone().startOf(displayMode === 'monthly' ? "month" : "week")}
                                        endDate={this.state.date.clone().endOf(displayMode === 'monthly' ? "month" : "week")}
                                        newEvent={this.createEventFromMission}
                                        eventsV2={usersEvents}
                                        showEvent={this.openEvent}
                                        mode={this.state.displayMode}
                                    /> : <></>}

                                {sidePanels.showSummary ? <DailySummary
                                    data={usersEvents}
                                    date={sidePanelDate}
                                    changeDate={(e) => this.setState({ sidePanelDate: e })}
                                    showEventDetails={this.openEvent}
                                    maxStartDate={date.clone().startOf(range.startOf)}
                                    maxEndDate={date.clone().endOf(range.startOf)}
                                /> : <></>}
                            </div>
                        </div>
                    </div>
                </Card>

                {this.state.openedEvent !== undefined
                    ?
                    <ShowEventModal
                        canEdit={true} // Change when simple user can only display details
                        onChangeEvent={(event) => this.setState({ openedEvent: event })}
                        event={openedEvent ?? undefined}
                        user={openedEvent?.userId ? this.props.users?.find(u => u.id === openedEvent.userId) : undefined}
                        group={openedEvent?.groupId ? this.props.groups?.find(g => g.id === openedEvent.groupId) : undefined}
                        onCancel={() => this.setState({ openedEvent: undefined })}
                        onEdit={this.editEvent}
                        onDelete={this.deleteEvent}
                        onCreateTemplate={this.refreshTemplates}
                        onDeleteOvertime={this.onDeleteOvertimeFromEvent}
                    />
                    :
                    <></>
                }

                {this.state.editEvent
                    ?
                    <ModifyEvent
                        modalKey={'edit-event-modal'}
                        currentEvent={this.state.editEvent}
                        canEditStartDate={this.state.canEditStartDate}
                        loadFileFromTemplate={this.state.loadFileFromTemplate}
                        onEdit={this.onEditEvent}
                        onCreate={this.onCreateEvent}
                        loading={this.props.loading}
                        onCancel={() => this.setState({ editEvent: undefined })}
                        openSettings={() => this.setState({ showSettings: true })}
                    />
                    :
                    <></>
                }

                {this.state.showSettings
                    ?
                    <SettingsModal
                        visible={this.state.showSettings}
                        onCancel={() => this.setState({ showSettings: false })}
                        onUpdate={this.onUpdateSettings}
                    />
                    :
                    <></>
                }

                {this.state.downloadPlanning
                    ?
                    <DownloadPlanningModal
                        onClose={() => this.setState({ downloadPlanning: false })}
                        opened={this.state.downloadPlanning}
                        date={date}
                    />
                    : <></>
                }

                {this.state.deleteModalOpened
                    ?
                    <DeleteEventsModal
                        onClose={() => this.setState({ deleteModalOpened: false })}
                        opened={this.state.deleteModalOpened}
                        selectedEvents={selectedEvents}
                        onDelete={this.deleteEvents}
                        usersEvents={usersEvents}
                        isLoading={this.state.loadingDelete}
                        setIsLoading={(value: boolean) => this.setState({ loadingDelete: value })}
                    />
                    : <></>
                }
                <DrawerCcntReport isLoading={loadingCcnt} ccnt={ccnt} isVisible={isCcntVisible} close={() => this.setState({ ccnt: undefined, isCcntVisible: false })} year={date} />
            </>
        );
    }
    //#endregion
}

//#region Redux
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    toggleLoadingPlanning: (l: boolean) => dispatch(toggleLoadingPlanning(l)),
    changeUserRows: (ur: PlanningUserRow[]) => dispatch(changeUserRows(ur)),
    changePrevUserRows: (ur: PlanningUserRow[]) => dispatch(changePrevUserRows(ur)),
    changeNowUserRows: (ur: PlanningUserRow[]) => dispatch(changeNowUserRows(ur)),
    changeNextUserRows: (ur: PlanningUserRow[]) => dispatch(changeNextUserRows(ur)),
    changePrevLoading: (l: boolean) => dispatch(changePrevLoading(l)),
    changeNowLoading: (l: boolean) => dispatch(changeNowLoading(l)),
    changeNextLoading: (l: boolean) => dispatch(changeNextLoading(l)),
    changeSelectGroups: (sg?: number[]) => dispatch(changeSelectGroups(sg)),
    changeSelectUsers: (su?: number[]) => dispatch(changeSelectUsers(su)),
    changeSettings: (s: PlanningSettings) => dispatch(changeSettings(s)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
    changeProject: (p: Project[]) => dispatch(changeProject(p)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
    changeTeamAvailabilities: (a: UserAvailabilityWithUpdate[]) => dispatch(changeTeamAvailabilities(a)),
    loadSettings: (forceReload?: boolean) => dispatch(loadSettings(forceReload)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    fullscreen: state.window.fullscreen,
    height: state.window.height,
    loading: state.user.loading,
    users: selectActiveUsers(state),
    company: state.user.company,
    groups: state.teamManagement.groups,
    groupsUsers: state.teamManagement.groupsUsers,
    settings: state.planning.settings,
    loadingPlanning: state.planning.loadingPlanning,
    userRows: state.planning.userRows,
    preloadUserRows: state.planning.preloadUserRows,
    loadingPrevPlanning: state.planning.loadingPrevPlanning,
    loadingNowPlanning: state.planning.loadingNowPlanning,
    loadingNextPlanning: state.planning.loadingNextPlanning,
    selectGroups: state.planning.selectGroups,
    selectUsers: state.planning.selectUsers,
    currentUser: state.user.currentUser,
    project: state.configurations.project,
    pois: state.location.pois,
    typesOfDay: state.configurations.typesOfDay,
    typesOfDayOff: state.configurations.typesOfDayOff,
    departments: state.configurations.departments,
    teamAvailabilities: state.planning.teamAvailabilities,
    loadingAvailabilities: state.planning.loadingAvailabilities,
});
// #endregion

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(withFullName(planningPerf))));
