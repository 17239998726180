import { Alert, Calendar, Col, DatePicker, InputNumber, Radio, Row, Segmented, Select, Space } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import { Component, ReactNode } from 'react';
import { defineMessages, FormattedMessage, injectIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Frequency, RRule, rrulestr } from 'rrule';
import { GREEN_COLOR, MOMENT_FORMAT_TIME_MIN_GMT, WeekDays, WeekDaysMessages } from '../../../../../utils/constants';
import getFormat from '../../../../../utils/Lang';
import { ApplicationState } from '../../../../../utils/types/storeTypes';
import { colorIsBright, generateNegativeUniqueId, isNullOrEmpty, showNotification } from '../../../../../utils/utils';
import { IntlProps } from '../../../../app/LanguageProvider';
import FAIcon from '../../../../common/FAIcon';
import CircleButton from '../../../../common/fields/circleButton';
import SpaceContent from '../../../../common/general/spaceContent';

export interface IEventRecurringRule {
    id?: number;
    startTime?: string;
    endTime?: string;
    startDate?: string;
    endDate?: string;
    rrule?: string;
    exdate?: string[];
    rdate?: string[];
    created?: string;
    modified?: string;
    main?: boolean;
    pairId?: number;
}

export interface IEventRecurring {
    rules: IEventRecurringRule[];
    startDate: string;
    endDate: string;
}

// #region Constants
export const enum AvailabilityState {
    Open = 0,
    Close = 1
}

const enum DateOrTime {
    StartDate = "startdaterule",
    EndDate = "enddaterule",
    StartTime = "starttime",
    EndTime = "endtime"
}

export const enum AllDayTimes {
    start = "00:00",
    end = "23:59"
}

enum ProposalsValues {
    EachWeek = "eachweek",
    EachMonthDay = "eachmonthday",
    EachMontFirstX = "eachmontfirstx",
    EachMontLastX = "eachmontlastx",
}

// enum MonthlySetPosNames {
//     First = "Premier",
//     Second = "Deuxième",
//     Third = "Troisième",
//     Fourth = "Quatrième",
//     Fifth = "Cinquième",
//     Last = "Dernier",
// }

const enum MonthlySetPosNames {
    First = "first",
    Second = "second",
    Third = "third",
    Fourth = "fourth",
    Fifth = "fifth",
    Last = "last",
}

const MonthlySetPosNamesText = defineMessages<MonthlySetPosNames>({
    [MonthlySetPosNames.First]: { defaultMessage: 'First' },
    [MonthlySetPosNames.Second]: { defaultMessage: 'Second' },
    [MonthlySetPosNames.Third]: { defaultMessage: 'Third' },
    [MonthlySetPosNames.Fourth]: { defaultMessage: 'Fourth' },
    [MonthlySetPosNames.Fifth]: { defaultMessage: 'Fifth' },
    [MonthlySetPosNames.Last]: { defaultMessage: 'Last' },
});

const proposalsRules: {
    id: ProposalsValues;
    title: (itnl: IntlShape, weekday?: MessageDescriptor, date?: string) => ReactNode;
    rrule: (rrule: RRule, startDateRule: Moment | undefined) => RRule;
}[] =
    [
        {
            id: ProposalsValues.EachWeek,
            title: (intl, weekday) => <FormattedMessage defaultMessage={'Each week, on {weekday}'} values={{ weekday: weekday ? intl.formatMessage(weekday) : '' }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined
            }),
        },
        {
            id: ProposalsValues.EachMonthDay,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            title: (intl, weekday, date = "") => <FormattedMessage defaultMessage={'Each month, on {date}'} values={{ date }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bymonthday: startDateRule?.date()

            }),
        },
        {
            id: ProposalsValues.EachMontFirstX,
            title: (intl, weekday) => <FormattedMessage defaultMessage={'Each week, on first {weekday}'} values={{ weekday: weekday ? intl.formatMessage(weekday) : '' }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bysetpos: 1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

            }),
        },
        {
            id: ProposalsValues.EachMontLastX,
            title: (intl, weekday) => <FormattedMessage defaultMessage={'Each month, on last {weekday}'} values={{ weekday: weekday ? intl.formatMessage(weekday) : '' }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bysetpos: -1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

            }),
        },

    ];

export const monthlySetPosOptions = [
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.First],
        bysetpos: 1
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Second],
        bysetpos: 2
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Third],
        bysetpos: 3
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Fourth],
        bysetpos: 4
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Fifth],
        bysetpos: 5
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Last],
        bysetpos: -1
    },
];

export const weekDaysRRule = [
    {
        weekday: RRule.MO.weekday,
        name: WeekDaysMessages[WeekDays.MONDAY],
        value: RRule.MO.toString()
    },
    {
        weekday: RRule.TU.weekday,
        name: WeekDaysMessages[WeekDays.TUESDAY],
        value: RRule.TU.toString()
    },
    {
        weekday: RRule.WE.weekday,
        name: WeekDaysMessages[WeekDays.WEDNESDAY],
        value: RRule.WE.toString()
    },
    {
        weekday: RRule.TH.weekday,
        name: WeekDaysMessages[WeekDays.THURSDAY],
        value: RRule.TH.toString()
    },
    {
        weekday: RRule.FR.weekday,
        name: WeekDaysMessages[WeekDays.FRIDAY],
        value: RRule.FR.toString()
    },
    {
        weekday: RRule.SA.weekday,
        name: WeekDaysMessages[WeekDays.SATURDAY],
        value: RRule.SA.toString()
    },
    {
        weekday: RRule.SU.weekday,
        name: WeekDaysMessages[WeekDays.SUNDAY],
        value: RRule.SU.toString()
    },
];
// #endregion

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps {
    eventRecurring: IEventRecurring;
    onChange: (eventRecurring: IEventRecurring) => void;
}
interface State {
    proposal?: ProposalsValues;
    evenOdd: boolean;
    onceUnkown: boolean;
}

class EditRecurring extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const state: State = {
            evenOdd: false,
            onceUnkown: false,
        };


        this.props.eventRecurring.rules.forEach(rule => {
            if (rule.main && rule.pairId) {
                state.evenOdd = true;
            }
        });

        this.onChangeDates([moment(this.props.eventRecurring.startDate), moment(this.props.eventRecurring.endDate)]);
        return state;
    };

    copyRRuleOnlyLimits = (rrule: RRule | undefined) => {
        return new RRule({
            dtstart: rrule?.origOptions.dtstart,
            until: rrule?.origOptions.until
        });
    };

    getProposalsText = (val: ProposalsValues, ruleId: number) => {
        const rules = cloneDeep(this.props.eventRecurring.rules);
        const rule = rules?.find(r => r.id === ruleId);
        if (rule) {
            const proposalFound = proposalsRules.find(p => p.id === val);
            if (proposalFound) {
                const weekday = weekDaysRRule.find(d => rule?.startDate && ((d.weekday + 1) === moment(rule.startDate).isoWeekday()))?.name;
                const monthday = moment(rule.startDate).format("D");
                return proposalFound.title(this.props.intl, weekday, monthday);
            } else {
                return '';
            }
        }
    };

    onChangeDates = (dates: [Moment | null, Moment | null] | null) => {
        let eventRecurring = cloneDeep(this.props.eventRecurring);
        if (dates) {
            const startDate = dates[0];
            const endDate = dates[1];
            if (startDate && endDate && eventRecurring && eventRecurring.rules) {
                eventRecurring.rules.filter(r => r.main !== false).forEach(rule => {
                    if (rule.id) {
                        if (rule.pairId && rule.startDate && rule.endDate) {
                            const pairRule = eventRecurring.rules.find(r => rule.pairId === r.pairId && r.id !== rule.id);
                            if (pairRule && pairRule.id && pairRule.startDate && pairRule.endDate) {
                                const startDateDiff = moment(pairRule.startDate).diff(rule.startDate, "day");
                                const endDateDiff = moment(pairRule.endDate).diff(rule.endDate, "day");
                                eventRecurring = this.onChangeDateOrTime(eventRecurring, startDate.clone().add(startDateDiff, "days"), DateOrTime.StartDate, pairRule.id);
                                eventRecurring = this.onChangeDateOrTime(eventRecurring, endDate.clone().add(endDateDiff, "days"), DateOrTime.EndDate, pairRule.id);
                            }
                        }
                        eventRecurring = this.onChangeDateOrTime(eventRecurring, startDate, DateOrTime.StartDate, rule.id);
                        eventRecurring = this.onChangeDateOrTime(eventRecurring, endDate, DateOrTime.EndDate, rule.id);
                    }
                });
                this.props.onChange({ ...eventRecurring, startDate: startDate.toISOString(), endDate: endDate.toISOString() });
            }
        }

    };

    onChangeDate = (date: Moment | null) => {
        let eventRecurring = cloneDeep(this.props.eventRecurring);
        if (date && eventRecurring) {
            eventRecurring.rules?.forEach(rule => {
                if (rule.id) {
                    eventRecurring = this.onChangeDateOrTime(eventRecurring, date, DateOrTime.EndDate, rule.id);
                    eventRecurring = this.onChangeDateOrTime(eventRecurring, date, DateOrTime.StartDate, rule.id);
                    this.props.onChange(eventRecurring);
                }
            });
            this.props.onChange({ ...eventRecurring, startDate: date.toISOString(), endDate: date.toISOString() });
        }
    };


    onChangeDateOrTime = (eventRecurring: IEventRecurring, val: Moment | null, field: DateOrTime, ruleId: number) => {
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            let rrule = stringToRrule(rule.rrule);
            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }
            if (val === null) return eventRecurring;
            switch (field) {
                case DateOrTime.StartDate:
                    rule.startDate = val.toISOString();
                    if (val.year() > 1970 && rule.endDate) {
                        rrule = new RRule({
                            ...rrule?.origOptions,
                            dtstart: val ? new Date(val.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined,
                            until: val ? new Date(moment(rule.endDate).format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined
                        });
                    }
                    break;
                case DateOrTime.EndDate:
                    rule.endDate = val.toISOString();
                    if (val.year() > 1970 && rule.startDate) {
                        rrule = new RRule({
                            ...rrule?.origOptions,
                            dtstart: val ? new Date(moment(rule.startDate).format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined,
                            until: val ? new Date(val.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined
                        });
                    }

                    break;
                case DateOrTime.StartTime:
                    rule.startTime = val.toISOString();
                    break;
                case DateOrTime.EndTime:
                    rule.endTime = val.toISOString();
                    break;
                default:
                    return eventRecurring;
            }
            rule.rrule = rrule.toString();
        }
        return eventRecurring;
    };

    onChangeProposal = (val: ProposalsValues, ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            let rrule = this.copyRRuleOnlyLimits(stringToRrule(rule.rrule));

            const proposalFound = proposalsRules.find(p => p.id === val);
            if (proposalFound) {
                rrule = new RRule({ ...proposalFound.rrule(rrule, moment(rule.startDate)).origOptions });
                rule.rrule = rrule.toString();
                this.props.onChange(eventRecurring);
                this.setState({ proposal: val });
            } else {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the proposal data ' }), "error");
                this.setState({ proposal: undefined });
            }
        }
    };

    onChangeFrequency = (val: number | string, ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            let rrule = this.copyRRuleOnlyLimits(stringToRrule(rule.rrule));

            val = toFinite(val);
            switch (val) {
                case RRule.DAILY:
                    rrule = new RRule({
                        ...rrule.origOptions,
                        freq: toFinite(val),
                        interval: 1
                    });
                    break;
                case RRule.WEEKLY:
                    rrule = new RRule({
                        ...rrule.origOptions,
                        freq: toFinite(val),
                        byweekday: [RRule.MO],
                        interval: 1
                    });
                    break;
                case RRule.MONTHLY:
                default:
                    rrule = new RRule({
                        ...rrule.origOptions,
                        freq: toFinite(val),
                        bymonthday: 1,
                        interval: 1
                    });
                    break;
            }
            rule.rrule = rrule.toString();

            this.props.onChange(eventRecurring);
            this.setState({ proposal: undefined });
        }
    };

    onChangeWeekDay = (val: number | number[], ruleId: number, single = false) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);
            let weekdays = rrule?.options.byweekday;

            if (isArray(val)) val = val[0];
            if (weekdays && !single) {
                if (weekdays.includes(val)) {
                    weekdays = weekdays.filter(wd => wd !== val);
                } else {
                    weekdays = weekdays.concat(val);
                }
            } else {
                weekdays = [val];
            }

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                byweekday: weekdays
            });
            rule.rrule = rrule.toString();

            this.props.onChange(eventRecurring);
            this.setState({ proposal: undefined });
        }
    };

    onChangeMonthType = (isDate: boolean, ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);
            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            if (isDate) {
                rrule = new RRule({
                    ...rrule?.origOptions,
                    bymonthday: 1,
                    byweekday: undefined,
                    bysetpos: undefined

                });
            } else {
                rrule = new RRule({
                    ...rrule?.origOptions,
                    bysetpos: 1,
                    bymonthday: undefined,
                    byweekday: [RRule.MO]
                });
            }

            rule.rrule = rrule.toString();
            this.props.onChange(eventRecurring);
        }
    };

    onChangeMonthDay = (monthDay: number | null, ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                bymonthday: monthDay
            });

            rule.rrule = rrule.toString();
            this.props.onChange(eventRecurring);
        }

    };

    onChangeSetPos = (val: number | number[], ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            if (isArray(val)) val = val[0];

            const monthlyOptions = monthlySetPosOptions.find(m => m.bysetpos === val);
            if (monthlyOptions) {
                rrule = new RRule({
                    ...rrule?.origOptions,
                    bysetpos: monthlyOptions.bysetpos
                });

                rule.rrule = rrule.toString();
                this.props.onChange(eventRecurring);
            } else {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'Option invalid' }), "error");
            }
        }
    };

    onIncreaseInterval = (ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                interval: rrule ? ++rrule.options.interval : 1
            });

            rule.rrule = rrule.toString();
            this.props.onChange(eventRecurring);
        }
    };

    onDecreaseInterval = (ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                interval: rrule ? --rrule.options.interval : 1
            });

            rule.rrule = rrule.toString();
            this.props.onChange(eventRecurring);
        }
    };


    addChildRule = (ruleId: number, freq: Frequency) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.WEEKLY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                interval: 2
            });

            rule.rrule = rrule.toString();
            rule.main = true;
            rule.pairId = generateNegativeUniqueId();

            let newStartDate: Moment;
            if (freq === RRule.WEEKLY) {
                newStartDate = moment(rule.startDate).add(1, "week").startOf("isoWeek");
            } else {
                newStartDate = moment(rule.startDate).add(1, "month").startOf("month");
            }
            const newRule = cloneDeep(rule);
            newRule.id = generateNegativeUniqueId();
            newRule.main = false;
            newRule.pairId = rule.pairId;
            newRule.startDate = newStartDate.toISOString();
            rrule = new RRule({
                ...rrule?.origOptions,
                dtstart: new Date(newStartDate.format(MOMENT_FORMAT_TIME_MIN_GMT)),
            });
            newRule.rrule = rrule.toString();

            eventRecurring.rules?.push(newRule);
            this.props.onChange(eventRecurring);
            this.setState({ evenOdd: true });
        }
    };

    deleteRule = (ruleId: number, main = false, pairId?: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const stopEvenOdd = Boolean(pairId && main === false);
        if (eventRecurring) {
            eventRecurring.rules = eventRecurring.rules?.filter(r => r.id !== ruleId);
        }
        this.props.onChange(eventRecurring);
        this.setState(prevState => ({ evenOdd: stopEvenOdd ? false : prevState.evenOdd }));
    };

    dateFullCellRender = (value: Moment, data: { dates: Date[], color: string; }[]) => {
        let daySpan = <span>{value.format(getFormat('DAY_SHORT'))}</span>;
        data.forEach(d => {
            const dateFound = d.dates.find(i => moment(i).isSame(value, "date"));
            if (dateFound) {
                daySpan = <div style={{ backgroundColor: d.color, borderRadius: '100%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '28px', width: '28px', color: colorIsBright(d.color) ? 'white' : '#383e42' }}>{daySpan}</div>;
            }
        });
        return daySpan;
    };

    haveDateError = (startDate?: string, endDate?: string) => {
        return startDate && endDate ? moment(startDate).isAfter(moment(endDate)) : false;
    };

    renderRecurringDaily = (ruleId: number) => {
        const eventRecurring = cloneDeep(this.props.eventRecurring);
        const rule = eventRecurring.rules?.find(r => r.id === ruleId);
        if (rule) {
            const rrule = stringToRrule(rule.rrule);

            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <CircleButton
                        small
                        disabled={rrule && rrule.options.interval <= 1}
                        icon={<FAIcon prefix='far' name='minus' />}
                        title={this.props.intl.formatMessage({ defaultMessage: 'Reduce the number of days' })}
                        placement="top"
                        onClick={() => this.onDecreaseInterval(ruleId)}
                    />
                    <p style={{ flex: '1', textAlign: 'center' }}>
                        {
                            !rrule || rrule.options.interval === 1 ?
                                <span><FormattedMessage defaultMessage={'Repeats every day'} /></span>
                                :
                                <span><FormattedMessage defaultMessage={'Repeats every {days} days'} values={{ days: rrule.options.interval }} /></span>
                        }
                    </p>
                    <CircleButton
                        small
                        icon={<FAIcon prefix='far' name='plus' />}
                        title={this.props.intl.formatMessage({ defaultMessage: 'Increase the number of days' })}
                        placement="top"
                        onClick={() => this.onIncreaseInterval(ruleId)}
                    />
                </div>
            );
        }
        return <></>;
    };

    renderWeeklyRepeat = (rrule: RRule | undefined, ruleId: number) => {
        const { evenOdd } = this.state;

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <CircleButton
                    small
                    disabled={rrule && rrule.options.interval <= 1 || evenOdd}
                    icon={<FAIcon prefix='far' name='minus' />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Reduce the number of weeks' })}
                    placement="top"
                    onClick={() => this.onDecreaseInterval(ruleId)}
                />
                <p style={{ flex: '1', textAlign: 'center' }}>
                    {
                        !rrule || rrule.options.interval === 1 ?
                            <span><FormattedMessage defaultMessage={'Repeat every week'} /></span>
                            :
                            <span><FormattedMessage defaultMessage={'Repeat every {weeks} weeks'} values={{ weeks: rrule.options.interval }} /></span>
                    }
                </p>
                <CircleButton
                    small
                    disabled={evenOdd}
                    icon={<FAIcon prefix='far' name='plus' />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Increase the number of weeks' })}
                    placement="top"
                    onClick={() => this.onIncreaseInterval(ruleId)}
                />
            </div>
        );
    };

    renderWeeklyDays = (rrule: RRule | undefined, ruleId: number) => {
        const { isSmartphone, intl } = this.props;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {
                    weekDaysRRule.map((day) => {
                        return (
                            <CircleButton
                                className="__weekdays-buttons"
                                key={`weekdays-${day.value}`}
                                value={day.value}
                                type={rrule?.options.byweekday?.includes(day.weekday) ? 'primary' : 'default'}
                                onClick={() => this.onChangeWeekDay(day.weekday, ruleId)}
                                icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{intl.formatMessage(day.name).substring(0, isSmartphone ? 1 : 2)}</div>}
                                title={intl.formatMessage(day.name)}
                            />
                        );
                    })
                }
            </div>
        );
    };

    renderMonthlyRepeat = (rrule: RRule | undefined, ruleId: number) => {
        const { evenOdd } = this.state;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <CircleButton
                    small
                    disabled={rrule && rrule.options.interval <= 1 || evenOdd}
                    icon={<FAIcon prefix='far' name='minus' />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Reduce the number of months' })}
                    placement="top"
                    onClick={() => this.onDecreaseInterval(ruleId)}
                />
                <p style={{ flex: '1', textAlign: 'center' }}>
                    {
                        !rrule || rrule.options.interval === 1 ?
                            <span><FormattedMessage defaultMessage={'Repeats every month'} /></span>
                            :
                            <span><FormattedMessage defaultMessage={'Repeats every {months} months'} values={{ months: rrule.options.interval }} /></span>
                    }
                </p>
                <CircleButton
                    small
                    disabled={evenOdd}
                    icon={<FAIcon prefix='far' name='plus' />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Increase the number of months' })}
                    placement="top"
                    onClick={() => this.onIncreaseInterval(ruleId)}
                />
            </div>
        );
    };

    renderMonthlyDays = (rrule: RRule | undefined, ruleId: number) => {
        return (
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '10px', borderRadius: 'var(--border-radius)', width: '100%' }}>
                <Radio.Group
                    value={rrule && rrule.options.bymonthday.length > 0}
                    onChange={(val) => this.onChangeMonthType(val.target.value, ruleId)}>
                    <Space direction="vertical">
                        <Space>
                            <div>
                                <Radio value={true}><FormattedMessage defaultMessage={'Day n°'} /></Radio>
                            </div>
                            <div>
                                <InputNumber
                                    disabled={rrule && rrule.options.bymonthday.length === 0}
                                    value={rrule && rrule.options.bymonthday[0]}
                                    onChange={(val) => this.onChangeMonthDay(val, ruleId)}
                                    min={1}
                                    max={31}
                                    style={{ width: '60px', minWidth: '60px' }}
                                />
                            </div>
                        </Space>
                        <Space>
                            <Radio value={false}><FormattedMessage defaultMessage={'the'} /></Radio>
                            <Select
                                disabled={rrule && rrule.options.bymonthday.length > 0}
                                value={rrule?.options.bysetpos}
                                onChange={(val) => this.onChangeSetPos(val, ruleId)}
                                style={{ width: '110px' }}
                            >
                                {monthlySetPosOptions.map(o => <Select.Option key={`monthly-set-pos-${o.name}`} value={o.bysetpos}>{this.props.intl.formatMessage(o.name)}</Select.Option>)}
                            </Select>
                            <Select
                                disabled={rrule && rrule.options.bymonthday.length > 0}
                                value={isNullOrEmpty(rrule?.options.byweekday) ? undefined : rrule!.options.byweekday.length > 0 ? rrule?.options.byweekday[0] : undefined}
                                onChange={(val) => this.onChangeWeekDay(val, ruleId, true)}
                                style={{ width: '100px' }}
                            >
                                {weekDaysRRule.map(p => <Select.Option key={`weekday-${p.value}`} value={p.weekday}>{this.props.intl.formatMessage(p.name).toLocaleLowerCase()}</Select.Option>)}
                            </Select>
                        </Space>
                    </Space>
                </Radio.Group>
            </div>
        );
    };

    renderEvenOddTitle = (rule: Partial<IEventRecurringRule>, weekly: boolean, color: string) => {
        const { intl } = this.props;
        const ruleId = rule.id;
        if (!ruleId) return;
        return (
            <SpaceContent style={{ justifyContent: 'space-between' }}>
                <Space>
                    <div title={intl.formatMessage({ defaultMessage: 'Badge color in calendar' })} style={{ width: '15px', height: '15px', borderRadius: '100%', backgroundColor: color }} />
                    <b>
                        <FormattedMessage
                            defaultMessage={'{count, plural, =0 {Even {type} from {start} to {end}} other {Odd {type} from {start} to {end}}}'}
                            values={{
                                count: moment(rule.startDate).week() % 2,
                                type: weekly ? intl.formatMessage({ defaultMessage: 'Week' }) : intl.formatMessage({ defaultMessage: 'Month' }),
                                start: moment(rule.startDate).format(getFormat('DATE')),
                                end: moment(rule.endDate).format(getFormat('DATE'))
                            }} />
                    </b>
                </Space>
                {
                    rule.pairId && rule.main === false ?
                        <FAIcon prefix='fad' name='trash-can'
                            className='__hover-zoom'
                            onClick={() => this.deleteRule(ruleId, rule.main, rule.pairId)}
                            title={intl.formatMessage({ defaultMessage: 'Delete odd/even configuration' })} />
                        : null
                }
            </SpaceContent>
        );
    };

    disableDates = (date: Moment): boolean => {
        if (date.isBefore(this.props.eventRecurring.startDate, "days"))
            return true;
        if (date.isAfter(this.props.eventRecurring.endDate, "days"))
            return true;

        return false;
    };

    renderRecurring = () => {
        const { evenOdd } = this.state;
        const { eventRecurring, intl } = this.props;

        const onyParentRules = eventRecurring && eventRecurring.startDate && eventRecurring.endDate ? eventRecurring.rules?.filter(r => r.main !== false) : undefined;
        const dateError = !eventRecurring ? true : this.haveDateError(eventRecurring.startDate, eventRecurring.endDate);

        return (
            <>
                {
                    dateError &&
                    <div className='crm-eventRecurring-error'>
                        <Alert
                            showIcon
                            type="error"
                            style={{ fontSize: "80%", fontStyle: 'italic' }}
                            message={<FormattedMessage defaultMessage={'The start date is later than the end date'} />}
                        />
                    </div>
                }
                <SpaceContent direction='vertical' space={20} align='center'>
                    <DatePicker.RangePicker
                        style={{ width: '100%' }}
                        disabledDate={this.disableDates}
                        format={getFormat('DATE')}
                        value={[moment(eventRecurring.startDate), moment(eventRecurring.endDate)]}
                        onChange={this.onChangeDates}
                    />
                    {
                        onyParentRules?.map(rule => {
                            const ruleId = rule.id;
                            if (ruleId === undefined) return null;
                            // TODO: For the moment, we're only checking the first rule, as it's virtually impossible to have 2 rules of different types.
                            const childRule = eventRecurring.rules?.filter(r => r.pairId && r.pairId === rule.pairId && r.main === false).at(0);
                            const childRrule = stringToRrule(childRule?.rrule);
                            const childAllDates = childRule && childRule.startDate && childRule.endDate && childRrule ? childRrule.all() : [];
                            const childColor = childRule && moment(childRule.startDate).week() % 2 == 0 ? GREEN_COLOR : 'var(--primary-color)';

                            const rrule = stringToRrule(rule.rrule);
                            const allDates = rule.startDate && rule.endDate && rrule ? rrule.all() : [];
                            const weekly = rrule?.options.freq === RRule.WEEKLY;
                            const parentColor = childRule && moment(rule.startDate).week() % 2 == 0 ? GREEN_COLOR : 'var(--primary-color)';

                            const calendarDates = [{ dates: allDates, color: parentColor }];
                            if (childRule) {
                                calendarDates.push({ dates: childAllDates, color: childColor });
                            }

                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
                                        <SpaceContent space={20} direction='vertical' align='flex-start'>
                                            {
                                                evenOdd ?
                                                    this.renderEvenOddTitle(rule, weekly, parentColor)
                                                    :
                                                    null
                                            }
                                            <SpaceContent style={{ width: '100%' }} space={20} direction='vertical'>
                                                {
                                                    evenOdd ?
                                                        null
                                                        :
                                                        <Select
                                                            placeholder={<FormattedMessage defaultMessage={'Select a recurrence proposition'} />}
                                                            style={{ width: '100%', overflow: 'hidden' }}
                                                            onChange={(val) => this.onChangeProposal(val, ruleId)}
                                                        >
                                                            {Object.values(ProposalsValues).map(p => <Select.Option key={`proposal-value-${p}`} value={p}>{this.getProposalsText(p, ruleId)}</Select.Option>)}
                                                        </Select>
                                                }
                                                {
                                                    evenOdd ?
                                                        null
                                                        :
                                                        <Segmented block
                                                            style={{ width: '100%' }}
                                                            disabled={evenOdd}
                                                            options={[
                                                                {
                                                                    label: intl.formatMessage({ defaultMessage: 'Day' }),
                                                                    value: RRule.DAILY.toString(),
                                                                },
                                                                {
                                                                    label: intl.formatMessage({ defaultMessage: 'Week' }),
                                                                    value: RRule.WEEKLY.toString(),
                                                                },
                                                                // {
                                                                //     label: intl.formatMessage({ defaultMessage: 'Month' }),
                                                                //     value: RRule.MONTHLY.toString(),
                                                                // }
                                                            ]}
                                                            value={rrule?.options.freq.toString()}
                                                            onChange={(val) => this.onChangeFrequency(val.valueOf(), ruleId)} />
                                                }
                                                {
                                                    rrule?.options.freq === RRule.DAILY ?
                                                        <>
                                                            {
                                                                this.renderRecurringDaily(ruleId)
                                                            }
                                                        </>
                                                        : rrule?.options.freq === RRule.WEEKLY ?
                                                            <>
                                                                {evenOdd ? null : this.renderWeeklyRepeat(rrule, ruleId)}
                                                                {this.renderWeeklyDays(rrule, ruleId)}
                                                                {
                                                                    childRule ?
                                                                        null
                                                                        :
                                                                        <Col span={24}>
                                                                            <SpaceContent align='center' style={{ cursor: 'pointer' }} onClick={() => this.addChildRule(ruleId, RRule.WEEKLY)}>
                                                                                <FAIcon prefix='far' name='circle-plus' /><span><FormattedMessage defaultMessage={'Odd/even week configuration'} /></span>
                                                                            </SpaceContent>
                                                                        </Col>
                                                                }
                                                            </>
                                                            : rrule?.options.freq === RRule.MONTHLY ?
                                                                <>
                                                                    {evenOdd ? null : this.renderMonthlyRepeat(rrule, ruleId)}
                                                                    {this.renderMonthlyDays(rrule, ruleId)}
                                                                    {
                                                                        childRule ?
                                                                            null
                                                                            :
                                                                            <Col span={24}>
                                                                                <SpaceContent align='center' style={{ cursor: 'pointer' }} onClick={() => this.addChildRule(ruleId, RRule.MONTHLY)}>
                                                                                    <FAIcon prefix='far' name='circle-plus' /><span><FormattedMessage defaultMessage={'Odd/even month configuration'} /></span>
                                                                                </SpaceContent>
                                                                            </Col>
                                                                    }
                                                                </>
                                                                : null
                                                }
                                            </SpaceContent>
                                            {
                                                evenOdd && childRule ?
                                                    <>
                                                        {this.renderEvenOddTitle(childRule, weekly, childColor)}
                                                        <div style={{ width: '100%' }}>
                                                            {
                                                                rrule?.options.freq === RRule.WEEKLY ?
                                                                    this.renderWeeklyDays(childRrule, childRule.id!)
                                                                    : rrule?.options.freq === RRule.MONTHLY ?
                                                                        this.renderMonthlyDays(childRrule, childRule.id!)
                                                                        : null
                                                            }
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </SpaceContent>
                                        <Calendar defaultValue={moment(eventRecurring.startDate)} headerRender={() => null} fullscreen={false} dateFullCellRender={(val) => this.dateFullCellRender(val, calendarDates)} />
                                    </div>
                                </>
                            );
                        })
                    }
                </SpaceContent >
            </>
        );
    };


    render() {
        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    {this.renderRecurring()}
                </Col >
            </Row >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(EditRecurring));


// export function getDatesFromRRule(rrules: IEventRecurringRule[]) {
//     rrules.map(rule => {
//         const rrule = stringToRrule(rule.rrule);
//         const allDates = rrule ? rrule.all() : [];

//         const calendarDates = [{ dates: allDates, color: parentColor }];
//         if (childRule) {
//             calendarDates.push({ dates: childAllDates, color: childColor });
//         }
//     });
// }


export function stringToRrule(rrule: string | undefined) {
    return rrule && rrule.length > 0 ? rrulestr(rrule) : undefined;
}