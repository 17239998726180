import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import { Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import toInteger from "lodash/toInteger";
import { Moment } from "moment";
import { useMemo } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import FullUser, { UserSmall } from "../components/common/general/fullUser";
import { useFullName } from "../hooks/useUsers";
import { useAppSelector } from "../store/store";
import { keyify } from "./array_utils";
import { GREEN_COLOR, RED_COLOR } from "./constants";
import { DictionaryNumber, GroupUser, StaffType, User, UserJobTMP } from "./types/generalTypes";
import { filterContracts, isNullOrEmpty } from "./utils";

declare type DataIndex = string | number | readonly (string | number)[];


// #region Contract Column
export const contractColumn = (intl: IntlShape, from: Moment, to: Moment, selectedContracts: DictionaryNumber<number | undefined>, setSelectedContracts: (data: DictionaryNumber<number | undefined>) => void, className?: string, key?: string, dataIndex?: DataIndex): ColumnsType<User> => {
    return [
        {
            title: intl.formatMessage({ defaultMessage: 'Contract' }),
            key: key ?? 'contract',
            dataIndex: dataIndex ?? 'job',
            className: className ?? '__width_300',
            render: (contracts: UserJobTMP[] | undefined, record): JSX.Element => {
                let availableContracts: UserJobTMP[] = [];
                let selectedContractId: number | undefined = undefined;
                let selectedContract: UserJobTMP | undefined = undefined;

                if (!isNullOrEmpty(contracts)) {
                    availableContracts = filterContracts(from, to, contracts);
                    selectedContractId = selectedContracts[record.id];
                    selectedContract = selectedContractId ? keyify(availableContracts, 'id')[selectedContractId] : undefined;
                }

                return (
                    <>
                        {
                            availableContracts.length === 1 && selectedContract ?
                                selectedContract.name
                                :
                                availableContracts.length > 0 ?
                                    <Select
                                        onClick={(e) => e.stopPropagation()}
                                        value={selectedContracts[record.id]}
                                        options={availableContracts.map(j => ({ value: j.id, label: j.name }))}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            const clonedSelectedContracts = { ...selectedContracts };
                                            clonedSelectedContracts[record.id] = e;
                                            setSelectedContracts(clonedSelectedContracts);
                                        }}
                                    />
                                    :
                                    <span style={{ fontStyle: 'italic', color: 'gray' }}>{intl.formatMessage({ defaultMessage: 'No contract available' })}</span>
                        }
                    </>
                );
            }
        },
    ]
}

export const useContractColumn = (from: Moment, to: Moment, selectedContracts: DictionaryNumber<number | undefined>, setSelectedContracts: (data: DictionaryNumber<number | undefined>) => void, className?: string, key?: string, dataIndex?: DataIndex): ColumnsType<User> => {
    const intl = useIntl();
    return useMemo(() =>
        contractColumn(intl, from, to, selectedContracts, setSelectedContracts, className, key, dataIndex), [intl, from, to, selectedContracts, setSelectedContracts, className, key, dataIndex]
    );
}
// #endregion

// #region User Column
export const userColumn = (intl: IntlShape, getFullName: (user: UserSmall) => string, isSmartphone?: boolean, className?: string, key?: string, dataIndex?: DataIndex): ColumnsType<User> => {
    return [
        {
            title: intl.formatMessage({ defaultMessage: 'User' }),
            key: key ?? 'user',
            dataIndex: dataIndex ?? undefined,
            className: className ?? "__min-width-300",
            fixed: isSmartphone ? 'left' : undefined,
            defaultSortOrder: 'ascend',
            sorter: (a: User, b: User) => getFullName(a).localeCompare(getFullName(b), undefined, { numeric: true }),
            render: (user): JSX.Element => <FullUser user={user} withAvatar />
        },
    ]
}

export const useUserColumn = (className?: string, key?: string, dataIndex?: DataIndex): ColumnsType<User> => {
    const intl = useIntl();
    const isSmartphone = useAppSelector(state => state.window.isSmartphone);
    const fullName = useFullName();

    return useMemo(() =>
        userColumn(intl, fullName.getFullName, isSmartphone, className, key, dataIndex), [intl, fullName, isSmartphone, className, key, dataIndex]
    );
}
// #endregion

// #region User Group Colum
export const userGroupColumn = (intl: IntlShape, setSelectedGroup?: (groupId: number) => void, className?: string, key?: string, dataIndex?: DataIndex): ColumnsType<User> => {
    return [
        {
            title: intl.formatMessage({ defaultMessage: 'Groups' }),
            dataIndex: dataIndex ?? 'group_users',
            key: key ?? 'group_users',
            className: className ?? "__min-width-300",
            render: (group_users: GroupUser[] | undefined) => {
                if (isNullOrEmpty(group_users)) return "";

                const groups = group_users.sort((a, b) => (a.group_name && b.group_name) ? a.group_name.localeCompare(b.group_name) : 0).map(gu => gu);

                return (
                    <>
                        {
                            groups.map(group => (
                                <Tag
                                    className="__report-groups-users-tags"
                                    style={group.id ? { cursor: 'pointer' } : {}}
                                    color="#f5f5f5"
                                    key={group.id}
                                    onClick={() => setSelectedGroup && setSelectedGroup(group.group)}
                                >
                                    {group.group_name}
                                </Tag>
                            ))
                        }
                    </>
                );
            }
        }
    ]
}

export const useUserGroupColumn = (setSelectedGroup?: (groupId: number) => void, className?: string, key?: string, dataIndex?: DataIndex): ColumnsType<User> => {
    const intl = useIntl();

    return useMemo(() =>
        userGroupColumn(intl, setSelectedGroup, className, key, dataIndex), [intl, setSelectedGroup, className, key, dataIndex]
    );
}
// #endregion

//the users' columns structure 
export const usersColumns = (intl: IntlShape, getFullName: (user: UserSmall) => string, isSmartphone?: boolean): ColumnsType<User> => {
    return [
        ...userColumn(intl, getFullName, isSmartphone),
        {
            title: <FormattedMessage defaultMessage={'Email'} />,
            dataIndex: 'email',
            key: 'email',
            className: '__width_300',
            sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        {
            title: <FormattedMessage defaultMessage={'Role'} />,
            dataIndex: 'role',
            key: 'role',
            className: '__width_120 __centered-text',
            render: (r: number) => r === 1 ? <FormattedMessage defaultMessage={'User'} description={'User short'} /> : <FormattedMessage defaultMessage={'Admin'} description={'Admin short'} />,
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'User'} />,
                    value: "1",
                },
                {
                    text: <FormattedMessage defaultMessage={'Administrator'} />,
                    value: "2",
                }
            ],
            filterMultiple: false,
            onFilter: (value: string | number | boolean, record: User) => record.role === toInteger(value),
        },
        {
            title: <FormattedMessage defaultMessage={'Username'} />,
            dataIndex: 'username',
            key: 'username',
            className: '__width_250',
            sorter: (a: User, b: User) => a.username.localeCompare(b.username),
        },
        {
            title: <FormattedMessage defaultMessage={'Ability'} />,
            dataIndex: 'staffType',
            key: 'staffType',
            className: '__width_280',
            render: (staffStypes: StaffType[]) => {
                if (staffStypes === undefined) return "";
                return (
                    <>
                        {
                            staffStypes.map(staffStype => (
                                <Tag className="__stafftype_tags" style={staffStype.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={staffStype.id}>
                                    {staffStype.name}
                                </Tag>
                            ))
                        }
                    </>
                );
            }
        },

        {
            title: <FormattedMessage defaultMessage={'Active'} />,
            dataIndex: 'active',
            key: 'active',
            className: '__width_80 __centered-text',
            render: (r: boolean) => {
                return r === true ? <CheckCircleOutlined style={{ color: GREEN_COLOR }} /> : <CloseCircleOutlined style={{ color: RED_COLOR }} />;
            },
        },

        {
            title: <FormattedMessage defaultMessage={'Report'} />,
            dataIndex: 'report',
            key: 'report',
            className: '__width_90 __centered-text',
            render: (r: boolean) => {
                return r === true ? <CheckCircleOutlined style={{ color: GREEN_COLOR }} /> : <CloseCircleOutlined style={{ color: RED_COLOR }} />;
            },
        },
    ]
};