import React from 'react';
import { UserSmall } from '../../components/common/general/fullUser';
import { useFullName } from '../../hooks/useUsers';



export interface FullUserProps {
    getFullName: (user: UserSmall) => string;
}

export function withFullName<T extends FullUserProps = FullUserProps>(WrappedComponent: React.ComponentType<T>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithFullName = (props: Omit<T, keyof FullUserProps>) => {
        const fullName = useFullName();

        return <WrappedComponent {...(props as T)} getFullName={(user: UserSmall) => fullName.getFullName(user)} />;
    };

    ComponentWithFullName.displayName = `withFullName(${displayName})`;

    return ComponentWithFullName;
}