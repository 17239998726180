import { createSelector } from "@reduxjs/toolkit";
import { UserSmall } from "../../components/common/general/fullUser";
import { compareUser } from "../../components/teamManagement/utils/utils";
import { keyify } from "../../utils/array_utils";
import { ECodePosition } from "../../utils/types/constants";
import { ApplicationState } from "../../utils/types/storeTypes";
import { getFullName } from "../../utils/utils";


export const selectUsersSortParams = (state: ApplicationState) => ({
    lastNameFirst: state.planning.settings.displayLastNameFirst ?? true,
    codePosition: state.user.company?.codePosition ?? ECodePosition.HIDDEN,
    codeAuthorization: state.user.company?.userCodeDisplayAuthorization ?? 'hidden'
});
  
export const selectUsers = createSelector(
    [
        (state: ApplicationState) => state.teamManagement.users,
        selectUsersSortParams
    ],
    (users, { lastNameFirst, codePosition, codeAuthorization }) => {
        const getFullNameUtil = (user: UserSmall) =>
            getFullName(user, lastNameFirst, codePosition, codeAuthorization);
  
        return [...users].sort((a, b) => compareUser(a, b, getFullNameUtil));
    }
);

export const selectActiveUsers = createSelector(
    [selectUsers],
    (users) => users.filter((u) => u.active && u.visible)
);

export const selectActiveReportUsers = createSelector(
    [selectActiveUsers],
    (users) => users.filter((u) => u.report)
);

export const selectActiveReportUsersKeyed = createSelector(
    [selectActiveUsers],
    (users) => keyify(users.filter((u) => u.report), "id")
);