import { Button, Checkbox, DatePicker, Divider, Empty, Segmented, Select, Spin } from 'antd';
import moment, { Moment } from 'moment';
import React, { createRef } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useProjectName } from '../../../hooks/useProjectName';
import { changeProject } from '../../../store/actions/configurations';
import { changeSidebarType } from '../../../store/actions/window';
import { selectActiveProjectsSortTitle, selectInactiveProjectsSortTitle, selectProjectsSortTitle } from '../../../store/selectors/projectsSelectors';
import { CaseType, MOMENT_MONTH_FORMAT } from '../../../utils/constants';
import { ProjectNameProps, withProjectName } from '../../../utils/customIntl/withProjectName';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { FullUserProps, withFullName } from '../../../utils/objects/withFullName';
import { DictionaryString, RouterProps } from '../../../utils/types/generalTypes';
import { NetworkResponse } from '../../../utils/types/networkTypes';
import { EventForProject, Project } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { isNullOrEmpty, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import SwitchLabeled from '../../common/fields/switchLabeled';
import Anticon from '../../common/general/anticon';
import Fieldset from '../../common/general/fieldset';
import { Sidebar, SidebarType } from '../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';
import { Filter } from '../../planningPerf/tabs/common/filters';
import { AbstractReportsGrouped } from './reportsGroupped/abstractReportsGrouped';
import ForwardedReportsGroupedByProject from './reportsGroupped/reportsGroupedByProject';
import ForwardedReportsGroupedByProjectAndUser from './reportsGroupped/reportsGroupedByProjectAndUser';
import ForwardedReportsGroupedByUser from './reportsGroupped/reportsGroupedByUser';
import ForwardedReportsNotGrouped from './reportsGroupped/reportsNotGrouped';
const { Option } = Select;
const { RangePicker } = DatePicker;

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps, ContainerTabsItemProps, ProjectNameProps, FullUserProps { }

interface State {
    eventsForProjects?: EventForProject[];
    dataGroupBy?: ProjectGroupBy[];
    dataDisplayDetails?: boolean;
    loadingProjects: boolean;
    loading: boolean;
    departmentIdSelected?: number;
    filters: Filters;
    selectStates: {
        [key: string]: {
            dropdownVisible: boolean;
        };
    };
}

export class ProjectReports extends ContainerTabsItem<Props, State> {
    private childRef = createRef<AbstractReportsGrouped<any, any>>();

    constructor(props: Props) {
        super(props);

        this.state = {
            loadingProjects: false,
            loading: false,
            filters: {
                startMonth: moment().startOf("month"),
                endMonth: moment().endOf("month"),
                selectedUsers: [],
                selectedGroups: [],
                selectedTypesOfDay: [],
                archiveType: ArchiveType.ONLY_ACTIVE,
                selectedProjects: [],
                groupBy: [ProjectGroupBy.PROJECT, ProjectGroupBy.USER],
                displayDetails: false,
            },
            selectStates: {
                projects: {
                    dropdownVisible: false,
                },
                users: {
                    dropdownVisible: false,
                },
            },
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (
            !isEqual(prevState.eventsForProjects, this.state.eventsForProjects) ||
            prevState.loading !== this.state.loading
        ) this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);

        if (
            prevState.loading !== this.state.loading ||
            !isEqual(prevState.selectStates, this.state.selectStates) ||
            !isEqual(prevState.filters, this.state.filters)
        ) this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
    }

    getExtra = () => {
        const { intl } = this.props;
        const { eventsForProjects, loading } = this.state;
        return (
            <>
                <CircleButton
                    small
                    disabled={isNullOrEmpty(eventsForProjects) || loading}
                    onClick={() => this.downloadProjectEvents()}
                    icon={<FAIcon prefix={'fad'} name="download" />}
                    title={`${intl.formatMessage({ defaultMessage: 'Download' })} (${eventsForProjects?.length})`}
                    placement="topLeft"
                    key="team-management-download-events-project" />
            </>
        );
    };

    getSidebars = () => {
        const { departments, users, groups, intl, projectNamePlural, projectName, typesOfDay, getFullName } = this.props;
        const { filters, loading, departmentIdSelected } = this.state;
        const { selectedGroups, selectedUsers, startMonth, endMonth, archiveType, selectedProjects, groupBy, displayDetails, selectedTypesOfDay: selectedTypeOfDay } = filters;
        const projects = this.getProjects(archiveType);

        const disabledFilters = loading;

        const sidebar: Sidebar = {
            button: {
                title: intl.formatMessage({ defaultMessage: 'Export configuration' }),
                icon: <Anticon><FAIcon prefix={'fad'} name="flask-gear" /></Anticon>
            },
            titleBar: {
                title: <FormattedMessage defaultMessage={'Export configuration'} />,
                extra: (
                    <CircleButton
                        small
                        disabled={disabledFilters || selectedProjects.length === 0}
                        title={intl.formatMessage({ defaultMessage: 'Force update' })}
                        icon={<FAIcon prefix={'fad'} name="rotate" />}
                        placement="left"
                        onClick={() => this.showProjectEvents(selectedProjects, filters, departmentIdSelected)} />
                )
            },
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Fieldset
                        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                        legendStyle={{ fontSize: '100%', marginLeft: '0' }}
                        legend={<><FAIcon prefix="fad" name="layer-group" /> <FormattedMessage defaultMessage={'Display format'} /></>}
                    >
                        <Checkbox.Group
                            disabled={disabledFilters}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            options={[
                                { label: <FormattedMessage defaultMessage={'Group by {project}'} values={{ project: projectName.toLocaleLowerCase() }} />, value: ProjectGroupBy.PROJECT },
                                { label: <FormattedMessage defaultMessage={'Group by user'} />, value: ProjectGroupBy.USER },
                            ]}
                            value={groupBy}
                            onChange={g => this.onChangeGroupBy(g as ProjectGroupBy[])}
                        />
                        <SwitchLabeled
                            disabled={disabledFilters}
                            style={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}
                            checked={displayDetails}
                            onChange={e => this.onChangeDisplayDetails(e)}
                            label={intl.formatMessage({ defaultMessage: "Display details" })}
                        />
                    </Fieldset>
                    <Fieldset
                        style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                        legendStyle={{ fontSize: '100%', marginLeft: '0' }}
                        legend={<><FAIcon prefix={'fad'} name="sitemap" />{projectNamePlural}</>}
                    >
                        <Segmented
                            block
                            disabled={disabledFilters}
                            options={[
                                { label: <FormattedMessage defaultMessage={'Active'} />, value: ArchiveType.ONLY_ACTIVE },
                                { label: <FormattedMessage defaultMessage={'Archived'} />, value: ArchiveType.ONLY_ARCHIVED },
                                { label: <FormattedMessage defaultMessage={'All'} />, value: ArchiveType.ALL },
                            ]}
                            onChange={(t) => this.onChangeArchiveType(t as ArchiveType)}
                            value={archiveType} />
                        <ProjectsFilter
                            disabled={disabledFilters}
                            projects={projects}
                            onChangeSelectedProjects={this.onChangeSelectedProjects}
                            selectedProjects={selectedProjects} />
                    </Fieldset>
                    <Fieldset
                        style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                        legendStyle={{ fontSize: '100%', marginLeft: '0' }}
                        legend={<><FAIcon prefix="fad" name="filter-list" /> <FormattedMessage defaultMessage={'Output data filtering'} /></>}
                    >
                        <RangePicker
                            disabled={disabledFilters}
                            allowClear={false}
                            allowEmpty={[false, false]}
                            format={getFormat('DATE')}
                            value={[startMonth, endMonth]}
                            onChange={this.onChangeDates} />

                        {
                            !isNullOrEmpty(departments.data) &&
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px' }}>
                                <span><FormattedMessage defaultMessage={'Department'} /></span>
                                <Select
                                    disabled={disabledFilters}
                                    className="select-width"
                                    showSearch
                                    allowClear
                                    onChange={(e: number | undefined) => this.setState({ departmentIdSelected: e })}
                                    placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                    value={this.state.departmentIdSelected}
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {
                                        departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)
                                    }
                                </Select>
                            </div>
                        }
                        {
                            !isNullOrEmpty(users) &&
                            <Filter title={<FormattedMessage defaultMessage={'Users'} />} input={
                                <Select
                                    // onDropdownVisibleChange={(visible) => this.handleDropdownVisibleChange("users", visible)}
                                    // open={selectStates["users"].dropdownVisible}
                                    disabled={disabledFilters}
                                    className="__filter-users-group-select"
                                    listHeight={500}
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    value={selectedUsers}
                                    onChange={(g) => this.onChangeSelectedUsers(g)}
                                    filterOption={true}
                                    optionFilterProp="label"
                                    showArrow
                                    maxTagCount={"responsive"}
                                >
                                    {
                                        users.map(user => {
                                            const fullName = getFullName(user);
                                            return (
                                                <Option
                                                    value={user.id}
                                                    key={"users-" + user.id}
                                                    label={fullName}
                                                >
                                                    {fullName}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            } />
                        }
                        {
                            !isNullOrEmpty(groups) &&
                            <>
                                <Filter title={<FormattedMessage defaultMessage={'Groups'} />} input={
                                    <Select
                                        disabled={disabledFilters}
                                        className="__filter-users-group-select"
                                        listHeight={500}
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        value={selectedGroups}
                                        onChange={(g) => this.onChangeSelectedGroups(g)}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                    >
                                        {
                                            groups.map(group => (
                                                <Option
                                                    value={group.id}
                                                    key={"groups-" + group.id}
                                                    label={`${group.name}`}
                                                >
                                                    {group.name}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                } />
                                {/* {props.groups.selectedGroups.length > 0 ? excludeFilter : null} */}
                            </>
                        }
                        {
                            !isNullOrEmpty(typesOfDay) &&
                            <>
                                <Filter title={<FormattedMessage defaultMessage={'Day types'} />} input={
                                    <Select
                                        disabled={disabledFilters}
                                        className="__filter-users-type-of-day-select"
                                        listHeight={500}
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        value={selectedTypeOfDay}
                                        onChange={(td) => this.onChangeSelectedTypesOfDay(td)}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                    >
                                        {
                                            typesOfDay.map(typeOfDay => (
                                                <Option
                                                    value={typeOfDay.id}
                                                    key={"typeofday-" + typeOfDay.id}
                                                    label={`${typeOfDay.title}`}
                                                >
                                                    {typeOfDay.title}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                } />
                                {/* {props.groups.selectedGroups.length > 0 ? excludeFilter : null} */}
                            </>
                        }
                    </Fieldset>

                </div>
            ),
            type: SidebarType.SETTINGS
        };
        return [sidebar];
    };

    downloadProjectEvents = () => {
        if (this.childRef.current) {
            this.childRef.current.downloadProjectEvents(); // Appel d'une méthode de l'enfant
        }
    };


    handleDropdownVisibleChange = (selectKey: string, visible: boolean) => {
        this.setState((prevState) => ({
            selectStates: {
                ...prevState.selectStates,
                [selectKey]: {
                    dropdownVisible: visible,
                },
            },
        }));
    };

    onChangeArchiveType = (archiveType: ArchiveType) => {
        const projects = this.getProjects(archiveType);
        const projectIds = projects.map(p => p.id) as number[];
        this.setState(prevState => {
            const filters: Filters = {
                ...prevState.filters,
                archiveType,
                selectedProjects: prevState.filters.selectedProjects.filter((p) => projectIds.includes(p))
            };

            this.showProjectEvents(filters.selectedProjects, filters, prevState.departmentIdSelected);
            return ({ filters });
        });
    };

    onChangeDisplayDetails = (displayDetails: boolean) => {
        this.setState(prevState => {
            const nextState: State = {
                ...prevState,
                filters: { ...prevState.filters, displayDetails },
                eventsForProjects: undefined,
                loading: prevState.filters.selectedProjects.length > 0 ? true : false
            };
            this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
            return (nextState);
        });
    };

    onChangeGroupBy = (groupBy: ProjectGroupBy[]) => {
        this.setState(prevState => {
            const nextState: State = {
                ...prevState,
                filters: { ...prevState.filters, groupBy, displayDetails: isNullOrEmpty(groupBy) ? false : prevState.filters.displayDetails },
                eventsForProjects: undefined,
                loading: prevState.filters.selectedProjects.length > 0 ? true : false
            };
            this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
            return (nextState);
        });
    };

    onChangeSelectedProjects = (selectedProjects: number[]) => {
        const { sidebarType, changeSidebarType } = this.props;
        if (sidebarType !== SidebarType.SETTINGS) changeSidebarType(SidebarType.SETTINGS);
        this.setState(prevState => {
            const nextState: State = {
                ...prevState,
                filters: { ...prevState.filters, selectedProjects },
                eventsForProjects: selectedProjects.length === 0 ? undefined : prevState.eventsForProjects
            };
            this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
            return (nextState);
        });
    };

    onChangeDates = (dates: [Moment | null, Moment | null] | null) => {
        if (dates && dates[0] && dates[1]) {
            const startMonth = dates[0];
            const endMonth = dates[1];
            this.setState(prevState => {
                const nextState: State = {
                    ...prevState,
                    filters: { ...prevState.filters, startMonth, endMonth }
                };
                this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
                return (nextState);
            });
        }
    };

    onChangeSelectedUsers = (selectedUsers: number[]) => {
        this.setState(prevState => {
            const nextState: State = {
                ...prevState,
                filters: { ...prevState.filters, selectedUsers }
            };
            this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
            return (nextState);
        });
    };

    onChangeSelectedGroups = (selectedGroups: number[]) => {
        this.setState(prevState => {
            const nextState: State = {
                ...prevState,
                filters: { ...prevState.filters, selectedGroups }
            };
            this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
            return (nextState);
        });
    };

    onChangeSelectedTypesOfDay = (selectedTypesOfDay: number[]) => {
        this.setState(prevState => {
            const nextState: State = {
                ...prevState,
                filters: { ...prevState.filters, selectedTypesOfDay }
            };
            this.showProjectEvents(nextState.filters.selectedProjects, nextState.filters, nextState.departmentIdSelected);
            return (nextState);
        });
    };



    // TODO: Supprime moi ça please, passe au back:
    getUsersFromGroups = (groupsIds: number[]) => {
        const { groups: allGroups, users } = this.props;
        const groupIds = allGroups?.filter(g => g.id && groupsIds.includes(g.id)).map(g => g.id);
        if (!isNullOrEmpty(groupIds)) {
            const userIdInSelectedGroups = groupIds.reduce((obj, group) => {
                if (group)
                    obj[group] = [];
                return obj;
            }, {} as DictionaryString<number[]>);
            for (const user of users)
                if (user.group_users)
                    if (user.group_users.some(g => groupIds.includes(g.group)))
                        for (const groupUser of user.group_users)
                            if (groupUser.group in userIdInSelectedGroups)
                                userIdInSelectedGroups[groupUser.group].push(user.id);

            return Object.keys(userIdInSelectedGroups).reduce((userIds, groupId) => [...userIds, ...userIdInSelectedGroups[groupId]], [] as number[]);
        }
    };

    showProjectEvents = (projectIds: number[], filters: Filters, departmentIdSelected: number | undefined) => {
        if (isNullOrEmpty(projectIds)) return;
        const { startMonth, endMonth, selectedUsers, selectedGroups, groupBy, displayDetails, selectedTypesOfDay } = filters;

        // TODO: Supprime moi ça please, passe au back :')
        const bypassGroups = this.getUsersFromGroups(selectedGroups) ?? [];

        Network.getProjectEventsV2(projectIds, selectedUsers.concat(bypassGroups), selectedGroups, selectedTypesOfDay, startMonth.format(MOMENT_MONTH_FORMAT), endMonth.format(MOMENT_MONTH_FORMAT), departmentIdSelected, displayDetails ? [] : groupBy).then(
            (response: NetworkResponse<EventForProject[]>) => {
                if (response.error) {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An unknown error occurred. Please contact the administrator' }), "error");
                } else {
                    this.setState({ eventsForProjects: response.data });
                }
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An unknown error occurred. Please contact the administrator' }), "error");
            },
        ).finally(() => this.setState({ loading: false }));
    };


    getProjects = (archiveType: ArchiveType) => {
        const { allProjects, activeProjects, inactiveProjects } = this.props;
        switch (archiveType) {
            case ArchiveType.ONLY_ACTIVE:
                return activeProjects;
            case ArchiveType.ONLY_ARCHIVED:
                return inactiveProjects;
            default:
                return allProjects;
        }
    };

    render() {
        const { height, sidebarType, projectName, projectNamePlural } = this.props;
        const { filters, eventsForProjects, loading } = this.state;
        const { selectedProjects, archiveType, groupBy, displayDetails } = filters;
        const projects = this.getProjects(archiveType);
        let tableHeight = height - 95;
        if (tableHeight < 250) tableHeight = 250;

        // let ReportsRender:AbstractReportsGrouped<any, any> = ForwardedReportsNotGrouped;
        let ReportsRender: any = ForwardedReportsNotGrouped;
        switch (groupBy.join('|')) {
            case `${ProjectGroupBy.PROJECT}|${ProjectGroupBy.USER}`:
                ReportsRender = ForwardedReportsGroupedByProjectAndUser;
                break;
            case ProjectGroupBy.PROJECT.toString():
                ReportsRender = ForwardedReportsGroupedByProject;
                break;
            case ProjectGroupBy.USER.toString():
                ReportsRender = ForwardedReportsGroupedByUser;
                break;
        }

        return (
            <>
                {
                    eventsForProjects ?
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                            <Spin spinning={loading} indicator={<FAIcon prefix='fas' name='spinner-third' fontSize={70} spin />} size='large' style={{ backgroundColor: 'transparent', minHeight: '70px' }} >
                                <ReportsRender fromDate={filters.startMonth.toISOString()} toDate={filters.endMonth.toISOString()} projectNamePlural={projectNamePlural} displayDetails={displayDetails} ref={this.childRef} eventsForProjects={eventsForProjects} height={tableHeight} />
                            </Spin>
                        </div>
                        :
                        loading ?
                            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Spin indicator={<FAIcon prefix='fas' name='spinner-third' fontSize={70} spin />} size='large' style={{ backgroundColor: 'transparent', minHeight: '70px' }} />
                            </div>
                            :
                            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={<FormattedMessage defaultMessage={'Please choose a {project}'} values={{ project: projectName.toLocaleLowerCase() }} />}
                                />
                                {
                                    sidebarType !== SidebarType.SETTINGS ?
                                        <ProjectsFilter projects={projects} onChangeSelectedProjects={this.onChangeSelectedProjects} selectedProjects={selectedProjects} disabled={loading} />
                                        : null

                                }
                            </div>
                }
            </>
        );
    }

}

const mapStateToProps = (state: ApplicationState) => ({
    groups: state.teamManagement.groups,
    users: state.teamManagement.users,
    isSmartphone: state.window.isSmartphone,
    typesOfDay: state.configurations.typesOfDay,
    height: state.window.height,
    departments: state.configurations.departments,
    sidebarType: state.window.sidebarType,
    activeProjects: selectActiveProjectsSortTitle(state),
    inactiveProjects: selectInactiveProjectsSortTitle(state),
    allProjects: selectProjectsSortTitle(state),
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeProject: (p: Project[]) => dispatch(changeProject(p)),
    changeSidebarType: (type: SidebarType) => dispatch(changeSidebarType(type)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(injectIntl(withProjectName(withFullName(ProjectReports)))));


// #region All utils
export const ProjectsFilter: React.FC<{ selectedProjects: number[], onChangeSelectedProjects: (selectedProjects: number[]) => void, projects: Project[], disabled: boolean; }> = ({ selectedProjects, onChangeSelectedProjects, projects, disabled = false }) => {
    const projectsString = useProjectName({ isPlural: true, caseType: CaseType.FIRST_LETTER_UPPERCASE });
    return (
        <Filter input={
            <Select
                disabled={disabled}
                autoClearSearchValue={false}
                className="__filter-users-group-select"
                listHeight={500}
                mode="multiple"
                placeholder={projectsString}
                allowClear
                style={{ width: '280px' }}
                value={selectedProjects}
                onChange={onChangeSelectedProjects}
                filterOption={true}
                optionFilterProp="label"
                showArrow
                maxTagCount={"responsive"}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                                style={{ fontSize: '90%', fontStyle: 'italic' }}
                                type='link'
                                onClick={() => onChangeSelectedProjects(projects.map(p => p.id) as number[])}>
                                <FormattedMessage defaultMessage={'Select all available {projects}'} values={{ projects: projectsString.toLocaleLowerCase() }} />
                            </Button>
                        </div>
                    </>
                )}
            >
                {
                    projects.length > 0 && projects.map(p => (<Option
                        value={p.id}
                        key={"project-" + p.id}
                        label={`${p.title}`}
                    >
                        {p.title}
                    </Option>
                    ))
                }
            </Select>
        } />
    );
};

export const enum ProjectGroupBy {
    PROJECT = 1,
    USER = 2,
}

export const enum ArchiveType {
    ALL = 1,
    ONLY_ARCHIVED = 2,
    ONLY_ACTIVE = 3,
}

interface Filters {
    archiveType: ArchiveType;
    selectedGroups: number[];
    selectedUsers: number[];
    selectedTypesOfDay: number[];
    startMonth: Moment;
    endMonth: Moment;
    selectedProjects: number[];
    displayDetails: boolean;
    groupBy: ProjectGroupBy[];
}
// #endregion