import { Alert, Button, InputNumber, Modal, Select } from "antd";
import { cloneDeep } from "lodash";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { PlanningEvent, Reminder } from "../../../../utils/types/planningTypes";
import { showNotification } from "../../../../utils/utils";

const enum DurationTypes {
    // YEAR = 'year',
    // YEARS = 'years',
    // MONTH = 'month',
    // MONTHS = 'months',
    // WEEK = 'week',
    // WEEKS = 'weeks',
    DAY = 'day',
    DAYS = 'days',
    HOUR = 'hour',
    HOURS = 'hours',
    MINUTE = 'minute',
    MINUTES = 'minutes',
}

const DurationMessages = defineMessages<DurationTypes>({
    // [DurationTypes.YEAR]: { defaultMessage: 'Year' },
    // [DurationTypes.YEARS]: { defaultMessage: 'Years' },
    // [DurationTypes.MONTH]: { defaultMessage: 'Month' },
    // [DurationTypes.MONTHS]: { defaultMessage: 'Months' },
    // [DurationTypes.WEEK]: { defaultMessage: 'Week' },
    // [DurationTypes.WEEKS]: { defaultMessage: 'Weeks' },
    [DurationTypes.DAY]: { defaultMessage: 'Day' },
    [DurationTypes.DAYS]: { defaultMessage: 'Days' },
    [DurationTypes.HOUR]: { defaultMessage: 'Hour' },
    [DurationTypes.HOURS]: { defaultMessage: 'Hours' },
    [DurationTypes.MINUTE]: { defaultMessage: 'Minute' },
    [DurationTypes.MINUTES]: { defaultMessage: 'Minutes' },
})

export const DURATIONTYPES: { label: string, name: MessageDescriptor, pluralName: MessageDescriptor, lengthInMin: number; }[] = [
    // { label: "year", name: DurationMessages[DurationTypes.YEAR], pluralName: DurationMessages[DurationTypes.YEARS], lengthInMin: 525600 },
    // { label: "month", name: DurationMessages[DurationTypes.MONTH], pluralName: DurationMessages[DurationTypes.MONTHS], lengthInMin: 43200 },
    // { label: "week", name: DurationMessages[DurationTypes.WEEK], pluralName: DurationMessages[DurationTypes.WEEKS], lengthInMin: 10080 },
    { label: "day", name: DurationMessages[DurationTypes.DAY], pluralName: DurationMessages[DurationTypes.DAYS], lengthInMin: 1440 },
    { label: "hour", name: DurationMessages[DurationTypes.HOUR], pluralName: DurationMessages[DurationTypes.HOURS], lengthInMin: 60 },
    { label: "minute", name: DurationMessages[DurationTypes.MINUTE], pluralName: DurationMessages[DurationTypes.MINUTES], lengthInMin: 1 }
];

export const MAXDURATIONINMIN = 5256000;

export const automaticDurationTypeSelection = (durationInMin: number, precise = true) => {
    for (let i = 0; i < 6; i++) {
        if ((precise && durationInMin % DURATIONTYPES[i].lengthInMin === 0) || (!precise && durationInMin && [0, 1, 2, 3].includes(DURATIONTYPES[i].lengthInMin))) {
            return DURATIONTYPES[i]
        }
    }
    return DURATIONTYPES[3]

}

interface Props {
    open: boolean;
    onClose(cancel?: boolean): void;
    onSave(reminder: Reminder): void;
    event: PlanningEvent;
    reminder?: Reminder;
    noErrors?: boolean
}



export const ReminderModal = (props: Props) => {


    const { open, event, onClose, onSave, reminder: propsReminder } = props
    const [reminder, setReminder] = useState<Reminder | undefined>(propsReminder)
    const [selectedDurationType, setSelectedDurationType] = useState<{ label: string, name: ReactNode, lengthInMin: number; }>(automaticDurationTypeSelection(propsReminder?.minBeforeEventStart || 5))
    const intl = useIntl();

    const noErrors = props.noErrors

    useEffect(() => {
        setReminder(propsReminder)
        setSelectedDurationType(automaticDurationTypeSelection(propsReminder?.minBeforeEventStart || 5))
    }, [propsReminder])



    const saveReminder = useCallback(() => {
        if (!reminder) {
            showNotification(intl.formatMessage({ defaultMessage: 'You must enter the information for the reminder' }), 'warning')
            return false
        }
        if (reminder?.minBeforeEventStart < 1) {
            showNotification(intl.formatMessage({ defaultMessage: 'You can send reminders with a minimum delay of 1 minute' }), 'warning')
            return false
        }
        if (reminder.retreived || reminder.sent) {
            showNotification(intl.formatMessage({ defaultMessage: 'The reminder has already been sent and cannot be modified' }), 'warning')
            return false
        }
        onSave(reminder)
    }, [reminder, onSave, intl])

    const error = useMemo((): { msg?: string, inError: boolean, value?: number } => {
        if (!noErrors && reminder) {
            if (event.startDate.clone().subtract(reminder.minBeforeEventStart, 'minutes').isSameOrBefore(moment())) {
                const timeBeforeNow = moment().diff(event.startDate.clone().subtract(reminder.minBeforeEventStart, 'minutes'), 'minutes')

                const durationType = automaticDurationTypeSelection(reminder.minBeforeEventStart, true)

                return ({
                    msg: intl.formatMessage({ defaultMessage: 'The reminder would take place {time} ago' }, { time: `${(timeBeforeNow / durationType.lengthInMin).toFixed(1)} ${(timeBeforeNow > 1 ? intl.formatMessage(durationType.pluralName) : intl.formatMessage(durationType.name)).toLowerCase()}` }),
                    inError: true,
                    value: event.startDate.clone().subtract(reminder.minBeforeEventStart, 'minutes').diff(moment(), 'minutes')
                })
            }
        }
        return ({ inError: false })

    }, [noErrors, reminder, event.startDate, intl])

    return (
        <Modal
            className="reminder-root"
            open={open ?? true}
            destroyOnClose={false}
            onCancel={() => onClose()}
            closable
            width={620}
            style={{ top: 150 }}
            title={reminder && reminder.id !== -1 ? <FormattedMessage defaultMessage={'Edit reminder'} /> : <FormattedMessage defaultMessage={'Add a reminder'} />}
            bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
            footer={[

                <div key={`eventmodal-add-reminder-action`} style={{ display: 'flex', justifyContent: 'end' }}>

                    <Button disabled={error.inError} type="primary" onClick={() => saveReminder()} key="reminder-modal-button-ok">
                        {event.id ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Continue'} />}
                    </Button>
                </div>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                <span><FormattedMessage defaultMessage={'The reminder will take place'} /></span>
                <InputNumber
                    className={`reminder-input-number`}
                    onChange={(value) => {
                        let newReminder = cloneDeep(reminder) as Reminder
                        if (!reminder) {
                            newReminder = {
                                id: -1,
                                minBeforeEventStart: 5,
                                created: moment(),
                                retreived: false,
                                sent: false
                            }
                        }
                        if (value && typeof value === 'number') {
                            newReminder.minBeforeEventStart = (value * selectedDurationType.lengthInMin)
                            setReminder(newReminder)
                        }
                    }}
                    max={MAXDURATIONINMIN / selectedDurationType.lengthInMin}
                    min={1}
                    value={Number(((reminder ? reminder.minBeforeEventStart : 90) / selectedDurationType.lengthInMin).toFixed(0))}
                    addonAfter={
                        <Select
                            style={{ width: '110px', margin: 0, height: 30 }}
                            value={selectedDurationType.label}
                            options={DURATIONTYPES.map((t) => ({ label: intl.formatMessage(t.pluralName), value: t.label }))}
                            onChange={(e) => {
                                const durationType = DURATIONTYPES.find(t => t.label === e);
                                if (durationType)
                                    setSelectedDurationType(durationType);
                            }}
                        />
                    }
                />
                <span><FormattedMessage defaultMessage={'before the event'} /></span>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                {error.inError && <Alert type="warning" showIcon
                    style={{ width: '50%', fontSize: "80%", fontStyle: 'italic' }}
                    message={error.msg} />}
            </div>

        </Modal>
    )
}
export default ReminderModal