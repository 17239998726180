import ExcelJS, { Alignment, TableColumnProperties } from 'exceljs';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import SunkhronosFullLogo from '../../images/logo/logo_sunkhronos_full.png';
import SunkhronosLogo from '../../images/logo/logo_sunkhronos_small.png';
import getFormat from '../Lang';
import { Company } from '../types/generalTypes';
import { getImageDimensions, isNullOrEmpty, normalizeName } from '../utils';

interface IReportExcelColum<T> extends TableColumnProperties {
    alignment?: Partial<Alignment>;
    width?: number;
    key?: string;
    render: (data: T) => any;
}

export type IReportExcelRow<T> = IReportExcelColum<T>[];

export default class ReportExcel {
    private mainColor: string = '#262e37';
    private workbook: ExcelJS.Workbook;
    private worksheet: ExcelJS.Worksheet;
    private totalColumns: number = 0;
    private intl: IntlShape;
    private company: Company | undefined;

    constructor(sheetName: string, totalColumns: number, intl: IntlShape, company:Company| undefined) {
        this.workbook = new ExcelJS.Workbook();
        this.workbook.properties.date1904 = true;
        this.workbook.calcProperties.fullCalcOnLoad = true;
        this.workbook.calcProperties.fullCalcOnLoad = true;
        this.workbook.creator = "Sunkhronos"
        this.workbook.created = new Date();
        this.workbook.lastModifiedBy = "Sunkhronos"
        this.workbook.modified = new Date();

        this.worksheet = this.workbook.addWorksheet(sheetName);
        this.worksheet.headerFooter.oddFooter = `&R&K919090${company?.name} &C&K919090${intl.formatMessage({defaultMessage: "Page {page} of {total}"}, {page: "&P", total: "&N"})} &L&K919090sunkhronos.ch`;
        this.totalColumns = totalColumns;
        this.intl = intl;
        this.company = company;
    }

    // Fonction utilitaire pour convertir un numéro de colonne en lettre (ex: 1 => A, 27 => AA)
    static getColumnLetter(col: number): string {
        let letter = '';
        while (col > 0) {
            const modulo = (col - 1) % 26;
            letter = String.fromCharCode(65 + modulo) + letter;
            col = Math.floor((col - modulo) / 26);
        }
        return letter;
    }

    static calculateAutoRowHeight(text:string, baseHeight = 5, charPerLine = 30, padding = 5) {
        if (typeof text !== 'string') return baseHeight + padding;
      
        const lines = text.split('\n');
        let totalLines = 0;
      
        for (let line of lines) {
            line = line.trim();
            totalLines += Math.ceil(line.length / charPerLine) || 1;
        }
        return totalLines * baseHeight + (padding * (totalLines+1));
    }

    private addOuterBorders(startRow: number, endRow: number, startCol: number, endCol: number) {
        for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
            for (let colNum = startCol; colNum <= endCol; colNum++) {
                const cell = this.worksheet.getCell(rowNum, colNum);
                const border:Partial<ExcelJS.Borders> = {};
                if (rowNum === startRow) {
                    border.top = {  style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') }  };
                }
                if (rowNum === endRow) {
                    border.bottom = {  style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') }  };
                }
                if (colNum === startCol) {
                    border.left = {  style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') }  };
                }
                if (colNum === endCol) {
                    border.right = {  style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') }  };
                }
      
                cell.border = border;
            }
        }
    }

    private adjustScaleForA4<T,>(cols:IReportExcelRow<T>, orientation = "portrait") {
        // Largeur approximative d'une page A4 selon l'orientation
        const A4_WIDTH_PORTRAIT = 170;
        const A4_WIDTH_LANDSCAPE = 250;
    
        // Déterminer la largeur disponible selon l'orientation
        const availableWidth = orientation === "landscape" ? A4_WIDTH_LANDSCAPE : A4_WIDTH_PORTRAIT;
    
        // Calculer la largeur totale des colonnes
        const totalTableWidth = cols.reduce((sum, col) => sum + (col.width || 10), 0);
    
        // Calculer le scale nécessaire pour remplir la page
        return Math.min(400, Math.max(10, Math.round((availableWidth / totalTableWidth) * 100)));
    }

    private async loadImageBuffer(url: string): Promise<Buffer> {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        return Buffer.from(arrayBuffer);
    }

    /**
   * Ajoute l'en-tête en fonction du nombre total de colonnes.
   * La répartition se fait ainsi :
   * - Logo gauche : Math.floor(totalColumns / 3) colonnes
   * - Titre : totalColumns - 2 * Math.floor(totalColumns / 3) colonnes
   * - Logo droit : Math.floor(totalColumns / 3) colonnes
   * @param logo URL du logo gauche
   * @param row la ligne à laquelle écrire
   */
    public async addHeader(
        withSunkhronosLogo: boolean,
        row: number,
        title: string,
        clientLogo?: string,
    ): Promise<void> {
        // Fusionner la première ligne (ex: de A1 à la dernière colonne)
        const lastColLetter = ReportExcel.getColumnLetter(this.totalColumns);
        let mergedRange = `A${row}:${lastColLetter}${row}`;
        this.worksheet.mergeCells(mergedRange);
        mergedRange = `A${row+1}:${lastColLetter}${row+1}`;
        this.worksheet.mergeCells(mergedRange);

    
        // Placer le titre centré dans la cellule fusionnée
        let headerRow = this.worksheet.getRow(row);
        headerRow.height = 35;
        let headerCell = this.worksheet.getCell(`A${row}`);
        headerCell.value = title;
        headerCell.alignment = { horizontal: 'right', vertical: 'middle' };
        headerCell.font = { size: 24, bold: true, color: { argb: 'FF262e37' } };
        
        headerRow = this.worksheet.getRow(row+1);
        headerRow.height = 15;
        headerCell = this.worksheet.getCell(`A${row+1}`);
        headerCell.value = `${this.intl.formatMessage({ defaultMessage: `Document generated on` })} ${moment().format(getFormat("DATE_AND_ON_TIME_SHORT"))} ${this.intl.formatMessage({ defaultMessage: `by` })} ${this.workbook.creator}`;
        headerCell.alignment = { horizontal: 'right', vertical: 'middle' };
        headerCell.font = { size: 12, italic: true, color: { argb: 'FF919090' } };
        // Charger les images et obtenir leurs dimensions via la fonction native
        if(withSunkhronosLogo) {            
            const [sunkhronosLogoBuffer] = await Promise.all([
                this.loadImageBuffer(SunkhronosLogo),
            ]);
            const [sunkhronosLogoDimensions] = await Promise.all([
                getImageDimensions(SunkhronosLogo),
            ]);

            const desiredHeight = 66;
            const sunkhronosLogoScale = desiredHeight / sunkhronosLogoDimensions.height;
            const sunkhronosLogoWidth = sunkhronosLogoDimensions.width * sunkhronosLogoScale;
    
            const sunkhronosLogoId = this.workbook.addImage({
                buffer: sunkhronosLogoBuffer,
                extension: 'png',
            });
            this.worksheet.addImage(sunkhronosLogoId, {
                tl: { col: 0, row: 0 },
                ext: { width: sunkhronosLogoWidth, height: desiredHeight },
                editAs: 'oneCell'
            });
        }else {
            if(clientLogo) {
                const [clientLogoBuffer] = await Promise.all([
                    this.loadImageBuffer(clientLogo),
                ]);
                const [clientLogoDimensions] = await Promise.all([
                    getImageDimensions(clientLogo),
                ]);

                const desiredHeight = 66;
                const clientLogoScale = desiredHeight / clientLogoDimensions.height;
                const clientLogoWidth = clientLogoDimensions.width * clientLogoScale;
    
                const clientLogoId = this.workbook.addImage({
                    buffer: clientLogoBuffer,
                    extension: 'png',
                });
                this.worksheet.addImage(clientLogoId, {
                    tl: { col: 0, row: 0 },
                    ext: { width: clientLogoWidth, height: desiredHeight },
                    editAs: 'absolute'
                });
            }
        }
    
    }

    /**
   * Ajoute une section "détails" avec des informations (ex: nom, prénom, date de génération).
   * @param details Objet contenant des paires clé/valeur (ex: { Nom: 'Doe', Prénom: 'John' })
   * @param startRow Numéro de la première ligne de la section
   */
    public addDetails(details: string, startRow: number): void {
        const lastColLetter = ReportExcel.getColumnLetter(this.totalColumns);
        this.worksheet.mergeCells(`A${startRow}:${lastColLetter}${startRow + 1}`);
        const detailsCell = this.worksheet.getCell(`A${startRow}`);
        const detailsRow = this.worksheet.getRow(startRow);
        detailsCell.value = details.trim();
        detailsCell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
        detailsCell.font = { size: 12 };
        detailsRow.height =  ReportExcel.calculateAutoRowHeight(details);
        // Appliquer une bordure à la zone des détails
        for (let row = startRow; row <= startRow + 1; row++) {
            for (let col = 1; col <= this.totalColumns; col++) {
                const cell = this.worksheet.getCell(row, col);
                cell.border = {
                    top: { style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') } },
                    left: { style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') } },
                    bottom: { style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') } },
                    right: { style: 'thin', color: { argb: 'FF' + this.mainColor.replace('#', '') } },
                };
            }
        }
    }

    /**
   * Ajoute une section tableau avec entêtes et données.
   * @param columns Tableau de colonnes ExcelJS (avec header, key, width)
   * @param data Données à insérer (tableau d'objets)
   * @param row Numéro de la ligne à partir de laquelle insérer le tableau
   */
    public addTable<T,>(
        title: string,
        columns: IReportExcelRow<T>,
        data: T[],
        row: number,
        mergeRows?: {col: number, row: number, nbRows: number}[]
    ): number {
        const headerRow = true;
        const totalsRow = true;

        let firstRowData = row;
        if(headerRow) firstRowData++;
        const hasRowsToMerge = !isNullOrEmpty(mergeRows);

        this.worksheet.addTable({
            name: normalizeName(title),
            ref: `A${row}`,
            headerRow:headerRow,
            totalsRow: totalsRow,
            style: {
                theme: hasRowsToMerge ? 'TableStyleLight15' : 'TableStyleLight1',
                showRowStripes: hasRowsToMerge ? false : true,
            },
            columns: columns.map((c, idx) => {
                const col = this.worksheet.getColumn(idx+1);
                col.width = c.width ?? 20;
                const cell = this.worksheet.getCell(row, idx+1)
                cell.alignment = c.alignment ?? {};
                return c;                
            }),
            rows: data.map(item => columns.map((c) => c.render(item))),
        });

        const lastRowData = firstRowData + data.length;

        if(hasRowsToMerge) {
            mergeRows.forEach(({col, row, nbRows}) => {
                const colLetter = ReportExcel.getColumnLetter(col);
                const absoluteRow = firstRowData+row;
                const mergedRange = `${colLetter}${absoluteRow}:${colLetter}${absoluteRow+nbRows-1}`;
                this.worksheet.mergeCells(mergedRange);
            })
        }

        const endRow = lastRowData + (totalsRow ? 1: 0);
        for (let startRow = row; startRow <= endRow; startRow++) {
            for (let startCol = 1; startCol <= this.totalColumns; startCol++)
                this.worksheet.getCell(startRow, startCol).alignment = { wrapText: true, vertical: 'middle' };
        }

        return endRow;
    }

    public async addFooter(row:number) {
        const lastColLetter = ReportExcel.getColumnLetter(this.totalColumns);
        const mergedRange = `A${row}:${lastColLetter}${row}`;
        this.worksheet.mergeCells(mergedRange);

        const headerRow = this.worksheet.getRow(row);
        headerRow.height = 44;
        const headerCell = this.worksheet.getCell(`A${row}`);
        headerCell.value = {
            text: 'sunkhronos.ch',
            hyperlink: 'https://sunkhronos.ch/'
        };
        headerCell.alignment = { horizontal: 'right', vertical: 'middle' };
        headerCell.font = { size: 13, italic: true, color: { argb: 'FF8a8787' } };

        const [sunkhronosLogoBuffer] = await Promise.all([
            this.loadImageBuffer(SunkhronosFullLogo),
        ]);

        const [sunkhronosLogoDimensions] = await Promise.all([
            getImageDimensions(SunkhronosFullLogo),
        ]);

        const sunkhronosLogoHeight = 60;
        const sunkhronosLogoScale = sunkhronosLogoHeight / sunkhronosLogoDimensions.height;
        const sunkhronosLogoWidth = sunkhronosLogoDimensions.width * sunkhronosLogoScale;
    
        const sunkhronosLogoId = this.workbook.addImage({
            buffer: sunkhronosLogoBuffer,
            extension: 'png',
        });
        
        this.worksheet.addImage(sunkhronosLogoId, {
            tl: { col: 0, row: row-1 },
            ext: { width: sunkhronosLogoWidth, height: sunkhronosLogoHeight },
            editAs: 'oneCell'
        });
    }

    /**
   * Génère le fichier Excel en combinant les trois sections.
   * @param options Paramètres nécessaires à la génération
   * @returns Buffer du fichier Excel généré
   */
    public async generateExcel<T,>(
        title: string,
        columns: IReportExcelRow<T>,
        data: T[],
        logo?: string,
        details?: string,
        mergeRows?: {col: number, row: number, nbRows: number}[]
    ): Promise<Buffer> {
        let row = 1;
        // 1. En-tête (ligne 1)
        await this.addHeader(logo ? false : true, row, title, logo);
        row += 4;

        // 2. Section détails (exemple sur les lignes 3 à 5)
        if(details) {
            this.addDetails(details, row);
            row += 3;
        }

        // 3. Tableau des données (à partir de la ligne 11)
        row = this.addTable(title, columns, data, row, mergeRows);
        row+=1;

        await this.addFooter(row);

        // Définir la configuration de l'impression
        this.worksheet.pageSetup = {
            paperSize: 9,       // 9 correspond à A4
            orientation: 'portrait',
            fitToPage: true,
            fitToWidth: 1,
            fitToHeight: 0,
            printArea: `A0:${ReportExcel.getColumnLetter(this.totalColumns)}${row}}`,
            horizontalCentered: true,
            margins: {
                left: 0.7, right: 0.7,
                top: 0.75, bottom: 0.75,
                header: 0.4, footer: 0.4
            }
        };
        
        const excelBuffer = await this.workbook.xlsx.writeBuffer();
        return Buffer.from(excelBuffer);
    }
}
