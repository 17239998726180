import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import { ECodePosition } from '../../utils/types/constants';
import { PlanningTemplate } from '../../utils/types/planningTypes';
import { PlanningState, StoreAction } from '../../utils/types/storeTypes';

export const PLANNING_INIT_STATE: PlanningState = {
    userRows: [],
    preloadUserRows: undefined,
    loadingPrevPlanning: false,
    loadingNowPlanning: false,
    loadingNextPlanning: false,
    loadingPlanning: false,
    loadingSettings: false,
    selectGroups: undefined,
    selectUsers: undefined,
    displayWorkingTime: true,
    templates: [],
    settings: {
        updated: undefined,
        codePosition: ECodePosition.HIDDEN
    },
    cellsPerRow: undefined,
    draggedGroupEventUserId: undefined,
    draggedUserEventUserId: undefined,
    teamAvailabilities: [],
    loadingAvailabilities: false,
    // shared methods
    onClickEvent: undefined,
    onEditEvent: undefined,
    onDeleteEvent: undefined,
    onEditOvertime: undefined,
    onDeleteOvertime: undefined,
};

export const TOGGLE_LOADING_PLANNING = ('TOGGLE_LOADING_PLANNING');
export const CHANGE_USER_ROWS = ('CHANGE_USER_ROWS');
export const CHANGE_PREV_USER_ROWS = ('CHANGE_PREV_USER_ROWS');
export const CHANGE_NOW_USER_ROWS = ('CHANGE_NOW_USER_ROWS');
export const CHANGE_NEXT_USER_ROWS = ('CHANGE_NEXT_USER_ROWS');
export const CHANGE_PREV_LOADING = ('CHANGE_PREV_LOADING');
export const CHANGE_NOW_LOADING = ('CHANGE_NOW_LOADING');
export const CHANGE_NEXT_LOADING = ('CHANGE_NEXT_LOADING');
export const CHANGE_SELECT_GROUPS = ('CHANGE_SELECT_GROUPS');
export const CHANGE_SELECT_USERS = ('CHANGE_SELECT_USERS');
export const TOGGLE_DISPLAY_WORKING_TIME = ('TOGGLE_DISPLAY_WORKING_TIME');
export const CHANGE_TEMPLATES = ('CHANGE_TEMPLATES');
export const UPDATE_TEMPLATES = ('UPDATE_TEMPLATES');
export const CHANGE_SETTINGS = ('CHANGE_SETTINGS');
export const TOGGLE_LOADING_SETTINGS = ('TOGGLE_LOADING_SETTINGS');
export const CHANGE_REPORT_SETTINGS = ('CHANGE_REPORT_SETTINGS');
export const CHANGE_CELLS_PER_ROW = ('CHANGE_CELLS_PER_ROW');
export const CHANGE_DRAGGED_GROUP_EVENT_USER_ID = ('CHANGE_DRAGGED_GROUP_EVENT_USER_ID');
export const CHANGE_DRAGGED_USER_EVENT_USER_ID = ('CHANGE_DRAGGED_USER_EVENT_USER_ID');
export const CHANGE_USER_AVAILABILITIES = ('CHANGE_USER_AVAILABILITIES');
export const CHANGE_TEAM_AVAILABILITIES = ('CHANGE_TEAM_AVAILABILITIES');
export const SET_LOADING_USER_AVAILABILITIES = ('SET_LOADING_USER_AVAILABILITIES');
export const SET_EVENT_METHODS = ('SET_EVENT_METHODS');
export const RESET = ('RESET_PLANNING');

const planning = (state: PlanningState = PLANNING_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case TOGGLE_LOADING_PLANNING:
            return {
                ...state,
                loadingPlanning: action.data,
            }
        case TOGGLE_LOADING_SETTINGS:
            return{
                ...state,
                loadingSettings: action.data,
            }
        case CHANGE_USER_ROWS:
            return {
                ...state,
                userRows: cloneDeep(action.data),
            }
        case CHANGE_PREV_USER_ROWS: {
            let preloadUserRowsPrev = cloneDeep(state.preloadUserRows);
            if (preloadUserRowsPrev === undefined) {
                preloadUserRowsPrev = {
                    prev: {
                        month: moment().subtract(1, "month"),
                        data: []
                    },
                    now: {
                        month: moment(),
                        data: []
                    },
                    next: {
                        month: moment().add(1, "month"),
                        data: []
                    },
                };
            }

            preloadUserRowsPrev.prev.month = moment().subtract(1, "month");
            preloadUserRowsPrev.prev.data = cloneDeep(action.data);
            return {
                ...state,
                preloadUserRows: preloadUserRowsPrev,
            }
        }
        case CHANGE_NOW_USER_ROWS: {
            let preloadUserRowsNow = cloneDeep(state.preloadUserRows);
            if (preloadUserRowsNow === undefined) {
                preloadUserRowsNow = {
                    prev: {
                        month: moment().subtract(1, "month"),
                        data: []
                    },
                    now: {
                        month: moment(),
                        data: []
                    },
                    next: {
                        month: moment().add(1, "month"),
                        data: []
                    },
                };
            }

            preloadUserRowsNow.now.month = moment();
            preloadUserRowsNow.now.data = cloneDeep(action.data);
            return {
                ...state,
                preloadUserRows: preloadUserRowsNow,
            }
        }
        case CHANGE_NEXT_USER_ROWS: {
            let preloadUserRowsNext = cloneDeep(state.preloadUserRows);
            if (preloadUserRowsNext === undefined) {
                preloadUserRowsNext = {
                    prev: {
                        month: moment().subtract(1, "month"),
                        data: []
                    },
                    now: {
                        month: moment(),
                        data: []
                    },
                    next: {
                        month: moment().add(1, "month"),
                        data: []
                    },
                };
            }

            preloadUserRowsNext.next.month = moment().add(1, "month");
            preloadUserRowsNext.next.data = cloneDeep(action.data);
            return {
                ...state,
                preloadUserRows: preloadUserRowsNext,
            }
        }
        case CHANGE_PREV_LOADING:
            return {
                ...state,
                loadingPrevPlanning: action.data,
            }
        case CHANGE_NOW_LOADING:
            return {
                ...state,
                loadingNowPlanning: action.data,
            }
        case CHANGE_NEXT_LOADING:
            return {
                ...state,
                loadingNextPlanning: action.data,
            }
        case CHANGE_SELECT_GROUPS:
            return {
                ...state,
                selectGroups: action.data,
            }
        case CHANGE_SELECT_USERS:
            return {
                ...state,
                selectUsers: action.data,
            }
        case TOGGLE_DISPLAY_WORKING_TIME:
            return {
                ...state,
                displayWorkingTime: !state.displayWorkingTime,
            };
        case CHANGE_TEMPLATES:
            return {
                ...state,
                templates: action.data,
            }
        case UPDATE_TEMPLATES: {            
            const templates = state.templates ? cloneDeep(state.templates): [];

            (action.data as PlanningTemplate[]).forEach(template => {
                const foundIndex = templates?.findIndex(t => t.id === template.id);
                if(foundIndex !== -1) templates[foundIndex] = template;
                else templates.push(template);             
            });

            return {
                ...state,
                templates,
            }
        }
        case CHANGE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...action.data,
                    updated: moment()
                },
                loadingSettings: false
            };
        case CHANGE_REPORT_SETTINGS:
            return {
                ...state,
                reportSettings: action.data,
            };
        case CHANGE_CELLS_PER_ROW:
            return {
                ...state,
                cellsPerRow: action.data,
            }
        case CHANGE_DRAGGED_GROUP_EVENT_USER_ID:
            return {
                ...state,
                draggedGroupEventUserId: action.data,
            }
        case CHANGE_DRAGGED_USER_EVENT_USER_ID:
            return {
                ...state,
                draggedUserEventUserId: action.data,
            };
        case CHANGE_TEAM_AVAILABILITIES:
            if (!action.forceReload && isEqual(state.teamAvailabilities, action.data)) {
                return state;
            } else {
                return {
                    ...state,
                    teamAvailabilities: cloneDeep(action.data)
                };
            }
        case CHANGE_USER_AVAILABILITIES: {
            const teamAvailabilities = cloneDeep(state.teamAvailabilities);
            const userId = action.data.userId;
            const newUserAvailabilities = {
                userId: userId,
                updated: moment(),
                data: action.data.userAvailabilities
            }
            const userAvailabilitiesId = teamAvailabilities.findIndex(ta => ta.userId === userId);

            if (userAvailabilitiesId === -1) {
                teamAvailabilities.push(newUserAvailabilities);
                return {
                    ...state,
                    teamAvailabilities: teamAvailabilities
                };
            } else if (!action.forceReload && isEqual(teamAvailabilities[userAvailabilitiesId].data, newUserAvailabilities.data)) {
                return state;
            } else {
                teamAvailabilities[userAvailabilitiesId] = newUserAvailabilities;
                return {
                    ...state,
                    teamAvailabilities: teamAvailabilities
                };
            }
        }
        case SET_LOADING_USER_AVAILABILITIES:
            return {
                ...state,
                loadingAvailabilities: action.data
            }
        case SET_EVENT_METHODS:
            return {
                ...state,
                onClickEvent: action.data.onClickEvent,
                onEditEvent: action.data.onEditEvent,
                onDeleteEvent: action.data.onDeleteEvent,
                onEditOvertime: action.data.onEditOvertime,
                onDeleteOvertime: action.data.onDeleteOvertime,
            }
        case RESET:
            return PLANNING_INIT_STATE;
        default:
            return state;
    }
};

export default planning;