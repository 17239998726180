import { Space, Tabs } from 'antd';
import Container from '../common/navigations/container';
//import MonthlyPlanning from './monthly/monthlyPlanning';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../../utils/types/storeTypes';
import FAIcon from '../common/FAIcon';
import Colors from './tabs/colors';
import OccupancyRate from './tabs/occupancyRate';
import PlanningPerf from './tabs/planningPerf';
import Template from './tabs/template';


export const Planning = () => {
    const history = useHistory();
    const { lang, tab } = useParams<{ tab?: string, lang?: string; }>();
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const fullscreen = useSelector((state: ApplicationState) => state.window.fullscreen);

    const intl = useIntl();

    const goTo = useCallback((link: string): void => history.push(`/${lang}/${link}`), [history, lang]);

    const onChangeTab = useCallback((tabStr: string): void => {
        if (tabStr === 'planning') {
            tabStr = 'view';
        }
        goTo('planning-v2/' + tabStr);
    }, [goTo]);

    const getCurrentTab = useCallback(() => {
        switch (tab) {
            case 'view':
            case 'monthly':
            case 'weekly':
                return 'planning';
            case 'requirements':
            case 'templates':
            case 'colors':
                return tab;
            default:
                goTo('planning-v2/view');
        }
        goTo('planning-v2/view');
    }, [tab, goTo]);

    const items = useMemo(() => [
        {
            label: (
                <Space>
                    <FAIcon prefix='fad' name='calendar-days' />
                    <FormattedMessage defaultMessage={'Planning'} />
                </Space>
            ),
            key: 'planning',
            children: <PlanningPerf key={'planningComponent'} />
        },
        ...([2, 3].includes(currentUser ? currentUser.role : 1) ? [
            {
                label: (
                    <Space>
                        <FAIcon prefix='fad' name='clipboard-list' />
                        <FormattedMessage defaultMessage={'Requirements'} />
                    </Space>
                ),
                key: 'requirements',
                children: <OccupancyRate key={'requirementsComponent'} />
            },
            {
                label: (
                    <Space>
                        <FAIcon prefix='fad' name='layer-group' />
                        <FormattedMessage defaultMessage={'Templates'} />
                    </Space>
                ),
                key: 'templates',
                children: <Template key={'templatesComponent'} />
            },
            {
                label: (
                    <Space>
                        <FAIcon prefix='fad' name='palette' />

                        <FormattedMessage defaultMessage={'Colors'} />
                    </Space>
                ),
                key: 'colors',
                children: <Colors key={'colorsComponent'} />
            }
        ]
            :
            []),

    ], [currentUser]);

    return (
        fullscreen ?
            <>{items.find(i => i.key === getCurrentTab())?.children}</>
            :
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Planning' }), link: "/planning" }]}>
                <Tabs animated activeKey={getCurrentTab()} onChange={onChangeTab} items={items} />
            </Container>
    );
};

export default Planning;
