import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReloadTimes } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import FAIcon from '../FAIcon';


interface Props {
    title: ReactNode;
    percentage: number;
    lastSync?: string;
    onClick: () => void;
}

const IntegrationSync = (props: Props) => {
    return (
        <Tooltip
            placement='bottom'
            title={
                <div>
                    <p style={{ marginRight: "5px", fontSize: "95%" }}>{props.title}</p>
                    <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}>
                        {
                            props.lastSync ?
                                <FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: moment(props.lastSync)?.format(getFormat('DATE_AND_ON_TIME')) }} />
                                :
                                <FormattedMessage defaultMessage={'Never synced'} />
                        }
                    </p>
                    <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic", marginTop: '5px' }}>
                        {
                            props.lastSync &&
                            <FormattedMessage defaultMessage={'Next sync available on {date}'} values={{ date: moment(props.lastSync).add(ReloadTimes.SPEED_RELOAD, 'minutes')?.format(getFormat('DATE_AND_ON_TIME')) }} />
                        }
                    </p>
                </div>
            }>
            <Button style={{ height: 30 }} className={`integration-button${props.percentage !== 100 ? ' loading' : ''}`} size={'middle'} type={'primary'} onClick={props.onClick}>
                <div className='integration-button-loading' style={{ width: `${props.percentage}%`, visibility: props.percentage === 100 ? 'hidden' : 'visible' }} />
                {/* <div className='integration-button-loading-icon' style={{visibility: props.percentage === 100 ? 'hidden' : 'visible'}}><FAIcon prefix='fas' name='spinner-third' spin /></div> */}
                <div className='integration-button-content'>
                    <FAIcon prefix='fad' name='arrow-right-arrow-left' />
                    {/* <div style={{fontSize: '14px'}} className='integration-button-title'>{props.title}</div> */}
                </div>
            </Button>
        </Tooltip>
    );
};

export default IntegrationSync;
