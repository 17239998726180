import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Collapse, Divider, Drawer, InputNumber, Popconfirm, Segmented, Select, Spin, Tag, Tooltip } from 'antd';
import toFinite from 'lodash/toFinite';
import * as momentJs from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import React, { CSSProperties, ReactElement, useMemo } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Rules } from '../../../../rbacRules';
import { EventDataType, PlanningEventOwner } from '../../../../utils/enumerations';
import Network from '../../../../utils/network';
import { DateRange, DateRangeString, DictionaryNumber, DictionaryString, GenericFile, StaffType, User, ValidateFile } from '../../../../utils/types/generalTypes';
import { BreakTime, EventCanBeCreatedOrEditedBody, EventCopyErrorCodes, EventCreateError, PlanningEffectivePeriod, PlanningEvent, PlanningTemplate, Reminder } from '../../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { alert, checkRBACRule, convertNetworkEffectivePeriodsToPlanningEffectivePeriods, convertNetworkReminderToReminder, convertNetworkTemplateToPlanningTemplate, generateNegativeUniqueId, isNullOrEmpty, openGoogleMapsRoute, showNotification } from '../../../../utils/utils';
import { InputFieldOnChangeEvent } from '../../../common/fields/inputField';
// import CircleButton from '../../common/fields/circleButton';
// import { RiPinDistanceFill } from 'react-icons/ri';
import { captureMessage } from '@sentry/react';
import { SegmentedValue } from 'antd/lib/segmented';
import { isArray } from 'lodash';
import isEqual from 'react-fast-compare';
import { RiPinDistanceFill } from 'react-icons/ri';
import { defineMessages, FormattedMessage, injectIntl, IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import { Editor, EditorProvider, Separator, Toolbar } from 'react-simple-wysiwyg';
import rfdc from 'rfdc';
import { useFullName } from '../../../../hooks/useUsers';
import KilometerIcon from '../../../../images/svg/km.svg';
import { loadTypesOfVehicle } from '../../../../store/actions/configurations';
import { toggleLoading } from '../../../../store/actions/user';
import { selectActiveUsers } from '../../../../store/selectors/usersSelectors';
import { MOMENT_FORMAT_TO_NETWORK, PASTEL_RED_COLOR } from '../../../../utils/constants';
import { ProjectNameProps, withProjectName } from '../../../../utils/customIntl/withProjectName';
import getFormat from '../../../../utils/Lang';
import { FullUserProps, withFullName } from '../../../../utils/objects/withFullName';
import { IMissionRule, IMissionRuleType } from '../../../../utils/types/customerTypes';
import { NetworkReminder } from '../../../../utils/types/networkTypes';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';
import ColorChooser from '../../../common/fields/colorChooser';
import Input from '../../../common/fields/input';
import TimeRangePickerWrapper from '../../../common/fields/TimeRangePickerWrapper/TimeRangePickerWrapper';
import Anticon from '../../../common/general/anticon';
import Fieldset from '../../../common/general/fieldset';
import FullUser, { UserSmall } from '../../../common/general/fullUser';
import SpaceContent from '../../../common/general/spaceContent';
import { CreationCallback } from '../../../planningNew/tabs/planning';
import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnNumberedList, BtnRedo, BtnUnderline, BtnUndo } from './../../../../components/common/general/Editor/editorButtons';
import './Clock.css';
import EditBreaktime, { findOverlappingBreakTimes, hasBreaktimeOverlap } from './editBreaktime';
import { IEventRecurring, stringToRrule } from './eventRecurring/editRecurring';
import EditRecurringModal from './eventRecurring/editRecurringModal';
import ReminderModal, { automaticDurationTypeSelection } from './reminderModal';
import UploadModal from './uploadModal';
const cloneDeep = rfdc({ proto: true });
type ValuePiece = Date | string | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const MAX_SECONDS = 86400 - 1;

const moment = extendMoment(momentJs);

export interface TemplateFileCallback {
    (files: GenericFile[] | undefined): void;
}
const TypeOfEventIntl = defineMessages({
    work: { defaultMessage: 'Type of work' },
    off: { defaultMessage: 'Day off' },
});

const MAX_USERS = 25;

const UserProblemIntl = defineMessages({
    incompatibleAptitude: { defaultMessage: 'Incompatible aptitude' },
    incompatiblePOI: { defaultMessage: 'Incompatible POI' },
    unavailable: { defaultMessage: 'Unavailable' },
    overlap: { defaultMessage: 'Overlap' },
    locked: { defaultMessage: 'Locked' },
});

const enum TypeOfEvent {
    WORK = 0,
    OFF = 1,
}

interface AvailableUsers extends User {
    missingHability?: boolean;
    missingLocation?: boolean;
    unavailable?: boolean;
    unavailableDates?: string[];
    overlap?: boolean;
    overlapDates?: DateRangeString[];
    locked?: boolean;
    lockedDates?: string[];
    problems?: MessageDescriptor[];
}

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, FullUserProps, ProjectNameProps {
    currentEvent: PlanningEvent;
    canEditStartDate: boolean;
    onEdit: (errors: EventCreateError[], event: PlanningEvent, sendMessage?: boolean, forceUpdate?: boolean, skipCheckAvailability?: boolean) => void;
    onCreate: (errors: EventCreateError[], event: PlanningEvent, userIds: number[], sendMessage?: boolean, asTemplate?: boolean, forceCreate?: boolean, skipCheckAvailability?: boolean, callback?: CreationCallback, dates?: DateRange[]) => void;
    onCancel: () => void;
    loading: boolean;
    openSettings: () => void;
    modalKey: string;
    loadFileFromTemplate?: number;
}

interface State {
    initCurrentEvent: PlanningEvent;
    userIds: number[];
    currentTemplate: PlanningTemplate | undefined;
    currentEvent: PlanningEvent;
    untilShowTime: boolean;
    periods: PlanningEffectivePeriod[];
    asTemplate: boolean;
    sendMessage: boolean;
    isCreate: boolean;
    editBreakTimeError: boolean;
    staffTypes?: StaffType[];

    loadingDistance: boolean;
    distanceDuration?: number;

    editReminder?: Reminder;
    newReminderIndex: number;
    addReminder: boolean;
    color: string;
    openFilter: boolean;


    availableUserIds?: DictionaryString<number[]>
    overlapUserIds?: DictionaryNumber<DateRangeString[]>;
    lockedDaysUserIds?: DictionaryNumber<string[]>;
    availableUsersLoading: boolean;
    popConfirmAvailabilityError: boolean;

    addDocuments: boolean;
    addImages: boolean;
    files: GenericFile[];
    loadingFiles: boolean;
    filesToValidate: ValidateFile[];
    imagesToValidate: ValidateFile[];

    loadingFromTemplate: boolean;

    forceCreate: boolean;
    createErrors: EventCreateError[];
    typeOfEvent: TypeOfEvent;

    missionRule?: IMissionRule;
    eventRecurring?: IEventRecurring;
    editEventRecurring?: IEventRecurring;

    hasError: boolean;
    checkBeforeLoading: boolean;
    overlappingBreakTimes: DictionaryNumber<number[]>;
    breaktimeHoveredId?: number;
}

class ModifyEvent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const userIds = this.props.currentEvent.userId ? [this.props.currentEvent.userId] : []

        this.state = {
            newReminderIndex: -1,
            filesToValidate: [],
            imagesToValidate: [],
            files: [],
            loadingFiles: false,
            addDocuments: false,
            addImages: false,
            addReminder: false,
            color: (this.props.currentEvent.color && this.props.currentEvent.color.color) ? this.props.currentEvent.color?.color : 'var(--primary-color)',
            openFilter: false,
            initCurrentEvent: cloneDeep(this.props.currentEvent),
            userIds: userIds,
            currentTemplate: undefined,
            currentEvent: cloneDeep(this.props.currentEvent),
            untilShowTime: true,
            periods: [],
            asTemplate: false,
            sendMessage: false,
            isCreate: !this.props.currentEvent.id,
            editBreakTimeError: false,
            loadingDistance: false,
            availableUsersLoading: false,
            popConfirmAvailabilityError: false,
            loadingFromTemplate: false,
            forceCreate: false,
            createErrors: [],
            typeOfEvent: this.props.currentEvent.typeOfDayOff ? TypeOfEvent.OFF : TypeOfEvent.WORK,
            hasError: this.checkHasError(this.props.currentEvent, userIds),
            checkBeforeLoading: false,
            overlappingBreakTimes: findOverlappingBreakTimes(this.props.currentEvent.breakTimes ?? [])
        };
    }

    componentDidMount() {
        if (this.state.currentEvent.owner === PlanningEventOwner.User && this.state.userIds.length > 0) this.getUserEffectivePeriods(this.state.currentEvent.userId!);
        this.loadUsersAvailable();
        this.loadEventFiles();
        if (isNullOrEmpty(this.props.vehicles.data)) this.props.loadTypesOfVehicle();

        const ruleId = this.state.currentEvent.ruleId;
        if (ruleId) {
            // Load mission rule and set recurrence
            Network.getMissionRule(ruleId).then(
                response => {
                    if (!response.error) {
                        const missionRule = response.data;
                        if (missionRule.type === IMissionRuleType.RECCURENT)
                            this.setState({ missionRule: response.data });
                    }
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred' }), "error")
            );
        }

        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the skills' }), "error")
        );

        if (this.props.loadFileFromTemplate !== undefined) {
            this.getTemplateFiles(this.props.loadFileFromTemplate, async (files) => {
                if (files) {
                    const validateFiles = await Promise.all(files.map(async f => this.generateFilesToValidateFromTemplate(f)));
                    const filesToValidate = validateFiles.filter(f => f.res_type === 'FLE');
                    const imagesToValidate = validateFiles.filter(f => f.res_type === 'IMG');
                    this.setState({ filesToValidate, imagesToValidate });
                } else {
                    this.setState({ filesToValidate: [], imagesToValidate: [] });
                }
            });
        }

        // focus on the title input
        document.getElementById('planning-title-input')?.focus();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // if the current event color have been updated in settings, update it in current event
        const { currentEvent } = this.state;
        if (isEqual(prevProps.settings, this.props.settings) && !isNullOrEmpty(this.props.settings.colors) && currentEvent.color) {
            const color = this.props.settings.colors.find(c => c.id === currentEvent.color?.id);
            if (!color) {
                this.setState(prevState => ({ currentEvent: { ...prevState.currentEvent, color: undefined } }));
            } else if (color.id !== currentEvent.color.id) {
                this.setState(prevState => ({ currentEvent: { ...prevState.currentEvent, color } }));
            }
        }

        if (
            !isEqual(prevState.currentEvent, this.state.currentEvent) ||
            !isEqual(prevState.userIds, this.state.userIds)
        ) {
            const hasError = this.checkHasError(this.state.currentEvent, this.state.userIds);
            if (this.state.hasError !== hasError) this.setState({ hasError });
        }


        if (!isEqual(prevState.currentEvent.breakTimes, this.state.currentEvent.breakTimes)) {
            const overlappingBreakTimes = findOverlappingBreakTimes(this.state.currentEvent.breakTimes ?? []);
            if (!isEqual(overlappingBreakTimes, this.state.overlappingBreakTimes)) {
                this.setState({ overlappingBreakTimes });
            }
        }

        if (
            !prevState.currentEvent.startDate.isSame(this.state.currentEvent.startDate, "seconds") ||
            !prevState.currentEvent.endDate.isSame(this.state.currentEvent.endDate, "seconds") ||
            !isEqual(prevState.eventRecurring, this.state.eventRecurring)
        ) {

            this.loadUsersAvailable()
        }

    }

    checkHasError = (currentEvent: PlanningEvent, userIds: number[]) => {
        if (
            currentEvent.title.length < 1 ||
            currentEvent.startDate.isAfter(currentEvent.endDate) ||
            currentEvent.endDate.diff(currentEvent.startDate, "seconds") > MAX_SECONDS ||
            userIds.length === 0 ||
            currentEvent.breakTimes?.some((breaktime) => breaktime.title.length < 1 || breaktime.startDate.isAfter(breaktime.endDate) || breaktime.startDate.isBefore(currentEvent.startDate) || breaktime.endDate.isAfter(currentEvent.endDate)) ||
            hasBreaktimeOverlap(currentEvent.breakTimes)
        ) {
            return true;
        }
        return false;
    }


    removeEventReminder = (reminder: Reminder) => {
        if (reminder) {
            if (reminder && this.state.currentEvent.id) {
                Network.removeEventReminder(reminder.id, this.state.currentEvent.id).then(
                    (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                        if (response.error === false) {
                            const curEvent = this.state.currentEvent;
                            curEvent.reminders = curEvent.reminders?.filter(r => r.id !== response.data.id);

                            this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
                        } else {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the reminder' }), "warning");
                        }
                    },
                    () => {
                        this.setState({ loadingFiles: false });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the reminder' }), "warning");
                    }
                );
            } else {
                const curEvent = this.state.currentEvent;
                curEvent.reminders = curEvent.reminders?.filter(r => r.id !== reminder.id);

                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
            }
        }
    };

    saveEventReminder = (reminder: Reminder) => {
        const { intl } = this.props;
        if (reminder) {
            if (this.state.currentEvent.id) {
                if (reminder.id > 0 && reminder.id !== undefined) {
                    Network.updateEventReminder(reminder.id, this.state.currentEvent.id, reminder.minBeforeEventStart).then(
                        (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                            if (response.error === false) {
                                const reminder = convertNetworkReminderToReminder(response.data);
                                const curEvent = this.state.currentEvent;
                                const findReminderId = curEvent.reminders?.findIndex(r => r.id === response.data.id);
                                if (findReminderId === undefined || findReminderId < 0) {
                                    curEvent.reminders?.push(reminder);
                                } else {
                                    if (!curEvent.reminders) {
                                        curEvent.reminders = [reminder];
                                    } else {
                                        curEvent.reminders[findReminderId] = reminder;
                                    }
                                }
                                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
                            } else {
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the reminder' }), "warning");
                            }
                        },
                        () => {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the reminder' }), "warning");
                        }
                    );
                } else {
                    Network.addEventReminder(this.state.currentEvent.id, reminder.minBeforeEventStart).then(
                        (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                            if (response.error === false) {
                                const reminder = convertNetworkReminderToReminder(response.data);
                                const curEvent = this.state.currentEvent;
                                if (!curEvent.reminders) {
                                    curEvent.reminders = [reminder];
                                } else {
                                    curEvent.reminders.push(reminder);
                                }
                                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
                            } else {
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the reminder' }), "warning");
                            }
                        },
                        () => {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the reminder' }), "warning");
                        }
                    );
                }
            } else {
                const curEvent = this.state.currentEvent;
                const findReminderId = (reminder !== undefined && reminder.id !== undefined) ? curEvent.reminders?.findIndex(r => r.id === reminder.id) : -1;
                if (findReminderId === undefined || findReminderId < 0) {
                    if (curEvent.reminders) {
                        curEvent.reminders.push(reminder);
                    } else {
                        curEvent.reminders = [reminder];
                    }
                } else {
                    if (!curEvent.reminders) {
                        curEvent.reminders = [reminder];
                    } else {
                        curEvent.reminders[findReminderId] = reminder;
                    }
                }

                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
            }

        }

    };

    loadEventFiles = () => {
        if (!this.state.loadingFiles) {
            this.setState({ loadingFiles: true }, () => {
                if (this.state.currentEvent && this.state.currentEvent.id) {
                    Network.getEventFiles(this.state.currentEvent.id).then(
                        (response) => {
                            if (response.error === false && response.data !== undefined) {
                                this.setState({ loadingFiles: false, files: response.data });
                            } else {
                                this.setState({ loadingFiles: false });
                            }
                        },
                        () => {
                            this.setState({ loadingFiles: false });
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                        }
                    );
                } else {
                    this.setState({ loadingFiles: false });
                }

            });

        }
    };

    loadUsersAvailable = () => {
        const { availableUsersLoading, currentEvent, eventRecurring } = this.state;
        if (!availableUsersLoading) {
            this.setState({ availableUsersLoading: true }, () => {
                const eventIds = currentEvent.id && currentEvent.id > 0 ? [currentEvent.id] : undefined;
                Network.getAvailableUser(this.getAllDates(currentEvent.startDate, currentEvent.endDate, eventRecurring), eventIds).then(
                    (response) => {
                        this.setState({ availableUserIds: response.data.availableUserIds, overlapUserIds: response.data.overlapUserIds, lockedDaysUserIds: response.data.lockedDaysUserIds, availableUsersLoading: false });
                    },
                    () => {
                        this.setState({ availableUsersLoading: false });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the availabilities' }), "warning");
                    }
                );
            });

        }
    };

    getUserEffectivePeriods = (userId: number): void => {
        Network.getEffectivePeriods(userId).then(
            response => this.setState({ periods: convertNetworkEffectivePeriodsToPlanningEffectivePeriods(response) }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning")
        );
    };

    getTemplateFiles = (templateId: number, callback: TemplateFileCallback) => {
        if (templateId) {
            Network.getTemplateFiles(templateId, true).then(
                (response) => {
                    if (response.error === false && response.data !== undefined) {
                        callback(response.data);
                    } else {
                        callback(undefined);
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                    }
                },
                () => {
                    callback(undefined);
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                }
            );
        } else {
            callback(undefined);
        }
    };

    generateFilesToValidateFromTemplate = async (f: GenericFile) => {
        let file = undefined;
        if (f.res_type === 'IMG') {
            const blob = await fetch(f.auth_url).then(r => r.blob());
            file = new File([blob], f.file_name);
        } else {
            file = new File([], f.file_name);
        }

        return ({ fileId: f.id, index: f.id, file_name: f.file_name, res_type: f.res_type, expiryDate: f.expiry_date, type: "ValidateFile", orgiginalFile: file } as ValidateFile);

    };

    loadTemplate = (templateId?: number) => {
        if (templateId !== undefined) {
            this.setState({ loadingFromTemplate: true });
            Network.getTemplate(templateId).then(
                (response) => {
                    if (response.error) {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template to load' }), "error");
                    } else {
                        const template = convertNetworkTemplateToPlanningTemplate(response.data);

                        this.getTemplateFiles(templateId, async (files) => {
                            if (files) {
                                const validateFiles = await Promise.all(files.map(async f => this.generateFilesToValidateFromTemplate(f)));
                                const filesToValidate = validateFiles.filter(f => f.res_type === 'FLE');
                                const imagesToValidate = validateFiles.filter(f => f.res_type === 'IMG');
                                this.setState({ filesToValidate, imagesToValidate });
                            } else {
                                this.setState({ filesToValidate: [], imagesToValidate: [] });
                            }
                        });
                        let currentEvent = cloneDeep(this.state.currentEvent);
                        const startDate = currentEvent.startDate.clone();

                        //clone event
                        const diffSeconds = template.endDate.diff(template.startDate, 'seconds');
                        currentEvent = cloneDeep(template);
                        currentEvent.id = undefined;
                        currentEvent.isDraggable = true;

                        currentEvent.startDate = startDate.clone().hours(template.startDate.hours()).minutes(template.startDate.minutes()).seconds(template.startDate.seconds());
                        currentEvent.endDate = currentEvent.startDate.clone().add(diffSeconds, 'seconds');

                        if (template.breakTimes && template.breakTimes.length > 0) {
                            let breakTimes: BreakTime[] = [];
                            let breaktimeTmp: BreakTime;
                            template.breakTimes?.forEach(b => {
                                breaktimeTmp = cloneDeep(b);

                                breaktimeTmp.startDate = currentEvent.startDate.clone().add(breaktimeTmp.startDate.diff(template.startDate, "seconds"), "seconds");
                                breaktimeTmp.endDate = currentEvent.startDate.clone().add(breaktimeTmp.endDate.diff(template.startDate, "seconds"), "seconds");

                                breakTimes.push(breaktimeTmp);
                            });

                            breakTimes = breakTimes.sort((a, b) => a.startDate.diff(b.startDate));

                            currentEvent.breakTimes = breakTimes;
                        }

                        this.setState({ currentEvent, currentTemplate: template, sendMessage: template.sendMessage === undefined ? false : template.sendMessage });
                    }
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template to edit' }), "error");
                }
            ).finally(() => this.setState({ loadingFromTemplate: false }));

        } else {
            this.setState({ currentEvent: this.state.initCurrentEvent, currentTemplate: undefined });

            templateId !== undefined && showNotification(this.props.intl.formatMessage({ defaultMessage: 'The template can not be found' }), "error");
        }
    };

    dataChanged = (type: EventDataType, value: any) => {
        const currentEvent = cloneDeep(this.state.currentEvent);

        switch (type) {
            case EventDataType.Title:
                currentEvent.title = value.target.value;
                break;
            case EventDataType.Description:
                currentEvent.description = value.target.value;
                break;
            case EventDataType.UserId:
                if (this.state.isCreate) {
                    this.setState({ userIds: value });
                    if (value.length === 1) this.getUserEffectivePeriods(value[0]);
                    return;
                } else {
                    currentEvent.userId = value;
                    if (value) this.getUserEffectivePeriods(value);
                }
                break;
            case EventDataType.Range:
                currentEvent.startDate = moment(value[0]);
                currentEvent.endDate = moment(value[1]);
                if (currentEvent.endDate.isBefore(currentEvent.endDate, "second")) {
                    currentEvent.endDate = currentEvent.startDate.clone().add(1, "hour");
                }
                break;
            case EventDataType.IsDraggable:
                currentEvent.isDraggable = value;
                break;
            case EventDataType.Color:
                currentEvent.color = this.props.settings.colors?.find(c => c.id === value);
                break;
            case EventDataType.AsTemplate:
                this.setState({ asTemplate: value });
                break;
            case EventDataType.SendMessage:
                this.setState({ sendMessage: value });
                break;
            case EventDataType.TypeOfDay:
                currentEvent.typeOfDay = this.props.typesOfDay?.find(t => t.id === value);
                if (currentEvent.typeOfDay != undefined) {
                    currentEvent.typeOfDayOff = undefined;
                }
                break;
            case EventDataType.TypeOfDayOff:
                currentEvent.typeOfDayOff = this.props.typesOfDayOff?.find(t => t.id === value);
                if (currentEvent.typeOfDayOff !== undefined) {
                    currentEvent.typeOfDay = undefined;
                }
                break;
            case EventDataType.IgnoreTimeClock:
                currentEvent.ignoreTimeClock = value;
                break;
            case EventDataType.StaffType:
                currentEvent.staffType = this.state.staffTypes?.find(t => t.id === value);
                break;
            case EventDataType.Poi:
                currentEvent.poi = this.props.pois?.data?.find(t => t.id === value);
                if (currentEvent.poi === undefined) {
                    currentEvent.distance = undefined;
                }
                break;
            case EventDataType.Department:
                currentEvent.department = this.props.departments.data.find(department => department.id === value);
                break;
            case EventDataType.Project:
                currentEvent.project = this.props.project?.find(t => t.id === value);
                break;

            case EventDataType.BasePoi:
                currentEvent.basePoi = this.props.basePois?.data?.find(t => t.id === value);
                if (currentEvent.basePoi === undefined) {
                    currentEvent.distance = undefined;
                }
                break;
            case EventDataType.Vehicle:
                currentEvent.vehicle = this.props.vehicles?.data?.find(t => t.id === value);
                break;
            case EventDataType.Distance:
                currentEvent.distance = value ? toFinite(value) : undefined;
                break;
        }

        this.setState({ currentEvent });
    };

    renderOwnerSelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {props.label}
        </Tag>
    );

    onCancel = (): void => {
        this.setState({ currentTemplate: undefined });
        this.props.onCancel();
    };

    getAllDates = (startDate: Moment, endDate: Moment, eventRecurring: IEventRecurring | undefined) => {
        const allDates: DateRange[] = [{ startDate, endDate }];

        const hoursStart = startDate.hours();
        const minutesStart = startDate.minutes();
        const secondsStart = startDate.seconds();
        const duration = endDate.diff(startDate, "seconds")

        eventRecurring?.rules.forEach(rule => {
            const rrule = stringToRrule(rule.rrule);
            const dates = rrule ? rrule.all() : [];
            dates.forEach(d => {
                const startDate = moment(d.setHours(hoursStart, minutesStart, secondsStart, 0))
                const endDate = startDate.clone().add(duration, "seconds");
                allDates.push({ startDate, endDate });
            })
        });
        return allDates;
    }

    onClickBeforConfirm = async (): Promise<void> => {
        const { currentEvent, createErrors, eventRecurring } = this.state;

        const create = (dates: DateRange[]) => {
            if (this.state.isCreate)
                this.props.onCreate(createErrors, currentEvent, this.state.userIds, this.state.sendMessage, this.state.asTemplate, false, true, this.validateFiles, dates);
            else
                this.props.onEdit(createErrors, currentEvent, this.state.sendMessage, false, true);
        };

        this.setState(prevState => ({ currentTemplate: undefined, checkBeforeLoading: this.state.forceCreate ? prevState.checkBeforeLoading : true }));

        const eventCanCreateOrEditedBody: EventCanBeCreatedOrEditedBody = {
            data: []
        };
        const allDates = this.getAllDates(currentEvent.startDate, currentEvent.endDate, eventRecurring);

        this.state.userIds.forEach((id) => {
            if (eventRecurring) {
                allDates.forEach(d => {
                    eventCanCreateOrEditedBody.data.push({
                        startDate: moment(d.startDate).format(MOMENT_FORMAT_TO_NETWORK),
                        endDate: moment(d.endDate).format(MOMENT_FORMAT_TO_NETWORK),
                        userId: id,
                        eventId: currentEvent.id
                    });
                });
            } else {

                eventCanCreateOrEditedBody.data.push({
                    endDate: currentEvent.endDate.format(MOMENT_FORMAT_TO_NETWORK),
                    startDate: currentEvent.startDate.format(MOMENT_FORMAT_TO_NETWORK),
                    userId: id,
                    eventId: currentEvent.id
                });
            }

        })

        if (this.state.forceCreate) {
            create(allDates);
        } else {
            Network.canEventBeCreatedOrEditedV2(eventCanCreateOrEditedBody).then(
                (response) => {
                    if (response.data.length > 0) {
                        const eventErrors: EventCreateError[] = [];
                        response.data.forEach(event => {
                            const foundEventError = eventErrors[eventErrors.findIndex(eb => eb.user.id === event.userId)];
                            const error = ({
                                type: event.errorType,
                                codes: event.errorValues
                            });

                            if (foundEventError) {
                                if (!foundEventError.errors.some(e => e.type === error.type))
                                    foundEventError.errors.push(error);
                            }
                            else {
                                const user = this.props.users.find(u => u.id === event.userId);
                                if (user)
                                    eventErrors.push({
                                        user,
                                        event: {
                                            endDate: moment(event.endDate),
                                            startDate: moment(event.startDate),
                                            id: event.eventId
                                        },
                                        errors: [error]
                                    });
                            }
                        });

                        this.setState({ createErrors: eventErrors, checkBeforeLoading: false });
                    }
                    else {
                        this.setState({ checkBeforeLoading: false });
                        create(allDates);
                    }
                },
                () => {
                    this.setState({ checkBeforeLoading: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while veryfing the creation or modification of the event' }), 'error');
                }
            )
        }
    };

    validateFiles = async (eventId: number) => {
        if (eventId != null) {
            if (this.state.filesToValidate.length > 0) {
                const flatFilesToValidate = this.state.filesToValidate.map(fTV => fTV.fileId);
                await Network.validateEventFiles(eventId, this.state.filesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            const actualFilesToValidate = cloneDeep(this.state.filesToValidate);
                            this.setState({ files: response.data, filesToValidate: actualFilesToValidate.filter(fTV => !flatFilesToValidate.includes(fTV.fileId)) });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the files' }), "warning");
                    }
                );
            }
            if (this.state.imagesToValidate.length > 0) {
                const flatImagesToValidate = this.state.imagesToValidate.map(fTV => fTV.fileId);
                Network.validateEventFiles(eventId, this.state.imagesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            const actualImagesToValidate = cloneDeep(this.state.imagesToValidate);
                            this.setState({ files: response.data, imagesToValidate: actualImagesToValidate.filter(fTV => !flatImagesToValidate.includes(fTV.fileId)) });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the images' }), "warning");
                    }
                );
            }
        }
    };

    onForceConfirm = async (): Promise<void> => {
        const { currentEvent, createErrors, eventRecurring } = this.state;

        const allDates = this.getAllDates(currentEvent.startDate, currentEvent.endDate, eventRecurring);
        if (this.state.isCreate) {
            this.props.onCreate(createErrors, currentEvent, this.state.userIds, this.state.sendMessage, this.state.asTemplate, true, false, this.validateFiles, allDates);
        } else {
            this.props.onEdit(createErrors, currentEvent, this.state.sendMessage, true, false);
        }
        this.setState({ popConfirmAvailabilityError: false, currentTemplate: undefined });
    };

    openGoogleMaps = () => {
        const { currentEvent } = this.state;
        if (currentEvent.poi && currentEvent.basePoi && currentEvent.poi.latitude && currentEvent.poi.longitude && currentEvent.basePoi.latitude && currentEvent.basePoi.longitude) {
            openGoogleMapsRoute(currentEvent.basePoi.latitude, currentEvent.basePoi.longitude, currentEvent.poi.latitude, currentEvent.poi.longitude);
        }
    };

    generateDistance = () => {
        const currentEvent = cloneDeep(this.state.currentEvent);
        if (currentEvent.poi && currentEvent.basePoi && currentEvent.poi.latitude && currentEvent.poi.longitude && currentEvent.basePoi.latitude && currentEvent.basePoi.longitude) {
            this.setState({ loadingDistance: true });
            Network.generateDistance(currentEvent.basePoi.latitude.toString(), currentEvent.basePoi.longitude.toString(), currentEvent.poi.latitude.toString(), currentEvent.poi.longitude.toString()).then(
                success => {
                    if (!success.error) {
                        currentEvent.distance = success.distance;
                        const distanceDuration = Math.round(success.time);
                        this.setState({ currentEvent, loadingDistance: false, distanceDuration });
                    } else {
                        captureMessage("DIS0 : " + success);
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the distance' }), "error");
                        this.setState({ loadingDistance: false, distanceDuration: undefined });
                    }
                },
                error => {
                    captureMessage("DIS1 : " + error.message ? error.message : String(error));
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the distance' }), "error");
                    this.setState({ loadingDistance: false, distanceDuration: undefined });
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Please select a starting and a working location' }), "error");
        }
    };

    onChangeDates = (dates: Value) => {
        const currentEvent = cloneDeep(this.state.currentEvent);
        if (dates && isArray(dates)) {
            dates.forEach((date, idx) => {
                if (date) {
                    const timeString = date instanceof Date ? moment(date).format("HH:mm:ss") : date;
                    const [hours, minutes, seconds] = timeString.split(":").map(Number);
                    if (idx === 0) currentEvent.startDate = currentEvent.startDate.set({ hour: hours, minute: minutes, second: seconds });
                    else currentEvent.endDate = currentEvent.endDate.set({ hour: hours, minute: minutes, second: seconds });

                }
            })
            const [newStartDate, newEndDate] = automaticChangeEndDate(currentEvent.startDate, currentEvent.endDate);
            currentEvent.startDate = newStartDate;
            currentEvent.endDate = newEndDate;
            this.setState({ currentEvent }, this.loadUsersAvailable);
        }
    }

    checkBreaktimesOutsideOfEvent = () => {
        const { currentEvent } = this.state;
        return currentEvent.breakTimes?.some((breaktime) => breaktime.startDate.isBefore(currentEvent.startDate) || breaktime.endDate.isAfter(currentEvent.endDate));
    };

    addBreaktime = () => {
        const currentEvent = cloneDeep(this.state.currentEvent);
        if (!currentEvent.breakTimes) currentEvent.breakTimes = []
        const startDate = currentEvent.startDate.clone().add(1, "hours");
        const endDate = startDate.clone().add(30, "minutes");
        currentEvent.breakTimes?.push({ id: generateNegativeUniqueId(), startDate, endDate, title: '', totalHours: 0, totalSeconds: 0, isClocked: false, active: true, isPaid: false });
        this.setState({ currentEvent });
    }

    removeBreaktime = (breaktimeId: number) => {
        const currentEvent = cloneDeep(this.state.currentEvent);
        currentEvent.breakTimes = currentEvent.breakTimes?.filter(b => b.id !== breaktimeId);
        this.setState({ currentEvent });
    }

    updateBreaktime = (breaktime: BreakTime) => {
        const currentEvent = cloneDeep(this.state.currentEvent);
        currentEvent.breakTimes = currentEvent.breakTimes?.map(b => {
            if (b.id === breaktime.id) return breaktime;
            return b;
        });
        this.setState({ currentEvent });
    }

    addReminder = () => {
        this.setState({
            addReminder: true,
            editReminder: { id: generateNegativeUniqueId(), minBeforeEventStart: 5, retreived: false, sent: false, created: moment() }
        });
    }

    editEventRecurring = () => {
        this.setState(prevState => {
            let editEventRecurring: IEventRecurring;
            const startDate = prevState.currentEvent.startDate;
            const endDate = prevState.currentEvent.startDate.clone().endOf("month");

            if (prevState.eventRecurring) editEventRecurring = cloneDeep(prevState.eventRecurring);
            else {
                const missionRuleStartDate = prevState.missionRule?.startDate ? moment(prevState.missionRule?.startDate) : undefined;
                const missionRuleEndDate = prevState.missionRule?.endDate ? moment(prevState.missionRule?.endDate) : undefined;
                const startDateStr = (missionRuleStartDate ? moment.max(startDate, missionRuleStartDate) : startDate).toISOString();
                const endDateStr = (missionRuleEndDate ? moment.min(endDate, missionRuleEndDate) : endDate).toISOString();
                editEventRecurring = { startDate: startDateStr, endDate: endDateStr, rules: [{ id: generateNegativeUniqueId(), startDate: startDateStr, endDate: endDateStr, rrule: prevState.missionRule?.rrule }] };
            }

            return ({
                editEventRecurring,
                asTemplate: false,
                sendMessage: false,
                filesToValidate: [],
                imagesToValidate: [],
                files: []
            })
        })
    }

    onChangeTypeOfEvent = (e: SegmentedValue) => {
        this.setState(prevState => {
            const typeOfEvent = e as TypeOfEvent;
            const currentEvent = prevState.currentEvent;

            if (
                prevState.currentEvent.typeOfDay ||
                prevState.currentEvent.typeOfDayOff ||
                prevState.currentEvent.staffType ||
                prevState.currentEvent.poi
            ) {
                currentEvent.typeOfDay = undefined;
                currentEvent.typeOfDayOff = undefined;
                currentEvent.staffType = undefined;
                currentEvent.poi = undefined;
            }

            return {
                typeOfEvent,
                currentEvent
            }
        });
    }

    renderModalContent = () => {
        const { Panel } = Collapse;
        const { currentEvent, availableUserIds, overlapUserIds, lockedDaysUserIds, userIds, typeOfEvent, eventRecurring, availableUsersLoading, loadingDistance, overlappingBreakTimes, breaktimeHoveredId } = this.state;
        const { intl, getFullName, isSmartphone, projectName, users } = this.props;
        let listOfUsers: AvailableUsers[] = cloneDeep(users);
        const canManageUser = !currentEvent.id;
        const empty = isNullOrEmpty(listOfUsers);

        const hasRecurring = !!eventRecurring;
        const disableDistance = !currentEvent.basePoi || !currentEvent.poi || loadingDistance;

        const selectedUsers: AvailableUsers[] = [];
        let processedUserIds: number[] = userIds;
        if (!canManageUser) processedUserIds = currentEvent.userId ? [currentEvent.userId] : [];

        const userMap = new Map<number, AvailableUsers>();

        if (!empty) {
            for (const u of listOfUsers) {
                u.unavailable = false;
                u.unavailableDates = [];
                userMap.set(u.id, u);

                u.problems = [];
                if (currentEvent.staffType && (isNullOrEmpty(u.staffType) || !u.staffType.some(st => st.id === currentEvent.staffType?.id))) {
                    u.missingHability = true;
                    u.problems.push(UserProblemIntl.incompatibleAptitude);
                }

                if (currentEvent.poi && !isNullOrEmpty(currentEvent.poi.groups) && !u.group_users?.some(gu => currentEvent.poi!.groups!.some(g => g.id === gu.group))) {
                    u.missingLocation = true;
                    u.problems.push(UserProblemIntl.incompatiblePOI);
                }

                // const userAvailabilities = userToDates[u.id]
                // if (userAvailabilities) {
                //     u.unavailable = true;
                //     u.unavailableDates = xx
                //     u.problems.push(UserProblemIntl.unavailable);
                // }

                const userOverlap = overlapUserIds && overlapUserIds[u.id];
                if (userOverlap) {
                    u.overlap = true;
                    u.overlapDates = userOverlap;
                    u.problems.push(UserProblemIntl.overlap);
                }
                const userLocked = lockedDaysUserIds && lockedDaysUserIds[u.id];
                if (userLocked) {
                    u.locked = true;
                    u.lockedDates = userLocked;
                    u.problems.push(UserProblemIntl.locked);
                }
            }
        }

        // 2. Traitement des dates (1 seule passe)
        for (const [date, availableIds] of Object.entries(availableUserIds ?? {})) {
            const availableSet = new Set(availableIds);

            for (const [userId, u] of userMap) {
                if (!availableSet.has(userId)) {
                    if (u.unavailable === false) {
                        u.problems?.push(UserProblemIntl.unavailable);
                        u.unavailable = true;
                    }
                    u.unavailableDates?.push(date);
                }
            }
        }

        processedUserIds.forEach(uId => {
            const user = listOfUsers?.find(u => u.id === uId);
            if (user) selectedUsers.push(user);
        });

        if (userIds.length > 0)
            listOfUsers = listOfUsers.filter(u => !userIds.includes(u.id));

        const flexBlock: CSSProperties = { display: 'flex', flexDirection: 'column', gap: '15px' };
        const inlineFlexBlock: CSSProperties = { display: "flex", alignItems: 'center', justifyContent: "space-between", gap: '10px', flexWrap: 'wrap', width: '100%' };
        const SelectedUsers = () => {
            if (selectedUsers.length === 0) return null;

            return (
                <>
                    {
                        selectedUsers.map(u => {
                            const { title } = problemMessage(u, intl, getFullName);

                            return (
                                <Tag
                                    color={
                                        u.locked || u.unavailable || u.overlap ?
                                            "error"
                                            : u.missingHability || u.missingLocation ?
                                                "warning"
                                                :
                                                undefined
                                    }
                                    icon={
                                        u.locked ?
                                            <FAIcon prefix='fad' title={title} name='do-not-enter' />
                                            : u.unavailable || u.overlap ?
                                                <FAIcon prefix='fad' title={title} name='circle-exclamation' />
                                                : u.missingHability || u.missingLocation ?
                                                    <FAIcon prefix='fad' title={title} name='circle-exclamation' />
                                                    :
                                                    undefined
                                    }
                                    key={`selu-${u.id}`}
                                    style={{ borderRadius: '20px', display: 'flex', gap: '5px', maxWidth: 'fit-content', alignItems: 'center', borderColor: 'var(--gray-color)', paddingTop: '2px', paddingBottom: '2px' }}
                                    onClose={() => this.setState(prevState => ({ userIds: prevState.userIds.filter(uId => uId !== u.id) }))}
                                    closable={canManageUser} >
                                    <FullUser user={u} withAvatar avatarSize='small' textStyle={u.locked ? { textDecoration: 'line-through' } : undefined} />
                                </Tag>
                            );
                        })
                    }
                </>
            );
        }
        return (

            <>
                <div style={{ ...flexBlock }}>
                    <div style={{ ...flexBlock }}>
                        <div style={inlineFlexBlock}>
                            <Input
                                id="planning-title-input"
                                placeholder={`${intl.formatMessage({ defaultMessage: 'Title' })}*`}
                                value={currentEvent.title}
                                wrapperStyle={{ flex: 1 }}
                                onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Title, e)} />
                            <ColorChooser
                                style={{ width: '70px' }}
                                colors={this.props.settings.colors}
                                activeColor={currentEvent.color}
                                onChangeColor={(color) => this.dataChanged(EventDataType.Color, color?.id)} />
                        </div>
                        <div style={{ ...flexBlock, gap: '5px' }}>
                            <EditorProvider>
                                <Editor
                                    value={currentEvent.description}
                                    onChange={(e) => this.dataChanged(EventDataType.Description, e)}>
                                    <Toolbar>
                                        <div style={{ ...inlineFlexBlock, gap: '0px', justifyContent: 'space-between', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                {/* <BtnLink /> */}
                                                <Separator />
                                                <BtnBulletList />
                                                <BtnNumberedList />
                                                <Separator />
                                                <BtnRedo />
                                                <BtnUndo />
                                                <BtnClearFormatting />
                                            </div>
                                            <div>
                                                <Badge
                                                    offset={[-5, 8]}
                                                    size={'small'}
                                                    count={this.props.currentEvent.id ? this.state.files.filter(f => f.res_type === 'IMG').length : this.state.imagesToValidate.filter(fTV => fTV.res_type === 'IMG').length}
                                                >
                                                    <CircleButton
                                                        small
                                                        disabled={hasRecurring}
                                                        withoutBorder
                                                        icon={<FAIcon prefix='fad' name='image' size='lg' />}
                                                        onClick={() => this.setState({ addImages: true })} />
                                                </Badge>
                                                <Badge
                                                    offset={[-10, 8]}
                                                    size={'small'}
                                                    count={this.props.currentEvent.id ? this.state.files.filter(f => f.res_type === 'FLE').length : this.state.filesToValidate.filter(fTV => fTV.res_type === 'FLE').length}
                                                >
                                                    <CircleButton
                                                        small
                                                        disabled={hasRecurring}
                                                        withoutBorder
                                                        icon={<FAIcon prefix='fad' name='file-pdf' size='lg' />}
                                                        onClick={() => this.setState({ addDocuments: true })}
                                                    />
                                                </Badge>
                                            </div>
                                        </div>
                                    </Toolbar>

                                </Editor>
                            </EditorProvider>

                        </div>
                    </div>

                    <div style={{ ...flexBlock, gap: '10px', backgroundColor: 'var(--gray-color)', borderRadius: 'var(--border-radius)', padding: '10px 10px 10px 10px', alignItems: 'center' }}>
                        <div style={{ ...inlineFlexBlock }}>
                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                <FAIcon prefix='fad' name='clock-three' size='lg' />
                                <span><FormattedMessage defaultMessage={'The {date}'} values={{ date: currentEvent.startDate.format(getFormat("DATE")) }} />:</span>
                            </div>
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <TimeRangePickerWrapper
                                    className={"trp-bg-white border-transparent"}
                                    shouldOpenClock={() => false}
                                    openClockOnFocus={false}
                                    clockIcon={null}
                                    clearIcon={null}
                                    format={getFormat("TIME")}
                                    maxDetail="second"
                                    onChange={this.onChangeDates}
                                    value={[currentEvent.startDate.format(getFormat("TIME")), currentEvent.endDate.format(getFormat("TIME"))]} />

                                {
                                    currentEvent.id && currentEvent.id > 0 ?
                                        null
                                        :
                                        <ActivateRecurring eventRecurring={eventRecurring} editEventRecurring={this.editEventRecurring} />
                                }
                            </div>
                        </div>
                        {
                            !isNullOrEmpty(currentEvent.breakTimes) ?
                                <div style={{ ...flexBlock, gap: '5px' }}>
                                    {
                                        currentEvent.breakTimes.map((bt) => (
                                            <EditBreaktime
                                                iconClassName={
                                                    overlappingBreakTimes[bt.id] ?
                                                        breaktimeHoveredId && overlappingBreakTimes[bt.id].includes(breaktimeHoveredId) ?
                                                            "icon-error-blink"
                                                            : "icon-error"
                                                        : undefined
                                                }
                                                title={overlappingBreakTimes[bt.id] ? intl.formatMessage({ defaultMessage: "There is an overlap with another break." }) : undefined}
                                                onMouseEnter={() => this.setState({ breaktimeHoveredId: bt.id })}
                                                onMouseLeave={() => this.setState({ breaktimeHoveredId: undefined })}
                                                key={`display-breaktime-${bt.id}`}
                                                onChange={this.updateBreaktime}
                                                delete={this.removeBreaktime}
                                                minDate={currentEvent.startDate}
                                                maxDate={currentEvent.endDate}
                                                breaktime={bt} />
                                        ))
                                    }
                                </div>
                                :
                                null
                        }
                        <Button
                            style={{ width: 'max-content', borderColor: 'transparent' }}
                            icon={<FAIcon prefix='far' name='plus' />}
                            onClick={this.addBreaktime}
                        >
                            <FormattedMessage defaultMessage={'Add a break'} />
                        </Button>
                    </div>
                    <div style={{ ...flexBlock, gap: '0px' }}>
                        <Segmented
                            style={{ borderRadius: 'var(--border-radius) var(--border-radius) 0 0' }}
                            block
                            onChange={this.onChangeTypeOfEvent}
                            value={typeOfEvent}
                            options={[
                                {
                                    label: intl.formatMessage(TypeOfEventIntl.work),
                                    value: TypeOfEvent.WORK
                                },
                                {
                                    label: intl.formatMessage(TypeOfEventIntl.off),
                                    value: TypeOfEvent.OFF
                                }
                            ]}
                        />
                        <div style={{ backgroundColor: 'var(--gray-color)', padding: '10px', borderRadius: '0 0 var(--border-radius) var(--border-radius)' }}>
                            {
                                typeOfEvent === TypeOfEvent.WORK ?
                                    <div style={{ ...flexBlock, gap: '10px' }}>
                                        <Select
                                            style={{ minWidth: 0, width: '100%' }}
                                            showSearch
                                            disabled={this.props.typesOfDay === undefined || this.props.typesOfDay.length <= 0}
                                            className='select-arrow-bold-color'
                                            allowClear
                                            suffixIcon={<FAIcon prefix='fad' name='screwdriver-wrench' />}
                                            placeholder={<FormattedMessage defaultMessage={'Choose a type of work'} />}
                                            onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDay, id)}
                                            value={currentEvent.typeOfDay?.id}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {this.props.typesOfDay?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                        </Select>

                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px', width: '100%' }}>
                                            <Select
                                                style={{ minWidth: 0, width: '100%' }}
                                                showSearch
                                                className='select-arrow-bold-color'
                                                allowClear
                                                suffixIcon={<FAIcon prefix='fad' name='head-side-gear' />}
                                                placeholder={<FormattedMessage defaultMessage={'Ability'} />}
                                                disabled={this.state.staffTypes === undefined || this.state.staffTypes.length <= 0}
                                                onChange={(id: number) => this.dataChanged(EventDataType.StaffType, id)}
                                                value={currentEvent.staffType?.id}
                                                filterOption={true}
                                                options={this.state.staffTypes?.map(t => ({ label: t.name, value: t.id }))}
                                                optionFilterProp="label"
                                                maxTagCount="responsive"
                                            />
                                            {
                                                checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) &&
                                                <Select
                                                    style={{ minWidth: 0, width: '100%' }}
                                                    placeholder={<FormattedMessage defaultMessage={'Workplace'} />}
                                                    showSearch
                                                    allowClear
                                                    className='select-arrow-bold-color'
                                                    suffixIcon={<FAIcon prefix='fad' name='location-dot' />}
                                                    disabled={this.props.pois === undefined || this.props.pois.data === undefined || this.props.pois.data.length <= 0}
                                                    onChange={(id: number) => this.dataChanged(EventDataType.Poi, id)}
                                                    value={currentEvent.poi?.id}
                                                    filterOption={true}
                                                    maxTagCount="responsive"
                                                    optionFilterProp="label">
                                                    {this.props.pois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <Select
                                        className="planning-modal-input-100"
                                        showSearch
                                        allowClear
                                        placeholder={<FormattedMessage defaultMessage={'Choose a day off'} />}
                                        disabled={this.props.typesOfDayOff === undefined || this.props.typesOfDayOff.length <= 0}
                                        onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDayOff, id)}
                                        value={currentEvent.typeOfDayOff?.id}
                                        filterOption={true}
                                        optionFilterProp="label">
                                        {this.props.typesOfDayOff?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                    </Select>
                            }

                        </div>
                    </div>
                    {
                        checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) ||
                            checkRBACRule(Rules.ProjectV2.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) ?
                            <div style={inlineFlexBlock}>
                                <span>{projectName}:</span>
                                <Select
                                    style={{ width: '240px' }}
                                    showSearch
                                    allowClear
                                    disabled={isNullOrEmpty(this.props.project)}
                                    placeholder={<FormattedMessage defaultMessage={'Please choose a {project}'} values={{ project: projectName.toLocaleLowerCase() }} />}
                                    onChange={(id: number) => this.dataChanged(EventDataType.Project, id)}
                                    value={currentEvent.project?.id}
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.project?.map(p => <Select.Option label={p.title} value={p.id!} key={`project-${p.id}`}>{p.title}</Select.Option>)}
                                </Select>
                            </div>
                            : null
                    }
                    <Spin spinning={availableUsersLoading}>
                        <div style={{ ...flexBlock, gap: '5px' }}>
                            <div style={inlineFlexBlock}>
                                <p><FormattedMessage defaultMessage={'User'} />{canManageUser ? <span style={{ fontSize: '95%' }}>{` (${selectedUsers.length}/${MAX_USERS})`}</span> : ""}:</p>
                                {
                                    canManageUser ?
                                        <SelectUsers listOfUsers={listOfUsers} tagRender={this.renderOwnerSelect} userIds={processedUserIds} onChange={value => this.dataChanged(EventDataType.UserId, value)} />
                                        :
                                        <SelectedUsers />
                                }
                            </div>
                            {
                                canManageUser ?
                                    <div style={{ ...inlineFlexBlock, justifyContent: 'flex-start', gap: '5px' }}>
                                        <SelectedUsers />
                                    </div>
                                    : null
                            }
                        </div>
                    </Spin>
                </div>
                <Collapse bordered={false} style={{ marginTop: '20px' }} expandIconPosition='end'>
                    <Panel header={<FormattedMessage defaultMessage={'Advanced Settings'} />} key="1">
                        <div style={{ ...flexBlock }}>
                            {
                                this.props.departments.updated && this.props.departments.data.length > 0 ?
                                    <div style={inlineFlexBlock}>
                                        <span><FormattedMessage defaultMessage={'Department'} /></span>
                                        <Select
                                            style={{ flex: 1 }}
                                            showSearch
                                            allowClear
                                            onChange={(e: number) => this.dataChanged(EventDataType.Department, e)}
                                            placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                            value={currentEvent.department?.id}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                        </Select>
                                    </div>
                                    : null
                            }
                            {
                                checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) ?
                                    <Fieldset style={{ paddingTop: '15px' }} legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={<><FAIcon prefix='fad' name='route' /> <FormattedMessage defaultMessage={'Work travel'} /></>} >
                                        <div style={{ ...flexBlock, gap: '5px' }}>
                                            <Tooltip mouseEnterDelay={0.8} title={currentEvent.basePoi?.id ? <FormattedMessage defaultMessage={'Departure point'} /> : undefined}>
                                                <Select
                                                    suffixIcon={<FAIcon prefix='fad' name='location-crosshairs' />}
                                                    style={{ flex: 1 }}
                                                    showSearch
                                                    allowClear
                                                    placeholder={<FormattedMessage defaultMessage={'Departure point'} />}
                                                    disabled={isNullOrEmpty(this.props.basePois?.data)}
                                                    onChange={(id: number) => this.dataChanged(EventDataType.BasePoi, id)}
                                                    value={currentEvent.basePoi?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.basePois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Tooltip>
                                            <div style={{ ...inlineFlexBlock, gap: '5px', width: 'auto', flex: 1 }}>
                                                <InputNumber
                                                    title={this.state.distanceDuration ? intl.formatMessage({ defaultMessage: 'Travel time ~{time} minutes' }, { time: this.state.distanceDuration ? this.state.distanceDuration : '' }) : ''}
                                                    disabled={disableDistance}
                                                    min={0}
                                                    max={5000}
                                                    prefix={<img src={KilometerIcon} alt={"kilometer"} style={{ width: "15px", opacity: 0.4 }} />}
                                                    style={{ flexGrow: '1', minWidth: 'unset' }}
                                                    value={currentEvent.distance}
                                                    placeholder={intl.formatMessage({ defaultMessage: 'Distance' })}
                                                    onChange={e => this.dataChanged(EventDataType.Distance, e)} />
                                                <Popconfirm
                                                    title={<FormattedMessage defaultMessage={'Do you want to generate the distance automatically according to the general conditions?'} />}
                                                    onConfirm={this.generateDistance}
                                                    okText={<FormattedMessage defaultMessage={'Yes'} />}
                                                    cancelText={<FormattedMessage defaultMessage={'No'} />}
                                                    placement="topRight"
                                                    disabled={disableDistance}
                                                >
                                                    <CircleButton
                                                        small
                                                        title={intl.formatMessage({ defaultMessage: 'Generate optimized distance' })}
                                                        placement="top"
                                                        disabled={disableDistance}
                                                        icon={<Anticon icon={<RiPinDistanceFill />} />}
                                                        loading={this.state.loadingDistance}
                                                    />
                                                </Popconfirm>
                                                <CircleButton
                                                    small
                                                    title={intl.formatMessage({ defaultMessage: 'Open Google Maps' })}
                                                    placement='top'
                                                    disabled={disableDistance}
                                                    icon={<FAIcon prefix='fad' name='map-location-dot' />}
                                                    loading={this.state.loadingDistance}
                                                    onClick={this.openGoogleMaps}
                                                />
                                            </div>
                                            <Tooltip mouseEnterDelay={0.8} title={currentEvent.vehicle?.id ? <FormattedMessage defaultMessage={'Vehicles'} /> : undefined}>
                                                <Select
                                                    suffixIcon={<FAIcon prefix='fad' name='car' />}
                                                    style={{ flex: 1 }}
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.Vehicle, id)}
                                                    placeholder={<FormattedMessage defaultMessage={'Vehicles'} />}
                                                    value={currentEvent.vehicle?.id}
                                                    filterOption={true}
                                                    disabled={isNullOrEmpty(this.props.vehicles?.data)}
                                                    optionFilterProp="label">
                                                    {this.props.vehicles?.data?.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                                                </Select>
                                            </Tooltip>
                                        </div>
                                    </Fieldset>
                                    : null
                            }
                            <Fieldset style={{ paddingTop: '15px' }} legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={<><FAIcon prefix='fad' name='alarm-clock' /> <FormattedMessage defaultMessage={'Reminders'} /></>} >
                                <div style={{ ...flexBlock, alignItems: 'center' }}>
                                    {
                                        isNullOrEmpty(currentEvent.reminders) ?
                                            null
                                            :
                                            <div style={{ ...flexBlock, gap: '5px', width: '100%' }}>
                                                {
                                                    currentEvent.reminders?.sort((a, b) => a.minBeforeEventStart > b.minBeforeEventStart ? 1 : -1).map((reminder) => {
                                                        const selectedDurationType = automaticDurationTypeSelection(reminder.minBeforeEventStart);
                                                        const isInThePast = moment().isAfter(currentEvent.startDate.clone().subtract(reminder.minBeforeEventStart, 'minutes'));
                                                        const disabled = isInThePast || reminder.retreived || reminder.sent;
                                                        let title = "";
                                                        let style: CSSProperties = {};

                                                        if (reminder.retreived && !reminder.sent) {
                                                            title += intl.formatMessage({ defaultMessage: "The reminder is being sent" });
                                                            style = { textDecoration: 'italic' };
                                                        } else if (reminder.retreived && reminder.sent) {
                                                            title += intl.formatMessage({ defaultMessage: "Reminder was sent at {time}" }, { time: reminder.modified?.format(getFormat('DATE_LONG_AND_ON_TIME_LONG')) });
                                                            style = { textDecoration: 'italic' };
                                                        } else if (isInThePast) {
                                                            title += intl.formatMessage({ defaultMessage: "The reminder is set in the past and will not be sent." });
                                                            style = { textDecoration: 'italic' };
                                                        }
                                                        return (
                                                            <div style={{ ...inlineFlexBlock, ...style }} key={`reminder-${reminder.id}`} title={title}>
                                                                <CircleButton
                                                                    small
                                                                    withoutBorder
                                                                    title={intl.formatMessage({ defaultMessage: 'Remove reminder' })}
                                                                    icon={<FAIcon prefix='far' name='xmark' />}
                                                                    disabled={disabled}
                                                                    onClick={() => this.removeEventReminder(reminder)}
                                                                />

                                                                <p style={{ flex: 1 }}>
                                                                    {reminder.minBeforeEventStart / selectedDurationType.lengthInMin} {(reminder.minBeforeEventStart / selectedDurationType.lengthInMin) <= 1 ? intl.formatMessage(selectedDurationType.name).toLowerCase() : intl.formatMessage(selectedDurationType.pluralName).toLowerCase()} <FormattedMessage defaultMessage={'before the event'} />
                                                                </p>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                    }
                                    <Button
                                        style={{ width: 'max-content', borderColor: 'transparent' }}
                                        icon={<FAIcon prefix='far' name='plus' />}
                                        onClick={this.addReminder}
                                    >
                                        <FormattedMessage defaultMessage={'Add a reminder'} />
                                    </Button>
                                </div>
                            </Fieldset>
                        </div>
                    </Panel>
                </Collapse>
                <div style={{ position: 'fixed', bottom: "70px", width: `calc(${isSmartphone ? '100%' : "550px"} - 30px)` }}>
                    {
                        this.checkBreaktimesOutsideOfEvent() &&
                        <Alert type='error' showIcon
                            style={{ fontSize: "80%", fontStyle: 'italic', marginTop: 10 }}
                            message={`Une ou plusieurs pauses sont en dehors de l'événement`}
                        />
                    }
                    {
                        currentEvent.clocked && !currentEvent.clocked.isRefused && !currentEvent.clocked.isConfirmed && !currentEvent.ignoreTimeClock ?
                            <Alert type="warning" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={<FormattedMessage defaultMessage={'The event has been clocked {confirmed} and is waiting validation by an administrator'} values={{ confirmed: currentEvent.clocked.isConfirmed && intl.formatMessage({ defaultMessage: 'and confirmed' }) }} />} />

                            : null
                    }
                </div>
            </>
        );
    }

    getCodeMessage = (code?: EventCopyErrorCodes) => {
        switch (code) {
            case 'NOT_IN_PERIODS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Date of copy outside mission period'} /></span><br /></>;
            case 'TOO_MANY_HOURS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Too many scheduled hours'} /></span><br /></>;
            case 'TOO_MANY_HOURS_IN_EVENT':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Too many hours scheduled in the event'} /></span><br /></>;
            case 'NOT_ENOUGH_HOURS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Not enough hours scheduled'} /></span><br /></>;
            case 'NOT_ENOUGH_HOURS_IN_EVENT':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Not enough hours scheduled in the event'} /></span><br /></>;
            case 'TOO_MANY_HUMANS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Overload of human needs for the mission'} /></span><br /></>;
            default:
                return <><span>{' - '}<FormattedMessage defaultMessage={'Unknown error'} /></span><br /></>;
        }
    };

    renderCreationErrors = () => {
        const { createErrors } = this.state;
        const { getFullName } = this.props;
        return (
            <div className='__mp-force-container'>
                <FormattedMessage defaultMessage={'Force copy'} />
                <Divider style={{ marginTop: 10 }} />
                <div style={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }} >
                    {
                        createErrors.map((error, idx) => {
                            const userFullName = getFullName(error.user)
                            return (
                                <div key={`event-${idx}`} className='__mp-force-event-container'>
                                    <div className='__mp-force-icons-container'>
                                        <div>
                                            {
                                                error.errors.findIndex(e => e.type && e.type === 'unavailabilities') !== -1 ?
                                                    <Tooltip
                                                        title={<FormattedMessage defaultMessage={'User not available'} />}
                                                    >
                                                        <FAIcon name='user-slash' prefix='fad' size='lg' color={PASTEL_RED_COLOR} />
                                                    </Tooltip>
                                                    :
                                                    <FAIcon name='user-slash' prefix='fad' size='lg' color={'#ececec'} />
                                            }
                                        </div>
                                        <div>
                                            {
                                                error.errors.findIndex(e => e.type && e.type === 'overlap' || e.type && e.type === 'new-events-overlap') !== -1 ?
                                                    <Tooltip
                                                        title={<FormattedMessage defaultMessage={'Event overlap'} />}
                                                    >
                                                        <FAIcon name='circles-overlap' prefix='fad' size='lg' color={PASTEL_RED_COLOR} />
                                                    </Tooltip>
                                                    :
                                                    <FAIcon name='circles-overlap' prefix='fad' size='lg' color={'#ececec'} />
                                            }
                                        </div>
                                        <div>
                                            {
                                                error.errors.findIndex(e => e.type && e.type === 'missionMissmatch') !== -1 ?
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <p><FormattedMessage defaultMessage={'CRM Error'} /></p>
                                                                {error.errors[error.errors.findIndex(e => e.type && e.type === 'missionMissmatch')].codes?.map(code => this.getCodeMessage(code))}
                                                            </>
                                                        }

                                                    >
                                                        <FAIcon name='user-gear' prefix='fad' size='lg' color={PASTEL_RED_COLOR} />
                                                    </Tooltip>
                                                    :
                                                    <FAIcon name='user-gear' prefix='fad' size='lg' color={'#ececec'} />
                                            }
                                        </div>
                                    </div>
                                    <p className='text-ellipsis' title={userFullName}>{userFullName}</p>
                                </div>
                            );
                        })
                    }
                </div>
                <br />
                <p><strong><FormattedMessage defaultMessage={"Do you really want to force the creation of the event?"} /></strong></p>
            </div>
        );
    };

    renderClockError = () => {
        const { isCreate } = this.state;
        return (
            <>
                <p style={{ fontWeight: 'bolder' }}>
                    {isCreate ? <FormattedMessage defaultMessage={'Force create'} /> : <FormattedMessage defaultMessage={'Force edit'} />}
                </p>
                <p>
                    <FormattedMessage defaultMessage={'will create the event despite the lack of availability of the selected user'} />
                </p>
            </>
        );
    };

    saveEventRecurring = (eventRecurring?: IEventRecurring) => this.setState({ eventRecurring, editEventRecurring: undefined });

    cancelEventRecurring = () => this.setState({ editEventRecurring: undefined });

    updateEditEventRecurring = (editEventRecurring?: IEventRecurring) => this.setState({ editEventRecurring });

    render() {
        const { currentEvent, isCreate, createErrors, editEventRecurring, loadingFromTemplate, availableUsersLoading, eventRecurring, hasError, checkBeforeLoading } = this.state;
        const { intl, isSmartphone, loading, company } = this.props;
        const canManage = checkRBACRule(Rules.Planning.Management);
        const title = isCreate ? <FormattedMessage defaultMessage={'Add an event'} /> : <FormattedMessage defaultMessage={'Edit event {title}'} values={{ title: currentEvent.title }} />;
        const disableCreate = availableUsersLoading || hasError || loadingFromTemplate;
        const loadingCreate = loading || checkBeforeLoading;

        return (
            currentEvent ?
                <>
                    <Drawer
                        destroyOnClose={true}
                        width={isSmartphone ? '100%' : "550px"}
                        closable={false}
                        maskClosable={false}
                        title={
                            <div style={{ display: 'flex', justifyContent: isSmartphone ? 'center' : 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}>
                                <p>{title}</p>
                                <SelectFromTemplate isCreate={isCreate} loadTemplate={this.loadTemplate} />
                            </div>
                        }
                        open={!!currentEvent}
                        bodyStyle={{ padding: '15px' }}
                        footer={
                            <SpaceContent style={{ justifyContent: 'space-between' }}>
                                <Button type="dashed" onClick={this.onCancel} key="planning-modal-cancel-button">
                                    <FormattedMessage defaultMessage={'Cancel'} />
                                </Button>
                                <div style={{ display: "flex", gap: '5px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1px', justifyContent: 'center', alignItems: 'center' }}>
                                        <CircleButton
                                            disabled={!!eventRecurring}
                                            title={intl.formatMessage({ defaultMessage: 'Send a notification message to the users concerned' })}
                                            icon={<FAIcon prefix='fad' name='bell' />}
                                            type={this.state.sendMessage ? 'primary' : 'default'}
                                            small
                                            onClick={() => this.dataChanged(EventDataType.SendMessage, !this.state.sendMessage)}
                                        />
                                        {
                                            canManage && this.state.isCreate ?
                                                <CircleButton
                                                    disabled={!!eventRecurring}
                                                    title={intl.formatMessage({ defaultMessage: 'Create a template' })}
                                                    icon={<FAIcon prefix='fad' name='layer-group' />}
                                                    type={this.state.asTemplate ? 'primary' : 'default'}
                                                    small
                                                    onClick={() => this.dataChanged(EventDataType.AsTemplate, !this.state.asTemplate)}
                                                />
                                                : null
                                        }
                                        {
                                            company?.isTimeClockEnabled ?
                                                <CircleButton
                                                    title={intl.formatMessage({ defaultMessage: 'Activate the timeclock' })}
                                                    icon={<FAIcon prefix='fad' name='stopwatch' />}
                                                    type={currentEvent.ignoreTimeClock ? 'default' : 'primary'}
                                                    small
                                                    onClick={() => this.dataChanged(EventDataType.IgnoreTimeClock, !currentEvent.ignoreTimeClock)}
                                                />
                                                : null
                                        }
                                    </div>
                                    {
                                        currentEvent.clocked && !currentEvent.clocked.isRefused && !currentEvent.ignoreTimeClock ?
                                            <Popconfirm
                                                disabled={disableCreate}
                                                open={this.state.popConfirmAvailabilityError || createErrors.length > 0}
                                                title={
                                                    createErrors.length > 0 ?
                                                        this.renderCreationErrors()
                                                        :
                                                        this.renderClockError()
                                                }
                                                okText={isCreate ? <FormattedMessage defaultMessage={'Force create'} /> : <FormattedMessage defaultMessage={'Force edit'} />}
                                                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                                okButtonProps={{ danger: true, loading: loadingCreate, disabled: loadingCreate }}
                                                cancelButtonProps={{ type: 'dashed' }}
                                                icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                                                placement={'topRight'}
                                                onConfirm={this.onForceConfirm}
                                                onCancel={() => this.setState({ popConfirmAvailabilityError: false })}>
                                                <Popconfirm
                                                    disabled={disableCreate}
                                                    title={<FormattedMessage defaultMessage={'Confirming this modification will adjust the current event hours and mark them as edited by the administrator.'} />}
                                                    okText={<FormattedMessage defaultMessage={'Confirm modification'} />}
                                                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                                    okButtonProps={{ type: 'link' }}
                                                    cancelButtonProps={{ type: 'link' }}
                                                    icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                                    placement={'topRight'}
                                                    onConfirm={() => this.onClickBeforConfirm()}
                                                    onCancel={(e) => e?.stopPropagation()}>
                                                    <Button
                                                        disabled={disableCreate}
                                                        id="planning-modal-ok-button"
                                                        type='primary'
                                                        onClick={(e) => e.stopPropagation()}
                                                        loading={loadingCreate}
                                                        key="planning-modal-button-ok">
                                                        <FormattedMessage defaultMessage={'Save and modify the timeclock'} />
                                                    </Button>
                                                </Popconfirm>
                                            </Popconfirm>
                                            :
                                            <Popconfirm
                                                disabled={disableCreate}
                                                open={createErrors.length > 0}
                                                title={
                                                    this.renderCreationErrors()
                                                }
                                                okText={isCreate ? <FormattedMessage defaultMessage={'Force create'} /> : <FormattedMessage defaultMessage={'Force edit'} />}
                                                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                                okButtonProps={{ danger: true, loading: loadingCreate, disabled: loadingCreate }}
                                                cancelButtonProps={{ type: 'dashed' }}
                                                placement={'topRight'}
                                                onConfirm={this.onForceConfirm}
                                                icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                                                onCancel={() => this.setState({ popConfirmAvailabilityError: false, createErrors: [] })}>
                                                <Button
                                                    type='primary'
                                                    disabled={disableCreate}
                                                    id="planning-modal-ok-button"
                                                    onClick={() => this.onClickBeforConfirm()}
                                                    loading={loadingCreate}
                                                    key="planning-modal-button-ok"
                                                >
                                                    {isCreate ? <FormattedMessage defaultMessage={'Add'} /> : <FormattedMessage defaultMessage={'Save'} />}
                                                </Button>
                                            </Popconfirm>
                                    }
                                </div>
                            </SpaceContent>
                        }>
                        <Spin spinning={loadingFromTemplate}>
                            {this.renderModalContent()}
                        </Spin>
                        <UploadModal
                            eventId={Number(this.state.currentEvent.id)}
                            type={this.state.addDocuments ? 'FLE' : this.state.addImages ? 'IMG' : 'VDO'}
                            title={this.state.addDocuments ? intl.formatMessage({ defaultMessage: 'Add documents' }) : this.state.addImages ? intl.formatMessage({ defaultMessage: 'Add images' }) : undefined}
                            onClose={(cancel?: boolean) => this.setState((state) => ({ addDocuments: false, addImages: false, filesToValidate: cancel ? [] : state.filesToValidate, imagesToValidate: cancel ? [] : state.imagesToValidate }))}
                            open={this.state.addDocuments || this.state.addImages}
                            files={this.state.files}
                            addFiles={(files) => this.setState({ files: files })}
                            filesToValidate={this.state.addDocuments ? this.state.filesToValidate : this.state.addImages ? this.state.imagesToValidate : []}
                            setFilesToValidate={(newFilesToValidate: ValidateFile[]) => {
                                if (this.state.addDocuments) {
                                    this.setState({ filesToValidate: newFilesToValidate.filter(f => f.type === 'ValidateFile' && f.res_type === 'FLE') });
                                } else if (this.state.addImages) {
                                    this.setState({ imagesToValidate: newFilesToValidate.filter(f => f.type === 'ValidateFile' && f.res_type === 'IMG') });
                                }
                            }}
                        />
                        <ReminderModal
                            event={this.state.currentEvent}
                            onClose={() => this.setState({ addReminder: false, editReminder: undefined })}
                            open={this.state.addReminder}
                            reminder={this.state.editReminder}
                            onSave={(reminder: Reminder) => this.saveEventReminder(reminder)}
                        />
                        <EditRecurringModal
                            eventRecurring={editEventRecurring}
                            onOk={this.saveEventRecurring}
                            onCancel={this.cancelEventRecurring}
                            onChange={this.updateEditEventRecurring}

                        />
                    </Drawer>
                </>
                : null

        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),
    loadTypesOfVehicle: (fr?: boolean) => dispatch(loadTypesOfVehicle(fr)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
    settings: state.planning.settings,
    templates: state.planning.templates,
    typesOfDay: state.configurations.typesOfDay,
    typesOfDayOff: state.configurations.typesOfDayOff,
    project: state.configurations.project,
    vehicles: state.configurations.vehicles,
    basePois: state.location.basePois,
    users: selectActiveUsers(state),
    groups: state.teamManagement.groups,
    pois: state.location.pois,
    departments: state.configurations.departments

});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(withProjectName(withFullName(ModifyEvent))));


interface SelectFromTemplateProps {
    isCreate: boolean;
    loadTemplate: (templateId?: number) => void;
}
const SelectFromTemplate: React.FC<SelectFromTemplateProps> = (props: SelectFromTemplateProps) => {
    const templates = useSelector((state: ApplicationState) => state.planning.templates);
    if (!props.isCreate || isNullOrEmpty(templates)) return null;
    return (
        <Select
            style={{ width: '300px' }}
            placeholder={<FormattedMessage defaultMessage={'Load data from a template'} />}
            allowClear
            showSearch
            onChange={(id) => props.loadTemplate(id)}
            filterOption={true}
            optionFilterProp="label"
        >
            {templates.map(t => <Select.Option label={t.title} key={`planning-templates-${t.id}`} value={t.id}>{t.title}</Select.Option>)}
        </Select>
    )
};

interface RecurringProps {
    editEventRecurring: () => void;
}
interface RecurringButtonProps extends RecurringProps {
    hasEventRecurring: boolean;
}
const RecurringButton = ({ hasEventRecurring, editEventRecurring }: RecurringButtonProps) => {
    const intl = useIntl();
    if (!hasEventRecurring) {
        return (
            <Popconfirm
                title={<FormattedMessage defaultMessage={'Recurrence disables the use of files, notifications, and template creation. Would you like to proceed?'} />}
                onConfirm={editEventRecurring}
                okText={<FormattedMessage defaultMessage={'Yes'} />}
                cancelText={<FormattedMessage defaultMessage={'No'} />}
                placement="left"
            >
                <FAIcon
                    style={{ cursor: 'pointer' }}
                    prefix='fad'
                    name='repeat'
                    title={intl.formatMessage({ defaultMessage: 'Recurring' })} />
            </Popconfirm>
        );
    }

    return (
        <FAIcon
            style={{ cursor: 'pointer' }}
            prefix='fad'
            name='repeat'
            onClick={editEventRecurring}
            title={intl.formatMessage({ defaultMessage: 'Recurring' })} />
    );
};

interface ActivateRecurringProps extends RecurringProps {
    eventRecurring?: IEventRecurring
}
const ActivateRecurring: React.FC<ActivateRecurringProps> = (props: ActivateRecurringProps) => {
    const hasEventRecurring = useMemo(() => Boolean(props.eventRecurring), [props.eventRecurring]);

    return (
        <Badge
            size={'small'}
            count={hasEventRecurring ? 1 : 0}
        >
            <RecurringButton hasEventRecurring={hasEventRecurring} editEventRecurring={props.editEventRecurring} />
        </Badge>
    )
}
interface SelectUsersProps {
    listOfUsers: AvailableUsers[];
    userIds: number[];
    onChange: (value: number[]) => void;
    tagRender: (props: any) => ReactElement<number>;
}
const SelectUsers: React.FC<SelectUsersProps> = React.memo((props: SelectUsersProps) => {
    const empty = useMemo(() => isNullOrEmpty(props.listOfUsers), [props.listOfUsers]);
    const compatibleUsers = useMemo(() => props.listOfUsers.filter(u => !u.missingHability && !u.missingLocation && !u.overlap && !u.unavailable && !u.locked), [props.listOfUsers]);
    const incompatibleUsers = useMemo(() => props.listOfUsers.filter(u => !u.locked && (u.missingHability || u.missingLocation || u.overlap || u.unavailable)), [props.listOfUsers]);
    const lockedUsers = useMemo(() => props.listOfUsers.filter(u => u.locked), [props.listOfUsers]);

    const fullNameProvider = useFullName();
    const intl = useIntl();
    return (
        <Select
            showSearch
            style={{ width: '240px' }}
            disabled={empty || props.userIds.length >= MAX_USERS}
            onChange={(value) => value ? props.onChange([...props.userIds, value]) : undefined}
            placeholder={empty ? <FormattedMessage defaultMessage={'No users available'} /> : <FormattedMessage defaultMessage={'Add a user'} />}
            tagRender={props.tagRender}
            filterOption={true}
            value={null}
            optionFilterProp="title"
            dropdownRender={menu => (
                <>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            style={{ fontSize: '90%', fontStyle: 'italic' }}
                            type='link'
                            onClick={() => props.onChange([...props.userIds, ...compatibleUsers.map(u => u.id).slice(0, (MAX_USERS - props.userIds.length))])}>
                            <FormattedMessage defaultMessage={'Select all available users'} />
                        </Button>
                    </div>
                </>
            )}
            options={[
                {
                    label: <FormattedMessage defaultMessage={'Compatible users'} />,
                    options: (
                        isNullOrEmpty(compatibleUsers) ?
                            [{
                                value: -1,
                                label: <FormattedMessage defaultMessage={'No users'} />,
                                disabled: true
                            }]
                            :
                            compatibleUsers.map(u => {
                                const label = fullNameProvider.getFullName(u);

                                return ({
                                    value: u.id,
                                    label: <p title={label}>{label}</p>,
                                    title: label,
                                });
                            })

                    )
                },
                {
                    label: <FormattedMessage defaultMessage={'Incompatible users'} />,
                    options: (
                        isNullOrEmpty(incompatibleUsers) ?
                            [{
                                value: -1,
                                label: <FormattedMessage defaultMessage={'No users'} />,
                                disabled: true
                            }]
                            :
                            incompatibleUsers.map(u => {
                                const { label, title, className } = problemMessage(u, intl, fullNameProvider.getFullName);

                                return ({
                                    value: u.id,
                                    label: <p title={title}>{label}</p>,
                                    title: label,
                                    className: className
                                });
                            })
                    )
                },
                {
                    label: <FormattedMessage defaultMessage={'Locked users'} />,
                    options: (
                        isNullOrEmpty(lockedUsers) ?
                            [{
                                value: -1,
                                label: <FormattedMessage defaultMessage={'No users'} />,
                                disabled: true
                            }]
                            :
                            lockedUsers.map(u => {
                                const { label, title, className } = problemMessage(u, intl, fullNameProvider.getFullName);

                                return ({
                                    value: u.id,
                                    label: <p title={title}>{label}</p>,
                                    title: label,
                                    className: className
                                });
                            })
                    )
                }
            ]}
        />
    )
}, (prevProps, nextProps) => isEqual(prevProps.userIds, nextProps.userIds) && isEqual(prevProps.listOfUsers, nextProps.listOfUsers));
SelectUsers.displayName = 'SelectUsers';

function problemDetail(p: MessageDescriptor, u: AvailableUsers) {
    let dates_detail = "";
    switch (p) {
        case UserProblemIntl.locked:
            dates_detail += " :"
            u.lockedDates?.forEach(ld => dates_detail += ` \n\t- ${moment(ld).format(getFormat("DATE"))}`)
            break;
        case UserProblemIntl.overlap:
            dates_detail += " :"
            u.overlapDates?.forEach(ld => dates_detail += ` \n\t- ${moment.utc(ld.startDate).format(getFormat("DATE_AND_TIME_SHORT"))} - ${moment.utc(ld.endDate).format(getFormat("TIME_SHORT"))}`)
            break;
        case UserProblemIntl.unavailable:
            dates_detail += " :"
            u.unavailableDates?.forEach(ld => dates_detail += ` \n\t- ${moment(ld).format(getFormat("DATE"))}`)
            break;
        default:
            break;
    }
    return dates_detail;
}

function problemMessage(u: AvailableUsers, intl: IntlShape, getFullName: (user: UserSmall) => string) {
    const label = getFullName(u);
    let className = "";
    let title = label;

    if (u.locked) className = "select-line-error-dashed";
    else if (u.unavailable || u.overlap) className = "select-line-error";
    else if (u.missingHability || u.missingLocation) className = 'select-line-warning';

    u.problems?.forEach(p => {
        title = `${title}\n • ${intl.formatMessage(p)}`;
        const dates_detail = problemDetail(p, u);

        title = `${title}${dates_detail}`;
    });

    return ({
        label,
        title,
        className
    });
}

export function automaticChangeEndDate(startDate: Moment, endDate: Moment) {
    const newStartDate = startDate.clone();
    const newEndDate = endDate.clone();
    if (newEndDate.diff(newStartDate, "seconds") > 86399) newEndDate.subtract(1, "days");
    else if (newEndDate.isBefore(newStartDate)) newEndDate.add(1, "days");

    return [newStartDate, newEndDate];
}
