import React from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { createButton } from "react-simple-wysiwyg";
import FAIcon from "../../FAIcon";

const EditorIntl = defineMessages({
    bold: { defaultMessage: 'Bold' },
    italic: { defaultMessage: 'Italic' },
    underline: { defaultMessage: 'Underline' },
    insertLink: { defaultMessage: 'Insert Link' },
    bulletList: { defaultMessage: 'Bullet List' },
    numberedList: { defaultMessage: 'Numbered List' },
    redo: { defaultMessage: 'Redo' },
    undo: { defaultMessage: 'Undo' },
    clearFormatting: { defaultMessage: 'Clear Formatting' },
});

const useLocalizedButton = (descriptor: MessageDescriptor, icon: React.ReactNode, command: string) => {
    const intl = useIntl();
    return createButton(intl.formatMessage(descriptor), icon, command);
};

export const BtnBold: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.bold, <FAIcon prefix="fad" name="bold" />, "bold");
    return <Button />;
};

export const BtnItalic: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.italic, <FAIcon prefix="fad" name="italic" />, "italic");
    return <Button />;
};

export const BtnUnderline: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.underline, <FAIcon prefix="fad" name="underline" />, "underline");
    return <Button />;
};

export const BtnLink: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.insertLink, <FAIcon prefix="fad" name="link" />, "createLink");
    return <Button />;
};

export const BtnBulletList: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.bulletList, <FAIcon prefix="fad" name="list-ul" />, "insertUnorderedList");
    return <Button />;
};

export const BtnNumberedList: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.numberedList, <FAIcon prefix="fad" name="list-ol" />, "insertOrderedList");
    return <Button />;
};

export const BtnRedo: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.redo, <FAIcon prefix="fad" name="rotate-right" />, "redo");
    return <Button />;
};

export const BtnUndo: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.undo, <FAIcon prefix="fad" name="rotate-left" />, "undo");
    return <Button />;
};

export const BtnClearFormatting: React.FC = () => {
    const Button = useLocalizedButton(EditorIntl.clearFormatting, <FAIcon prefix="fad" name="text-slash" />, "removeFormat");
    return <Button />;
};