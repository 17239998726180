import { createTheme, CssBaseline, SxProps, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { frFR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Button, Popconfirm, Space, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { changeUserAvailabilities, loadUserAvailabilities } from '../../../store/actions/planning';
import '../../../styles/external-app.css';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { RouterProps, User } from '../../../utils/types/generalTypes';
import { GeneralResponse } from '../../../utils/types/networkTypes';
import { UserAvailability, UserAvailabilityEdit } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { availabilityRruleToString, colorIsBright, showNotification } from '../../../utils/utils';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import { AppTheme } from '../../specialRoutes/externalRouteSelector';
import AvailabilityEditDrawer from '../../teamManagement/userAvailability/availabilityEditDrawer';
import { AvailabilityState } from '../../teamManagement/userAvailability/availabilityEditForm';
import { UserAvailabilityStatus } from '../../teamManagement/userAvailability/userAvailabilityTab';
import { IntlProps } from '../LanguageProvider';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouterProps, IntlProps {
    theme: AppTheme;
    user?: User;
}
interface State {
    firstLoad: boolean;
    editAvailability?: UserAvailabilityEdit;
    year: Moment;
}

class Availabilities extends React.Component<Props, State> {
    TimePickerStyle: SxProps;

    constructor(props: Props) {
        super(props);
        this.state = {
            year: moment(),
            firstLoad: true
        };

        this.TimePickerStyle = [
            {
                '.MuiFormControl-root': {
                }
            },
            {
                '.MuiInputBase-root': {
                    borderRadius: '30px ',
                }
            },
            {
                '.MuiInputBase-root:hover fieldset': {
                    borderColor: '#c78034',
                }
            },
            {
                '.Mui-focused fieldset': {
                    borderColor: '#c78034!important',
                    borderWidth: '1px!important'
                }
            },
            {
                input: {
                    textAlign: 'center',
                    padding: '0px 10px',
                    height: '33px',
                    color: this.props.theme.color,
                    fontSize: '16px',
                    fontFamily: 'Nunito, sans-serif'
                }
            },
            {
                fieldset: {
                    borderColor: 'var(--border-color)',
                }
            },
        ];
    }

    componentDidMount() {
        if (this.props.user) {
            this.getAvailabilities();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.user, this.props.user)) {
            this.getAvailabilities();
        }

        if (prevProps.loadingAvailabilities !== this.props.loadingAvailabilities && this.props.loadingAvailabilities === false) {
            this.setState({ firstLoad: false });
        }
    }

    columns = (): ColumnsType<UserAvailability> => [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
            className: '__width_200',
        },
        {
            title: <FormattedMessage defaultMessage={'Description'} />,
            key: 'description',
            className: '__width_180 ',
            render: (_, record) => {
                if (record.isContinue) {
                    return <FormattedMessage defaultMessage={'{availability} from {startDate} to {endDate}'} values={{ availability: record.user.availabilityClosedByDefault ? this.props.intl.formatMessage({ defaultMessage: 'Available' }) : this.props.intl.formatMessage({ defaultMessage: 'Unavailable' }), startDate: `${record.startDateRule.format(getFormat('DATE'))} ${record.startTime.format(getFormat('TIME'))}`, endDate: `${record.endDateRule.format(getFormat('DATE'))} ${record.endTime.format(getFormat('TIME'))}` }} />;
                } else {
                    return `${record.user.availabilityClosedByDefault ? this.props.intl.formatMessage({ defaultMessage: 'Available' }) : this.props.intl.formatMessage({ defaultMessage: 'Unavailable' })} ${availabilityRruleToString(this.props.intl, record, false).toLocaleLowerCase()}`;
                }
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Creator'} />,
            key: 'isUserCreated',
            dataIndex: 'isUserCreated',
            className: '__width_150 __centered-text',
            render: (isUserCreated: boolean) => {
                if (isUserCreated) {
                    return <span><FormattedMessage defaultMessage={'User'} /></span>;
                } else {
                    return <span><FormattedMessage defaultMessage={'Administrator'} /></span>;
                }
            },
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'User'} />,
                    value: true
                },
                {
                    text: <FormattedMessage defaultMessage={'Administrator'} />,
                    value: false,
                }
            ],
            filterMultiple: false,
            onFilter: (isUserCreated, record) => record.isUserCreated === isUserCreated,
        },
        {
            title: <FormattedMessage defaultMessage={'Status'} />,
            key: 'status',
            className: '__width_100 __centered-text',
            render: (_, record) => {
                if (record.isConfirmed) {
                    return <span title={this.props.intl.formatMessage({ defaultMessage: 'Confirmed' })}><FAIcon prefix='fad' name='circle-check' color={GREEN_COLOR} /></span>;
                } else if (record.isRefused) {
                    return <span title={this.props.intl.formatMessage({ defaultMessage: 'Rejected' })}><FAIcon prefix='fad' name='circle-xmark' color={GREEN_COLOR} /></span>;
                } else {
                    return <span title={this.props.intl.formatMessage({ defaultMessage: 'Pending' })}><FAIcon prefix='fad' name='circle-question' color={GREEN_COLOR} /></span>;
                }
            },
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Confirmed'} />,
                    value: UserAvailabilityStatus.CONFIRMED,
                },
                {
                    text: <FormattedMessage defaultMessage={'Rejected'} />,
                    value: UserAvailabilityStatus.REFUSED,
                },
                {
                    text: <FormattedMessage defaultMessage={'Pending'} />,
                    value: UserAvailabilityStatus.WAITING,
                }
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                switch (value) {
                    case UserAvailabilityStatus.CONFIRMED:
                        return record.isConfirmed;
                    case UserAvailabilityStatus.REFUSED:
                        return record.isRefused;
                    default:
                        return record.isConfirmed === false && record.isRefused === false;
                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            fixed: 'right',
            className: '__width_80 __centered-text',
            render: (_, record) => {
                const notHaveRights = record.isConfirmed || record.isRefused || !record.isUserCreated;
                return (
                    <Space>
                        <DeleteButton
                            disabled={notHaveRights}
                            text={<FormattedMessage defaultMessage={'Do you want to delete this avalaibility?'} />}
                            key="template-modal-delete-color"
                            onConfirm={() => this.deleteAvailabilities([record.id])}
                            placement="topRight"
                            buttonPlacement="right"
                        />
                        <CircleButton
                            disabled={notHaveRights}
                            icon={<FAIcon prefix='fad' name='pencil' />}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                            placement="left"
                            small
                            onClick={() => this.startEditPeriod(cloneDeep(record))} />
                    </Space>
                );
            },
        }
    ];

    startEditPeriod = (availability?: UserAvailabilityEdit) => {
        if (this.props.user && availability === undefined) {
            availability = {
                user: {
                    id: this.props.user.id,
                    firstName: this.props.user.first_name,
                    lastName: this.props.user.last_name,
                    availabilityClosedByDefault: false, //TODO
                },
                rrule: undefined,
                isContinue: true
            };
        }
        this.setState({ editAvailability: cloneDeep(availability) });
    };


    deleteAvailabilities = (availabilities: number[]) => {
        Network.deleteUserAvailability(availabilities).then(
            (response: GeneralResponse) => {
                const availabilities = this.props.teamAvailabilities.find(ta => ta.userId === this.props.user?.id);
                const successIds = response.successIds;
                if (successIds !== undefined && successIds.length > 0) {
                    if (this.props.user)
                        this.props.changeUserAvailabilities(this.props.user.id, availabilities ? availabilities.data.filter(d => !successIds.includes(d.id)) : []);
                }
                if (response.error) {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while deleting the availability(s), please reload the page' }), "error");
                } else {
                    const isPlural = response.successIds !== undefined && response.successIds.length > 1;
                    showNotification(isPlural ? this.props.intl.formatMessage({ defaultMessage: 'The {ids} availabilities have been successfully deleted' }, { ids: response.successIds?.toString() }) : this.props.intl.formatMessage({ defaultMessage: 'The availability has been successfully deleted' }), "success");
                }
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while deleting the availability(s), please reload the page' }), "error");
            }
        );
    };

    changeYear = (year: null | Moment) => {
        if (year) this.setState({ year: year }, () => this.getAvailabilities());
    };

    getAvailabilities = () => {
        if (this.props.user) {
            this.props.loadUserAvailabilities(this.props.user.id, this.state.year, true);
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: "An error occured while loading your account's data" }), "error");
        }
    };

    render() {
        const { year, editAvailability } = this.state;
        const { teamAvailabilities, loadingAvailabilities, intl } = this.props;
        const availabilityClosedByDefault = this.props.user?.availabilityClosedByDefault;
        const availabilities = teamAvailabilities.find(ta => ta.userId === this.props.user?.id);

        const themePicker = createTheme({
            palette: {
                mode: this.props.theme && colorIsBright(this.props.theme.backgroundColor) ? 'light' : 'dark',
            },
        });

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', zIndex: 3, paddingTop: '10px', paddingBottom: '10px', marginTop: '-10px', position: 'sticky', top: '0px', left: 0, width: '100%', backgroundColor: this.props.theme.backgroundColor }}>
                    <Button
                        style={{ minWidth: "32px", backgroundColor: this.props.theme.backgroundColor, color: this.props.theme.color, borderColor: this.props.theme.color + "aa" }}
                        icon={<FAIcon prefix='far' name='chevron-left' />}
                        title={intl.formatMessage({ defaultMessage: 'Previous year' })}
                        disabled={loadingAvailabilities || this.state.firstLoad}
                        onClick={() => this.changeYear(this.state.year.clone().subtract(1, 'year'))}
                    />
                    <ThemeProvider theme={themePicker}>
                        <CssBaseline />
                        <LocalizationProvider dateAdapter={AdapterMoment} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <MobileDatePicker
                                sx={this.TimePickerStyle}
                                views={['year']}
                                value={year}
                                onChange={this.changeYear}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                    <Button
                        style={{ minWidth: "32px", backgroundColor: this.props.theme.backgroundColor, color: this.props.theme.color, borderColor: this.props.theme.color + "aa" }}
                        icon={<FAIcon prefix='far' name='chevron-right' />}
                        title={intl.formatMessage({ defaultMessage: 'Next year' })}
                        disabled={loadingAvailabilities || this.state.firstLoad}
                        onClick={() => this.changeYear(this.state.year.clone().add(1, 'year'))}
                    />
                </div>
                <div style={{ marginTop: '15px' }}>
                    {
                        availabilities === undefined || availabilities.data.length === 0 ?
                            <span className="dashboard-empty">
                                <FAIcon prefix='fad' name='inbox' />
                                <p><FormattedMessage defaultMessage={'No availability configured'} /></p>
                            </span>
                            :
                            <Spin size='large' spinning={loadingAvailabilities || this.state.firstLoad}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {
                                        availabilities.data?.map(record => {
                                            const disabledActions = record.isConfirmed || record.isRefused || !record.isUserCreated;
                                            let status: React.ReactNode = (
                                                <>
                                                    <FAIcon prefix='fad' name='circle-question' color='white' />
                                                    <span style={{ lineHeight: '15px', color: 'white' }}><FormattedMessage defaultMessage={'Pending'} /></span>
                                                </>
                                            );
                                            let statusBackgroundColor = YELLOW_COLOR;
                                            let statusColor = colorIsBright(statusBackgroundColor) ? "black" : 'white';
                                            if (record.isConfirmed) {
                                                status = (
                                                    <>
                                                        <FAIcon prefix='fad' name='circle-check' />
                                                        <span style={{ lineHeight: '15px' }}><FormattedMessage defaultMessage={'Confirmed'} /></span>
                                                    </>
                                                );
                                                statusBackgroundColor = GREEN_COLOR;
                                                statusColor = colorIsBright(statusBackgroundColor) ? "black" : 'white';
                                            } else if (record.isRefused) {
                                                status = (
                                                    <>
                                                        <FAIcon prefix='fad' name='circle-xmark' color='white' />
                                                        <span style={{ lineHeight: '15px', color: 'white' }}><FormattedMessage defaultMessage={'Rejected'} /></span>
                                                    </>
                                                );
                                                statusBackgroundColor = RED_COLOR;
                                                statusColor = colorIsBright(statusBackgroundColor) ? "black" : 'white';
                                            }

                                            return (
                                                <div key={`availabilities-data-${record.id}`}>
                                                    <div style={{ position: 'relative', borderRadius: '10px 10px 10px 0', backgroundColor: this.props.theme.backgroundAccent, padding: '10px 10px 0px 10px' }}>
                                                        {
                                                            !record.isUserCreated ?
                                                                <div style={{ position: 'absolute', width: '100%', display: 'flex', top: '0', left: '0', zIndex: 1 }}>
                                                                    <div style={{ flex: 1, backgroundColor: this.props.theme.company, borderRadius: '15px 0 0 0' }}>
                                                                        <div style={{ borderRadius: '10px 10px 0 0', width: '100%', height: '100%', backgroundColor: this.props.theme.backgroundAccent }}>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ fontSize: '15px', width: 'fit-content', padding: '0 10px', backgroundColor: this.props.theme.company, borderRadius: '0 10px 0 10px', color: colorIsBright(this.props.theme.company) ? "black" : "white" }}>{'Administrateur'}</div>
                                                                </div>
                                                                : null
                                                        }
                                                        <div style={{ position: 'relative', fontWeight: 'bold', zIndex: 2 }}>
                                                            <p style={{ fontWeight: 'bold' }}>{record.title}</p>
                                                        </div>
                                                        <div style={{ paddingBottom: '5px' }}>
                                                            {
                                                                record.isContinue ?
                                                                    <FormattedMessage defaultMessage={'{availability} from {startDate} to {endDate}'} values={{ availability: record.user.availabilityClosedByDefault ? <FormattedMessage defaultMessage={'Available'} /> : <FormattedMessage defaultMessage={'Unavailable'} />, startDate: `${record.startDateRule.format(getFormat('DATE'))} ${record.startTime.format(getFormat('TIME'))}`, endDate: `${record.endDateRule.format(getFormat('DATE'))} ${record.endTime.format(getFormat('TIME'))}` }} />
                                                                    :
                                                                    `${record.user.availabilityClosedByDefault ? <FormattedMessage defaultMessage={'Available'} /> : <FormattedMessage defaultMessage={'Unavailable'} />} ${availabilityRruleToString(intl, record, false).toLocaleLowerCase()}`

                                                            }
                                                        </div>
                                                        {
                                                            !disabledActions ?
                                                                <>
                                                                    <div style={{ borderTop: '1px solid #bababa', width: 'calc(100% + 20px)', marginLeft: '-10px' }} />
                                                                    <div style={{ fontSize: '15px', textAlign: 'right', paddingTop: '3px', paddingBottom: '3px', lineHeight: '15px' }}>
                                                                        <Space>
                                                                            <div
                                                                                style={{ display: 'flex', alignItems: 'center', gap: '3px', opacity: disabledActions ? '0.5' : 1, cursor: disabledActions ? 'not-allowed' : 'pointer' }}
                                                                                onClick={() => !disabledActions && this.startEditPeriod(cloneDeep(record))}
                                                                            >
                                                                                <FAIcon prefix='fad' name='pencil' />
                                                                                <span style={{ lineHeight: '15px' }}><FormattedMessage defaultMessage={'Edit'} /></span>
                                                                            </div>
                                                                            <Popconfirm
                                                                                disabled={disabledActions}
                                                                                title={intl.formatMessage({ defaultMessage: 'Do you want to delete this availability?' })}
                                                                                okText={intl.formatMessage({ defaultMessage: 'Delete' })}
                                                                                cancelText={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                                                                okButtonProps={{ type: 'link', danger: true }}
                                                                                cancelButtonProps={{ type: 'link' }}
                                                                                icon={<FAIcon prefix='fad' name='circle-question' color='#ff2626' />}
                                                                                placement={"top"}
                                                                                onConfirm={() => !disabledActions && this.deleteAvailabilities([record.id])}
                                                                                onCancel={(e) => e?.stopPropagation()}>
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        gap: '3px',
                                                                                        opacity: disabledActions ? '0.5' : 1,
                                                                                        cursor: disabledActions ? 'not-allowed' : 'pointer'
                                                                                    }}
                                                                                >
                                                                                    <FAIcon prefix='fad' name='trash-can' />
                                                                                    <span style={{ lineHeight: '15px' }}><FormattedMessage defaultMessage={'Delete'} /></span>
                                                                                </div>
                                                                            </Popconfirm>
                                                                        </Space>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                    </div>
                                                    <div style={{ width: '100%', display: 'flex' }}>
                                                        <div
                                                            style={{
                                                                fontSize: '15px',
                                                                backgroundColor: statusBackgroundColor,
                                                                color: statusColor,
                                                                display: 'flex',
                                                                gap: '5px',
                                                                alignItems: 'center',
                                                                padding: '5px 10px 5px 5px',
                                                                borderRadius: '0 0 10px 10px',
                                                                width: 'fit-content',

                                                            }}>
                                                            {status}
                                                        </div>
                                                        <div style={{ flex: 1, backgroundColor: statusBackgroundColor }}>
                                                            <div style={{ borderRadius: '10px 0 0 0', width: '100%', height: '100%', backgroundColor: this.props.theme.backgroundColor }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Spin>
                    }
                </div>
                <div style={{ zIndex: 1, position: 'fixed', bottom: '10px', right: '10px', textAlign: 'right' }}>

                    <CircleButton
                        style={{ backgroundColor: this.props.theme.button, borderColor: this.props.theme.button, color: 'white' }}
                        placement='left'
                        disabled={editAvailability !== undefined || loadingAvailabilities}
                        icon={<FAIcon prefix='fas' name='plus' />}
                        small
                        title={intl.formatMessage({ defaultMessage: 'Add an availability' })}
                        onClick={() => this.startEditPeriod()} />
                </div>
                {
                    <AvailabilityEditDrawer
                        theme={this.props.theme}
                        availabilityState={availabilityClosedByDefault ? AvailabilityState.Close : AvailabilityState.Open}
                        availability={editAvailability}
                        onCancel={() => this.setState({ editAvailability: undefined })}
                        onOk={() => this.setState({ editAvailability: undefined })}
                        loading={loadingAvailabilities || this.state.firstLoad} />
                }
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    loadUserAvailabilities: (a: number, b: Moment, c?: boolean) => dispatch(loadUserAvailabilities(a, b, c)),
    changeUserAvailabilities: (a: number, b: UserAvailability[]) => dispatch(changeUserAvailabilities(a, b)),
});

const mapStateToProps = (state: ApplicationState) => ({
    height: state.window.height,
    teamAvailabilities: state.planning.teamAvailabilities,
    loadingAvailabilities: state.planning.loadingAvailabilities,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(Availabilities));