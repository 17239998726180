import { Modal } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { changeOccupancyRates, changeTypesOfDay } from "../../../store/actions/configurations";
import { changeTemplates } from "../../../store/actions/planning";
import { workItemCreated, workItemDeleted, workItemEdited } from "../../../store/features/productivitySlice";
import { Loaded, StatusType } from "../../../utils/types/networkTypes";
import { PlanningOccupancyRate, PlanningTemplate, TypeOfDay } from "../../../utils/types/planningTypes";
import { IWorkItem, IWorkItemBare } from "../../../utils/types/productivityTypes";
import { ApplicationState, ConfigurationsDispatchProps, PlanningDispatchProps, StoreDispatch } from "../../../utils/types/storeTypes";
import { showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import './productivityTab.css';
import WorkItemConfigModal, { WorkItemConfigRef } from "./workItemConfig";
import WorkItemCreate, { WorkItemCreateRef } from "./workItemCreate";

interface IProps {
    height: number;
}


type ReduxProps = ConnectedProps<typeof connector>;
type Props = IProps & ConfigurationsDispatchProps & PlanningDispatchProps & IntlProps & ReduxProps;


interface State {
    workItems: Loaded<IWorkItem[]>;
    createWorkItemModalOpened: boolean;
}

/**
 * Component for the productivity tab in the configurations page
 */
class ProductivityTab extends React.Component<Props, State> {
    configsRef = React.createRef<WorkItemConfigRef>();
    workItemRef = React.createRef<WorkItemCreateRef>();

    constructor(props: Props) {
        super(props);

        this.state = {
            createWorkItemModalOpened: false,
            workItems: { status: StatusType.NONE }
        };
    }

    createWorkItem = () => {
        this.setState({ createWorkItemModalOpened: true });
        this.workItemRef.current?.create();
    };

    editWorkItem = (workItem: IWorkItemBare) => {
        this.setState({ createWorkItemModalOpened: true });
        this.workItemRef.current?.edit(workItem);
    };

    openConfigPanel = (workItem: IWorkItem) => {
        this.configsRef.current?.setWorkItem(workItem);
    };

    handleWorkItem = () => {
        const item = this.workItemRef.current?.workItem;
        const onSuccess = () => this.setState({ createWorkItemModalOpened: false });
        const onFailure = () => showNotification(this.props.intl.formatMessage({ defaultMessage: "An error has occurred" }), "error");
        if (item) {
            if (item.id < 0)
                this.props.workItemCreated(item, onSuccess, onFailure);
            else
                this.props.workItemEdited(item, onSuccess, onFailure);
        }

    };

    columns: ColumnProps<IWorkItem>[] = [
        {
            title: <FormattedMessage defaultMessage={"Type of work"} />,
            key: "title",
            className: "__min-width-220",
            sorter: (a, b) => a.title.localeCompare(b.title),
            defaultSortOrder: 'ascend',
            render: (_, workItem) => (
                <div className="__pr_work_item_title_cell">
                    <div>
                        <FAIcon title={workItem.title} prefix={"fad"} name={workItem.icon.split(' ')[1]} />
                    </div>
                    <span>{workItem.title}</span>
                </div>
            )

        },
        {
            title: <FormattedMessage defaultMessage={"Actions"} />,
            key: "actions",
            align: 'right',
            className: "__width-220",
            render: (_, workItem) => (
                <div className="__pr_work_item_action_cell">
                    <CircleButton
                        small
                        placement='topLeft'
                        title={this.props.intl.formatMessage({ defaultMessage: 'Settings' })}
                        icon={<FAIcon prefix='fad' name='pencil' size="2x" />}
                        onClick={() => this.editWorkItem(workItem)} />
                    <CircleButton
                        small
                        placement='topLeft'
                        title={this.props.intl.formatMessage({ defaultMessage: 'Settings' })}
                        icon={<FAIcon prefix='fad' name='gear' size="2x" />}
                        onClick={() => this.openConfigPanel(workItem)} />
                    { /* 
                    WARNING
                    In order to enabled the delete button :
                        1. Make a query that returns the number of time a work item was used.
                        2. Display that number above the delete button
                        3. Make the delete button clicakble only after a few seconds
                        4. Force the user to type out the name of the work item he wants to delete.
                        Deleting work items can retro-actively change all productivity reports using it.
                    */ }
                    {/* <DeleteButton
                        small
                        onConfirm={() => this.props.workItemDeleted(workItem.id)}
                        text={<><p>{this.props.intl.formatMessage({ defaultMessage: "Are you sure that you want to delete this record ?" })}</p><p className='error'>{this.props.intl.formatMessage({ defaultMessage: "This action is irreversible and might cause productivity reports to change retro-actively !" })}</p></>}
                    /> */}
                </div>
            )
        }
    ];

    render() {
        let tableHeight = this.props.height - 50;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.workItems.data ?? []}
                    pagination={false}
                />

                <div className='__wic-add-row __clickable' onClick={this.createWorkItem}>
                    <FAIcon prefix='fad' name='circle-plus' color='grey' /> <FormattedMessage defaultMessage={"Add a type of work"} />
                </div>

                <WorkItemConfigModal ref={this.configsRef} />

                <Modal
                    open={this.state.createWorkItemModalOpened}
                    onCancel={() => this.setState({ createWorkItemModalOpened: false })}
                    onOk={this.handleWorkItem}
                    destroyOnClose={false}
                    forceRender={true}
                >
                    <WorkItemCreate ref={this.workItemRef} />
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeOccupancyRates: (o: PlanningOccupancyRate[]) => dispatch(changeOccupancyRates(o)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    workItemDeleted: (workItemId: IWorkItem['id']) => dispatch(workItemDeleted({ workItemId })),
    workItemCreated: (workItem: IWorkItemBare, onSuccess: () => void, onFailure: () => void) => dispatch(workItemCreated({ workItem, onSuccess, onFailure })),
    workItemEdited: (workItem: IWorkItemBare, onSuccess: () => void, onFailure: () => void) => dispatch(workItemEdited({ workItem, onSuccess, onFailure }))
});

const mapStateToProps = (state: ApplicationState) => ({
    height: state.window.height,
    workItems: state.productivity.workItems
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(ProductivityTab));
