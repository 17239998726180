import { captureMessage } from "@sentry/react";
import { Col, DatePicker, InputNumber, Row, Space } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeUser } from '../../../store/actions/teamManagement';
import Network from '../../../utils/network';
import { Company, User, UserYearlyParams } from '../../../utils/types/generalTypes';
import { UserContractSmall } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { alert, convertUserYearlyParamsToNetworkUserYearlyParams, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from "../../common/FAIcon";
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

interface IProps {
    user: User | undefined;
    company: Company | undefined;
}

type Props = IProps & TeamManagementDispatchProps & IntlProps;

interface State {
    year: number;
    loading: boolean;
    // currentUserYearlyParams?: UserYearlyParams;
    currentUserYearlyParamsByContract?: UserYearlyParams[];
    newUserYearlyParams?: UserYearlyParams;
    contractsForYear?: UserContractSmall[];
    userContractsLoading: boolean;
}

/**
 * Component that represent the user vacations tab
 */
class UserHoursTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
            loading: false,
            // currentUserYearlyParams: undefined,
            newUserYearlyParams: undefined,
            userContractsLoading: false
        };
    }



    getContracts = () => {
        if (this.props.user) {
            if (!this.state.userContractsLoading) {
                this.setState({ userContractsLoading: true });
                Network.getUserContracts(this.props.user.id, undefined, this.state.year)
                    .then(
                        response => {
                            this.setState({ contractsForYear: response });
                        },
                        () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the contracts' }), "error"),
                    )
                    .finally(() => this.setState({ userContractsLoading: false }));
            }
        }

    };

    componentDidMount() {
        this.getContracts();

        if (this.props.user?.id) {
            Network.getUserYearlyParams(this.props.user.id, this.state.year).then(
                response => {
                    this.setState({ currentUserYearlyParamsByContract: response });
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the initial annual data' }), "warning"),
            );
        }
    }

    onStartEditYearlyParamsByContract = (c: UserContractSmall, hours?: number, id?: number): void => {
        if (this.props.user?.id) {
            const newUserYearlyParams: UserYearlyParams = {
                id: id,
                year: this.state.year,
                user: this.props.user.id,
                userJob: c,
                initial_overtime_hours: hours,

            };
            this.setState({ newUserYearlyParams: newUserYearlyParams });
        } else {
            captureMessage(`Should never arrive here, user should be set : id:${String(id)} userjob:${String(c.id)} year: ${this.state.year}`);
        }

    };

    handleOnSubmitYearlyParamsByContract = () => {
        const { newUserYearlyParams } = this.state;
        const { intl } = this.props;
        newUserYearlyParams && Network.updateUserYearlyParams(convertUserYearlyParamsToNetworkUserYearlyParams(newUserYearlyParams)).then(
            response => {
                if (response.error) {
                    console.log("HDUWAHDWIAHDIAWUD");
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the initial annual data' }), "error");
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The initial annual data has been successfully updated' }), "success");
                    this.props.user && Network.getUserYearlyParams(this.props.user.id, this.state.year).then(
                        response => {
                            this.setState({ newUserYearlyParams: undefined, currentUserYearlyParamsByContract: response });
                        },
                        () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the initial annual data' }), "warning"),
                    );
                }
            },
            (error) => {
                if (typeof error === 'string')
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred' }), "error");
                else {
                    if (error.code === 'PLANNING_LOCKED')
                        showNotification(intl.formatMessage({ defaultMessage: "Reports using this value have already been signed or are awaiting signature." }), "error");
                    else
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred' }), "error");
                }
            },
        );
    };

    renderYearlyParamsByContract() {
        const contractsForYear = cloneDeep(this.state.contractsForYear);
        const userYearlyParams = cloneDeep(this.state.currentUserYearlyParamsByContract);
        const { intl } = this.props;
        if (userYearlyParams !== undefined) {


            const { newUserYearlyParams } = this.state;
            let paramsWithoutContract = cloneDeep(userYearlyParams);
            const contentToReturn: ReactNode[] = [];

            if (!isEmpty(contractsForYear)) {
                paramsWithoutContract = userYearlyParams.filter(u => u.userJob === undefined || contractsForYear!.findIndex(c => c.id === u.userJob?.id) === -1);

                contentToReturn.push(contractsForYear?.map((c, idx) => {
                    const paramsFound = userYearlyParams?.find(y => y.userJob?.id === c.id);
                    if (paramsFound) {
                        return (
                            <div key={`user-hours-tab-contractsForYear-${c.id}-${idx}-${paramsFound}`} className="team-create-user-body">
                                <span className="team-edit-user-item">
                                    {
                                        newUserYearlyParams?.id === paramsFound.id ?
                                            <Space>
                                                <p>{`${c.name}:`}</p>
                                                <InputNumber
                                                    style={{ marginLeft: '10px' }}
                                                    placeholder={intl.formatMessage({ defaultMessage: 'N° hours' })}
                                                    value={this.state.newUserYearlyParams?.initial_overtime_hours}
                                                    onChange={(e) => this.onChangeNumberOfHours(toFinite(e))} />
                                                <CircleButton
                                                    className='__single-line-element'
                                                    // loading={adminsLoading}
                                                    small={true}
                                                    title={intl.formatMessage({ defaultMessage: 'Save' })}
                                                    icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                                    onClick={this.handleOnSubmitYearlyParamsByContract} />
                                                <CircleButton
                                                    className='__single-line-element'
                                                    // loading={adminsLoading}
                                                    small={true}
                                                    title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                                    icon={<FAIcon prefix='far' name='xmark' />}
                                                    onClick={this.cancelEditionYearlyParams} />
                                            </Space>
                                            :
                                            <Space>
                                                <p><FormattedMessage defaultMessage={'{count, plural, one {{contract}: Start the year with {count} hour} other {{contract}: Start the year with {count} hours}}'} values={{ count: paramsFound.initial_overtime_hours, contract: c.name }} /></p>
                                                <FAIcon prefix='fad' name='pencil'
                                                    className='__single-line-element'
                                                    title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                                    style={{ "cursor": "pointer", marginLeft: '10px' }}
                                                    onClick={() => this.onStartEditYearlyParamsByContract(c, paramsFound.initial_overtime_hours, paramsFound.id)}
                                                />
                                            </Space>
                                    }
                                </span>
                            </div>
                        );
                    } else {
                        return (
                            <div key={`user-hours-tab-contractsForYear-${c.id}-${idx}-${paramsFound}`} className="team-create-user-body">
                                <span className="team-edit-user-item">
                                    {
                                        newUserYearlyParams?.id === -idx ?
                                            <Space>
                                                <p>{`${c.name}:`}</p>
                                                <InputNumber
                                                    style={{ marginLeft: '10px' }}
                                                    placeholder={intl.formatMessage({ defaultMessage: 'N° hours' })}
                                                    value={this.state.newUserYearlyParams?.initial_overtime_hours}
                                                    onChange={(e) => this.onChangeNumberOfHours(toFinite(e))} />
                                                <CircleButton
                                                    className='__single-line-element'
                                                    // loading={adminsLoading}
                                                    small={true}
                                                    title={intl.formatMessage({ defaultMessage: 'Save' })}
                                                    icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                                    onClick={this.handleOnSubmitYearlyParamsByContract} />
                                                <CircleButton
                                                    className='__single-line-element'
                                                    // loading={adminsLoading}
                                                    small={true}
                                                    title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                                    icon={<FAIcon prefix='far' name='xmark' />}
                                                    onClick={this.cancelEditionYearlyParams} />
                                            </Space>
                                            :
                                            <Space>
                                                <p><FormattedMessage defaultMessage={'{contract}: no initial hours configured'} values={{ contract: c.name }} /></p>
                                                <FAIcon prefix='fad' name='pencil'
                                                    className='__single-line-element'
                                                    title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                                    style={{ "cursor": "pointer", marginLeft: '10px' }}
                                                    onClick={() => this.onStartEditYearlyParamsByContract(c, undefined, -idx)}
                                                />
                                            </Space>
                                    }
                                </span>
                            </div>
                        );
                    }

                }));
            }
            if (!isEmpty(paramsWithoutContract)) {
                contentToReturn.push(paramsWithoutContract.map(c => {
                    return (
                        <div key={`user-hours-tab-paramsWithoutContract-${c.id}`} className="team-create-user-body">
                            <span className="team-edit-user-item">
                                {
                                    newUserYearlyParams?.id === c.id ?
                                        <Space>
                                            <p>{`${c.userJob ? c.userJob.name : <FormattedMessage defaultMessage={'Without contract'} />}:`}</p>
                                            <InputNumber
                                                style={{ marginLeft: '10px' }}
                                                placeholder={intl.formatMessage({ defaultMessage: 'N° hours' })}
                                                value={this.state.newUserYearlyParams?.initial_overtime_hours}
                                                onChange={(e) => this.onChangeNumberOfHours(toFinite(e))} />
                                            <CircleButton
                                                className='__single-line-element'
                                                // loading={adminsLoading}
                                                small={true}
                                                title={intl.formatMessage({ defaultMessage: 'Save' })}
                                                icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                                onClick={this.handleOnSubmitYearlyParamsByContract} />
                                            <CircleButton
                                                className='__single-line-element'
                                                // loading={adminsLoading}
                                                small={true}
                                                title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                                icon={<FAIcon prefix='far' name='xmark' />}
                                                onClick={this.cancelEditionYearlyParams} />
                                        </Space>
                                        :
                                        <Space>
                                            <p><FormattedMessage defaultMessage={'{count, plural, one {{contract}: Start the year with {count} hour} other {{contract}: Start the year with {count} hours}}'} values={{ count: c.initial_overtime_hours, contract: c.userJob ? c.userJob.name : intl.formatMessage({ defaultMessage: 'Without contract' }) }} /></p>
                                            <FAIcon prefix='fad' name='pencil'
                                                className='__single-line-element'
                                                title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                                style={{ "cursor": "pointer", marginLeft: '10px' }}
                                                onClick={() => this.onStartEditYearlyParamsByContract(c.userJob, c.initial_overtime_hours, c.id)}
                                            />
                                        </Space>
                                }
                            </span>
                        </div>
                    );
                }));
            }


            if (isEmpty(contentToReturn)) {
                return (
                    <div className="team-create-user-body">
                        <span className="team-edit-user-item"><FormattedMessage defaultMessage={'No existing contracts for this year'} /></span>
                    </div>
                );
            }

            return contentToReturn;
        }
        return (
            <div className="team-create-user-body">
                <span className="team-edit-user-item"><FormattedMessage defaultMessage={'No existing contracts for this year'} /></span>
            </div>
        );
    }

    /**
     * Translate user payload information to CreateUserBodyRequest type values
     */
    // onStartEditYearlyParams = (c?: UserJobTMP): void => {
    //     let { currentUserYearlyParams } = this.state;
    //     currentUserYearlyParams && !(currentUserYearlyParams.year) && (currentUserYearlyParams.year = this.state.year)
    //     currentUserYearlyParams && !(currentUserYearlyParams.user) && (currentUserYearlyParams.user = this.props.user?.id)
    //     this.setState({ newUserYearlyParams: currentUserYearlyParams })
    // }

    cancelEditionYearlyParams = () => {
        this.setState({ newUserYearlyParams: undefined });
    };

    /**
    * Change a type of day title
    * @param event the triggered event
    */
    onChangeNumberOfHours = (value: number) => {
        const { newUserYearlyParams } = this.state;
        newUserYearlyParams && (newUserYearlyParams.initial_overtime_hours = value ? value : undefined);
        this.setState({ newUserYearlyParams });
    };

    renderEditionYearlyParams() {
        return (
            <div className="team-create-user-body">
                <span className="team-create-user-group">
                    <p><FormattedMessage defaultMessage={'Initial hours'} /></p>
                    <InputNumber
                        style={{ marginLeft: '10px' }}
                        placeholder={this.props.intl.formatMessage({ defaultMessage: 'N° hours' })}
                        value={this.state.newUserYearlyParams?.initial_overtime_hours}
                        onChange={(e) => this.onChangeNumberOfHours(toFinite(e))} />
                </span>
            </div>
        );
    }

    renderNormalYearlyParams(c: UserYearlyParams) {
        return (
            <div className="team-create-user-body">
                <span className="team-edit-user-item">
                    <FAIcon prefix='fad' name='clock' />
                    <p><FormattedMessage defaultMessage={'{count, plural, one {Start the year with {count} hour} other {Start the year with {count} hours}}'} values={{ count: c.initial_overtime_hours }} /></p>
                </span>
            </div>
        );
    }


    /**
     * Change the current year
     * @param date the moment date 
     */
    changeYear = (date: Moment | null | undefined) => {
        if (!date) return;
        const year = date.year();
        this.setState({ year }, () => {
            this.props.user?.id &&
                Network.getUserYearlyParams(this.props.user?.id, this.state.year).then(
                    response => this.setState({ currentUserYearlyParamsByContract: response }, () => this.getContracts()),
                    () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the initial annual data' }), "warning"),
                );
        });
    };

    render() {
        const { newUserYearlyParams } = this.state;

        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} md={{ span: 18 }} xxl={{ span: 10 }}>
                    <Card title={<FormattedMessage defaultMessage={'Initial hours'} />} icon={<FAIcon prefix='fad' name='clock' />}
                        headerElements={[
                            <DatePicker
                                key="user-hours-tab-datepicker"
                                style={{ marginRight: '10px' }}
                                disabled={newUserYearlyParams !== undefined}
                                picker="year"
                                placeholder={this.props.intl.formatMessage({ defaultMessage: 'Year' })}
                                value={moment().year(this.state.year)}
                                onChange={this.changeYear}
                                allowClear={false} />,
                        ]}>
                        {
                            this.renderYearlyParamsByContract()
                        }
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUser: (u: User) => dispatch(changeUser(u)),
});


const mapStateToProps = (state: ApplicationState) => ({
    user: state.teamManagement.user,
    company: state.user.company,
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserHoursTab));