import { Space } from "antd";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { IIntegrationDetailsData, IIntegrationSyncButtons, IIntegrationSyncType } from "../../../utils/types/integrationTypes";
import useIntegration from "../../hooks/useIntegrations";
import IntegrationSync from "../chart/integrationSync";


interface IntegrationsDetailsList {
    enabled: boolean;
    details: IIntegrationDetailsData;
    logo: string;
    name: string;
    syncs: IIntegrationSyncButtons[];
}
interface Props {
    type: IIntegrationSyncType;
}

const IntegrationsSyncButton = (props: Props) => {
    const integrations = useIntegration();

    const integrationsDetailsList: IntegrationsDetailsList[] = useMemo(() => ([
        ...(integrations.enabledIntegrations?.hotelaEnabled && integrations.hotelaDetails ? [{
            enabled: integrations.enabledIntegrations?.hotelaEnabled,
            details: integrations.hotelaDetails, daw: 'awd',
            logo: '/icons/hotela.png',
            name: 'Hotela',
            syncs: [
                ...(integrations.hotelaDetails?.sync.users ? [{
                    ...integrations.hotelaDetails.sync.users,
                    title: <FormattedMessage defaultMessage={'Sync Hotela users'} />,
                    onClick: integrations.syncHotelaUsers,
                    type: 'users' as IIntegrationSyncType
                }] : []),
            ]
        }] : []),
        ...(integrations.enabledIntegrations?.globalOfficeEnabled && integrations.globalOfficeDetails ? [{
            enabled: integrations.enabledIntegrations?.globalOfficeEnabled,
            details: integrations.globalOfficeDetails,
            logo: '/icons/globalOffice.png',
            name: 'Global Office',
            syncs: [
                ...(integrations.globalOfficeDetails?.sync.users ? [{
                    ...integrations.globalOfficeDetails.sync.users,
                    title: <FormattedMessage defaultMessage={'Sync GlobalOffice users'} />,
                    onClick: integrations.syncGlobalOfficeUsers,
                    type: 'users' as IIntegrationSyncType
                }] : []),
                ...(integrations.globalOfficeDetails?.sync.data ? [{
                    ...integrations.globalOfficeDetails.sync.data,
                    title: <FormattedMessage defaultMessage={'Sync GlobalOffice data'} />,
                    onClick: integrations.syncGlobalOfficeData,
                    type: 'data' as IIntegrationSyncType
                }] : []),
                ...(integrations.globalOfficeDetails?.sync.customers ? [{
                    ...integrations.globalOfficeDetails.sync.customers,
                    title: <FormattedMessage defaultMessage={'Sync GlobalOffice customers'} />,
                    onClick: integrations.syncGlobalOfficeCustomers,
                    type: 'customers' as IIntegrationSyncType
                }] : []),
                ...(integrations.globalOfficeDetails?.sync.mandates ? [{
                    ...integrations.globalOfficeDetails.sync.mandates,
                    title: <FormattedMessage defaultMessage={'Sync GlobalOffice mandates'} />,
                    onClick: integrations.syncGlobalOfficeMandates,
                    type: 'mandates' as IIntegrationSyncType
                }] : []),
            ],
        }] : []),
    ]), [integrations]);

    return (
        <Space>
            {integrationsDetailsList.map((integrationDetail, idx) => {
                if (!integrationDetail.enabled)
                    return null;
                const sync = integrationDetail.syncs.find(s => s.type === props.type);

                if (sync === undefined)
                    return null;

                return (
                    <IntegrationSync key={`dashboard-display-${props.type?.toLocaleLowerCase().trim()}-${idx}`} lastSync={sync.lastSync} title={sync.title} percentage={sync.percentage} onClick={sync.onClick} />
                );
            })}
        </Space>
    );

};
export default IntegrationsSyncButton;