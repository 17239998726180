import { Button, Modal, Select, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeTypesOfDayOff, loadDepartments } from '../../../../store/actions/configurations';
import { changeUsers, toggleUsersLoading } from '../../../../store/actions/teamManagement';
import { selectActiveReportUsers } from '../../../../store/selectors/usersSelectors';
import { MOMENT_FORMAT_DATE_TO_NETWORK, MOMENT_MONTH_FORMAT } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { CctSecurity } from '../../../../utils/objects/cct/cctSecurity';
import { FullUserProps, withFullName } from '../../../../utils/objects/withFullName';
import { userColumn } from '../../../../utils/tableUtils';
import { IFilterUsers, RouterProps, User, UserJobTMP } from '../../../../utils/types/generalTypes';
import { NetworkAllHolidays, NetworkCctSecurityResponse, NetworkIncreasedHoursByDay } from '../../../../utils/types/networkTypes';
import { AllHolidays, MonthlyHoursOfTheYear, MonthlyHoursOfTheYearArray, TypeOfDayOff } from '../../../../utils/types/planningTypes';
import { CcntType, CctSecurityEventsByContractByMonth, CctSecurityResponse, CctSecurityType, IncreasedHoursByDay, InitialDaysOffCctSecurity, InitialHoursByContractByYear, InitialHoursCctSecurity, MonthlyReportType } from '../../../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { alert, convertNetworkAllHolidaysToAllHolidays, convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay, convertNetworkCctSecurityResponseToCctSecurityResponse, downloadBlob, filterUsers, isNullOrEmpty, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import ActionsToolbar from '../../../common/fields/ActionsToolbar/actionsToolbar';
import AmazingDatePicker, { PickerMode } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import VirtualTable from '../../../common/general/virtualTable';
import { FilterSidebar } from '../../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';
import Filters from '../../../planningPerf/tabs/common/filters';
import DrawerCctSecurity from './drawerReportContent';
import MultipleCcntPrintPage from './multipleCcntPrintPage';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends RouterProps, IntlProps, ContainerTabsItemProps, ReduxProps, FullUserProps {
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
}

interface State {
    startMonth: Moment;
    endMonth: Moment;
    selectedUsers: number[];
    isLoading: number | undefined; //the id of the concerned user, or -1 if all
    users?: User[];
    selectedJob?: { userId: number, jobId: number | undefined; }[];
    isCcntVisible: boolean;
    isMultipleCcntVisible: boolean;
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
    multipleCcnt?: CcntType[];
    monthlyReport?: MonthlyReportType;
    monthlyHours?: MonthlyHoursOfTheYearArray;
    compressionProgression: number;
    modalDownloadMultipleVisible: boolean;
    manualForceRefreshTable: number;
    cctUser?: User;
    allHolidays?: AllHolidays[];
    cctSecurityEventsByContractByMonth?: CctSecurityEventsByContractByMonth[];
    increasedhoursByDayLoading: boolean;
    increasedhoursByDay?: IncreasedHoursByDay[];
    departmentIdSelected?: number;
    imageToOpen?: string;
    filters: IFilterUsers;
}

class MonthlyReport extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            startMonth: moment().startOf("month"),
            endMonth: moment().endOf("month"),
            selectedUsers: [],
            isLoading: undefined,
            isCcntVisible: false,
            isMultipleCcntVisible: false,
            compressionProgression: 0.0,
            modalDownloadMultipleVisible: false,
            manualForceRefreshTable: 0,
            increasedhoursByDayLoading: false,
            filters: {
                users: [],
                groups: [],
                usersToExclude: []
            },
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.props.loadDepartments();

        this.getAllHolidays();
        this.getAllIncreasedhoursByDay();

        Network.getMonthlyHours(this.state.startMonth.year()).then(
            (response: MonthlyHoursOfTheYear) => {
                const monthlyHours: MonthlyHoursOfTheYearArray = {
                    id: response.id,
                    year: response.year,
                    hours: [
                        response.janHours ? response.janHours : 0,
                        response.febHours ? response.febHours : 0,
                        response.marHours ? response.marHours : 0,
                        response.aprHours ? response.aprHours : 0,
                        response.mayHours ? response.mayHours : 0,
                        response.junHours ? response.junHours : 0,
                        response.julHours ? response.julHours : 0,
                        response.augHours ? response.augHours : 0,
                        response.sepHours ? response.sepHours : 0,
                        response.octHours ? response.octHours : 0,
                        response.novHours ? response.novHours : 0,
                        response.decHours ? response.decHours : 0,
                    ]
                };
                this.setState({ monthlyHours: monthlyHours });
            },
            (error) => {
                if (error.message.includes(this.props.intl.formatMessage({ defaultMessage: 'No monthly hours' }))) {
                    this.setState({ monthlyHours: undefined });
                } else {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                }
            },
        );
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { users } = this.state;

        if (
            prevState.isLoading !== this.state.isLoading ||
            !prevState.startMonth.isSame(this.state.startMonth, "month") ||
            !prevState.endMonth.isSame(this.state.endMonth, "month")
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }

        if (!isNullOrEmpty(this.props.users) && (!users || users.length === 0)) {
            this.updateListOfContracts();
        }

        if (!isEqual(prevProps.departments, this.props.departments) && this.props.departments.data.length > 0) {
            this.setState({ departmentIdSelected: this.props.departments.data[0].id }, this.updateListOfContracts);
        }
    }

    getExtra = () => {
        const { startMonth, endMonth, isLoading } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.MONTH}
                    disabled={isLoading !== undefined}
                    loadingData={isLoading === 0}
                    controlled={{
                        valueFrom: startMonth,
                        valueTo: endMonth,
                        onChange: this.changeMonth,
                    }}
                />
            </>
        );
    };

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                }}
                groups={{
                    selectedGroups: filters.groups,
                    usersToExclude: filters.usersToExclude,
                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                }}
            />
        );
        return [FilterSidebar(content, intl)];
    };

    resetFilters = () => this.setState({ filters: { users: [], groups: [], usersToExclude: [] } });

    getAllHolidays = () => {
        Network.getAllHolidays(this.state.startMonth.clone().startOf("year").format(MOMENT_MONTH_FORMAT), this.state.endMonth.clone().endOf("month").format(MOMENT_MONTH_FORMAT)).then(
            (response: NetworkAllHolidays[]) => {
                this.setState({ allHolidays: convertNetworkAllHolidaysToAllHolidays(response) });
            },
            () => {
                this.setState({ allHolidays: undefined });
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the public holidays' }), "error");
            }
        );
    };

    getAllIncreasedhoursByDay = () => {
        this.setState({ increasedhoursByDayLoading: true });
        Network.getAllIncreasedhoursByDay(this.state.startMonth.year()).then(
            (response: NetworkIncreasedHoursByDay[]) => {
                this.setState({ increasedhoursByDayLoading: false, increasedhoursByDay: convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay(response) });
            },
            () => {
                this.setState({ increasedhoursByDayLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the year {year}' }, { year: this.state.startMonth.year().toString() }), "warning");
            }
        );
    };

    updateListOfContracts = () => {
        const users = cloneDeep(this.props.users);

        const selectedJob: { userId: number, jobId: number | undefined; }[] = [];
        users?.forEach((u: User) => {
            const contracts = cloneDeep(u.job);

            if (contracts && contracts.length > 0) {
                u.job = this.contractsAvailableBetweenMonths(contracts);
                selectedJob.push({
                    userId: u.id,
                    jobId: u.job[0]?.id
                });
            }
        });

        this.setState({ users, selectedJob });
    };

    contractsAvailableBetweenMonths = (user_contracts: Array<UserJobTMP>) => {
        const { startMonth, endMonth } = this.state;
        let contracts = user_contracts.sort((a, b) => {
            const dateA = moment(a.date_in_report);
            const dateB = moment(b.date_in_report);
            return dateB.diff(dateA);
        });

        contracts = contracts.filter((c) => !(endMonth.isBefore(moment(c.date_in_report), "month") || startMonth.isAfter(moment(c.contract_expiry_date), "month")));
        if (this.props.departments.data.length > 0) {
            contracts = contracts.filter(c => c.department?.id === this.state.departmentIdSelected);
        }
        return contracts;
    };

    getInitialOvertimeHoursByUser = (userId: number, initialHours: InitialHoursCctSecurity[]) => {
        return initialHours?.filter(ih => ih.userId === userId).map(ih => {
            const initialHours: InitialHoursByContractByYear = {
                id: ih.id,
                initialHours: ih.initialOvertimeHours,
                year: ih.year,
                contractId: ih.contractId ? ih.contractId : -1
            };
            return initialHours;
        });
    };

    getInitialDaysOffByUser = (userId: number, initialDaysOff: InitialDaysOffCctSecurity[]) => {
        return initialDaysOff?.filter(ih => ih.userId === userId);
    };

    parseCctSecurityResponse = (cctSecurityResponse: CctSecurityResponse, mutiple: boolean, startMonth: Moment, endMonth: Moment): (undefined | CctSecurityEventsByContractByMonth[]) => {
        const { increasedhoursByDay } = this.state;
        const initialHours: InitialHoursByContractByYear[] = this.getInitialOvertimeHoursByUser(cctSecurityResponse.data[0].id, cctSecurityResponse.initialHours);
        const initialDaysOff: InitialDaysOffCctSecurity[] = this.getInitialDaysOffByUser(cctSecurityResponse.data[0].id, cctSecurityResponse.initialDaysOff);
        const allHolidays: AllHolidays[] = this.state.allHolidays ? cloneDeep(this.state.allHolidays) : [];
        if (mutiple) {
            // TODO
            showNotification("TODO", "error");
            return;
        } else {
            if (cctSecurityResponse.data.length === 1) {
                const userEventsData = new CctSecurity(cctSecurityResponse.data[0].events, {}, cctSecurityResponse.data[0].contracts);
                const contractsEvents = userEventsData.initCctSecurity(startMonth.clone().startOf("year"), endMonth.clone(), increasedhoursByDay ? increasedhoursByDay : [], allHolidays, this.state.monthlyHours, initialHours, initialDaysOff, this.props.typesOfDayOff);
                if (contractsEvents === undefined) {
                    showNotification("Problem dev", "error");
                    return;
                }

                return contractsEvents;
            }
        }
    };

    generateReports = (user?: User) => {
        let userIds: number[] | undefined = [];

        //if a userId is specified, create an array with one id 
        if (user) {
            userIds.push(user.id);
            this.setState({ isLoading: user.id });
        }
        // else use the selected users array
        else {
            userIds = this.state.selectedUsers;
            this.setState({ isLoading: -1 });
        }
        const { selectedUsers } = this.state;
        if (selectedUsers.length > 0) {
            const multipleCcnt: CcntType[] = [];
            let departmenId: number | undefined = this.props.departments.data.length > 0 ? 0 : -1;
            if (departmenId === 0) departmenId = this.state.departmentIdSelected;
            Network.generateReportsCcntAccounting(this.state.startMonth.format(MOMENT_FORMAT_DATE_TO_NETWORK), this.state.endMonth.format(MOMENT_FORMAT_DATE_TO_NETWORK), userIds, departmenId).then(
                (networkCctSecurityResponse: NetworkCctSecurityResponse) => {
                    const cctSecurityResponse = convertNetworkCctSecurityResponseToCctSecurityResponse(networkCctSecurityResponse);

                    const test = this.parseCctSecurityResponse(cctSecurityResponse, true, this.state.startMonth, this.state.endMonth);
                    if (test) {
                        this.setState({ isLoading: undefined, selectedUsers: [], multipleCcnt, isMultipleCcntVisible: true });
                    }
                },
                () => {
                    this.setState({ isLoading: undefined, isMultipleCcntVisible: true });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the report n°{user}' }, { user: user?.id }), "warning");
                }
            );
        } else {
            // TODO Temporary => to remove
            this.setState({ cctUser: user });
            this.setState({ isCcntVisible: true });
            let departmenId: number | undefined = this.props.departments.data.length > 0 ? 0 : -1;
            if (departmenId === 0) departmenId = this.state.departmentIdSelected;
            Network.generateReportsCcntAccounting(this.state.startMonth.format(MOMENT_FORMAT_DATE_TO_NETWORK), this.state.endMonth.format(MOMENT_FORMAT_DATE_TO_NETWORK), userIds, departmenId).then(
                (networkCctSecurityResponse: NetworkCctSecurityResponse) => {
                    const cctSecurityResponse = convertNetworkCctSecurityResponseToCctSecurityResponse(networkCctSecurityResponse);
                    const cctSecurityEventsByContractByMonth = this.parseCctSecurityResponse(cctSecurityResponse, false, this.state.startMonth, this.state.endMonth);

                    if (cctSecurityEventsByContractByMonth) {
                        this.setState({ isLoading: undefined, selectedUsers: [], cctSecurityEventsByContractByMonth });
                    }
                },
                () => {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                    this.setState({ isLoading: undefined });
                }
            );
        }
    };

    generateCompanyReport = () => {
        this.setState({ isLoading: -2 });
        // generate reports
        Network.generateCompanyReport(this.state.startMonth.format(MOMENT_FORMAT_DATE_TO_NETWORK)).then(
            blob => {
                downloadBlob(blob, `company_${this.state.startMonth.format("YYYY-MM")}.xlsx`);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ isLoading: undefined });
            }
        );
    };

    changeMonth = (from: Moment | null, to: Moment | null) => {
        if (from === null || to === null) return;

        const startMonth = from.clone();
        const endMonth = to.clone();

        this.setState({ startMonth, endMonth, ccnt: undefined, multipleCcnt: undefined, isCcntVisible: false, isMultipleCcntVisible: false }, () => {
            this.updateListOfContracts();
            this.getAllHolidays();

            Network.getMonthlyHours(this.state.startMonth.year()).then(
                (response: MonthlyHoursOfTheYear) => {
                    const monthlyHours: MonthlyHoursOfTheYearArray = {
                        id: response.id,
                        year: response.year,
                        hours: [
                            response.janHours ? response.janHours : 0,
                            response.febHours ? response.febHours : 0,
                            response.marHours ? response.marHours : 0,
                            response.aprHours ? response.aprHours : 0,
                            response.mayHours ? response.mayHours : 0,
                            response.junHours ? response.junHours : 0,
                            response.julHours ? response.julHours : 0,
                            response.augHours ? response.augHours : 0,
                            response.sepHours ? response.sepHours : 0,
                            response.octHours ? response.octHours : 0,
                            response.novHours ? response.novHours : 0,
                            response.decHours ? response.decHours : 0,
                        ]
                    };
                    this.setState({ monthlyHours: monthlyHours });
                },
                (error) => {
                    if (error.message.includes("No monthly hours")) {
                        this.setState({ monthlyHours: undefined });
                    } else {
                        alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                    }
                },
            );
        });
    };

    usersOnRow = (record: User) => ({
        onClick: () => this.generateReports(record)
    });

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    columns = (): ColumnsType<User> => [
        ...userColumn(this.props.intl, this.props.getFullName, this.props.isSmartphone),
        {
            title: <FormattedMessage defaultMessage={'Dates'} />,
            key: "dates",
            className: '__min-width-250',
            render: (record: User) => {
                const { selectedJob } = this.state;
                const selectValue = selectedJob?.find(e => e.userId === record.id);
                const job = record.job?.find(e => e.id === selectValue?.jobId);

                if (job) return <span><FormattedMessage defaultMessage={'Contract from {start} to {end}'} values={{ start: moment(job.date_in_report, MOMENT_FORMAT_DATE_TO_NETWORK).format(getFormat('DATE')), end: moment(job.contract_expiry_date, MOMENT_FORMAT_DATE_TO_NETWORK).format(getFormat('DATE')) }} /></span>;
                else return <span><FormattedMessage defaultMessage={'No contract selected'} /></span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Contract'} />,
            key: 'contract',
            className: '__width_280',
            fixed: this.props.isSmartphone ? undefined : "right",
            render: (record: User) => {
                if (record.job && record.job.length > 0) {
                    const { selectedJob } = this.state;
                    const selectValue = selectedJob?.find(e => e.userId === record.id);
                    return (
                        <Select
                            style={{ width: '250px' }}
                            value={selectValue?.jobId}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                            onChange={(e) => {
                                selectValue && (selectValue.jobId = e);
                                this.setState({ selectedJob: [...(selectedJob ?? []).filter((j) => j.userId !== record.id), { userId: record.id, jobId: e }] });
                            }}
                        >
                            {record.job.map(contract => (
                                <Select.Option
                                    key={`contract-${contract.id}`}
                                    value={contract.id!}>
                                    {contract.name ? contract.name : <FormattedMessage defaultMessage={'Unamed contract'} />}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                } else return <span><FormattedMessage defaultMessage={'No contract available'} /></span>;
            }
        }
    ];

    render() {
        const { selectedUsers, multipleCcnt, isCcntVisible, isMultipleCcntVisible, isLoading, filters, users } = this.state;
        const { isSmartphone, intl, usersLoading, groups, height } = this.props;
        let tableHeight = height - 135;
        if (tableHeight < 250) tableHeight = 250;

        const filteredUsers = filterUsers(users, groups, filters);

        return (
            <>
                <Spin spinning={usersLoading} indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                    <div>
                        <VirtualTable
                            className="__basic-table"
                            dataSource={filteredUsers}
                            columns={this.columns()}
                            rowKey={(u: User) => u.id}
                            // rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }}
                            onRow={this.usersOnRow}
                            scroll={{ x: true, y: tableHeight }}

                        />
                        <ActionsToolbar
                            title={`${selectedUsers.length.toString()} ${intl.formatMessage({ defaultMessage: "Users" })}`}
                            open={selectedUsers.length > 0}
                            close={() => this.setState({ selectedUsers: [] })}
                            actions={
                                <CircleButton
                                    style={isSmartphone ? { width: '100%' } : {}}
                                    type="primary"
                                    icon={<FAIcon prefix='fad' name='download' />}
                                    onClick={() => this.generateReports()}
                                    loading={this.state.isLoading === -1}
                                    title={intl.formatMessage({ defaultMessage: '{count, plural, one {Download {count} report} other {Download {count} reports}}' }, { count: this.state.selectedUsers.length })}
                                />
                            }
                        />
                    </div>
                </Spin>
                {
                    isMultipleCcntVisible &&
                    <MultipleCcntPrintPage multipleCcnt={multipleCcnt} close={() => this.setState({ isMultipleCcntVisible: false, multipleCcnt: undefined, modalDownloadMultipleVisible: false })} year={this.state.startMonth} />
                }
                <DrawerCctSecurity
                    key={`drawer-cct-security-${isCcntVisible}`}
                    startMonth={this.state.startMonth}
                    endMonth={this.state.endMonth}
                    user={this.state.cctUser}
                    isLoading={Boolean(isLoading)}
                    cctSecurity={this.state.cctSecurityEventsByContractByMonth}
                    selectedJobs={this.state.selectedJob ?? []}
                    isVisible={isCcntVisible}
                    close={() => this.setState({ isCcntVisible: false })}
                />
                <Modal
                    onCancel={() => this.setState({ modalDownloadMultipleVisible: false, selectedUsers: [] })}
                    title={<FormattedMessage defaultMessage={'Download the users N-CLA report'} />}
                    footer={[
                        <Button type="dashed" onClick={() => this.setState({ modalDownloadMultipleVisible: false, selectedUsers: [] })} loading={Boolean(this.state.isLoading)} key="multiple-ccnt--cancel">
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button type="primary" onClick={() => this.generateReports()} loading={Boolean(this.state.isLoading)} key="multiple-ccnt-download">
                            <FormattedMessage defaultMessage={'Download'} />
                        </Button>
                    ]}
                    visible={this.state.modalDownloadMultipleVisible}
                >
                    <Select
                        // disabled={this.props.loading}
                        mode="multiple"
                        maxTagCount="responsive"
                        allowClear
                        placeholder={<><FormattedMessage defaultMessage={'Users'} />{':'}</>}
                        style={{ width: '100%' }}
                        onChange={(e) => this.setState({ selectedUsers: e })}
                        value={this.state.selectedUsers}
                        filterOption={true}
                        optionFilterProp="label"
                        showArrow
                    >
                        {
                            this.props.users?.filter(u => u.active === true && u.report === true).map((u) => {
                                return <Select.Option label={u.first_name + " " + u.last_name} value={u.id} key={"users-select" + u.id}>{u.first_name + " " + u.last_name}</Select.Option>;
                            })
                        }
                    </Select>
                </Modal>
                {
                    this.state.imageToOpen !== undefined ?
                        <Lightbox
                            mainSrc={this.state.imageToOpen}
                            onCloseRequest={() => this.setState({ imageToOpen: undefined })}
                        />
                        : null
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    toggleUsersLoading: (b: boolean) => dispatch(toggleUsersLoading(b)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
    changeTypesOfDayOff: (t: TypeOfDayOff[]) => dispatch(changeTypesOfDayOff(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
    currentUser: state.user.currentUser,
    users: selectActiveReportUsers(state),
    groups: state.teamManagement.groups,
    company: state.user.company,
    usersLoading: state.teamManagement.usersLoading,
    width: state.window.width,
    departments: state.configurations.departments,
    typesOfDayOff: state.configurations.typesOfDayOff,
});


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(withFullName(MonthlyReport))));