import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { changeCompany, getCurrentUser } from '../../store/actions/user';
import Network from '../../utils/network';
import { Company, RouterProps, User } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { colorIsBright, showNotification } from '../../utils/utils';
import Availabilities from '../app/user/availabilities';
import ExternalNotFound from './externalNotFound';

import { captureException, captureMessage, setTag } from "@sentry/react";
import Cookie from 'js-cookie';
import PlanningStatistics from '../stats/planningStatisticsV2';

export interface AppTheme {
    backgroundColor: string;
    backgroundAccent: string;
    color: string;
    company: string;
    button: string;
    primary?: string;
    success?: string;
}

const themeDefault: AppTheme = {
    backgroundColor: "#ffffff",
    backgroundAccent: '#f8f8f8',
    color: "#000000",
    company: '#ff8d00',
    button: '#ff8d00',
}
// Dark mode colors
// const themeDefault: AppTheme = {
//     backgroundColor: "#212121",
//     backgroundAccent: "#303030",
//     color: "#ffffff",
//     company: "#ff8d00",
//     button: "#ff8d00"
// }


type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends RouterProps, PropsFromRedux {
    currentUser: User | undefined;
    company: Company | undefined;
}

interface State {
    theme: AppTheme;
}

/**
 * Component that indicate wich address correspond to wich component
 */
class ExternalRouteSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let theme = themeDefault;

        const themeCookie = Cookie.get("theme");
        if (themeCookie && themeCookie.length > 0 && themeCookie !== 'undefined') {
            try {
                theme = { ...JSON.parse(themeCookie) as AppTheme };
            } catch (e) {
                captureException(e);
                captureMessage(String(themeCookie))
            }
        }

        this.state = { theme };

        document.body.style.backgroundColor = theme.backgroundColor;
        document.body.style.color = theme.color;

        const isDarkMode = theme && !colorIsBright(theme.backgroundColor);
        document.body.className = isDarkMode ? "__dark" : '';
    }

    componentDidMount() {
        //check if connected, otherwise go to login page
        const userId = Cookie.get("userId");
        setTag("a_userId", userId);

        if (!userId) {
            //redirect to login it not connected (except for reset-password)
            // this.props.history.push(`/${this.props.match.params.lang ? this.props.match.params.lang : 'fr'}/app/notFound`);
            return;
        } else {
            //load user details
            if (!this.props.currentUser) {
                this.props.getCurrentUser!();
            }

            //load company
            if (!this.props.company) {
                Network.getCompanyDetails().then(
                    response => this.props.changeCompany(response),
                    () => {
                        try {
                            throw new Error(`Could not get company with userId: ${String(userId)}`)
                        }
                        catch (e) {
                            captureException(e);
                        }
                        showNotification("Un problème est survenu pendant le chargement de la société.", "warning")
                    },
                )
            }
        }
    }

    render() {
        const userId = Cookie.get("userId");
        const { theme } = this.state;

        return (
            <div className='__container-external-app'>
                {
                    userId ?
                        <Switch>
                            <Route exact path="/:lang(en|fr|de)/app/user/availabilities" render={(props) => <Availabilities {...props as RouterProps} theme={theme} user={this.props.currentUser} />} />
                            <Route exact path="/:lang(en|fr|de)/app/planning-statistics" render={(props) => <PlanningStatistics {...props as RouterProps} theme={theme} />} />
                            <Route path="/:lang(en|fr|de)/:notFound" render={(props) => <ExternalNotFound {...props as RouterProps} theme={theme} />} />
                        </Switch>
                        :
                        <Switch>
                            <Route path="/:lang(en|fr|de)/:notFound" component={ExternalNotFound} />
                        </Switch>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
    changeCompany: (c: Company) => dispatch(changeCompany(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    company: state.user.company,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ExternalRouteSelector);